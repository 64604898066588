<template>
  <VueDatePicker
    locale="fr"
    range
    multi-calendars
    v-model="selectedDate"
    @update:modelValue="selectDate"
    class="shadow border rounded-md focus:outline-2 outline-sky-300"
  />
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "DatepickerVue",
  components: {
    VueDatePicker,
  },
  data() {
    return {
      selectedDate: [],
    };
  },
  methods: {
    selectDate() {
      if (!this.selectedDate) {
        this.$emit("selectDate", { startDate: null, endDate: null });
      } else {
        const [startDate, endDate] = this.selectedDate;
        this.$emit("selectDate", { startDate, endDate });
      }
    },
  },
};
</script>
