<template>
  <!-- Back Button -->
  <!-- <div class="flex justify-between">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/detailClient'" />
    </div>
  </div> -->
  <div class="flex justify-between items-center space-x-14 mb-10">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/detailClient'" />
    </div>

    <div class="flex space-x-14">
      <div
        class="flex"
        v-if="
          this.user.accountType == 'Administrateur' &&
          this.clientChoosedTab.archived == false
        "
      >
        <button
          class="flex w-fit mt-6 px-2 py-1 h-fit text-blue-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
          @click="showArchive = !showArchive"
        >
          <span class="material-icons-outlined m-auto">archive</span>
          <span class="m-auto">Archiver</span>
        </button>
        <div
          v-if="showArchive"
          @click="showArchive = false"
          class="absolute w-screen h-screen z-10 top-0 left-0"
        ></div>
        <div
          v-if="showArchive"
          class="absolute mt-6 bg-gray-200 p-2 rounded shadow-lg z-10 overflow-hidden"
        >
          <div class="flex flex-col justify-center space-x-4">
            <span>Êtes-vous sûr de vouloir archiver ?</span>
            <div class="flex space-x-4 justify-center">
              <button
                class="w-fit px-2 py-1 h-fit text-blue-600 rounded-md bg-gray-100 hover:text-xl hover:bg-white"
                @click="archiveClient()"
              >
                Oui
              </button>
              <button
                class="w-fit px-2 py-1 h-fit rounded-md hover:text-xl hover:bg-white"
                @click="showArchive = false"
              >
                Non
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex"
        v-if="
          this.user.accountType == 'Administrateur' &&
          this.clientChoosedTab.archived == true
        "
      >
        <button
          class="flex w-fit px-2 py-1 h-fit text-blue-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
          @click="showArchive = !showArchive"
        >
          <span class="material-icons-outlined m-auto">unarchive</span>
          <span class="m-auto">Désarchiver</span>
        </button>
        <div
          v-if="showArchive"
          @click="showArchive = false"
          class="absolute w-screen h-screen z-10 top-0 left-0"
        ></div>
        <div
          v-if="showArchive"
          class="absolute mt-6 bg-gray-200 p-2 rounded shadow-lg z-10 overflow-hidden"
        >
          <div class="flex flex-col justify-center space-x-4">
            <span>Êtes-vous sûr de vouloir désarchiver ?</span>
            <div class="flex space-x-4 justify-center">
              <button
                class="w-fit px-2 py-1 h-fit text-blue-600 rounded-md bg-gray-100 hover:text-xl hover:bg-white"
                @click="unarchiveClient()"
              >
                Oui
              </button>
              <button
                class="w-fit px-2 py-1 h-fit rounded-md hover:text-xl hover:bg-white"
                @click="showArchive = false"
              >
                Non
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex" v-if="this.user.accountType == 'Administrateur'">
        <button
          class="flex w-fit mt-6 px-2 py-1 h-fit text-red-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
          @click="showDelete = !showDelete"
        >
          <span class="material-icons-outlined m-auto">delete</span>
          <span class="m-auto">Supprimer le client</span>
        </button>
        <div
          v-if="showDelete"
          @click="showDelete = false"
          class="absolute w-screen h-screen z-10 top-0 left-0"
        ></div>
        <div
          v-if="showDelete"
          class="absolute mt-6 bg-gray-200 p-2 rounded shadow-lg z-10 overflow-hidden"
        >
          <div class="flex flex-col justify-center space-x-4">
            <span>Êtes-vous sûr de vouloir supprimer ?</span>
            <div class="flex space-x-4 justify-center">
              <button
                class="w-fit px-2 py-1 h-fit text-red-600 rounded-md bg-gray-100 hover:text-xl hover:bg-white"
                @click="deleteClient()"
              >
                Oui
              </button>
              <button
                class="w-fit px-2 py-1 h-fit rounded-md hover:text-xl hover:bg-white"
                @click="showDelete = false"
              >
                Non
              </button>
            </div>
          </div>
        </div>
      </div>

      <menuDetailClient />
    </div>
  </div>

  <div class="drop-shadow-md border">
    <h2
      class="border-b border-gray-200 bg-gray-50 drop-shadow-md text-md font-bold leading-4 uppercase p-3 pl-6 text-gray-700"
    >
      Liste des bons de livraison
    </h2>
    <div class="bg-white overflow-y-scroll" style="height: 30vh">
      <table class="w-full">
        <thead class="sticky top-0">
          <tr class="drop-shadow-md">
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              RÉF. CLIENT
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              DATE
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              NUMÉRO
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              CLIENT
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              PRIX
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              PDF
            </th>
          </tr>
        </thead>
        <tbody class="" v-for="(p, Client) in allBdlByClient" :key="Client">
          <tr>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ p.Note }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ getFRDate(p.Date) }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ p.Numero }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ p.Client }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ p.TotalPrice }} €
            </td>
            <td
              class="px-6 text-left border-b border-gray-200 whitespace-nowrap"
            >
              <span
                title="télécharger le PDF"
                @click="createPDF(p)"
                class="text-left border-b border-gray-200 whitespace-nowrap material-icons-round rounded-full cursor-pointer shadow-md p-2"
                >download</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="px-6 w-full py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase border-t border-gray-200 bg-gray-50 drop-shadow-md"
    >
      Total des bons de livraison:
      <strong class="text-xl">{{ financial(setTotalBdl) }} € </strong>
    </div>
  </div>
  <div class="drop-shadow-md border mt-6">
    <h2
      class="border-b border-gray-200 bg-gray-50 drop-shadow-md text-md font-bold leading-4 uppercase p-3 pl-6 text-gray-700"
    >
      Liste des factures
    </h2>
    <div class="bg-white overflow-y-scroll" style="height: 30vh">
      <table class="w-full">
        <thead class="sticky top-0">
          <tr class="drop-shadow-md">
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              RÉF. CLIENT
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              DATE
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              NUMÉRO
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              CLIENT
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              PRIX
            </th>
            <th
              class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              PDF
            </th>
          </tr>
        </thead>
        <tbody class="" v-for="(p, Client) in allFactureByClient" :key="Client">
          <tr>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ p.Note }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ getFRDate(p.Date) }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ p.Numero }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ p.Client }}
            </td>
            <td
              class="px-6 w-80 py-4 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ p.TotalPrice }} €
            </td>
            <td
              class="px-6 text-left border-b border-gray-200 whitespace-nowrap"
            >
              <span
                title="télécharger le PDF"
                @click="createPDF1(p)"
                class="text-left border-b border-gray-200 whitespace-nowrap material-icons-round rounded-full cursor-pointer shadow-md p-2"
                >download</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="px-6 w-full py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase border-t border-gray-200 bg-gray-50 drop-shadow-md"
    >
      Total des factures:
      <strong class="text-xl">{{ financial(setTotalFacture) }} € </strong>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import menuDetailClient from "../components/Client/menuDetailClient.vue";
import { createBdl } from "./../hooks/pdf/chiffresBdl.js";
import { createlivraison } from "@/hooks/pdf/livraison";
import { getFrDate } from "@/hooks/tools/date.ts";
import backButton from "@/components/Button/back.vue";

export default {
  data() {
    return {
      allBdlByClient: [],
      allFactureByClient: [],
    };
  },
  computed: {
    ...mapGetters(["user", "clientChoosedTab"]),
    setTotalBdl() {
      let totalPrice = 0;
      this.allBdlByClient.forEach((element, index) => {
        totalPrice += element.TotalPrice;
      });
      return totalPrice;
    },
    setTotalFacture() {
      let totalPrice = 0;
      this.allFactureByClient.forEach((element, index) => {
        totalPrice += element.TotalPrice;
      });
      return totalPrice;
    },
  },
  components: {
    menuDetailClient,
    backButton,
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    back() {
      this.$router.back();
    },
    getBdlByClient() {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getBdlByClient`, {
          plateform: this.user.proprietaire,
          client: this.clientChoosedTab.Name,
        })
        .then((response) => {
          this.allBdlByClient = response.data;
          console.log(this.allBdlByClient);
        });
    },
    getFactureByClient() {
      axios
        .post(`${process.env.VUE_APP_API}/facture/getFactureByClient`, {
          plateform: this.user.proprietaire,
          client: this.clientChoosedTab.Name,
        })
        .then((response) => {
          this.allFactureByClient = response.data;
          console.log(this.allFactureByClient);
        });
    },
    createPDF(objet) {
      var ref = [];
      for (const refA of this.allBdlByClient) {
        ref.push([
          refA.Note,
          refA.Date,
          refA.Numero,
          refA.Client,
          parseFloat(refA.TotalPrice).toFixed(2).toString() + " €",
        ]);
        createlivraison(objet, false, false);
        // createBdl(ref);
      }
    },
    createPDF1(objet) {
      var ref = [];
      for (const refA of this.allFactureByClient) {
        ref.push([
          refA.Note,
          refA.Date,
          refA.Numero,
          refA.Client,
          parseFloat(refA.TotalPrice).toFixed(2).toString() + " €",
        ]);
        createlivraison(objet, false, false);
        // createBdl(ref);
      }
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  mounted() {
    this.getBdlByClient();
    this.getFactureByClient();
  },
};
</script>

<style scoped></style>
