<template>
  <div class="flex flex-col space-y-4">
    <div class="flex justify-between mb-10">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/configurationCatalogueHub'" />
    </div>
    <titrePages
      class="flex flex-1 justify-center"
      :title="'Configuration du catalogue universel'"
    />
  </div>
    <div class="flex justify-center"><menuConfiguration /></div>
    <div class="flex flex-col space-y-2">
      <div class="flex flex-row space-x-2 mt-4 items-center">
        <div class="w-1/5">
          <VueDatePicker
            v-model="date"
            locale="fr"
            cancelText="Annuler"
            select-text="Choisir"
            range
          />
        </div>
        <div class="w-fit">
          <select
            class="p-2 rounded border border-gray-300 text-gray-400"
            v-model="selectedFilter"
            @change="getAlertes(currentPage, pageNext)"
          >
            <option value="Remise">Remise</option>
            <option value="Marge">Marge</option>
          </select>
        </div>
        <div class="w-fit">
          <button
            @click="getAlertes(currentPage, pageNext)"
            class="p-1 rounded bg-blue-500 text-white shadow"
          >
            Rechercher
          </button>
        </div>
      </div>
      <div class="shadow-md sm:rounded-lg w-full">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-white uppercase bg-bluevk">
            <tr>
              <th scope="col" class="px-6 py-3">Type</th>

              <th scope="col" class="px-6 py-3">Document</th>

              <th scope="col" class="px-6 py-3">Numéro</th>
              <th scope="col" class="px-6 py-3">Ref.</th>
              <th scope="col" class="px-6 py-3">Utilisateur</th>
              <th
                v-if="selectedFilter == 'Remise'"
                scope="col"
                class="px-6 py-3"
              >
                Explication
              </th>
              <th scope="col" class="px-6 py-3">Mouvement</th>
              <th scope="col" class="px-6 py-3">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white border-b hover:bg-orangevk hover:text-white cursor-pointer"
              v-for="(alerte, index) in alertes"
              :key="index"
            >
              <td class="px-6 py-4">
                <div v-if="alerte.type == 'marge'">Alerte Marge</div>
                <div v-else-if="alerte.type == 'discount'">Alerte Remise</div>
              </td>
              <td class="px-6 py-4">{{ alerte.documentType }}</td>
              <td class="px-6 py-4">{{ alerte.documentNumber }}</td>
              <td class="px-6 py-4">
                {{ alerte.article.Ref_fournisseur }} ({{
                  alerte.article.Code_marque
                }}
                - {{ alerte.article.Prefixe_tarif }})
              </td>
              <td class="px-6 py-4">{{ alerte.created_by }}</td>
              <td v-if="alerte.type == 'discount'" class="px-6 py-4">
                {{ alerte.reason }}
              </td>
              <td class="px-6 py-4 flex items-center">
                <div v-if="alerte.type == 'marge'">
                  min {{ alerte.minMarge }}% -> {{ alerte.Marge }}%
                </div>
                <div v-else-if="alerte.type == 'discount'">
                  {{ alerte.initialDiscount }}% -> {{ alerte.discount }}%
                </div>
              </td>
              <td class="px-6 py-4">{{ getFRDate(alerte.createdAt) }}</td>
            </tr>
          </tbody>
        </table>

        <pageNumber
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="alertesLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(alertesLength / 10)"
          :clickFunction="getAlertes"
          :currentPage="currentPage"
          :typeList="'alertes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import pageNumber from "@/components/pageNumber.vue";
import backButton from "@/components/Button/back.vue";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { getFrDate } from "@/hooks/tools/date.ts";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  data() {
    return {
      date: [
        new Date(new Date().setDate(new Date().getDate() - 30)),
        new Date(),
      ],
      selectedFilter: "Marge",
      alertes: [],

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      alertesLength: 0,
    };
  },
  components: {
    backButton,
    menuConfiguration,
    VueDatePicker,
    pageNumber,
    titrePages,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    async getAlertes(skip, limit) {
      this.currentPage = skip;
      const response = await axios.post(
        `${process.env.VUE_APP_API}/article/getAlertes`,
        {
          plateform: this.user.proprietaire,
          skip: (skip - 1) * 10,
          limit: limit,
          date: this.date,
          filter: this.selectedFilter,
        }
      );
      this.alertes = response.data.alertes;
      this.alertesLength = response.data.count;
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getAlertes(1, 10);
    this.pageSwitch();
  },
};
</script>
<style></style>
