<template>
  <router-link to="/setarticles">
    <div
      id="retour"
      class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    >
      <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
      <span>Retour</span>
    </div>
  </router-link>

  <div class="flex w-full mt-6">
    <table class="w-full text-sm text-left text-gray-500">
      <thead class="text-xs text-white uppercase bg-bluevk">
        <tr>
          <th scope="col" class="px-6 py-3">N° BL</th>
          <th scope="col" class="px-6 py-3">Date</th>
          <th scope="col" class="px-6 py-3">Client</th>
          <th scope="col" class="px-6 py-3">Quantité</th>
          <th scope="col" class="px-6 py-3">Prix</th>
          <th scope="col" class="px-6 py-3">Prix de vente</th>
          <th scope="col" class="px-6 py-3">
            Marge (seuil
            <input
              type="number"
              class="w-12 text-black"
              v-model="seuil_pourcent"
            />
            %)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b hover:bg-orangevk hover:text-white cursor-pointer"
          v-for="(ma, indexMarge) in margeTab"
          :key="indexMarge"
        >
          <td class="px-6 py-4">{{ ma.Numero }}</td>

          <td class="px-6 py-4">{{ ma.Date }}</td>

          <td class="px-6 py-4">
            {{ ma.Client }}
          </td>
          <td class="px-6 py-4">{{ ma.quantity }}</td>
          <td class="px-6 py-4">{{ ma.Prix_euro }} €</td>
          <td class="px-6 py-4">
            <div v-if="ma.Prix_vente">
              <div
                v-if="ma.PrixFournisseurType == 'prixnet'"
                class="flex flex-col"
              >
                <span
                  >Prix de vente :
                  {{ parseFloat(ma.Prix_vente).toFixed(2) }} €</span
                >
                <span
                  >Prix d'achat :
                  {{ parseFloat(ma.PrixAchat).toFixed(2) }} €</span
                >
              </div>
              <div v-else class="flex flex-col">
                <span
                  >Prix de vente :
                  {{
                    parseFloat(ma.Prix_euro * (1 - ma.remise / 100)).toFixed(2)
                  }}
                  €</span
                >
                <span
                  >Prix d'achat :
                  {{ parseFloat(ma.PrixAchat).toFixed(2) }} €</span
                >
              </div>
            </div>
            <div
              v-else
              class="p-1 bg-orange-400 text-white rounded shadow w-fit"
            >
              ?
            </div>
          </td>
          <td class="px-6 py-4">
            <div
              class="flex flex-col p-1 bg-red-500 text-white rounded shadow w-fit"
              v-if="ma.marge_pourcent < seuil_pourcent"
            >
              <div>{{ ma.marge_euro }} €</div>
              <div>{{ ma.marge_pourcent }} %</div>
            </div>
            <div
              v-else
              class="flex flex-col p-1 bg-green-500 text-white rounded shadow w-fit"
            >
              <div>{{ ma.marge_euro }} €</div>
              <div>{{ ma.marge_pourcent }} %</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { margeBdl } from "@/hooks/statistiques/ventes.ts";

export default {
  name: "StockArticle",
  computed: {
    ...mapGetters(["article", "user"]),
  },
  data() {
    return {
      margeTab: [],
      seuil_pourcent: 30,
    };
  },
  methods: {
  },
  async mounted() {
    this.margeTab = await margeBdl(
      this.user.proprietaire,
      this.article.Ref_fournisseur,
      this.article.Prefixe_tarif,
      this.article.Code_EAN
    );
  },
};
</script>

<style scoped></style>
