<template>
  <div>
    <div
      v-if="infoInCmd"
      :class="`modal ${
        !infoInCmd && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="infoInCmd = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 70rem"
        class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Récapitulatif du bon de commande</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="infoInCmd = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-1/3 ml-2">
              Référence client:
              {{ detailCommande.Note }}
            </div>

            <div class="flex flex-col my-auto items-center ml-auto mx-10">
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="createPDF(detailCommande)"
              >
                download
              </span>
              <div>BC n° {{ detailCommande.Numero }}</div>
            </div>
          </div>

          <table v-if="detailCommande.groupedTab" class="min-w-full mt-4">
            <div class="overflow-y-auto max-h-[25rem]">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody
                v-if="detailCommande.groupedTab.length > 0"
                class="bg-white w-fit"
              >
                <div
                  v-for="(cmd, ind) in detailPdf"
                  :key="ind"
                  class="tableHEADTR"
                >
                  <br />
                  <div class="bg-gray-100">BC n°{{ cmd.Numero }}</div>

                  <tr
                    v-for="(r, index) in detailCommande.groupedTab[ind].pdfData"
                    :key="index"
                    class="tableHEADTR"
                  >
                    <td
                      class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center">
                        <span class="ml-4 text-sm leading-5 text-gray-900">
                          {{ r.ref }}
                        </span>
                      </div>
                    </td>

                    <td
                      class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5 text-gray-500">
                        {{ r.description }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="text-sm leading-5">
                        {{ r.quantite }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{
                        parseFloat(
                          detailCommande.groupedTab[ind].Articles[index]
                            .Prix_euro
                        ).toFixed(2)
                      }}
                      €
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        v-if="
                          detailCommande.groupedTab[ind].Articles[index]
                            .PrixFournisseurType != 'prixnet'
                        "
                      >
                        {{ r.remise }} %
                      </div>
                      <div v-else>prix net</div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        v-if="
                          detailCommande.groupedTab[ind].Articles[index]
                            .PrixFournisseurType != 'prixnet'
                        "
                      >
                        {{
                          parseFloat(
                            detailCommande.groupedTab[ind].Articles[index]
                              .Prix_euro -
                              detailCommande.groupedTab[ind].Articles[index]
                                .Prix_euro *
                                (r.remise / 100)
                          ).toFixed(2)
                        }}
                        €
                      </div>

                      <div v-else>
                        {{
                          parseFloat(
                            detailCommande.groupedTab[ind].Articles[index]
                              .Prix_vente
                          ).toFixed(2)
                        }}
                        €
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{ parseFloat(r.total).toFixed(2) }}€
                    </td>
                  </tr>
                </div>
              </tbody>
              <!-- 2 eme  -->
              <tbody
                v-else-if="detailCommande.groupedTab.length == 0"
                class="bg-white w-fit"
              >
                <tr
                  v-for="(r, index) in detailPdf"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ r.ref }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-500">
                      {{ r.description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5">
                      {{ r.quantite }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      parseFloat(
                        detailCommande.Articles[index].Prix_euro
                      ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailCommande.Articles[index].PrixFournisseurType !=
                        'prixnet'
                      "
                    >
                      {{ r.remise }} %
                    </div>
                    <div v-else>prix net</div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailCommande.Articles[index].PrixFournisseurType !=
                        'prixnet'
                      "
                    >
                      {{
                        parseFloat(
                          detailCommande.Articles[index].Prix_euro -
                            detailCommande.Articles[index].Prix_euro *
                              (r.remise / 100)
                        ).toFixed(2)
                      }}
                      €
                    </div>

                    <div v-else>
                      {{
                        parseFloat(
                          detailCommande.Articles[index].Prix_vente
                        ).toFixed(2)
                      }}
                      €
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(r.total).toFixed(2) }}€
                  </td>
                </tr>
              </tbody>
            </div>
            <thead class="tableHEADTR mt-12 border-t">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    Total HT :
                    {{ parseFloat(detailCommande.TotalPrice).toFixed(2) }} €
                  </div>
                  <div
                    v-if="detailCommande.TotalPriceTTC"
                    class="text-sm leading-5 text-gray-500"
                  >
                    Total TTC :
                    {{ parseFloat(detailCommande.TotalPriceTTC).toFixed(2) }} €
                  </div>
                  <div v-else class="text-sm leading-5 text-gray-500">
                    Total TTC :
                    {{
                      parseFloat(
                        detailCommande.TotalPrice +
                          detailCommande.TotalPrice * 0.2
                      ).toFixed(2)
                    }}
                    €
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>

    <div
      v-if="openSave"
      :class="`modal ${
        !openSave && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openSave = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Encaissement</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openSave = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Titre du document</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> description </span>
              </span>

              <input
                type="text"
                v-model="titreDocument"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Note</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> article </span>
              </span>

              <input
                type="textfield"
                v-model="noteDocument"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Mode de règlement</label>
            <div class="relative">
              <label class="text-xs"
                >Montant à payer :
                {{
                  parseFloat(
                    devisSelected.TotalPrice + devisSelected.TotalPrice * 0.2
                  ).toFixed(2)
                }}
                €</label
              >
            </div>

            <div
              class="relative mt-2 rounded-md shadow-sm"
              v-for="(reglement, index) in reglementTab"
              :key="index"
            >
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> {{ reglement.icon }} </span>
              </span>

              <input
                type="Number"
                :placeholder="reglement.placeholder"
                v-model="reglementTab[index].model"
                class="w-2/5 px-12 focus:outline-2 outline-sky-300 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
              <input
                type="Date"
                placeholder="Date"
                v-model="reglementTab[index].date"
                class="w-2/5 px-5 focus:outline-2 outline-sky-300 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
              <span
                class="w-1/5 px-5 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              >
                <span
                  class="mt-2 material-icons-outlined cursor-pointer"
                  @click="removeReglement(index)"
                >
                  clear
                </span>
              </span>
            </div>
            <!-- <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> add </span>
              </span>

              <input
                type="text"
                v-on:keyup.enter="setReglement()"
                v-model="reglementMethod"
                placeholder="Ajouter"
                class="w-full px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div> -->
            <div class="relative mt-3 rounded-md">
              <label
                class="text-l cursor-pointer shadow-sm w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('es')"
                >Espèce</label
              >
              <label
                class="text-l cursor-pointer shadow-sm ml-1 w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('cb')"
                >CB</label
              >
              <label
                class="text-l cursor-pointer shadow-sm ml-1 w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('cc')"
                >Chèque</label
              >
              <label
                class="text-l cursor-pointer shadow-sm ml-1 w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('vi')"
                >Virement</label
              >
              <label
                class="text-l cursor-pointer shadow-sm ml-1 w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('lc')"
                >LCR</label
              >
            </div>
            <div class="relative mt-3" v-show="errorPaymentMethod">
              <label class="text-xs text-red-500"
                >Cette methode de paiement n'existe pas</label
              >
            </div>
            <div class="relative mt-3">
              <label class="text-l"
                >Reste dû : {{ parseFloat(setRestant).toFixed(2) }} €</label
              >
              <label class="text-xs"> - </label>
              <label class="text-l"
                >A rendre : {{ parseFloat(setRendu).toFixed(2) }} €</label
              >
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openSave = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="(openSave = false), transformToFacture(devisSelected)"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="openModifier"
      :class="`modal ${
        !openModifier && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openModifier = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Modifier un article</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openModifier = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">N° Article</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">qr_code_2</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Dépot</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">warehouse</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Quantité</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">inventory</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Description</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">description</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openModifier = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between">
      <searchInput
        v-if="archivedState == false"
        placeholder="Rechercher dans bons de commande"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
      <searchInput
        v-if="archivedState == true"
        placeholder="Rechercher dans bons de commande archivés"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
      <div class="flex justify-center">
        <titrePages :title="'Bons de commande'" />
      </div>
      <div v-if="archivedState == false">
        <button
          v-if="selectedDoc.length > 1 && loadingRegrouper"
          class="shadow-lg text-white my-auto bg-sky-600 hover:bg-sky-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
        >
          <PulseLoader color="#ffffff" class="my-auto" />
        </button>
        <button
          v-else-if="selectedDoc.length > 1 && !loadingRegrouper"
          @click="regrouper()"
          class="shadow-lg text-white my-auto bg-sky-600 hover:bg-sky-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5"
        >
          Regrouper {{ selectedDoc.length }} docs
        </button>
      </div>
      <div class="flex flex-row space-x-2 my-auto">
        <button
          class="w-32 bg-blue-600 p-1 text-white text-sm font-medium rounded-md shadow-lg border-2 border-orange-600"
          v-if="archivedState == false"
          @click="getInfosCommandes(1, 10, false)"
        >
          Actif
        </button>
        <button
          class="w-32 bg-blue-600 hover:bg-blue-500 hover:border-orange-600 p-1 text-white text-sm rounded-md shadow-lg border-2 border-gray-500"
          v-else
          @click="getInfosCommandes(1, 10, false)"
        >
          Actif
        </button>
        <button
          class="w-32 bg-blue-600 p-1 text-white text-sm font-medium rounded-md shadow-lg border-2 border-orange-600"
          v-if="archivedState == true"
          @click="getInfosCommandes(1, 10, true)"
        >
          Archivé
        </button>
        <button
          class="w-32 bg-blue-600 hover:bg-blue-500 hover:border-orange-600 p-1 text-white text-sm rounded-md shadow-lg border-2 border-gray-500"
          v-else
          @click="getInfosCommandes(1, 10, true)"
        >
          Archivé
        </button>
      </div>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  v-if="archivedState == false"
                  style="width: 1rem"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex space-x-0.5">
                    <input
                      v-if="!loadingSelection"
                      type="checkbox"
                      class="focus:outline-2 outline-sky-300"
                      v-model="selectAll"
                      @change="selectAllDoc"
                    />
                    <PulseLoader v-else color="#2d74da" />
                    <span>Sélect.</span>
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  style="width: 1em"
                >
                  <span>Réf. client</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Date</span>
                  <span
                    v-if="showSortDate"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByDate(),
                        (showReverseDate = true),
                        (showSortDate = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseDate"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByDate(),
                        (showSortDate = true),
                        (showReverseDate = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Numéro</span>
                  <span
                    v-if="showSortNumero"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByNumero(),
                        (showReverseNumero = true),
                        (showSortNumero = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseNumero"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByNumero(),
                        (showSortNumero = true),
                        (showReverseNumero = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Client</span>
                  <span
                    v-if="showSortAlpha"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByAlpha(),
                        (showReverseAlpha = true),
                        (showSortAlpha = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseAlpha"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByAlpha(),
                        (showSortAlpha = true),
                        (showReverseAlpha = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Créé par</span>
                  <span
                    v-if="showSortUsername"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByUsername(),
                        (showReverseUsername = true),
                        (showSortUsername = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseUsername"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByUsername(),
                        (showSortUsername = true),
                        (showReverseUsername = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>HT</span>
                  <span
                    v-if="showSortTotalPrice"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByTotalPrice(),
                        (showReverseTotalPrice = true),
                        (showSortTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseTotalPrice"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByTotalPrice(),
                        (showSortTotalPrice = true),
                        (showReverseTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>TTC</span>
                  <span
                    v-if="showSortTotalPrice"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByTotalPrice(),
                        (showReverseTotalPrice = true),
                        (showSortTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseTotalPrice"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByTotalPrice(),
                        (showSortTotalPrice = true),
                        (showReverseTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  PDF
                </th>
                <!-- <th
                  v-if="archivedState == false"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Modifier
                </th> -->
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in infosCommandes" :key="index">
                <td
                  v-if="archivedState == false"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1rem"
                >
                  <input
                    type="checkbox"
                    class="focus:outline-2 outline-sky-300"
                    :value="u"
                    v-model="selectedDoc"
                    :checked="selectAll"
                  />
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1em"
                >
                  {{ u.Note }}
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 1em"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ getFRDate(u.Date) }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.Numero }}
                    <span
                      @click="getDetailCommande(u)"
                      title="Plus d'informations"
                      class="material-icons-outlined text-base text-cyan-600 cursor-pointer"
                    >
                      info
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Client }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Name }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice).toFixed(2) }} €
                </td>

                <td
                  v-if="u.TotalPriceTTC"
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
                </td>
                <td
                  v-else
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }}
                  €
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <!-- <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    v-if="u.IsProcessed === true"
                    ><span class="material-icons-outlined"> done </span></span
                  >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                    v-else
                    ><span class="material-icons-outlined">close</span></span
                  >
                  <span
                    class="material-icons-round ml-2 cursor-pointer"
                    @click="(openSave = !openSave), setDevisSelected(u)"
                  >
                    receipt
                  </span> -->
                  <div class="flex">
                    <div class="flex flex-row relative">
                      <span
                        class="material-icons-outlined mr-4 cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="openCommandes(u)"
                      >
                        visibility
                      </span>
                      <span
                        class="material-icons-outlined cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="setEmails(u, index)"
                      >
                        email
                      </span>
                      <modalCommande
                        v-if="showEmail[index]"
                        :showEmail="showEmail[index]"
                        :ind="index"
                        :objet="u"
                        :tabCommandes="infosCommandes[index].clientInfo[0]"
                        :tabTempInfosCommandes="infosCommandes"
                        @close="showEmail[index] = false"
                      />
                    </div>

                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPDF(u)"
                    >
                      download
                    </span>
                  </div>
                </td>

                <!-- <td
                  v-if="archivedState == false"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                > -->
                <!-- <div
                    class="flex text-xs w-24 h-6 p-1 cursor-pointer rounded-md bg-sky-400 hover:bg-sky-300 text-white"
                    @click="showReprise[index] = !showReprise[index]"
                  >
                    <span class="material-icons-round text-xs">
                      keyboard_double_arrow_right
                    </span>
                    <span class="ml-2">Reprise</span>
                  </div> -->

                <!-- <div
                    v-show="showReprise[index]"
                    class="-ml-20 absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20"
                     >
                    <div class="flex flex-col justify-center space-x-4">
                      <span>Êtes-vous sûr de vouloir modifier ?</span>
                      <div class="flex space-x-4 justify-center">
                        <div class="flex space-x-1" @click="setInComptoir(u)">
                          <input
                            type="radio"
                            value="oui"
                            name="oui"
                            class="focus:outline-2 outline-sky-300"
                          />
                          <span class="mb-1">oui</span>
                        </div>
                        <div
                          class="flex space-x-1"
                          @click="showReprise[index] = false"
                        >
                          <input
                            type="radio"
                            value="non"
                            name="non"
                            class="focus:outline-2 outline-sky-300"
                          />
                          <span class="mb-1">non</span>
                        </div>
                      </div>
                    </div>
                  </div> -->
                <!-- </td> -->
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosBdcLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosBdcLength / 10)"
            :clickFunction="
              (arg1, arg2) => getInfosCommandes(arg1, arg2, archivedState)
            "
            :currentPage="currentPage"
            :typeList="'bons de commande'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'bons de commande'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createcommande } from "@/hooks/pdf/commande.js";
// import { sendemailcommande } from "@/hooks/email/emailCommande";
import { useToast } from "vue-toastification";
import modalCommande from "@/components/Email/modalCommande";
import { mvStock } from "@/hooks/stock/stock.ts";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { groupedDocByClient } from "@/hooks/regroupeDoc/regroupeDoc.ts";
import { createGroupedDoc } from "@/hooks/pdf/groupedDoc.js";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyCommandes",
  data: () => ({
    archivedState: false,
    infosCommandes: [],
    infosCommandesTmp: [],
    devisSelected: [],
    openSave: false,
    openModifier: false,
    reglementTab: [],
    numDoc: 0,
    index: 0,
    showReprise: [],
    openSaveCommande: false,
    errorPaymentMethod: false,
    inputFilter: "",
    noteDocument: "",
    titreDocument: "",

    showSortDate: true,
    showReverseDate: false,
    showSortNumero: true,
    showReverseNumero: false,
    showSortAlpha: true,
    showReverseAlpha: false,
    showSortUsername: true,
    showReverseUsername: false,
    showSortTotalPrice: true,
    showReverseTotalPrice: false,

    showEmail: [],
    errors: [],

    infoInCmd: false,
    detailCommande: [],
    detailPdf: [],

    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    currentPage: 1,
    infosBdcLength: 0,

    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,

    selectAll: false,
    selectedDoc: [],
    loadingSelection: false,
    loadingRegrouper: false,
  }),
  components: {
    modalCommande,
    pageNumber,
    searchInput,
    PulseLoader,
    titrePages,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
    setRestant() {
      var restant = 0;
      var paye = 0;
      var totalTTC =
        this.devisSelected.TotalPrice + this.devisSelected.TotalPrice * 0.2;
      this.reglementTab.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      restant = parseFloat(totalTTC) - paye;
      if (restant < 0) {
        restant = 0;
      }
      return restant;
    },
    setRendu() {
      var rendu = 0;
      var paye = 0;
      var totalTTC =
        this.devisSelected.TotalPrice + this.devisSelected.TotalPrice * 0.2;
      this.reglementTab.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      if (paye > totalTTC) {
        rendu = Math.abs(paye - totalTTC);
      }

      return rendu;
    },
  },
  methods: {
    ...mapActions([
      "suppStockArticle",
      "addStockArticle",
      // "setMvtStock",
      "updateactionArticle",
      "createcomptoirsession",
      "setbcselected",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    openCommandes(commandes) {
      this.setbcselected(commandes);
      this.$router.push("/detailCommandes");
    },
    getInfosCommandes(skipValue, limitValue, archive) {
      this.currentPage = skipValue;
      this.archivedState = archive;
      axios
        .post(`${process.env.VUE_APP_API}/commandes/getbyowner/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
          archive: archive,
        })
        .then((response) => {
          console.log(response.data);
          this.infosCommandes = response.data;
          this.infosCommandesTmp = response.data;
          this.getInfosBdcLength(archive);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosBdcLength(archive) {
      axios
        .post(`${process.env.VUE_APP_API}/commandes/getbyowner/count`, {
          plateform: this.user.proprietaire,
          archive: archive,
        })
        .then((response) => {
          this.infosBdcLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDetailCommande(data) {
      this.detailCommande = data;
      if (data.groupedTab && data.groupedTab.length > 0) {
        this.detailPdf = data.groupedTab;
      } else {
        this.detailPdf = data.pdfData;
      }
      this.infoInCmd = true;
    },
    async transformToFacture(object) {
      await axios.put(`${process.env.VUE_APP_API}/commandes/${object._id}`, {
        Archived: true,
      });
      await axios
        .post(`${process.env.VUE_APP_API}/facture/getlast`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          this.numDoc = response.data + 1;
        });
      axios
        .post(`${process.env.VUE_APP_API}/facture`, {
          Name: this.user.username,
          Date: object.Date,
          Devis: object.Numero,
          Numero: this.numDoc,
          TotalPrice: object.TotalPrice,
          TotalPriceTTC: object.TotalPrice + object.TotalPrice * 0.2,
          Client: object.Client,
          IsProcessed: false,
          Note: object.Note,
          Articles: object.Articles,
          reglement: this.reglementTab,
          pdfData: object.pdfData,
          clientInfo: object.clientInfo,
          Transport: object.Transport,
        })
        .then(() => {
          object.Articles.forEach((element) => {
            const stockData = axios.post(
              `${process.env.VUE_APP_API}/stock/getone`,
              {
                plateform: this.user.proprietaire,
                Ref_fournisseur: element.Ref_fournisseur,
              }
            );

            var tmpStock = 0;

            stockData.data.length > 0
              ? (tmpStock = stockData.data[0].stock)
              : (tmpStock = 0);

            this.suppStockArticle({
              id: element.Code_EAN,
              Stock: tmpStock,
              inputstock: element.quantity,
            });
            mvStock({
              plateform: this.user.proprietaire,
              Ref_fournisseur: element.Ref_fournisseur,
              Code_EAN: element.Code_EAN,
              Prefixe_tarif: element.Prefixe_tarif,
              User: this.user.username,
              Description: "Transformation devis vers facture : " + "Facture",
              OldStock: parseInt(element.Stock),
              NewStock: parseInt(element.Stock) - parseInt(element.quantity),
            });
          });
        });
      this.getInfosCommandes(1, 10);
    },
    setDevisSelected(doc) {
      this.devisSelected = doc;
      this.reglementTab = doc.reglement;
    },
    // async setInComptoir(cmdData) {
    //   const articles =
    //     cmdData.groupedTab.length > 0
    //       ? cmdData.groupedTab.flatMap((tab) => tab.Articles)
    //       : cmdData.Articles;

    //   try {
    //     const client = await axios.post(
    //       `${process.env.VUE_APP_API}/client/getone/${cmdData.Client}`,
    //       { plateform: this.user.proprietaire }
    //     );

    //     this.createcomptoirsession({
    //       clientFocus: client.data,
    //       refClient: cmdData.Note,
    //       Articles: articles,
    //       reprise: true,
    //       repriseTypeDoc: "commandes",
    //       repriseNumDoc: cmdData.Numero,
    //       repriseIdDoc: cmdData._id,
    //     });
    //   } catch (error) {
    //     console.error(
    //       "Erreur lors de la récupération des informations du client:",
    //       error
    //     );
    //   }
    // },
    setReglement(method) {
      switch (method) {
        case "es":
          this.reglementTab.push({
            icon: "payments",
            placeholder: "Espèce",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "",
          });
          this.errorPaymentMethod = false;
          break;
        case "cb":
          this.reglementTab.push({
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "",
          });
          this.errorPaymentMethod = false;
          break;
        case "cc":
          this.reglementTab.push({
            icon: "money",
            placeholder: "Chèque Comptant",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "",
          });
          this.errorPaymentMethod = false;
          break;
        case "vi":
          this.reglementTab.push({
            icon: "account_balance",
            placeholder: "Virement",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "26/04/2022",
          });
          this.errorPaymentMethod = false;
          break;
        case "lc":
          this.reglementTab.push({
            icon: "account_balance",
            placeholder: "LCR",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "",
          });
          this.errorPaymentMethod = false;
          break;
        default:
          this.errorPaymentMethod = true;
          break;
      }
    },
    removeReglement(index) {
      this.reglementTab.splice(index, 1);
    },
    setEmails(objet, index) {
      if (
        objet.clientInfo[0] &&
        objet.clientInfo[0].Emails &&
        objet.clientInfo[0].Emails.length > 0
      ) {
        objet.clientInfo[0].Emails.forEach((element, index) => {
          if (element.bondecommande == false) {
            objet.clientInfo[0].Emails.splice(index, 1);
          } else {
            this.checkForm(this.plateform.Email, element.address);
          }
        });
        this.showEmail[index] = !this.showEmail[index];
      } else {
        this.checkForm(this.plateform.Email, objet.clientInfo[0].email);
        this.showEmail[index] = !this.showEmail[index];
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(emailFrom, emailTo) {
      console.log(emailTo);
      this.errors = [];
      if (!emailFrom || !emailTo) {
        this.errors = [];
        this.errors.push("E-mail obligatoire.");
      } else if (!this.validEmail(emailFrom) || !this.validEmail(emailTo)) {
        this.errors = [];
        this.errors.push("E-mail incorrect.");
      } else {
        this.errors = [];
      }
    },
    // sendEmail(objet, index, emailFrom, emailTo) {
    //   if (this.errors.length > 0) {
    //     this.toast.info("Vérifiez l'adresse mail !", {
    //       position: "bottom-right",
    //       timeout: 1000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: false,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false,
    //     });
    //   } else {
    //     sendemailcommande(objet, emailFrom, emailTo);
    //     this.toast.success("Bon de commande envoyé !", {
    //       position: "bottom-right",
    //       timeout: 1000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: false,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false,
    //     });
    //     this.showEmail[index] = false;
    //   }
    // },
    async createPDF(objet) {
      if (objet.groupedTab && objet.groupedTab.length > 0) {
        this.openGroupedPDF(objet);
      } else {
        var today = new Date();
        var da = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        var dateedition = getFrDateSansH(objet.Date);
        var datepdf = da + "/" + mm + "/" + yyyy;
        if (dateedition == datepdf) {
          createcommande(objet, false);
        } else {
          createcommande(objet, true);
        }
        // axios
        //   .post(`${process.env.VUE_APP_API}/commandes/savepdf`, {
        //     objet: objet,
        //     duplicata: false,
        //     plateform: this.plateform,
        //   })
        //   .then((response) => {
        //     console.log(response);
        //   });
      }
    },
    openGroupedPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createGroupedDoc(objet, "Bon de commande", "BC", false);
      } else {
        createGroupedDoc(objet, "Bon de commande", "BC", true);
      }
    },
    sortByDate() {
      this.infosCommandes.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    reverseByDate() {
      this.sortDate = this.infosCommandes.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
      this.sortDate.reverse((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    sortByNumero() {
      this.infosCommandes.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
    },
    reverseByNumero() {
      this.sortNumero = this.infosCommandes.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
      this.sortNumero.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByAlpha() {
      this.infosCommandes.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    reverseByAlpha() {
      this.sortAlpha = this.infosCommandes.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
      this.sortAlpha.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByUsername() {
      this.infosCommandes.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    reverseByUsername() {
      this.sortUsername = this.infosCommandes.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
      this.sortUsername.reverse((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    sortByTotalPrice() {
      this.infosCommandes.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    reverseByTotalPrice() {
      this.sortTotalPrice = this.infosCommandes.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
      this.sortTotalPrice.reverse((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    filterByValue(value) {
      var filterTab = [];
      if (value != "") {
        this.infosCommandes = this.infosCommandesTmp;
        this.infosCommandes.filter((obj) => {
          //loop through each object
          for (const key in obj) {
            if (
              obj[key].toString().toLowerCase().includes(value.toLowerCase())
            ) {
              filterTab.push(obj);
              break;
            }
          }
        });
        this.infosCommandes = filterTab;
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getInfosCommandes(skipValue, limitValue, this.archivedState);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/commandes/searchInPlateform`, {
            plateform: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
            archive: this.archivedState,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosCommandes = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },

    // Selection
    async selectAllDoc() {
      this.selectedDoc = [];
      this.loadingSelection = true;
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (this.selectAll) {
        this.selectedDoc = [...this.infosCommandes];
      } else {
        this.selectedDoc = [];
      }
      this.loadingSelection = false;
    },
    // Regroupement
    async regrouper() {
      if (this.selectedDoc.length > 1) {
        this.loadingRegrouper = true;
        const tabGrouped = await groupedDocByClient(
          this.user.proprietaire,
          this.user.username,
          "commandes",
          this.selectedDoc
        );
        console.log(tabGrouped);
        this.loadingRegrouper = false;
        if (tabGrouped.length == 0) {
          this.error("Impossible de regrouper !");
        } else {
          this.selectedDoc = [];
          this.success("Créé !");
          this.currentPage = 1;
          this.getInfosCommandes(this.currentPage, 10, this.archivedState);
          this.getInfosBdcLength(this.archivedState);
        }
      } else {
        this.warning("Sélectionnez au moins deux documents !");
      }
    },

    // Message
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(msg) {
      this.toast.warning(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(msg) {
      this.toast.error(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getInfosCommandes(1, 10, false);
    this.pageSwitch();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
