<template>
  <div
    v-if="showUniversel"
    :class="`modal ${
      !showUniversel && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showUniversel = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 70vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showUniversel = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        {{ sousFamilleSelected_U.name }}
        <div class="flex flex-row space-x-1 h-[60vh]">
          <div class="w-1/3">
            <div class="flex flex-col bg-gray-50">
              <div
                v-for="(conf, indexC) in configurationUniversel"
                :key="indexC"
                class="ml-2"
              >
                <div
                  @click="conf.showPromo = !conf.showPromo"
                  class="p-2 w-full flex justify-between cursor-pointer"
                >
                  <div>{{ conf.name }}</div>
                  <div>
                    <span
                      v-if="conf.showPromo"
                      class="material-symbols-rounded"
                    >
                      arrow_drop_down
                    </span>
                    <span v-else class="material-symbols-rounded">
                      arrow_right
                    </span>
                  </div>
                </div>
                <div
                  v-for="(fam, indexF) in conf.famille"
                  v-show="conf.showPromo"
                  :key="indexF"
                  class="ml-4"
                >
                  <div
                    @click="fam.showPromo = !fam.showPromo"
                    class="p-2 w-full flex justify-between cursor-pointer"
                  >
                    <div>{{ fam.name }}</div>
                    <div>
                      <span
                        v-if="fam.showPromo"
                        class="material-symbols-rounded"
                      >
                        arrow_drop_down
                      </span>
                      <span v-else class="material-symbols-rounded">
                        arrow_right
                      </span>
                    </div>
                  </div>
                  <div
                    v-for="(sfam, indexSF) in fam.sousFamille"
                    v-show="fam.showPromo"
                    :key="indexSF"
                    class="ml-4 p-1 hover:underline hover:text-blue-500 cursor-pointer"
                    @click="linkUniversel(sousFamilleSelected_U, sfam)"
                  >
                    {{ sfam.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-2/3">
            <div class="grid grid-cols-4 gap-2">
              <div
                v-for="(uni, indexUni) in sousFamilleSelected_U.universel"
                :key="indexUni"
                class="flex flex-col space-y-1 p-2 border border-gray-200 rounded"
              >
                <div class="flex justify-between items-center">
                  <div>{{ uni.name }}</div>
                  <button
                    @click="linkUniversel(sousFamilleSelected_U, uni)"
                    class="material-symbols-rounded text-xl text-red-500"
                  >
                    delete
                  </button>
                </div>
                <div class="text-sm">Description</div>
                <div class="text-xs">parents</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showGenArt"
    :class="`modal ${
      !showGenArt && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showGenArt = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 70vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showGenArt = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <genArtConf
          :sousFamille="selectedSousFamille"
          @updateSousFamille="updateGASousFamille"
          :key="componentKeyReloadGA"
        />
      </div>
    </div>
  </div>
  <div class="flex justify-between mb-14">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/configurationCatalogueHub'" />
    </div>
    <titrePages
      class="flex flex-1 justify-center"
      :title="'Configuration du catalogue'"
    />
    <div class="flex justify-end space-x-2">
      <button
        type="button"
        class="px-3 py-2 font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
        @click="exportExcel()"
      >
        Exporter Excel
      </button>
      <button
        type="button"
        class="px-3 py-2 font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
        @click="addGroup()"
      >
        Ajouter un groupe
      </button>
    </div>
  </div>
  <div class="flex flex-col space-y-4">
    <div class="flex justify-center"><menuConfiguration /></div>

    <div
      v-for="(groupe, indexGp) in configuration"
      :key="indexGp"
      class="flex flex-col space-y-4 p-2 bg-white rounded shadow"
    >
      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-row items-center space-x-2">
          <div class="relative">
            <input
              type="text"
              id="floating_groupe"
              v-model="groupe.name"
              @change="updateGroup(indexGp, groupe._id, groupe.name)"
              class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            />
            <label
              for="floating_groupe"
              class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >Groupe</label
            >
          </div>
          <span
            v-show="groupe.loading"
            class="material-icons-outlined text-orangevk animate-spin"
          >
            sync
          </span>
          <div>
            <span
              v-show="groupe.actif"
              class="p-1 bg-green-500 text-white text-xs shadow rounded cursor-pointer"
              >actif</span
            >
            <span
              v-show="!groupe.actif"
              class="p-1 bg-red-500 text-white text-xs shadow rounded cursor-pointer"
              >actif</span
            >
          </div>
          <button
            class="material-icons-round text-md text-gray-500"
            @click="showConfGroupe(groupe._id, !groupe.show, indexGp)"
            v-if="!groupe.show"
          >
            navigate_next
          </button>
          <button
            class="material-icons-round text-md text-gray-500"
            @click="showConfGroupe(groupe._id, !groupe.show, indexGp)"
            v-else
          >
            keyboard_arrow_down
          </button>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <div>
            <span
              class="p-1 bg-bluevk text-white text-xs shadow rounded cursor-pointer"
              @click="addFamille(indexGp, groupe._id, groupe.name)"
              >ajouter</span
            >
          </div>
          <div>
            <span
              v-show="!groupe.valideClear"
              @click="groupe.valideClear = true"
              class="p-1 bg-orangevk text-white text-xs shadow rounded cursor-pointer"
              >supprimer</span
            >
            <div
              class="flex flex-row space-x-1 items-center"
              v-show="groupe.valideClear"
            >
              <button
                class="material-icons-outlined text-base text-green-500"
                @click="deleteGroup(indexGp, groupe._id)"
              >
                done
              </button>
              <button
                class="material-icons-outlined text-base text-red-500"
                @click="groupe.valideClear = false"
              >
                clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4" v-show="groupe.show">
        <div
          v-for="(famille, indexF) in groupe.famille"
          :key="indexF"
          class="flex flex-col space-y-3 bg-beigevk rounded shadow p-2"
        >
          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row items-center space-x-2">
              <div class="relative">
                <input
                  type="text"
                  id="floating_famille"
                  v-model="famille.name"
                  @change="
                    updateFamille(indexGp, indexF, famille._id, famille.name)
                  "
                  class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                />
                <label
                  for="floating_famille"
                  class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Famille</label
                >
              </div>
              <span
                v-show="famille.loading"
                class="material-icons-outlined text-orangevk animate-spin"
              >
                sync
              </span>
              <button
                class="material-icons-outlined text-md text-gray-500"
                @click="setBrandPage(famille)"
              >
                style
              </button>

              <button
                class="material-icons-round text-md text-gray-500"
                @click="
                  showConfFamille(famille._id, !famille.show, indexGp, indexF)
                "
                v-if="!famille.show"
              >
                navigate_next
              </button>
              <button
                class="material-icons-round text-md text-gray-500"
                @click="
                  showConfFamille(famille._id, !famille.show, indexGp, indexF)
                "
                v-else
              >
                keyboard_arrow_down
              </button>
            </div>

            <div class="flex flex-row items-center space-x-2">
              <div>
                <span
                  class="p-1 bg-bluevk text-white text-xs shadow rounded cursor-pointer"
                  @click="
                    addSousFamille(
                      indexGp,
                      indexF,
                      groupe._id,
                      groupe.name,
                      famille._id,
                      famille.name
                    )
                  "
                  >ajouter</span
                >
              </div>
              <div>
                <span
                  v-show="!famille.valideClear"
                  @click="famille.valideClear = true"
                  class="p-1 bg-orangevk text-white text-xs shadow rounded cursor-pointer"
                  >supprimer</span
                >
                <div
                  class="flex flex-row space-x-1 items-center"
                  v-show="famille.valideClear"
                >
                  <button
                    class="material-icons-outlined text-base text-green-500"
                    @click="deleteFamille(indexGp, indexF, famille._id)"
                  >
                    done
                  </button>
                  <button
                    class="material-icons-outlined text-base text-red-500"
                    @click="famille.valideClear = false"
                  >
                    clear
                  </button>
                </div>
              </div>
              <div>
                <span
                  v-show="famille.actif"
                  class="p-1 bg-green-500 text-white text-xs shadow rounded cursor-pointer"
                  >actif</span
                >
                <span
                  v-show="!famille.actif"
                  class="p-1 bg-red-500 text-white text-xs shadow rounded cursor-pointer"
                  >actif</span
                >
              </div>
              <div>
                <button
                  class="material-icons-round text-blue-500"
                  v-if="famille.isGeneral"
                  @click="
                    setGeneralFamille(famille._id, false, indexGp, indexF)
                  "
                >
                  verified
                </button>
                <button
                  class="material-icons-round text-gray-300"
                  v-else
                  @click="setGeneralFamille(famille._id, true, indexGp, indexF)"
                >
                  verified
                </button>
              </div>
            </div>
            <div>
              <!-- Image -->
              <div v-if="famille.stateLogo">
                <div class="relative">
                  <img :src="famille.logo_url" class="w-16 h-16 mx-auto" />
                  <span
                    class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                    @click="deleteImg(indexGp, indexF, famille._id)"
                  >
                    clear
                  </span>
                </div>
              </div>
              <div v-else class="flex items-center justify-center h-16 w-16">
                <label
                  :for="'dropzone-file' + famille._id"
                  class="flex flex-col items-center justify-center w-full h-16 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                >
                  <div class="flex flex-col items-center justify-center">
                    <svg
                      class="w-8 h-8 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                  </div>
                  <input
                    @change="readImg($event, famille._id, indexGp, indexF)"
                    :id="'dropzone-file' + famille._id"
                    type="file"
                    class="hidden"
                  />
                </label>
              </div>
            </div>
          </div>

          <div class="ml-2 flex flex-col space-y-2" v-show="famille.show">
            <div
              v-for="(sousFamille, indexSF) in famille.sousFamille"
              :key="indexSF"
              class="flex flex-row items-center justify-between"
            >
              <div class="flex flex-row items-center space-x-2">
                <div>
                  <span class="text-sm text-gray-500">{{
                    sousFamille.index + 1
                  }}</span>
                </div>
                <div class="relative">
                  <input
                    type="text"
                    id="floating_sousfamille"
                    v-model="sousFamille.name"
                    @change="
                      updateSousFamille(
                        indexGp,
                        indexF,
                        indexSF,
                        sousFamille._id,
                        sousFamille.name
                      )
                    "
                    class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    for="floating_sousfamille"
                    class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >Sous famille</label
                  >
                </div>
                <span
                  class="material-icons-outlined text-orangevk animate-spin"
                  v-show="sousFamille.loading"
                >
                  sync
                </span>
                <div>
                  <button
                    class="material-icons-round text-blue-500"
                    v-if="sousFamille.isGeneral"
                    @click="
                      setGeneralSousFamille(
                        sousFamille._id,
                        false,
                        indexGp,
                        indexF,
                        indexSF
                      )
                    "
                  >
                    verified
                  </button>
                  <button
                    class="material-icons-round text-gray-300"
                    v-else
                    @click="
                      setGeneralSousFamille(
                        sousFamille._id,
                        true,
                        indexGp,
                        indexF,
                        indexSF
                      )
                    "
                  >
                    verified
                  </button>
                </div>
                <div>
                  <button
                    v-if="sousFamille.isUniversel"
                    class="material-icons-round text-blue-500"
                    @click="
                      setUniverselSousFamille(
                        sousFamille._id,
                        false,
                        indexGp,
                        indexF,
                        indexSF
                      )
                    "
                  >
                    build_circle
                  </button>
                  <button
                    v-else
                    class="material-icons-round text-gray-300"
                    @click="
                      setUniverselSousFamille(
                        sousFamille._id,
                        true,
                        indexGp,
                        indexF,
                        indexSF
                      )
                    "
                  >
                    build_circle
                  </button>
                </div>
                <div>
                  <button
                    v-if="sousFamille.isElectric"
                    class="material-icons-round text-blue-500"
                    @click="
                      setElectricSousFamille(
                        sousFamille._id,
                        false,
                        indexGp,
                        indexF,
                        indexSF
                      )
                    "
                  >
                    electrical_services
                  </button>
                  <button
                    v-else
                    class="material-icons-round text-gray-300"
                    @click="
                      setElectricSousFamille(
                        sousFamille._id,
                        true,
                        indexGp,
                        indexF,
                        indexSF
                      )
                    "
                  >
                    electrical_services
                  </button>
                </div>
              </div>

              <div class="flex flex-row space-x-2 items-center">
                <div class="relative">
                  <button
                    class="flex flex-row items-center text-xs text-gray-600 p-1 border border-gray-200 rounded shadow italic"
                    @click="showUniverselConfig(sousFamille)"
                  >
                    <span>{{ sousFamille.universel.length }}</span>
                    <span class="material-symbols-rounded text-sm">
                      build
                    </span>
                  </button>
                </div>
                <div class="relative">
                  <button
                    class="text-xs text-gray-600 p-1 border border-gray-200 rounded shadow italic"
                    @click="
                      showGenArtConfig(sousFamille, indexGp, indexF, indexSF)
                    "
                    :title="sousFamille.genericArticleId_name"
                  >
                    {{ sousFamille.genericArticleId.length }} GenArts
                  </button>
                </div>
                <div>
                  <span
                    v-show="sousFamille.actif"
                    class="p-1 bg-green-500 text-white text-xs shadow rounded cursor-pointer"
                    >actif</span
                  >
                  <span
                    v-show="!sousFamille.actif"
                    class="p-1 bg-red-500 text-white text-xs shadow rounded cursor-pointer"
                    >actif</span
                  >
                </div>
                <div>
                  <span
                    v-show="!sousFamille.valideClear"
                    @click="sousFamille.valideClear = true"
                    class="p-1 bg-orangevk text-white text-xs shadow rounded cursor-pointer"
                    >supprimer</span
                  >
                  <div
                    class="flex flex-row space-x-1 items-center"
                    v-show="sousFamille.valideClear"
                  >
                    <button
                      class="material-icons-outlined text-base text-green-500"
                      @click="
                        deleteSousFamille(
                          indexGp,
                          indexF,
                          indexSF,
                          sousFamille._id
                        )
                      "
                    >
                      done
                    </button>
                    <button
                      class="material-icons-outlined text-base text-red-500"
                      @click="sousFamille.valideClear = false"
                    >
                      clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import backButton from "@/components/Button/back.vue";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
import genArtConf from "@/components/Catalogue/genArtConf.vue";
import * as XLSX from "xlsx";
import titrePages from "@/components/Text/grandeTitre.vue";
export default {
  data() {
    return {
      configuration: [],
      genArtTab: [],
      loadingTD: false,
      valideClearSousFamille: false,
      valideClearFamille: false,
      valideClearGroupe: false,
      genartModel: 0,
      selectedSousFamille: null,
      showGenArt: false,
      indexGp: 0,
      indexF: 0,
      indexSF: 0,
      componentKeyReloadGA: 0,
      showUniversel: false,
      configurationUniversel: [],
      sousFamilleSelected_U: null,
    };
  },
  components: {
    backButton,
    menuConfiguration,
    genArtConf,
    titrePages,
  },
  computed: {
    ...mapGetters(["plateform", "user"]),
  },
  methods: {
    ...mapActions(["setfamillecatalogue"]),
    async getConfiguration() {
      const response = await axios.get(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getConfiguration/${this.user.proprietaire}`
      );
      this.configuration = response.data;
    },
    async getGenArt() {
      const response = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getGenArt`
      );
      this.genArtTab = response.data;
    },
    async addGroup() {
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/addGroup`,
        {
          plateform: this.user.proprietaire,
          username: this.user.username,
          name: "nom du groupe",
        }
      );
      await this.getConfiguration();
    },
    async addFamille(index, groupeId, groupeName) {
      this.configuration[index].loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/addFamille`,
        {
          plateform: this.user.proprietaire,
          username: this.user.username,
          groupeId: groupeId,
          groupeName: groupeName,
          name: "nom de la famille",
        }
      );
      this.configuration[index].loading = false;
      await this.getConfiguration();
    },
    async addSousFamille(
      indexGp,
      indexF,
      groupeId,
      groupeName,
      familleId,
      familleName
    ) {
      this.configuration[indexGp].famille[indexF].loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/addSousFamille`,
        {
          plateform: this.user.proprietaire,
          username: this.user.username,
          groupeId: groupeId,
          groupeName: groupeName,
          familleId: familleId,
          familleName: familleName,
          name: "nom de la sous famille",
        }
      );
      this.configuration[indexGp].famille[indexF].loading = false;
      await this.getConfiguration();
    },
    async deleteGroup(index, groupeId) {
      this.configuration[index].loading = true;
      await axios.delete(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteGroup/${groupeId}`
      );
      this.configuration[index].loading = false;
      await this.getConfiguration();
    },
    async deleteFamille(indexGp, indexF, familleId) {
      this.configuration[indexGp].famille[indexF].loading = true;
      await axios.delete(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteFamille/${familleId}`
      );
      this.configuration[indexGp].famille[indexF].loading = false;
      await this.getConfiguration();
    },
    async deleteSousFamille(indexGp, indexF, indexSF, sousFamilleId) {
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].loading = true;
      await axios.delete(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteSousFamille/${sousFamilleId}`
      );
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].loading = false;
      await this.getConfiguration();
    },
    async updateGroup(index, groupeId, groupeName) {
      this.configuration[index].loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/updateGroup`,
        {
          id: groupeId,
          name: groupeName,
          username: this.user.username,
        }
      );
      this.configuration[index].loading = false;
      await this.getConfiguration();
    },
    async updateFamille(indexGp, indexF, familleId, familleName) {
      this.configuration[indexGp].famille[indexF].loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/updateFamille`,
        {
          id: familleId,
          name: familleName,
          username: this.user.username,
        }
      );
      this.configuration[indexGp].famille[indexF].loading = false;
      await this.getConfiguration();
    },
    async updateSousFamille(
      indexGp,
      indexF,
      indexSF,
      sousFamilleId,
      sousFamilleName
    ) {
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/updateSousFamille`,
        {
          id: sousFamilleId,
          name: sousFamilleName,
          username: this.user.username,
        }
      );
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].loading = false;
      await this.getConfiguration();
    },
    async setGenArt(indexGp, indexF, indexSF, sousFamilleId, genericArticleId) {
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setGenArt`,
        {
          id: sousFamilleId,
          genericArticleId: parseInt(genericArticleId),
        }
      );
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].loading = false;
      await this.getConfiguration();
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].showGenArt = true;
    },
    async readImg(e, familleId, indexGp, indexF) {
      this.configuration[indexGp].famille[indexF].loading = true;

      const file = e.target.files[0];
      const imagefile = file;

      var fd = new FormData();

      fd.append("image", imagefile);
      fd.append("id", familleId);

      const createImage = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setImage`,
        fd
      );
      this.configuration[indexGp].famille[indexF].loading = false;
      await this.getConfiguration();
    },
    async deleteImg(indexGp, indexF, familleId) {
      this.configuration[indexGp].famille[indexF].loading = true;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteImage`,
        {
          id: familleId,
        }
      );
      this.configuration[indexGp].famille[indexF].loading = false;
      await this.getConfiguration();
    },
    async setBrandPage(famille) {
      await this.setfamillecatalogue(famille);
      this.$router.push("/configurationFamilleMarqueCatalogue");
    },
    async showConfGroupe(groupeId, show, index) {
      this.configuration[index].show = show;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/showConfGroupe`,
        {
          id: groupeId,
          show: show,
        }
      );
    },
    async showConfFamille(familleId, show, indexGp, indexF) {
      this.configuration[indexGp].famille[indexF].show = show;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/showConfFamille`,
        {
          id: familleId,
          show: show,
        }
      );
    },
    async setGeneralFamille(familleId, state, indexGp, indexF) {
      this.configuration[indexGp].famille[indexF].isGeneral = state;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setGeneralFamille`,
        {
          id: familleId,
          state: state,
        }
      );
    },
    async setGeneralSousFamille(
      sousFamilleId,
      state,
      indexGp,
      indexF,
      indexSF
    ) {
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].isGeneral = state;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setGeneralSousFamille`,
        {
          id: sousFamilleId,
          state: state,
        }
      );
    },
    async setUniverselSousFamille(
      sousFamilleId,
      state,
      indexGp,
      indexF,
      indexSF
    ) {
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].isUniversel = state;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setUniverselSousFamille`,
        {
          id: sousFamilleId,
          state: state,
        }
      );
    },
    async setElectricSousFamille(
      sousFamilleId,
      state,
      indexGp,
      indexF,
      indexSF
    ) {
      this.configuration[indexGp].famille[indexF].sousFamille[
        indexSF
      ].isElectric = state;
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setElectricSousFamille`,
        {
          id: sousFamilleId,
          state: state,
        }
      );
    },
    showGenArtConfig(sousFamille, indexGp, indexF, indexSF) {
      this.selectedSousFamille = sousFamille;
      this.showGenArt = true;
      this.indexGp = indexGp;
      this.indexF = indexF;
      this.indexSF = indexSF;
    },
    async updateGASousFamille() {
      await this.getConfiguration();
    },
    async exportExcel() {
      let excelTab = [];
      let excelTabGenArt = [];
      let excelMarque = [];
      let marqueSecondary = [];
      for (const group of this.configuration) {
        for (const famille of group.famille) {
          for (const sousFamille of famille.sousFamille) {
            excelTab.push({
              groupe: group.name,
              famille: famille.name,
              sousFamille: sousFamille.name,
              isGeneral: sousFamille.isGeneral,
              isUniversel: sousFamille.isUniversel,
            });
            for (const [
              index,
              genArt,
            ] of sousFamille.genericArticleId.entries()) {
              excelTabGenArt.push({
                groupe: group.name,
                famille: famille.name,
                sousFamille: sousFamille.name,
                genericArticleId: genArt,
                genericArticleId_name: sousFamille.genericArticleId_name[index],
              });
            }
            for (const marque of sousFamille.brandPrimary) {
              excelMarque.push({
                groupe: group.name,
                famille: famille.name,
                sousFamille: sousFamille.name,
                marque: marque,
              });
            }
            for (const marque of sousFamille.brandSecondary) {
              marqueSecondary.push({
                groupe: group.name,
                famille: famille.name,
                sousFamille: sousFamille.name,
                marque: marque,
              });
            }
          }
        }
      }
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(excelTab);
      let wsGenArt = XLSX.utils.json_to_sheet(excelTabGenArt);
      let wsMarque = XLSX.utils.json_to_sheet(excelMarque);
      let wsMarqueSecondary = XLSX.utils.json_to_sheet(marqueSecondary);
      XLSX.utils.book_append_sheet(wb, ws, "configuration");
      XLSX.utils.book_append_sheet(wb, wsGenArt, "genArt");
      XLSX.utils.book_append_sheet(wb, wsMarque, "marque");
      XLSX.utils.book_append_sheet(wb, wsMarqueSecondary, "marqueSecondary");

      XLSX.writeFile(wb, "Catalogue_" + this.user.proprietaire + ".xlsx");
    },
    async showUniverselConfig(sousFamille) {
      const response = await axios.get(
        `${process.env.VUE_APP_API}/configurationUniversel/getConfiguration/${this.user.proprietaire}`
      );
      this.configurationUniversel = response.data;
      this.sousFamilleSelected_U = sousFamille;
      this.showUniversel = true;
    },
    async linkUniversel(sousFamille, universel) {
      let res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/linkUniversel`,
        {
          sousFamille: sousFamille,
          universel: universel,
        }
      );

      this.sousFamilleSelected_U.universel = res.data.universel;
      this.getConfiguration();
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getConfiguration();
  },
};
</script>
<style></style>
