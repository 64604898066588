<template>
  <div class="flex justify-between">
    <div
      id="retour"
      class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
      @click="$router.push('/articlehub')"
    >
      <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
      <span>Retour</span>
    </div>
    <div><titrePages :title="'Liste des article'" /></div>
    <div class="flex justify-end">
      <!-- <searchInput
        placeholder="Rechercher dans articles"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      /> -->

      <addArticle v-if="showArticle" :showArticle="showArticle" />
      <button
        @click="toggleModale"
        class="px-6 py-2 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
      >
        Créer
      </button>
    </div>
  </div>

  <div>
    <!-- Modal pour un article -->
    <div
      v-if="openArticle"
      :class="`modal ${
        !openArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <titrePages :title="'Liste'" />
      <div
        @click="openArticle = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">
              FOURNISSEUR{{ selectedArticle.Code_fournisseur }}
            </p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openArticle = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->

          <div class="px-5 py-2 text-black bg-gray-300 border-b">
            <p class="absolute text-2xl font-bold text-left">
              MARQUE{{ selectedArticle.Code_marque }}
            </p>
            <p class="relative text-2xl font-bold text-right">
              REF. : {{ selectedArticle.Ref_fournisseur }}
            </p>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b text-center">
            <label class="text-xs">Libellé</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">description</span>
              </span>

              <input
                type="text"
                :placeholder="selectedArticle.Ref_fournisseur"
                class="w-full focus:outline-2 outline-sky-300 px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <div class="absolute float-left">
              <label class="text-xs">Prix d'achat HT</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">euro</span>
                </span>

                <div
                  type="text"
                  class="w-full px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                >
                  {{ selectedArticle.Prix_euro }}
                </div>
              </div>
            </div>

            <div class="float-right">
              <label class="text-xs">P.P.C. HT</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">euro</span>
                </span>

                <div
                  type="text"
                  class="w-full px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                >
                  {{ selectedArticle.Prix_euro }}
                </div>
              </div>
            </div>
          </div>

          <div class="px-5 py-2 pt-20 text-gray-700 bg-gray-200 border-b">
            <div class="absolute float-left">
              <label class="text-xs">Emplacement</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">not_listed_location</span>
                </span>

                <div
                  type="text"
                  class="w-full px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                >
                  {{ selectedArticle.Prix_euro }}
                </div>
              </div>
            </div>

            <div class="float-right">
              <label class="text-xs">Code-barres</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">qr_code_scanner</span>
                </span>

                <div
                  type="text"
                  class="w-full px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                >
                  {{ selectedArticle.Code_EAN }}
                </div>
              </div>
            </div>
          </div>

          <div class="px-5 py-2 pt-20 text-gray-700 bg-gray-200 border-b">
            <div class="absolute float-left">
              <label class="text-xs">Quantité stock</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">inventory</span>
                </span>

                <div
                  type="text"
                  class="w-full px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                >
                  {{ selectedArticle.Prix_euro }}
                </div>
              </div>
            </div>

            <div class="absolute float-center mx-28">
              <label class="text-xs">Mouvement stock</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">timeline</span>
                </span>

                <div
                  type="text"
                  class="w-full px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                >
                  {{ selectedArticle.Prix_euro }}
                </div>
              </div>
            </div>

            <div class="absolute float-center ml-60">
              <label class="text-xs">Mouvement +/-</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">trending_up</span>
                </span>

                <div
                  type="text"
                  class="w-full px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                >
                  <!-- {{ selectedArticle.Prix_euro }} -->
                </div>
              </div>
            </div>
          </div>

          <div class="px-5 py-6 pt-20 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Dimension</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> straighten </span>
              </span>

              <div
                type="text"
                class="w-full px-10 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
              >
                Poids : {{ selectedArticle.Poids }} g <br />
                Hauteur : {{ selectedArticle.Hauteur }} cm <br />
                Longueur : {{ selectedArticle.Longueur }} cm <br />
                Largeur : {{ selectedArticle.Largeur }} cm
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openArticle = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="openArticle = false"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal pour un article -->

    <!-- Modal modifier article -->
    <div
      v-if="openModifier"
      :class="`modal ${
        !openModifier && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openModifier = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 65rem; max-height: 45rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Ajouter un article</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openModifier = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->

          <div class="grid grid-cols-2">
            <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Référence fournisseur</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  v-model="modif_ref_fournisseur"
                  :placeholder="articleChosed.Ref_fournisseur"
                  class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putRefFourn(articleChosed.Code_EAN, modif_ref_fournisseur)
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>

            <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Référence fournisseur courte</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  v-model="modif_ref_fournisseur_courte"
                  :placeholder="articleChosed.Ref_fournisseur_courte"
                  class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putRefFournCourte(
                      articleChosed.Code_EAN,
                      modif_ref_fournisseur_courte
                    )
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-2">
            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Code-barres</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  v-model="modif_code_ean"
                  :placeholder="articleChosed.Code_EAN"
                  class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="putCodeEan(articleChosed.Code_EAN, modif_code_ean)"
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Description</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  v-model="modif_description"
                  :placeholder="articleChosed.Description"
                  class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putDescription(articleChosed.Code_EAN, modif_description)
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-2">
            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Fournisseur</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <select
                  v-model="modif_fournisseur"
                  :value="articleChosed.fournisseur"
                  class="cursor-pointer w-full focus:outline-2 outline-sky-300 text-black p-2 border-transparent rounded-md"
                >
                  <option
                    v-for="(c, index) in fournisseurList"
                    :key="index"
                    :value="c.MakerName"
                  >
                    {{ c.MakerName }}
                  </option>
                </select>

                <!-- <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <svg
                      class="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div> -->

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putFournisseur(articleChosed.Code_EAN, modif_fournisseur)
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Marque</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  v-model="modif_code_marque"
                  :placeholder="articleChosed.Code_marque"
                  class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putCodeMarque(articleChosed.Code_EAN, modif_code_marque)
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-3">
            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Quantité min</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="Number"
                  v-model="modif_quantite_min"
                  :placeholder="articleChosed.Qte_mini"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putQuantiteMin(articleChosed.Code_EAN, modif_quantite_min)
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Quantité max</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="Number"
                  v-model="modif_quantite_max"
                  :placeholder="articleChosed.Qte_maxi"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putQuantiteMax(articleChosed.Code_EAN, modif_quantite_max)
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Quantité stock</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="Number"
                  v-model="modif_quantite_stock"
                  :placeholder="articleChosed.StockInfo"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putQuantiteStock(
                      articleChosed.Code_EAN,
                      modif_quantite_stock
                    )
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-2">
            <div div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">P.P.C.</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="Number"
                  v-model="modif_prix_euro"
                  :placeholder="articleChosed.Prix_euro"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="putPrixEuro(articleChosed.Code_EAN, modif_prix_euro)"
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>

            <div div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Prix d'achat</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="Number"
                  v-model="modif_prix_achat"
                  :placeholder="articleChosed.PNF"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putPrixAchat(articleChosed.Code_EAN, modif_prix_achat)
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-3">
            <div div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">PNT 1</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="Number"
                  v-model="modif_prix_pnt1"
                  :placeholder="articleChosed.PNT1"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="putPrixPNT1(articleChosed.Code_EAN, modif_prix_pnt1)"
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>

            <div div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">PNT 2</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="Number"
                  v-model="modif_prix_pnt2"
                  :placeholder="articleChosed.PNT2"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="putPrixPNT2(articleChosed.Code_EAN, modif_prix_pnt2)"
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>

            <div div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">PNT 3</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="Number"
                  v-model="modif_prix_pnt3"
                  :placeholder="articleChosed.PNT3"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="putPrixPNT3(articleChosed.Code_EAN, modif_prix_pnt3)"
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-2">
            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Code famille</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  v-model="modif_code_famille"
                  :placeholder="articleChosed.Code_famille_NU"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putCodeFamille(articleChosed.Code_EAN, modif_code_famille)
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Code sous-famille</label>

              <div class="flex relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  v-model="modif_code_sous_famille"
                  :placeholder="articleChosed.Code_sousfamille_NU"
                  class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />

                <button
                  class="m-2 p-0.5 w-8 h-8 hover:bg-red-500 shadow hover:bg-opacity-25 border border-red-500 border-opacity-25 rounded-full"
                  @click="
                    putCodeSousFamille(
                      articleChosed.Code_EAN,
                      modif_code_sous_famille
                    )
                  "
                >
                  <span class="material-icons-outlined text-red-500">save</span>
                </button>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openModifier = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin modal modifier article -->

    <!-- Breadcrumb -->
    <div class="flex flex-col mt-3 sm:flex-row">
      <div class="flex">
        <div class="relative">
          <select
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:bg-white"
          >
            <option>5</option>
            <option>10</option>
            <option>20</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>

        <div class="relative">
          <select
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border-t border-b border-r border-gray-400 rounded-r appearance-none sm:rounded-r-none sm:border-r-0 focus:bg-white"
          >
            <option>All</option>
            <option>Active</option>
            <option>Inactive</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="relative block mt-2 sm:mt-0">
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" class="w-4 h-4 text-gray-500 fill-current">
            <path
              d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
            />
          </svg>
        </span>
        <div class="">
          <input
            placeholder="Chercher par code article"
            v-model="articleSearch"
            @input="errorrecherchearticle = false"
            v-on:keyup.enter="getOneArticle()"
            class="block w-full focus:outline-2 outline-sky-300 py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 appearance-none sm:rounded-l-none focus:bg-white"
          />
        </div>
      </div>
      <div class="relative block mt-2 sm:mt-0">
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" class="w-4 h-4 text-gray-500 fill-current">
            <path
              d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
            />
          </svg>
        </span>

        <input
          placeholder="Chercher par EAN"
          v-model="articleSearchEAN"
          v-on:keyup.enter="getOneArticlebyEAN()"
          class="block w-full focus:outline-2 outline-sky-300 py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none sm:rounded-l-none focus:bg-white"
        />
      </div>
      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
        <label
          v-if="errorrecherchearticle && articleSearch != ''"
          class="text-xs"
          style="color: red"
        >
          L'article " {{ articleSearch }} {{ articleSearchEAN }} " n'existe pas
          !
        </label>
      </div>
    </div>

    <div class="flex flex-col mt-8">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Ref
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Famille d'article
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Sous famille d'article
                </th>
                <!-- <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Stock
                </th> -->
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(a, index) in Articles"
                :key="index"
                class="hover:bg-gray-50"
                style="cursor: pointer"
                @click="updateArticle(a)"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        <span v-if="a.Ref_fournisseur">{{
                          a.Ref_fournisseur.replace(/[^A-Z0-9]+/gi, "")
                        }}</span>
                        <span
                          v-if="a.plateform == 'all_plateform'"
                          class="p-1 ml-12 bg-orange-500 text-white rounded shadow"
                          >golda</span
                        >
                        <span
                          v-else
                          class="p-1 ml-12 bg-blue-500 text-white rounded shadow"
                        >
                          {{ a.plateform }}</span
                        >
                        <span
                          class="p-1 ml-4 bg-blue-500 text-white rounded shadow"
                        >
                          {{ a.Prefixe_tarif }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    {{ a.Code_EAN }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ a.Code_marque }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  @click="selectArticle(a)"
                >
                  {{ a.Description }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ a.Code_famille_NU }}
                  </div>
                  <!-- <div class="text-sm leading-5 text-gray-500">famille1</div> -->
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ a.Code_sousfamille_NU }}
                </td>

                <!-- <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    FAG
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                    </div>
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    EPE
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                    </div>
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    GAP
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                    </div>
                  </div>
                </td> -->
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="articlesLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(articlesLength / 10)"
            :clickFunction="getArticles"
            :currentPage="currentPage"
            :typeList="'articles'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'articles'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import addArticle from "../components/ajoutArticle/ajoutArticle.vue";
import pageNumber from "../components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
/* import backButton from "@/components/Button/back.vue"; */

import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyArticle",
  data() {
    return {
      open: false,
      openArticle: false,
      openModifier: false,
      Articles: [],
      Familles: [],
      articleChosed: [],
      articleNumber: 0,
      familyName: "",
      sousfamilyName: "",
      selectedArticle: [],
      articleSearch: "",
      articleSearchEAN: "",
      errorrecherchearticle: false,
      ref_fournisseur: "",
      ref_fournisseur_courte: "",
      code_ean: "",
      description: "",
      fournisseur: "",
      fournisseurList: [],
      code_marque: "",
      quantite_min: "",
      quantite_max: "",
      quantite_stock: "",
      prix_euro: "",
      prix_achat: "",
      prix_pnt1: "",
      prix_pnt2: "",
      prix_pnt3: "",
      code_famille: "",
      code_sous_famille: "",
      modif_ref_fournisseur: "",
      modif_ref_fournisseur_courte: "",
      modif_code_ean: "",
      modif_description: "",
      modif_fournisseur: "",
      modif_fournisseurList: [],
      modif_code_marque: "",
      modif_quantite_min: "",
      modif_quantite_max: "",
      modif_quantite_stock: "",
      modif_prix_euro: "",
      modif_prix_achat: "",
      modif_prix_pnt1: "",
      modif_prix_pnt2: "",
      modif_prix_pnt3: "",
      modif_code_famille: "",
      modif_code_sous_famille: "",
      showArticle: false,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      articlesLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,
    };
  },
  components: {
    addArticle,
    pageNumber,
    titrePages,
    // searchInput,
    /*  backButton, */
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getArticles(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/article/getbyowner/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.Articles = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArticlesLength() {
      axios
        .get(`${process.env.VUE_APP_API}/article/count`)
        .then((response) => {
          this.articlesLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFamille(codeRecherche) {
      axios
        .post(`${process.env.VUE_APP_API}/famillearticle/getone`, {
          Code: codeRecherche,
        })
        .then((response) => {
          this.familyName = response.data[0].Name;
        });
      return this.familyName;
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    getSousFamille(codeRecherchesous) {
      axios
        .post(`${process.env.VUE_APP_API}/sousfamillearticle/getone`, {
          Code: codeRecherchesous,
        })
        .then((response) => {
          this.sousfamilyName = response.data[0].Name;
        });
      return this.sousfamilyName;
    },
    selectArticle(articleTab) {
      this.openArticle = true;
      this.selectedArticle = articleTab;
    },
    async getOneArticle() {
      if (this.articleSearch != "") {
        this.errorrecherchearticle = false;
        axios
          .post(`${process.env.VUE_APP_API}/article/getone`, {
            Ref_fournisseur: this.articleSearch,
          })
          .then((response) => {
            this.Articles = response.data;
            if (response.data == 0) {
              this.errorrecherchearticle = true;
            }
          });
      } else {
        this.getArticles(1, 10);
      }
    },
    toggleModale() {
      this.showArticle = !this.showArticle;
    },
    getErrorSearch() {
      if (this.articleSearch != "") {
        console.log(this.articleSearch);
      }
    },
    getOneArticlebyEAN() {
      if (this.articleSearchEAN != "") {
        this.errorrecherchearticle = false;
        axios
          .post(`${process.env.VUE_APP_API}/article/getbyEAN`, {
            Code_EAN: this.articleSearchEAN,
          })
          .then((response) => {
            this.Articles = response.data;
            if (response.data == 0) {
              this.errorrecherchearticle = true;
            }
          });
      } else {
        this.getArticles(1, 10);
        this.errorrecherchearticle = true;
      }
    },
    // postArticles() {
    //   axios
    //     .post(`${process.env.VUE_APP_API}/article`, {
    //       Ref_fournisseur: this.ref_fournisseur,
    //       Ref_fournisseur_courte: this.ref_fournisseur_courte,
    //       Code_EAN: this.code_ean,
    //       Description: this.description,
    //       fournisseur: this.fournisseur.CompanyName,
    //       Code_marque: this.code_marque,
    //       Qte_mini: this.quantite_min,
    //       Qte_maxi: this.quantite_max,
    //       Prix_euro: parseFloat(this.prix_euro),
    //       PNF: this.prix_achat,
    //       PNT1: this.prix_pnt1,
    //       PNT2: this.prix_pnt2,
    //       PNT3: this.prix_pnt3,
    //       Code_famille_NU: this.code_famille,
    //       Code_sousfamille_NU: this.code_sous_famille,
    //       StockInfo: {
    //         Depot: {
    //           Prefix: "GAP",
    //         },
    //         Product: {
    //           Stock: this.quantite_stock,
    //         },
    //       },
    //       plateform: this.user.proprietaire,
    //     })
    //     .then(() => {
    //       this.getArticles(10, 10);
    //       this.toast.success("Article ajouté !", {
    //         position: "bottom-right",
    //         timeout: 1000,
    //         closeOnClick: true,
    //         pauseOnFocusLoss: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         draggablePercent: 1,
    //         showCloseButtonOnHover: false,
    //         hideProgressBar: true,
    //         closeButton: "button",
    //         icon: true,
    //         rtl: false,
    //       });
    //     });
    // },
    chooseArticle(art) {
      this.articleChosed = [];
      this.articleChosed = art;
      console.log(this.articleChosed);
      this.openModifier = true;
    },
    putRefFourn(articleid, modifier_ref_fournisseur) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Ref_fournisseur: modifier_ref_fournisseur,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putRefFournCourte(articleid, modifier_ref_fournisseur_courte) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Ref_fournisseur_courte: modifier_ref_fournisseur_courte,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putCodeEan(articleid, modifier_code_ean) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Code_EAN: modifier_code_ean,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putDescription(articleid, modifier_description) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Description: modifier_description,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putFournisseur(articleid, modifier_fournisseur) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          fournisseur: modifier_fournisseur,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putCodeMarque(articleid, modifier_code_marque) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Code_marque: modifier_code_marque,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putQuantiteMin(articleid, modifier_quantite_min) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Qte_mini: modifier_quantite_min,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putQuantiteMax(articleid, modifier_quantite_max) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Qte_maxi: modifier_quantite_max,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putQuantiteStock(articleid, modifier_quantite_stock) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          StockInfo: {
            Product: {
              Stock: modifier_quantite_stock,
            },
          },
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putPrixEuro(articleid, modifier_prix_euro) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Prix_euro: modifier_prix_euro,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putPrixAchat(articleid, modifier_prix_achat) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          PNF: modifier_prix_achat,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putPrixPNT1(articleid, modifier_prix_pnt1) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          PNT1: modifier_prix_pnt1,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putPrixPNT2(articleid, modifier_prix_pnt2) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          PNT2: modifier_prix_pnt2,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putPrixPNT3(articleid, modifier_prix_pnt3) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          PNT3: modifier_prix_pnt3,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putCodeFamille(articleid, modifier_code_famille) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Code_famille_NU: modifier_code_famille,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    putCodeSousFamille(articleid, modifier_code_sous_famille) {
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, {
          Code_sousfamille_NU: modifier_code_sous_famille,
        })
        .then(() => {
          this.getArticles(1, 10);
        });
    },
    updateArticle(article) {
      this.$store.dispatch("setArticle", article);
      this.$router.push({
        name: "SetArticles",
        // params: article,
      });
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getArticles(skipValue, limitValue);
        this.getArticlesLength();
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/article/searchInPlateform`, {
            plateform: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.Articles = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
  },
  mounted() {
    this.getArticles(1, 10);
    this.getArticlesLength();
    this.getFournisseur();
    this.pageSwitch();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
