<template>
  <div class="space-y-4">
    <div class="flex justify-between items-center mb-12">
      <div>
        <button
          class="p-1 bg-bluevk rounded shadow text-white"
          @click="createControle()"
        >
          Nouveau controle
        </button>
      </div>
      <titrePages class="flex flex-1 justify-center" :title="'Atelier'" />
    </div>

    <div class="grid grid-cols-3 gap-3">
      <div
        class="p-2 bg-orangevk rounded shadow hover:bg-gray-100 cursor-pointer"
        @click="setCP(cp._id)"
        v-for="(cp, indexCP) in cpTab"
        :key="indexCP"
      >
        <div class="flex bg-white px-4 py-1 rounded shadow-md">
          <p class="text-blue-500 font-bold">{{ cp.controleNumber }}</p>
          <div
            v-if="cp.vehiculeData.length > 0"
            class="flex items-center space-x-4"
          >
            <div class="flex-shrink-0">
              <img
                src="@/assets/ControlePreliminaire/car.jpeg"
                alt="Car Image"
                class="h-16 object-cover rounded-full"
              />
            </div>
            <div v-if="cp.vehiculeData[0].data" class="">
              <h3 class="text-lg font-semibold uppercase">
                {{ cp.vehiculeData[0].data.AWN_immat }}
              </h3>
              <p class="text-gray-500" title="VIN">
                {{ cp.vehiculeData[0].data.AWN_VIN }}
              </p>
              <p class="text-gray-400" title="Type mine">
                {{ cp.vehiculeData[0].data.AWN_type_mine }}
              </p>
              <h4>{{ cp.vehiculeData[0].data.AWN_marque }}</h4>
              <h4>
                {{ cp.vehiculeData[0].data.AWN_modele }}
                {{ cp.vehiculeData[0].data.AWN_version }}
              </h4>
              <h4 title="Mise en circulation">
                {{ cp.vehiculeData[0].data.AWN_date_mise_en_circulation }}
              </h4>
            </div>
            <div v-if="cp.vehiculeData[0].data" class="text-xs">
              <h4>{{ cp.vehiculeData[0].data.AWN_energie }}</h4>
              <h4>{{ cp.vehiculeData[0].data.AWN_nbr_vitesses }} Cv</h4>
              <h4>{{ cp.vehiculeData[0].data.AWN_puissance_KW }} kW</h4>
              <h4>{{ cp.vehiculeData[0].data.AWN_nbr_portes }} portes</h4>
              <h4>{{ cp.vehiculeData[0].data.AWN_couleur }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import {
  createCP,
  getCP,
} from "@/hooks/controlePreliminaire/controlePreliminaire.ts";
import titrePages from "@/components/Text/grandeTitre.vue";
export default {
  name: "MyControlePreliminaire",
  data() {
    return {
      cpTab: [],
    };
  },
  components: { titrePages },
  computed: {
    ...mapGetters(["user", "comptoirSessionActive", "vehicule"]),
  },
  methods: {
    ...mapActions(["setcpselected"]),
    async createControle() {
      let cr = await createCP(
        this.user.proprietaire,
        this.user.username,
        this.comptoirSessionActive[0].Client,
        this.vehicule
      );
      this.setcpselected(cr._id);
      this.$router.push({
        name: "ControlePreliminaire",
      });
    },
    async setCP(id) {
      this.setcpselected(id);
      this.$router.push({
        name: "ControlePreliminaire",
      });
    },
  },
  async beforeMount() {
    this.cpTab = await getCP(this.user.proprietaire);
  },
};
</script>
