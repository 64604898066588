<template>
  <titrePages
    class="flex justify-center"
    :title="'Bons de transport clôturés'"
  />
  <div>
    <!-- Modal Detail Bdl -->
    <div
      v-if="showDetailBdl"
      :class="`modal ${
        !showDetailBdl && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showDetailBdl = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 70rem"
        class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Récapitulatif</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showDetailBdl = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-1/3 ml-2">
              Référence client:
              {{ detailBdl.Note }}
            </div>

            <!--  -->
            <div
              class="flex flex-col h-8 justify-end items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="createPDF(detailBdl)"
              >
                download
              </span>
              <div>BT n°{{ detailBdl.Numero }}</div>
            </div>
          </div>

          <!--Body-->
          <table v-if="detailBdl.groupedTab" class="min-w-full mt-4">
            <div class="overflow-y-auto max-h-[25rem]">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Poids
                  </th>
                </tr>
              </thead>

              <tbody
                v-if="detailBdl.groupedTab.length > 0"
                class="bg-white w-fit"
              >
                <div
                  v-for="(bdl, ind) in detailBdl.groupedTab"
                  :key="ind"
                  class="tableHEADTR"
                >
                  <br />
                  <div class="bg-gray-100">BL n°{{ bdl.Numero }}</div>

                  <tr
                    v-for="(r, index) in detailBdl.groupedTab[ind].pdfData"
                    :key="index"
                    class="tableHEADTR"
                  >
                    <td
                      class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center">
                        <span class="ml-4 text-sm leading-5 text-gray-900">
                          {{ r.ref }}
                        </span>
                      </div>
                    </td>

                    <td
                      class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5 text-gray-500">
                        {{ r.description }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="text-sm leading-5">
                        {{ r.quantite }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{
                        parseFloat(
                          detailBdl.groupedTab[ind].Articles[index].Prix_euro
                        ).toFixed(2)
                      }}
                      €
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        v-if="
                          detailBdl.groupedTab[ind].Articles[index].Prix_euro !=
                          'prixnet'
                        "
                      >
                        {{ r.remise }} %
                      </div>
                      <div v-else>prix net</div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        v-if="
                          detailBdl.groupedTab[ind].Articles[index]
                            .PrixFournisseurType != 'prixnet'
                        "
                      >
                        {{
                          parseFloat(
                            detailBdl.groupedTab[ind].Articles[index]
                              .Prix_euro -
                              detailBdl.groupedTab[ind].Articles[index]
                                .Prix_euro *
                                (r.remise / 100)
                          ).toFixed(2)
                        }}
                        €
                      </div>

                      <div v-else>
                        {{
                          parseFloat(
                            detailBdl.groupedTab[ind].Articles[index].Prix_vente
                          ).toFixed(2)
                        }}
                        €
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{ parseFloat(r.total).toFixed(2) }}€
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{ detailBdl.groupedTab[ind].Articles[index].Poids }}
                      {{ detailBdl.groupedTab[ind].Articles[index].Udm_poids }}
                    </td>
                  </tr>
                </div>
              </tbody>
            </div>
            <thead class="tableHEADTR mt-12 border-t">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    Total HT : {{ parseFloat(detailTotal).toFixed(2) }} €
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    Total TTC :
                    {{ parseFloat(detailTotal + detailTotal * 0.2).toFixed(2) }}
                    €
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
              </tr>
            </thead>
          </table>

          <!--Footer-->
          <!-- <div class="flex justify-end pt-2">
          <button
            @click="showDetailBdl = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div> -->
        </div>
      </div>
    </div>
    <!-- Fin modal Detail Bdl -->
    <regrouperCmdPro class="mb-4" @regroupement="regroupement = $event" />

    <searchInput
      v-if="!regroupement"
      placeholder="Rechercher dans bons de transport clôturés"
      @searchWord="search(1, 10, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
    />

    <div v-if="!regroupement" class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  style="width: 1rem"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Sélect.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  style="width: 1em"
                >
                  <span>Réf. client</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Date</span>
                  <span
                    v-if="showSortDate"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByDate(),
                        (showReverseDate = true),
                        (showSortDate = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseDate"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByDate(),
                        (showSortDate = true),
                        (showReverseDate = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Numéro</span>
                  <span
                    v-if="showSortNumero"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByNumero(),
                        (showReverseNumero = true),
                        (showSortNumero = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseNumero"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByNumero(),
                        (showSortNumero = true),
                        (showReverseNumero = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Client</span>
                  <span
                    v-if="showSortAlpha"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByAlpha(),
                        (showReverseAlpha = true),
                        (showSortAlpha = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseAlpha"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByAlpha(),
                        (showSortAlpha = true),
                        (showReverseAlpha = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Créé par</span>
                  <span
                    v-if="showSortUsername"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByUsername(),
                        (showReverseUsername = true),
                        (showSortUsername = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseUsername"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByUsername(),
                        (showSortUsername = true),
                        (showReverseUsername = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <!-- <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>HT</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>TTC</span>
                </th> -->
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  PDF
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Modifier
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in infosGroupedCmdPro" :key="index">
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1rem"
                >
                  <input
                    type="checkbox"
                    class="focus:outline-2 outline-sky-300"
                    :value="u"
                    v-model="selectedBDL"
                  />
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1em"
                >
                  {{ u.Note }}
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 1em"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                        <img
                          class="w-10 h-10 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt
                        />
                      </div>-->

                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ getFRDate(u.Date) }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.Numero }}
                    <span
                      @click="getDetailBdl(u)"
                      title="Plus d'informations"
                      class="material-icons-outlined text-base text-cyan-600 cursor-pointer"
                    >
                      info
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Client }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Name }}
                </td>

                <!-- <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice).toFixed(2) }} €
                </td> -->

                <!-- <td
                  v-if="u.TotalPriceTTC"
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
                </td>
                <td
                  v-else
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }}
                  €
                </td> -->

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                    @click="createPDF(u)"
                  >
                    download
                  </span>
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="relative">
                    <div
                      class="flex text-xs w-fit h-6 p-1 cursor-pointer rounded-md bg-orange-400 hover:bg-orange-300 text-white"
                      @click="
                        showCanceledGroup[index] = !showCanceledGroup[index]
                      "
                    >
                      <span class="material-icons-round text-xs"> cancel </span>
                      <span class="mx-2">Annuler groupement</span>
                    </div>

                    <div
                      v-show="showCanceledGroup[index]"
                      class="absolute mt-1 bg-gray-200 p-2 rounded shadow-lg z-20"
                    >
                      <div class="flex flex-col justify-center space-x-4">
                        <span>Êtes-vous sûr de vouloir supprimer ?</span>
                        <div class="flex space-x-4 justify-center">
                          <button
                            class="w-24 p-3 px-6 py-3 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
                            @click="cancel(u, index)"
                          >
                            Oui
                          </button>
                          <button
                            class="w-24 p-3 px-6 py-3 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none py-1 px-2"
                            @click="showCanceledGroup[index] = false"
                          >
                            Annuler
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosBdcLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosBdcLength / 10)"
            :clickFunction="getInfosBdls"
            :currentPage="currentPage"
            :typeList="'bons de transport'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'bons de transport'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { createGroupedBT } from "@/hooks/pdf/groupedCmd.js";
import { useToast } from "vue-toastification";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { cancelGrouping } from "@/hooks/regroupeDoc/commandePro";
import regrouperCmdPro from "@/components/Commandes/regrouperCmdPro.vue";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyBdls",
  data() {
    return {
      regroupement: false,
      infosGroupedCmdPro: [],
      infosGroupedCmdProTmp: [],
      devisSelected: [],
      openSave: false,
      openModifier: false,
      reglementTab: [],
      numDoc: 0,
      index: 0,
      showCanceledGroup: [],
      openSaveBdl: false,
      errorPaymentMethod: false,
      inputFilter: "",
      noteDocument: "",
      titreDocument: "",

      showSortDate: true,
      showReverseDate: false,
      showSortNumero: true,
      showReverseNumero: false,
      showSortAlpha: true,
      showReverseAlpha: false,
      showSortUsername: true,
      showReverseUsername: false,
      showSortTotalPrice: true,
      showReverseTotalPrice: false,

      showEmail: [],
      errors: [],

      showDetailBdl: false,
      detailBdl: [],
      detailTotal: null,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      infosBdcLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,
      selectedBDL: [],
    };
  },
  components: {
    pageNumber,
    searchInput,
    regrouperCmdPro,
    titrePages,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    ...mapActions([
      "suppStockArticle",
      "addStockArticle",
      // "setMvtStock",
      "updateactionArticle",
      "createcomptoirsession",
      "setbcselected",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    getInfosBdls(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/groupedCmd/getbyowner/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log(response.data);
          this.infosGroupedCmdPro = response.data;
          this.infosGroupedCmdProTmp = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosBdcLength() {
      axios
        .post(`${process.env.VUE_APP_API}/groupedCmd/getbyowner/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.infosBdcLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDetailBdl(u) {
      this.detailBdl = u;
      this.detailTotal = u.TotalPrice;
      this.showDetailBdl = true;
    },
    async cancel(data, index) {
      await cancelGrouping(this.user.proprietaire, data);
      this.showCanceledGroup[index] = false;
      this.success("Bon de transport supprimé !");
      this.getInfosBdls(this.currentPage, 10);
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createGroupedBT(objet, false);
      } else {
        createGroupedBT(objet, true);
      }
    },
    // Message
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    // FILTRES
    sortByDate() {
      this.infosGroupedCmdPro.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    reverseByDate() {
      this.sortDate = this.infosGroupedCmdPro.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
      this.sortDate.reverse((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    sortByNumero() {
      this.infosGroupedCmdPro.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
    },
    reverseByNumero() {
      this.sortNumero = this.infosGroupedCmdPro.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
      this.sortNumero.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByAlpha() {
      this.infosGroupedCmdPro.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    reverseByAlpha() {
      this.sortAlpha = this.infosGroupedCmdPro.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
      this.sortAlpha.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByUsername() {
      this.infosGroupedCmdPro.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    reverseByUsername() {
      this.sortUsername = this.infosGroupedCmdPro.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
      this.sortUsername.reverse((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    sortByTotalPrice() {
      this.infosGroupedCmdPro.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    reverseByTotalPrice() {
      this.sortTotalPrice = this.infosGroupedCmdPro.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
      this.sortTotalPrice.reverse((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    filterByValue(value) {
      var filterTab = [];
      if (value != "") {
        this.infosGroupedCmdPro = this.infosGroupedCmdProTmp;
        this.infosGroupedCmdPro.filter((obj) => {
          //loop through each object
          for (const key in obj) {
            if (
              obj[key].toString().toLowerCase().includes(value.toLowerCase())
            ) {
              filterTab.push(obj);
              break;
            }
          }
        });
        this.infosGroupedCmdPro = filterTab;
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getInfosBdls(skipValue, limitValue);
        this.getInfosBdcLength();
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/groupedCmd/searchInPlateform`, {
            plateform: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosGroupedCmdPro = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
  },
  mounted() {
    this.getInfosBdls(1, 10);
    this.getInfosBdcLength();
    this.pageSwitch();
  },
};
</script>

<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
