<template>
  <div class="flex flex-row">
    <div class="bg-white rounded shadow w-1/5 flex flex-col overflow-y-auto">
      <div class="p-2">Familles :</div>
      <div
        v-for="(fam, indexF) in familleUniverselle"
        :key="indexF"
        class="flex flex-row space-x-4 h-10 w-full hover:bg-gray-100 cursor-pointer p-2"
        @mouseover="openCategory(fam)"
      >
        <!-- <div>img</div> -->
        <div>{{ fam.name }}</div>
      </div>
      <div class="p-2">Filtres</div>
      <div>Sections (get sections)</div>
      <div>Filtre dimensions</div>
      <div>Filtre specialisé</div>
    </div>
    <div v-if="!showCatalogue" class="relative flex flex-col w-4/5">
      <div
        v-if="showCategory"
        class="absolute bg-gray-500 bg-opacity-50 w-full h-full z-20"
        @mouseover="closeCategory()"
      >
        <div class="bg-white w-4/5 p-4" @mouseover.stop>
          <div class="grid grid-cols-4 gap-4">
            <div
              class=""
              v-for="(cat, indexC) in categoryUniverselle"
              :key="indexC"
            >
              <div class="font-bold">{{ cat.name }}</div>
              <div class="flex flex-col space-y-1">
                <div
                  class="text-sm hover:text-gray-500 cursor-pointer w-fit"
                  v-for="(sousCat, indexSC) in cat.sousFamille"
                  :key="indexSC"
                  @click="selectSousCategory(sousCat)"
                >
                  {{ sousCat.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-4">
        <div class="grid grid-cols-2 gap-4">
          <div class="h-[40vh]">
            <div class="h-full w-full" v-if="promoUniverselImage0">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage0.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[40vh]">
            <div class="h-full w-full" v-if="promoUniverselImage1">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage1.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="bg-white mt-4 rounded shadow w-full h-[3vh] flex flex-row itemse-center text-sm"
        >
          Barre de message
        </div> -->
        <div class="mt-4 grid grid-cols-5 gap-4">
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage2">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage2.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage3">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage3.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage4">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage4.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage5">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage5.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage6">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage6.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCatalogue" class="relative flex flex-col w-4/5">
      <div
        v-if="showCategory"
        class="absolute bg-gray-500 bg-opacity-50 w-full h-full z-20"
        @mouseover="closeCategory()"
      >
        <div class="bg-white w-4/5 p-4" @mouseover.stop>
          <div class="grid grid-cols-4 gap-4">
            <div
              class=""
              v-for="(cat, indexC) in categoryUniverselle"
              :key="indexC"
            >
              <div class="font-bold">{{ cat.name }}</div>
              <div class="flex flex-col space-y-1">
                <div
                  class="text-sm hover:text-gray-500 cursor-pointer w-fit"
                  v-for="(sousCat, indexSC) in cat.sousFamille"
                  :key="indexSC"
                  @click="selectSousCategory(sousCat)"
                >
                  {{ sousCat.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="ml-4 text-sm flex flex-row items-center w-fit hover:text-blue-500"
        @click="backAccueil()"
      >
        <span class="material-symbols-rounded text-sm"> arrow_back_ios </span>
        <span>Accueil</span>
      </button>
      <div
        class="scroll-container flex flex-col space-y-2 ml-4 mt-2 h-80 overflow-y-auto"
        @scroll="handleScroll"
        ref="articleContainer"
      >
        <div
          class="space-y-2"
          v-for="(famille, indexFamille) in ArticlesTab.data"
          :key="indexFamille"
        >
          <section-title
            :titre="famille.designation"
            @click="famille.show = !famille.show"
          />
          <div
            v-for="(article, indexArticle) in famille.data"
            :key="article.dbArticle._id + reloadkey.toString()"
          >
            <article-catalogue
              v-if="article.type == 'db'"
              v-show="famille.show"
              :tdArticle="article.tdArticle"
              :article="article.dbArticle"
              :Ref_fournisseur="article.dbArticle.Ref_fournisseur"
              :Code_marque="article.dbArticle.Code_marque"
              :Fournisseur="article.dbArticle.Prefixe_tarif"
              :genericArticleId="
                article.tdArticle.genericArticles[0].genericArticleId
              "
              :Description="article.dbArticle.Description"
              :partsList="article.partsList"
              :Images="article.image"
              :Location="[]"
              :Systeme="[]"
              :Stock="article.stock"
              :Prix="article.prix"
              :quantity="article.quantity"
              @checkedArticle="checkArticle(indexArticle, indexFamille)"
              :compareTabLength="compareTab.length"
              @infoArticle="plusInfo(article.dbArticle)"
              :partsListTab="localpartsListTab"
              @displayPartsList="displayPartsList"
              @deletePartsList="deletePartsList"
              :savedSearch="ArticlesTab.savedSearch"
              @setFraisdePort="article.dbArticle['fraisdeport'] = $event"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import articleCatalogue from "@/components/Article/articleCatalogue.vue";
export default {
  data() {
    return {
      familleUniverselle: [],
      selectedFamille: "",
      selectedCategory: "",
      selectedSousCategory: "",
      categoryUniverselle: [],
      sousCategoryUniverselle: [],
      showCategory: false,
      showSousCategory: false,
      showCatalogue: false,
      ArticlesTab: [],
      reloadkey: 0,
      compareTab: [],
      scrollTab: [],

      currentPage: 1,
      maxPages: 1,
      lastPageLimit: 0,
      articlesPerPage: 20,

      promoUniverselImage0: null,
      promoUniverselImage1: null,
      promoUniverselImage2: null,
      promoUniverselImage3: null,
      promoUniverselImage4: null,
      promoUniverselImage5: null,
      promoUniverselImage6: null,
    };
  },
  components: {
    articleCatalogue,
  },
  computed: {
    ...mapGetters(["plateform", "user", "plateform", "linkUniverselSelected"]),
  },
  methods: {
    ...mapActions(["selectSousCategoryUniverselle", "selectLinkUniversel"]),
    async getFamilleUniverselle() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      const catconf = await axios.get(
        `${process.env.VUE_APP_API}/configurationUniversel/getGroupe/${plateform_mere}`
      );
      const response = await axios.get(
        `${process.env.VUE_APP_API}/configurationUniversel/getConfiguration/${plateform_mere}`
      );
      this.familleUniverselle = response.data;
    },
    async openCategory(famille) {
      if (this.selectedFamille === famille._id) {
        // this.showCategory = false;
        // this.showSousCategory = false;
        this.categoryUniverselle = [];
        this.sousCategoryUniverselle = [];
        this.selectedFamille = "";
        this.selectedCategory = "";
      } else {
        this.showCategory = true;
        this.sousCategoryUniverselle = [];
        this.selectedFamille = famille._id;
      }

      if (this.showCategory) {
        // const catconf = await axios.post(
        //   `${process.env.VUE_APP_API}/configurationUniversel/getFamille`,
        //   {
        //     id: famille._id,
        //   }
        // );
        this.categoryUniverselle = famille.famille;
      }
    },
    closeCategory() {
      this.showCategory = false;
      this.showSousCategory = false;
      this.categoryUniverselle = [];
      this.sousCategoryUniverselle = [];
      this.selectedFamille = "";
      this.selectedCategory = "";
    },
    async openSousCategory(category) {
      if (this.selectedCategory === category._id) {
        this.showSousCategory = false;
        this.sousCategoryUniverselle = [];
        this.selectedCategory = "";
      } else {
        this.showSousCategory = true;
        this.selectedCategory = category._id;
      }

      if (this.showSousCategory) {
        const catconf = await axios.post(
          `${process.env.VUE_APP_API}/configurationUniversel/getSousFamille`,
          {
            id: category._id,
          }
        );
        this.sousCategoryUniverselle = catconf.data;
      }
    },
    async selectSousCategory(sousCategory) {
      this.ArticlesTab = [];
      this.scrollTab = [];
      this.articlesPerPage = 20;
      this.currentPage = 1;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      const article = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/getCatalogueUniverselleArticles`,
        {
          plateform: plateform_mere,
          sousCategory: sousCategory,
          skip: (this.currentPage - 1) * 10,
          limit: this.articlesPerPage,
          sort_by: "prix",
          sort_order: -1,
        }
      );
      this.maxPages = Math.ceil(article.data.count / this.articlesPerPage) - 1;
      this.lastPageLimit = article.data.count % this.articlesPerPage;
      this.ArticlesTab = article.data;
      this.selectedSousCategory = sousCategory;
      this.showCatalogue = true;
      this.closeCategory();
    },
    async loadMoreArticles() {
      if (this.currentPage <= this.maxPages) {
        this.currentPage === this.maxPages
          ? (this.articlesPerPage = this.lastPageLimit)
          : (this.articlesPerPage = 20);
        this.currentPage++;
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere_catalogue) {
          plateform_mere = this.plateform.plateform_mere_catalogue;
        }
        const article = await axios.post(
          `${process.env.VUE_APP_API}/configurationUniversel/getCatalogueUniverselleArticles`,
          {
            plateform: plateform_mere,
            sousCategory: this.selectedSousCategory,
            skip: (this.currentPage - 1) * 10,
            limit: this.articlesPerPage,
            sort_by: "prix",
            sort_order: -1,
          }
        );
        this.ArticlesTab.data.push(...article.data.data);
      }
    },
    handleScroll() {
      const container = this.$refs.articleContainer;
      const scrollH = container.scrollHeight - 500;
      if (container.scrollTop + container.clientHeight + 1 >= scrollH) {
        if (!this.scrollTab.includes(scrollH)) {
          this.loadMoreArticles();
          this.scrollTab.push(scrollH);
        }
      }
    },

    async getPromoUniverselImage() {
      const getPromoUniverselImage = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/getPromoUniverselImage`,
        {
          plateform: this.user.proprietaire,
        }
      );
      this.promoUniverselImage0 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 0
      );
      this.promoUniverselImage1 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 1
      );
      this.promoUniverselImage2 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 2
      );
      this.promoUniverselImage3 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 3
      );
      this.promoUniverselImage4 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 4
      );
      this.promoUniverselImage5 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 5
      );
      this.promoUniverselImage6 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 6
      );
    },
    backAccueil() {
      this.showCatalogue = false;
      this.ArticlesTab = [];
      this.selectedSousCategory = "";
    },
  },
  beforeMount() {
    this.getPromoUniverselImage();
  },
  async mounted() {
    this.getFamilleUniverselle();
    if (this.linkUniverselSelected) {
      this.selectSousCategory(this.linkUniverselSelected);
      await this.selectLinkUniversel({ state: "del", uni: null });
    }
  },
};
</script>
<style scoped>
.scroll-container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: just to hide the scrollbar */
}
</style>
