import { createApp } from "vue";

import DashboardLayout from "./components/DashboardLayout.vue";
import EmptyLayout from "./components/EmptyLayout.vue";
import "./assets/tailwind.css?v=1.0.35";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initializeApp } from "firebase/app";
import "firebase/auth";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import PrimeVue from "primevue/config";

import * as Sentry from "@sentry/vue";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

const appFirebase = initializeApp(firebaseConfig);
const app = createApp(App)
  .use(store)
  .use(PrimeVue)
  .use(appFirebase)
  .use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
  });

// Sentry.init({
//   app,
//   dsn: "https://bddeb9c4bb0f40b2b130c1d612884fb8@o4508444608626688.ingest.de.sentry.io/4508444758704208",
//   integrations: [
//     Sentry.browserTracingIntegration({ router }),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

app.component("default-layout", DashboardLayout);
app.component("empty-layout", EmptyLayout);

app.use(router).mount("#app");
