<template>
  <div
    v-if="openFournisseur"
    :class="`modal ${
      !openFournisseur && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openFournisseur = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 80vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">
            Disponibilité chez les fournisseurs pour
            {{ Article.Ref_fournisseur }}
          </p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openFournisseur = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div>
          <div class="w-full bg-white flex flex-col">
            <div class="flex w-full justify-end"></div>
            <div class="flex flex-col">
              <div
                class="flex flex-row items-center justify-between animate-pulse"
                v-show="loadingFournisseur"
              >
                <div>Recherche fournisseur...</div>
              </div>

              <div
                class="m-4 relative overflow-x-auto shadow-md sm:rounded-lg"
                v-show="!loadingFournisseur"
              >
                <table
                  class="w-full text-sm text-left rtl:text-right text-gray-500"
                >
                  <thead class="text-xs text-white uppercase bg-blue-500">
                    <tr>
                      <th scope="col" class="px-6 py-3">Fournisseur</th>
                      <th scope="col" class="px-6 py-3">PPC</th>
                      <th scope="col" class="px-6 py-3">Prix de vente</th>
                      <th scope="col" class="px-6 py-3">
                        Marge (seuil
                        <input
                          type="number"
                          class="w-12 text-black"
                          v-model="seuil_pourcent"
                        />
                        %)
                      </th>
                      <th scope="col" class="px-6 py-3">Prix d'Achat</th>
                      <th scope="col" class="px-6 py-3">Dispo</th>

                      <th scope="col" class="px-6 py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(fourn, indexFourn) in fournisseurTab"
                      :key="indexFourn"
                      :class="
                        indexFourn % 2 === 0
                          ? 'odd:bg-white  even:bg-gray-50 border-b '
                          : 'odd:bg-white  even:bg-gray-50 border-b '
                      "
                    >
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {{ fourn.fournisseur }}
                      </th>
                      <td class="px-6 py-4">
                        <div>
                          {{ parseFloat(fourn.prix.prix_brut).toFixed(2) }}
                          €
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div>
                          {{ parseFloat(fourn.prix.prix_net).toFixed(2) }}
                          €
                        </div>
                      </td>

                      <td class="px-6 py-4">
                        <div class="flex flex-row space-x-2">
                          <div
                            v-if="fourn.prix.marge_pourcent < seuil_pourcent"
                            class="text-red-700"
                          >
                            {{
                              parseFloat(fourn.prix.marge_pourcent).toFixed(2)
                            }}%
                          </div>
                          <div v-else class="text-green-700">
                            {{
                              parseFloat(fourn.prix.marge_pourcent).toFixed(2)
                            }}%
                          </div>
                          <div>
                            {{ parseFloat(fourn.prix.marge_euro).toFixed(2) }}
                            €
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="font-bold text-black">
                          {{ parseFloat(fourn.prix.prix_achat).toFixed(2) }}
                          €
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div v-if="fourn.loadingDispo">...</div>
                        <div v-else>
                          <div
                            v-if="
                              fourn.dispo.qtyJour + fourn.dispo.qtySoir >=
                              fourn.quantity
                            "
                            class="flex flex-row items-center space-x-2 text-blue-500"
                          >
                            <div>
                              <span class="material-icons-round">
                                check_circle
                              </span>
                            </div>
                            <div>
                              {{ fourn.dispo.qtyJour + fourn.dispo.qtySoir }}
                            </div>
                          </div>
                          <div
                            v-else
                            class="flex flex-row items-center space-x-2 text-orange-500"
                          >
                            <div>
                              <span class="material-icons-round"> help </span>
                            </div>
                            <div>
                              {{ fourn.dispo.qtyJour + fourn.dispo.qtySoir }}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex flex-row">
                          <input
                            type="number"
                            v-model="fourn.quantity"
                            @change="ajustDispoFournisseur(fourn, indexFourn)"
                            class="w-8 border border-gray-200 rounded"
                          />
                          <button
                            v-if="fourn.inCard && !loadingCommandeFournisseur"
                            class="material-icons-round text-green-500"
                            @click="
                              addToCommandeFournisseur(
                                fourn.article,
                                indexFourn,
                                fourn.quantity
                              )
                            "
                          >
                            add_shopping_cart
                          </button>
                          <button
                            v-else-if="!loadingCommandeFournisseur"
                            class="material-icons-round text-bluevk"
                            @click="
                              addToCommandeFournisseur(
                                fourn.article,
                                indexFourn,
                                fourn.quantity
                              )
                            "
                          >
                            add_shopping_cart
                          </button>
                          <span
                            v-else-if="loadingCommandeFournisseur"
                            class="material-icons-round text-bluevk animate-spin"
                          >
                            sync
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="openFillialeCommande"
    :class="`modal ${
      !openFillialeCommande && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openFillialeCommande = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 30vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Commande Filliale</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openFillialeCommande = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div>
          <div class="w-full bg-white flex flex-col">
            <div v-show="!loadingCommandeFilliale">
              Souhaitez-vous commander
              <input
                type="Number"
                class="w-10"
                v-model="infosCommandeFilliale.stock"
              />
              pièces chez {{ infosCommandeFilliale.plateform }} ?
            </div>
            <div v-show="loadingCommandeFilliale" class="animate-pulse">
              Commande en cours d'envoi...
            </div>
            <div class="flex justify-end mb-2">
              <button
                class="p-2 bg-blue-500 text-white rounded shadow"
                @click="reserveStockFilliale()"
              >
                Commander
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- NEW -->
  <div>
    <!-- DISPONIBLE VERTE/ORANGE/BLEU -->
    <div
      class="flex space-x-1.5 text-sm shadow px-1 cursor-pointer border border-gray-300 rounded-lg justify-between w-fit"
      :style="{ backgroundColor: componentColor }"
      @click="displayFournisseur()"
      :title="
        Stock.address && Stock.address != ''
          ? user.proprietaire + ' - Adresse : ' + Stock.address
          : user.proprietaire
      "
    >
      <p class="text-sm">{{ user.proprietaire.substring(0, 3) }}</p>
      <p>{{ Stock.stock }}</p>
      <div class="flex space-x-1 ml-2">
        <span class="material-symbols-rounded text-sm">inventory</span>
        <div
          class="text-sm"
          v-if="Stock.stock || Number.isInteger(Stock.stock)"
        >
          <span v-if="Stock.reserved">{{
            parseInt(Stock.stock) + parseInt(Stock.reserved)
          }}</span
          ><span v-else>{{ parseInt(Stock.stock) }}</span>
        </div>
        <span class="text-sm" v-else>0</span>
        <span class="material-symbols-rounded text-sm"
          >deployed_code_history</span
        >
        <span v-if="Stock.reserved">{{ Stock.reserved }}</span>
        <span v-else>0</span>
        <span class="material-symbols-rounded text-sm">local_shipping</span>
        <p>{{ Stock.backorder }}</p>
      </div>
    </div>

    <!-- DISPONIBLE FILIALE -->
    <div
      v-show="filialeTab.length > 0"
      @click="setFillialeCommande(filliale)"
      v-for="(filliale, indexFilliale) in filialeTab"
      class="w-fit flex space-x-1.5 text-sm shadow px-1 cursor-pointer border border-gray-300 rounded-lg justify-between"
      :style="
        filliale.stock >= quantity
          ? { backgroundColor: '#65d675' }
          : { backgroundColor: componentColor }
      "
      :key="indexFilliale"
    >
      <div v-if="filliale.stock != null" class="flex space-x-1.5">
        <div class="text-sm">
          {{ filliale.plateform.substring(0, 3) }}
        </div>
        <p>{{ filliale.stock }}</p>
        <div class="flex space-x-1 ml-2">
          <span class="material-symbols-rounded text-sm">inventory</span>
          <div
            class="text-sm"
            v-if="filliale.stock || Number.isInteger(filliale.stock)"
          >
            <span v-if="filliale.reserved">{{
              parseInt(filliale.stock) + parseInt(filliale.reserved)
            }}</span
            ><span v-else>{{ parseInt(filliale.stock) }}</span>
          </div>
          <span class="material-symbols-rounded text-sm">
            deployed_code_history
          </span>
          <span v-if="filliale.reserved">{{ filliale.reserved }}</span>
          <span v-else>0</span>
          <span class="material-symbols-rounded text-sm">local_shipping</span>
          <p>{{ filliale.backorder }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFournisseurList } from "@/hooks/articles/articles.ts";
import { mapGetters, mapActions } from "vuex";
import { addPanierInst } from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import {
  getStockFournisseur,
  checkStock,
  backorderStock,
  reserveStock,
  addStock,
} from "@/hooks/stock/stock.ts";
import axios from "axios";
import { createReception } from "@/hooks/commandeFournisseur/commandeFournisseur.ts";

export default {
  name: "componentStock",
  data() {
    return {
      fournisseurTab: [],
      loadingFournisseur: false,
      loadingCommandeFournisseur: false,
      loadingDispoFournisseur: false,
      stateFournisseur: null,
      colorFournisseur: null,
      Stock: {},
      openFournisseur: false,
      seuil_pourcent: 30,
      stockFilliale: {},
      openFillialeCommande: false,
      infosCommandeFilliale: {},
      loadingCommandeFilliale: false,
      componentColor: "#ff8732",
      filialeTab: [],

      reference: "",
      tabArticle: "",
    };
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  props: {
    Article: { type: Object, default: () => ({}) },
    Client: { type: Object, default: () => ({}) },
    quantity: { type: Number, default: 1 },
  },
  methods: {
    async displayFournisseur() {
      this.fournisseurTab = [];
      this.loadingFournisseur = true;
      this.openFournisseur = true;
      this.fournisseurTab = await getFournisseurList(
        this.user.proprietaire,
        this.tabArticle,
        this.Client.Name,
        this.Client.Group.Name,
        this.quantity
      );
      this.loadingFournisseur = false;
      for (const fourn of this.fournisseurTab) {
        fourn.dispo = await getStockFournisseur(
          fourn.article,
          this.reference,
          this.Article.Code_marque,
          fourn.Prefixe_tarif,
          this.plateform.grossierID,
          this.quantity,
          this.plateform.grossierID,
          this.user.proprietaire
        );
        fourn.article["fraisdeport"] = fourn.dispo.fraisdeport;
        fourn.loadingDispo = false;
      }
    },
    async addToCommandeFournisseur(article, index, quantity) {
      this.loadingCommandeFournisseur = true;
      await addPanierInst(this.user.proprietaire, article, quantity);
      this.fournisseurTab[index].inCard = true;
      this.loadingCommandeFournisseur = false;
    },
    async ajustDispoFournisseur(fourn, index) {
      this.fournisseurTab[index].loadingDispo = true;
      this.fournisseurTab[index].dispo = await getStockFournisseur(
        fourn.article,
        this.reference,
        this.Article.Code_marque,
        fourn.Prefixe_tarif,
        this.plateform.grossierID,
        fourn.quantity,
        this.plateform.grossierID,
        this.user.proprietaire
      );
      this.fournisseurTab[index].loadingDispo = false;
    },
    async setFillialeCommande(data) {
      this.openFillialeCommande = true;
      let stockToTransfert = data.stock;
      data.stock > this.Article.quantity
        ? (stockToTransfert = this.Article.quantity)
        : (stockToTransfert = data.stock);
      this.infosCommandeFilliale = {
        plateform: data.plateform,
        stock: stockToTransfert,
      };
    },
    async reserveStockFilliale() {
      this.loadingCommandeFilliale = true;
      let ArticlesTab = [];
      let pdfinfo = [];
      const filialeInformation = await axios.get(
        `${process.env.VUE_APP_API}/plateform/getone/${this.infosCommandeFilliale.plateform}`
      );
      const getCoef = await axios.post(
        `${process.env.VUE_APP_API}/plateform/getCoef`,
        {
          plateform: this.infosCommandeFilliale.plateform,
          plateform_receiver: this.user.proprietaire,
        }
      );
      let art_data = { ...this.Article };
      art_data.quantity = parseInt(this.infosCommandeFilliale.stock);
      art_data.PrixFournisseurType = "prixnet";
      art_data.Prix_vente = art_data.Prix_achat * getCoef.data;
      art_data.prix_net = art_data.Prix_achat * getCoef.data;
      art_data.quantity_received = 0;
      art_data.quantity_asked = parseInt(this.infosCommandeFilliale.stock);

      ArticlesTab.push(art_data);

      pdfinfo.push({
        description: art_data.Description,
        ref: art_data.Ref_fournisseur,
        quantite: art_data.quantity,
        remise: 0,
        commentaire: art_data.commentaire,
        remisegenerale: 0,
        prixU: art_data.Prix_vente,
        total: art_data.Prix_vente * art_data.quantity,
        totalSansRemise: art_data.Prix_vente * art_data.quantity,
      });

      let numDoc = 0;
      await axios
        .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
          uid: this.infosCommandeFilliale.plateform,
        })
        .then((response) => {
          numDoc = response.data + 1;
        });

      var dataCommande = {
        Name: this.user.username,
        Owner: this.infosCommandeFilliale.plateform,
        // Date: today,
        Numero: numDoc,
        TotalPrice: art_data.Prix_vente,
        TotalPriceTTC: art_data.Prix_vente + art_data.Prix_vente * 0.2,
        Client: this.user.proprietaire,
        IsProcessed: false,
        Note: "Commande inter depot",
        Articles: ArticlesTab,
        reglement: [],
        pdfData: pdfinfo,
        clientInfo: filialeInformation.data,
        Transport: 0,
      };
      await axios.post(`${process.env.VUE_APP_API}/commandes`, dataCommande);
      await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
        Type: "Commande",
        cmdPro: filialeInformation.data[0].professionnel,
        Owner: this.user.proprietaire,
        Name: this.user.username,
        plateform: this.infosCommandeFilliale.plateform,
        // Date: today,
        Numero: 123,
        TotalPrice: art_data.Prix_vente,
        TotalPriceTTC: art_data.Prix_vente + art_data.Prix_vente * 0.2,
        Client: this.user.proprietaire,
        IsProcessed: false,
        Note: "Commande inter depot",
        Commentaire: "Commande Automatique inter depot",
        clientInfo: filialeInformation.data,
        BCNumber: numDoc,
        Articles: ArticlesTab,
        status: "Commande Passée",
        preparedBy: "personne",
        refCommande: "kjgkjyg",
        PourcentageStatus: "10",
        cmdstate: [true, false, false, false, false],
        Transport: 0,
      });
      if (art_data.Prefixe_tarif) {
        await reserveStock(
          this.user._id,
          this.infosCommandeFilliale.plateform,
          art_data._id,
          art_data.Ref_fournisseur,
          art_data.Code_marque,
          art_data.Code_EAN,
          0,
          0,
          10,
          "",
          this.user.username,
          "BC n°" + numDoc + "pour transfert édité par " + this.user.username,
          art_data.Prefixe_tarif,
          this.user.proprietaire,
          numDoc,
          "BC",
          "comptoir",
          art_data.quantity
        );

        //transfert stock direct
        await addStock(
          this.user._id,
          this.user.proprietaire,
          art_data._id,
          art_data.Ref_fournisseur,
          art_data.Code_marque,
          art_data.Code_EAN,
          0,
          0,
          10,
          "",
          this.user.username,
          "BC transfert n°" +
            numDoc +
            " : transfert stock filiale par " +
            this.user.username,
          art_data.Prefixe_tarif,
          this.user.proprietaire,
          "",
          "NA",
          "transfert automatique",
          art_data.quantity
        );
      }

      this.loadingCommandeFilliale = false;
      this.openFillialeCommande = false;
    },
  },
  async mounted() {
    if (this.Article.Ref_fournisseur.startsWith("C : ")) {
      this.reference = this.Article.Ref_fournisseur.replace("C : ", "");
      this.tabArticle = { ...this.Article, Ref_fournisseur: this.reference };
    } else {
      this.reference = this.Article.Ref_fournisseur;
      this.tabArticle = this.Article;
    }

    let chkStock = await checkStock(
      this.user.proprietaire,
      this.reference,
      this.Article._id,
      this.Article.Code_marque,
      this.Article.Prefixe_tarif,
      this.quantity,
      this.user._id
    );
    if (chkStock.state === "available") {
      this.Stock = chkStock.data;
      this.$emit("stockstate", "En stock");
      this.componentColor = "#65d675";
    } else if (
      chkStock.state === "plateform + subsidiary" ||
      chkStock.state === "subsidiary"
    ) {
      this.Stock = chkStock.data.plateform;
      this.filialeTab = chkStock.data.filiale.division;
      for (const fl of chkStock.data.filiale.division) {
        //TODO update the filiale management to send BL to the filiale
        if (fl.stock) {
          if (fl.stock >= this.quantity) {
            let stockPlateform = fl.stock;
            this.$emit("setStateGroupement", {
              plateform: fl.plateform,
              stockToTransfert:
                this.quantity - (this.Stock.stock >= 0 ? this.Stock.stock : 0),
            });
          }
        }
      }
    } else if (chkStock.state === "not available") {
      this.Stock = chkStock.data;
      this.Stock.state = "not available";
    } else if (chkStock.state === "not available + no subsidiary") {
      this.Stock = chkStock.data.plateform;
      this.Stock.state = "not available";
    }
  },
  async updated() {
    if (this.Stock.state === "not available") {
      const dispoFournisseur = await getStockFournisseur(
        this.tabArticle,
        this.reference,
        this.Article.Code_marque,
        this.Article.Prefixe_tarif,
        this.plateform.grossierID,
        this.quantity,
        this.plateform.grossierID,
        this.user.proprietaire
      );
      this.stateFournisseur = dispoFournisseur.dispoFournisseur;
      if (dispoFournisseur.dispoFournisseur) {
        this.$emit("fraisdeportfournisseur", dispoFournisseur.fraisdeport);
        this.$emit("stockstate", "En fournisseur");
        this.componentColor = "#68beff";
      } else {
        this.$emit("fraisdeportfournisseur", dispoFournisseur.fraisdeport);
        this.$emit("stockstate", "En rupture");
        this.componentColor = "#ff8732";
      }
      if (dispoFournisseur.color) {
        this.colorFournisseur = dispoFournisseur.color;
      }
    } else {
      this.$emit("stockstate", "En stock");
      this.$emit("fraisdeportfournisseur", 0);
    }
    // else {
    //   if (this.Stock.stock >= this.quantity) {
    //     this.$emit("stockstate", "En stock");
    //   } else {
    //     this.$emit("stockstate", "En rupture");
    //   }
    // }
  },
};
</script>
<style>
.back {
  background-color: #68beff;
}
</style>
