<template>
  <!-- modal détail cmd -->
  <div
    v-if="infoInCmd"
    :class="`modal ${
      !infoInCmd && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="infoInCmd = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 70rem"
      class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Récapitulatif de la commande</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="infoInCmd = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-1/3 ml-2">
            Référence client:
            {{ detailCommande.Note }}
          </div>

          <div class="flex space-x-2">
            <div
              v-if="
                detailCommande.BCNumber &&
                !detailCommande.BCNumber.startsWith('R')
              "
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="openBC(detailCommande)"
              >
                download
              </span>
              <div>BC n° {{ detailCommande.BCNumber }}</div>
            </div>
            <div
              v-if="detailCommande.BLNumber"
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="openBL(detailCommande)"
              >
                download
              </span>
              <div>BL n° {{ detailCommande.BLNumber }}</div>
            </div>
            <div
              v-if="detailCommande.BLNumber"
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="openBT(detailCommande)"
              >
                download
              </span>
              <div>BT n° {{ detailCommande.BLNumber }}</div>
            </div>
          </div>
        </div>

        <table class="min-w-full mt-4">
          <div class="overflow-y-auto max-h-[25rem]">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="w-44 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="w-80 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody class="bg-white w-fit">
              <tr
                v-for="(cmd, index) in detailCommande.Articles"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="w-44 px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row items-center">
                    <span class="ml-4 text-sm leading-5 text-gray-900">
                      {{ cmd.Ref_fournisseur }}
                    </span>
                  </div>
                </td>

                <td
                  class="w-80 px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="text-sm leading-5 text-gray-500 overflow-y-auto">
                    {{ cmd.Description }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-sm leading-5">
                    {{ cmd.quantity }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(cmd.Prix_euro).toFixed(2) }}
                  €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div v-if="cmd.PrixFournisseurType != 'prixnet'">
                    {{ cmd.remise }} %
                  </div>
                  <div v-else>prix net</div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="cmd.PrixFournisseurType == 'prixnet'">
                    {{ parseFloat(cmd.Prix_vente).toFixed(2) }}
                    €
                  </span>
                  <span v-else>
                    {{
                      parseFloat(
                        cmd.Prix_euro - (cmd.Prix_euro * cmd.remise) / 100
                      ).toFixed(2)
                    }}
                    €
                  </span>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="cmd.PrixFournisseurType == 'prixnet'">
                    {{ parseFloat(cmd.Prix_vente * cmd.quantity).toFixed(2) }}
                    €
                  </span>
                  <span v-else>
                    {{
                      parseFloat(
                        (cmd.Prix_euro - (cmd.Prix_euro * cmd.remise) / 100) *
                          cmd.quantity
                      ).toFixed(2)
                    }}
                    €
                  </span>
                </td>
              </tr>
            </tbody>
          </div>
          <thead class="tableHEADTR mt-12 border-t">
            <tr class="tableHEADTR">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="text-sm leading-5 text-gray-900">
                  Total HT :
                  {{ parseFloat(detailCommande.TotalPrice).toFixed(2) }} €
                </div>
                <div
                  v-if="detailCommande.TotalPriceTTC"
                  class="text-sm leading-5 text-gray-500"
                >
                  Total TTC :
                  {{ parseFloat(detailCommande.TotalPriceTTC).toFixed(2) }} €
                </div>
                <div v-else class="text-sm leading-5 text-gray-500">
                  Total TTC :
                  {{
                    parseFloat(
                      detailCommande.TotalPrice +
                        detailCommande.TotalPrice * 0.2
                    ).toFixed(2)
                  }}
                  €
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
  <!-- fin modal détail cmd -->

  <div class="flex">
    <!-- Back Button -->
    <div class="flex justify-between">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/detailFournisseur'" />
      </div>
    </div>
    <div class="flex items-center space-x-2 ml-auto">
      <!-- Aligns buttons to the right -->
      <button
        class="flex flex-row space-x-2 xs:hidden sm:block items-center h-fit px-2 py-1 rounded-[7px] bg-bluevk hover:bg-blue-500 focus:outline-none text-white font-normal rounded px-2 shadow cursor-pointer"
        @click="goToSuiviNew"
      >
        Articles des BDLS
      </button>
      <button
        class="flex flex-row space-x-2 xs:hidden sm:block items-center w-fit h-fit px-2 py-1 rounded-[7px] bg-bluevk hover:bg-blue-500 focus:outline-none text-white font-normal rounded px-2 shadow cursor-pointer"
        @click="goToLogEnvois"
        v-if="fournisseurTab.Prefixe_tarif == 'Dsv'"
      >
        Logs Emails
      </button>
    </div>
  </div>
  <!-- Table -->
  <div class="flex flex-col mt-10">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full border shadow">
          <thead class="bg-gray-100 text-gray-900">
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                N° Commande
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Date
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Statut
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Commandé par
              </th>
              <th
                class="md:px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Progression
              </th>

              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                HT
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                TTC
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                BL
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                BT
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(u, index) in cmdTab"
              :key="index"
              style="cursor: pointer"
              :class="
                u.cmdPro ? 'hover:bg-gray-100' : 'bg-blue-100 hover:bg-blue-200'
              "
            >
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex justify-between items-center">
                  <div class="flex flex-col">
                    <div class="text-sm font-medium leading-5 text-gray-900">
                      {{ u.BCNumber }}
                    </div>
                    <div
                      class="text-sm font-medium leading-5 text-gray-900"
                      v-if="
                        plateform.structure_depot == true &&
                        ((u.textFile && u.textFile.consignataire != '') ||
                          u.Fournisseur == user.proprietaire)
                      "
                    >
                      {{ u.Fournisseur }}
                    </div>
                    <p
                      v-if="u.relicatFromNumber && u.relicatFromNumber != '0'"
                      class="text-sm text-orangevk font-medium"
                    >
                      Reliquat de {{ u.relicatFromNumber }}
                    </p>
                    <!-- <p v-if="u.relicatNumber && u.relicatNumber != '0'" class="text-sm text-orangevk font-medium">
                      Reliquat {{ u.relicatNumber }}
                    </p> -->
                  </div>
                  <div
                    v-if="u.status == 'Commande annulée'"
                    title="Reprendre la commande"
                  ></div>

                  <!-- <div
                    v-else
                    class="py-4 border-b border-gray-200 whitespace-nowrap"
                  ></div> -->
                </div>
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="text-sm leading-5 text-gray-900">
                  {{ getFRDate(u.Date) }}
                </div>
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <span
                  @click="getDetailCmd(u)"
                  title="Plus d'informations"
                  class="material-icons-outlined text-md text-cyan-600 hover:text-cyan-700 cursor-pointer"
                >
                  info
                </span>
              </td>
              <td
                class="px-3 md:px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 rounded-full w-fit"
                  >
                    {{ u.status }}
                  </span>
                  <div
                    v-if="u.status == 'Commande annulée' && u.cancellation"
                    class="text-xs rounded-md bg-orange-400 text-white w-fit px-1 text-sm overflow-x-auto"
                    :title="u.cancellation.reason"
                  >
                    <h4>
                      Raison d'annulation:
                      {{
                        u.cancellation.reason.length > 11
                          ? u.cancellation.reason.substr(0, 11) + "..."
                          : u.cancellation.reason
                      }}
                    </h4>
                    <h4>Par: {{ u.cancellation.user }}</h4>
                  </div>
                  <span
                    v-if="u.status == 'Commandé chez le fournisseur'"
                    class="rounded-md bg-orange-400 text-white w-fit px-1 text-xs md:text-sm overflow-x-auto"
                    >{{ u.commandeFournisseur.user }}:
                    {{ u.commandeFournisseur.message }}</span
                  >
                </div>
                <div class="text-xs leading-5 text-gray-900 md:hidden">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u.Client }}</span
                  >
                </div>
              </td>

              <td
                class="pl-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <span
                  class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                  >{{ u.Name }}</span
                >
                <div class="text-sm leading-5 text-gray-900">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u.Client }}</span
                  >
                </div>
              </td>

              <td
                class="md:pr-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                <progressBar
                  v-if="u.status == 'Commande annulée'"
                  :pourcentageStatus="parseInt(u.PourcentageStatus)"
                  :isRed="true"
                  class="hidden md:block"
                />
                <progressBar
                  v-else
                  :pourcentageStatus="parseInt(u.PourcentageStatus)"
                  :isRed="false"
                  class="hidden md:block"
                />
                <span class="md:hidden">{{ u.PourcentageStatus }} %</span>
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                {{ parseFloat(u.TotalPrice).toFixed(2) }} €
              </td>

              <td
                v-if="u.TotalPriceTTC"
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
              </td>
              <td
                v-else
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }} €
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex flex-col w-fit">
                  <span
                    class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                    v-if="u.BLNumber"
                    style="color: blue"
                    @click="openBL(u)"
                  >
                    download
                  </span>

                  <span class="m-auto">{{ u.BLNumber }}</span>
                </div>
              </td>
              <!--  -->
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex flex-col w-fit">
                  <span
                    class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                    v-if="u.BLNumber"
                    style="color: blue"
                    @click="openBT(u)"
                  >
                    download
                  </span>
                  <span class="m-auto">{{ u.BLNumber }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import backButton from "@/components/Button/back.vue";
import { mapGetters } from "vuex";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import ProgressBar from "@/components/Commandes/progressBar.vue";
import { createlivraison } from "./../hooks/pdf/livraison.js";
import { createtransport } from "./../hooks/pdf/transport.js";

export default {
  data() {
    return {
      fournisseurTab: {},
      cmdTab: [],
      infoInCmd: false,
      detailCommande: {},
    };
  },
  computed: {
    ...mapGetters(["fournisseurID", "user", "plateform"]),
  },
  components: {
    backButton,

    ProgressBar,
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    goToLogEnvois() {
      this.$router.push("/logEnvois");
    },
    goToSuiviNew() {
      this.$router.push("/suiviArticleByDepot");
    },
    goToSuiviBdl() {
      this.$router.push("/suiviBdl");
    },
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
    getCmdByDepot() {
      axios
        .post(
          `${process.env.VUE_APP_API}/commandesClient/getByDepotStructure`,
          {
            plateform: this.user.proprietaire,
            fournisseur: this.fournisseurTab.CompanyName,
          }
        )
        .then((response) => {
          console.log(response);
          this.cmdTab = response.data;
        });
    },
    getDetailCmd(object) {
      this.detailCommande = object;
      this.infoInCmd = true;
    },
    openBL(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab.BLNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDF(response.data[0]);
        });
    },
    openBT(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab.BLNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDFtransport(response.data[0]);
        });
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createlivraison(objet, false, false);
      } else {
        createlivraison(objet, true, false);
      }
    },
    createPDFtransport(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createtransport(objet, false);
      } else {
        createtransport(objet, true);
      }
    },
  },
  async mounted() {
    await this.getFournisseur();
    this.getCmdByDepot();
  },
};
</script>
