<template>
  <addClientParticulier
    v-if="showClientPar"
    :clientInput="inputClient"
    @close="(showClientPar = false), updateSessionClient($event, false)"
  />

  <modifierClientParticulier
    v-if="showEditClientPar"
    :clientSelectedToModify="clientSelectedToModify"
    @close="(showEditClientPar = false), getFavori(false)"
  />

  <div
    v-if="showPaymentModal"
    class="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50"
  >
    <div
      @click="showPaymentModal = false"
      class="absolute w-screen h-screen bg-gray-900 opacity-50 modal-overlay"
    ></div>
    <div class="relative bg-white p-5 rounded shadow-lg">
      <button
        class="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
        @click="showPaymentModal = false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <!--Footer-->
      <p class="text-base font-medium mb-8">
        Êtes-vous sûr de vouloir continuer sans sélectionner un mode de paiement
        ?
      </p>
      <label class="text-l">Reste dû : {{ financial(setRestant) }} €</label>
      <div class="flex justify-end mt-4">
        <button
          class="px-4 py-1 text-xm mr-2 text-white bg-red-600 rounded hover:bg-red-400"
          @click="showPaymentModal = false"
        >
          Non
        </button>
        <button
          class="px-4 py-1 text-xm text-white bg-green-600 rounded hover:bg-green-400"
          :disabled="isProcessing"
          @click="handleOuiClick"
        >
          <PulseLoader
            v-if="loadingSelection"
            color="#ffffff"
            class="my-auto"
          />
          Oui
        </button>
      </div>
    </div>
  </div>

  <!-- MODAL SUPPRIMER CLIENT PARTICULIER -->
  <div
    v-if="showDeleteClientPar"
    :class="`modal ${
      !showDeleteClientPar && 'opacity-0 pointer-events-none'
    } z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showDeleteClientPar = false"
      class="absolute w-screen h-screen bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-40 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-40 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="showDeleteClientPar = false"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left h-full modal-content">
        <!--Title-->
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold"></p> -->
          <div
            class="z-40 cursor-pointer modal-close"
            @click="showDeleteClientPar = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="flex flex-col space-y-4">
          <p class="font-medium text-xl">Supprimer définitivement ?</p>
          <div class="flex space-x-4 justify-center">
            <button
              class="shadow w-fit px-2 py-1 h-fit text-red-600 rounded-md bg-gray-100 hover:text-white hover:bg-red-300"
              @click="confirmDelete()"
            >
              Oui
            </button>
            <button
              class="shadow w-fit px-2 py-1 h-fit rounded-md hover:bg-gray-100"
              @click="showDeleteClientPar = false"
            >
              Non
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="openModal"
    :class="`modal ${
      !openModal && 'opacity-0 pointer-events-none'
    } z-40 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="$emit('closeModalSelectClientSession', 'close')"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 72rem; max-height: 52rem"
      class="z-40 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-40 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="$emit('closeModalSelectClientSession', 'close')"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>
      <div
        v-show="showClientList"
        @click="showClientList = false"
        class="absolute z-10"
        style="width: 72rem; height: 35rem"
      ></div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left h-full modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Encaissement</p>
          <div
            class="z-40 cursor-pointer modal-close"
            @click="$emit('closeModalSelectClientSession', 'close')"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->

        <div class="flex flex-col" style="height: 40rem">
          <div v-if="sectionClient" class="relative" style="height: 8vh">
            <div
              id="client_info_display"
              class="block px-2.5 pb-2.5 pt-4 w-[32rem] h-full text-gray-900 bg-white rounded-lg border-2 border-sky-200 appearance-none focus:outline-none focus:ring-0 focus:border-sky-300 resize-none"
            >
              <div
                v-if="
                  comptoirSessionActive.length > 0 &&
                  !comptoirSessionActive[0].Client[0]._id.startsWith('inconnu')
                "
                class="flex justify-between"
              >
                <p class="text-lg font-bold text-gray-900">
                  {{ comptoirSessionActive[0].Client[0]?.Name }}
                </p>
                <p class="text-lg text-gray-900">
                  {{ comptoirSessionActive[0].Client[0]?.Group.Name }}
                </p>
              </div>
              <p
                v-if="
                  comptoirSessionActive.length > 0 &&
                  !comptoirSessionActive[0].Client[0]._id.startsWith('inconnu')
                "
                class="text-sm text-gray-900 font-semibold"
              >
                {{
                  comptoirSessionActive[0].Client[0]?.NumeroClient
                    ? comptoirSessionActive[0].Client[0]?.NumeroClient + " - "
                    : ""
                }}
                {{
                  comptoirSessionActive[0].Client[0]?.Adresse
                    ? comptoirSessionActive[0].Client[0]?.Adresse
                    : ""
                }}
                {{
                  comptoirSessionActive[0].Client[0]?.Complement
                    ? ", " + comptoirSessionActive[0].Client[0]?.Complement
                    : ""
                }}
                {{
                  comptoirSessionActive[0].Client[0]?.PostalCode
                    ? ", " + comptoirSessionActive[0].Client[0]?.PostalCode
                    : ""
                }}
                <span class="text-base font-bold text-gray-900">
                  {{
                    comptoirSessionActive[0].Client[0]?.City
                      ? ", " + comptoirSessionActive[0].Client[0]?.City
                      : ""
                  }}
                </span>
              </p>
            </div>
            <label
              for="client_info_display"
              class="ml-1 absolute text-base font-bold text-gray-600 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-gray-50 px-2 peer-focus:px-2 peer-focus:text-blue-600"
              >Client</label
            >
          </div>

          <div v-if="sectionClient" class="relative">
            <div class="flex flex-col">
              <div
                v-if="!comptoirSessionActive[0]?.Client[0]?.professionnel"
                class="flex flex-row space-x-2"
              >
                <input
                  @input="searchClientFav(inputClient, false)"
                  @focus="inputClient = ''"
                  @keydown.enter="searchClient()"
                  v-model="inputClient"
                  placeholder="Modifier la session"
                  @click="(showClientList = true), getFavori(false)"
                  class="my-auto mt-1 border-2 px-1 py-0.5 border-gray-500 w-full text-black rounded-md focus:placeholder-gray-600 focus:text-gray-700 focus:outline-2 outline-sky-300"
                />
                <button
                  @click="newClient()"
                  class="bg-bluevk hover:bg-opacity-60 text-white px-4 py-2 shadow rounded-md"
                >
                  Nouveau
                </button>
              </div>
            </div>
            <div
              v-if="showClientList"
              style="height: 53vh"
              class="absolute z-20 border-2 border-sky-200 shadow-lg rounded-md mt-1 bg-gray-50 text-gray-800 p-1 w-full flex flex-col overflow-y-auto"
            >
              <div
                class="cursor-pointer rounded-sm hover:bg-gray-200 flex items-center justify-between p-2"
                @click="
                  (clientChoosed = cl),
                    (Clients = []),
                    updateSessionClient(clientChoosed, false),
                    (showClientList = false),
                    (inputClient = '')
                "
                v-for="(cl, indexCL) in Clients"
                :key="indexCL"
              >
                <div>
                  <p class="font-bold">{{ cl.Name }}</p>
                  <p class="">
                    {{ cl.NumeroClient ? cl.NumeroClient + " - " : "" }}
                    {{ cl.Adresse ? cl.Adresse : "" }}
                    {{ cl.Complement ? ", " + cl.Complement : "" }}
                    {{ cl.PostalCode ? ", " + cl.PostalCode : "" }}
                    {{ cl.City ? ", " + cl.City : "" }}
                  </p>
                </div>

                <div class="flex space-x-2">
                  <button
                    class="text-red-500 hover:text-red-700"
                    @click.stop="suppClient(cl)"
                  >
                    <span class="material-icons-outlined">delete</span>
                  </button>
                  <button
                    class="text-blue-600 hover:text-blue-400"
                    @click.stop="editClient(cl)"
                  >
                    <span class="material-icons-outlined">edit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="sectionPaiement"
            class="my-auto px-5 py-2 text-gray-700 bg-gray-50 shadow-lg border"
          >
            <div class="flex justify-between">
              <label class="text-lg font-bold">MODE DE REGLEMENT</label>
              <div class="relative">
                <label class="text-xm mt-6 font-medium text-gray-500"
                  >Montant à payer :
                </label>
                <label class="text-xl font-bold"
                  >{{ financial(setTotalTTC) }} €</label
                >
              </div>
            </div>
            <div class="relative flex justify-center space-x-8 p-1">
              <div class="" v-for="(moyen, ind) in moyenPaiement" :key="ind">
                <div class="flex flex-col">
                  <img
                    class="w-[5rem] border-2 border-sky-300 rounded-md m-auto cursor-pointer hover:shadow hover:bg-gray-200 focus:outline-2 outline-sky-300 shadow-lg"
                    :src="moyen.image"
                    @click="setReglement(moyen.abbrev)"
                  />
                  <span
                    style="font-size: 15px"
                    class="m-auto mt-1 font-medium text-black"
                    >{{ moyen.type }}</span
                  >
                </div>
              </div>
            </div>

            <div
              class="relative mt-2 rounded-md shadow-sm"
              v-for="(reglement, index) in comptoirSessionActive[0]
                .reglementTab"
              :key="index"
            >
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <img
                  class="w-9 h-9 rounded-md m-auto"
                  :src="reglement.img"
                />
              </span>

              <input
                type="number"
                :placeholder="reglement.placeholder"
                :disabled="
                  comptoirSessionActive[0].reglementTab[index].disabled
                "
                v-model="comptoirSessionActive[0].reglementTab[index].model"
                class="w-2/5 focus:outline-2 outline-sky-300 pl-16 pr-2 py-2 border border-gray-400 text-black font-semibold rounded-md appearance-none"
              />
              <input
                @change="errorEncaissement = false"
                type="date"
                :disabled="
                  comptoirSessionActive[0].reglementTab[index].disabled
                "
                v-model="comptoirSessionActive[0].reglementTab[index].date"
                :min="todayDate"
                class="ml-2 focus:outline-2 outline-sky-300 w-2/5 px-5 py-2 border border-gray-400 rounded-md appearance-none text-black font-semibold"
              />
              <span
                v-if="!comptoirSessionActive[0].reglementTab[index].disabled"
                class="w-1/5 px-5 py-2 border-transparent rounded-md appearance-none"
              >
                <span
                  class="mt-2 material-icons-outlined text-red-600 cursor-pointer"
                  @click="removeReglement(index)"
                >
                  clear
                </span>
              </span>
            </div>

            <div class="relative mt-3" v-show="errorPaymentMethod">
              <label class="text-xs font-medium text-red-500"
                >Cette méthode de paiement n'existe pas</label
              >
            </div>
            <div class="relative mt-3" v-show="errorEncaissement">
              <label class="text-xs font-medium text-red-500"
                >Veuillez encaisser la facture</label
              >
            </div>
            <div class="relative mt-3">
              <label class="text-xl font-medium"
                >Reste dû : {{ financial(setRestant) }} €</label
              >
              <label class="text-xs"> - </label>
              <label class="text-xl font-medium"
                >A rendre : {{ financial(setRendu) }} €</label
              >
            </div>
            <div class="relative mt-8 rounded-md">
              <textarea
                placeholder="Commentaire interne"
                class="block px-2.5 pb-2.5 w-full h-12 max-h-28 text-gray-900 bg-white rounded-lg border-2 border-gray-300 appearance-none shadow-md focus:outline-none focus:ring-0 focus:border-sky-300 resize-none"
                v-model="commentaireEncaissement"
                @input="adjustHeight"
              ></textarea>
            </div>
          </div>
          <div v-else style="height: 18rem"></div>
        </div>

        <!--Footer-->
        <div class="flex justify-end mt-4 w-full right-4">
          <button
            @click="$emit('closeModalSelectClientSession', 'close')"
            class="p-3 px-3 py-2 mr-2 text-white bg-red-600 shadow-lg rounded-lg hover:bg-red-400 hover:text-white focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="
              updateSessionClient(comptoirSessionActive[0]?.Client[0], true)
            "
            :disabled="loadingSelection"
            class="px-1 py-2 tracking-wide text-white shadow-lg bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none"
          >
            <PulseLoader
              v-if="loadingSelection"
              color="#ffffff"
              class="my-auto"
            />
            <p v-else>Valider</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import addClientParticulier from "../Comptoir/addClientParticulier.vue";
import modifierClientParticulier from "../Comptoir/modifierClientParticulier.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openModal: true,
      inputClient: "",
      Clients: [],
      clientChoosed: null,

      showClientPar: false,

      loadingSelection: false,

      clientSelectedToModify: null,
      showDeleteClientPar: false,
      showEditClientPar: false,

      errorPaymentMethod: false,
      moyenPaiement: [
        {
          type: "Espèce",
          abbrev: "es",
          image: require("@/assets/icons_payments/ESP.png"),
        },
        {
          type: "Carte Bancaire",
          abbrev: "cb",
          image: require("@/assets/icons_payments/CB.png"),
        },
        {
          type: "Chèque Comptant",
          abbrev: "cc",
          image: require("@/assets/icons_payments/CHQ.png"),
        },
        {
          type: "LCR",
          abbrev: "lc",
          image: require("@/assets/icons_payments/LCR.png"),
        },

        {
          type: "Virement",
          abbrev: "vi",
          image: require("@/assets/icons_payments/Virement.png"),
        },
      ],
      // setRestant: null,
      // setRendu: null,
      setTotalTTC: null,
      showClientList: false,
      showPaymentModal: false,
      sectionClient: false,
      sectionPaiement: false,

      isProcessing: false,
    };
  },
  components: {
    PulseLoader,
    addClientParticulier,
    modifierClientParticulier,
  },
  props: [
    "setRest",
    "setRend",
    "setTTC",
    "selectionClient",
    "selectionPaiement",
  ],
  computed: {
    ...mapGetters([
      "clientFocus",
      "comptoirSession",
      "actionArticle",
      "user",
      "comptoirSessionActive",
      "refClient",
      "dataConsigne",
      "dataNonConsigne",
      "dsvCheck",
      "plateform",
      "Ref_fournisseur_search",
    ]),
    setRestant() {
      var restant = 0;
      var paye = 0;
      this.comptoirSessionActive[0].reglementTab.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      restant = parseFloat(this.setTotalTTC) - parseFloat(paye);
      if (restant < 0) {
        restant = 0;
      }
      return parseFloat(restant).toFixed(2);
    },
    setRendu() {
      var rendu = 0;
      var paye = 0;
      this.comptoirSessionActive[0].reglementTab.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      if (paye > this.setTotalTTC) {
        rendu = Math.abs(paye - this.setTotalTTC);
      }
      return rendu;
    },
  },
  methods: {
    ...mapActions([
      "updateactionArticle",
      "getallcomptoirsession",
      "createcomptoirsession",
      "setcomptoirsession",
      "removecomptoirsession",
      "savecomptoirsession",
      "setrefclient",
      "getclientchoosedtab",
      "updaterefclient",
      "setdsvcheck",
      "updateactionarticlesession",
      "sendToRestoreTransportCart",
      "searchRefFournisseur",
    ]),
    getFavori(cdtnCl) {
      axios
        .post(`${process.env.VUE_APP_API}/client/favoriClient/get`, {
          plateform: this.user.proprietaire,
          pro: cdtnCl,
        })
        .then((res) => {
          this.Clients = res.data;
        });
    },
    searchClientFav(searchTerm, pro) {
      axios
        .post(`${process.env.VUE_APP_API}/client/searchClientFav`, {
          plateform: this.user.proprietaire,
          searchTerm: searchTerm,
          pro: pro,
          archived: false,
        })
        .then((res) => {
          this.Clients = res.data;
        });
    },
    async updateSessionClient(client, closeOption) {
      if (!client._id.startsWith("inconnu")) {
        let clientChoosed = client;
        this.loadingSelection = true;
        let response = await axios.post(
          `${process.env.VUE_APP_API}/client/getByID`,
          {
            _id: clientChoosed._id,
          }
        );

        if (response.data) {
          await this.savecomptoirsession({
            clientFocus: response.data,
            refClient: this.comptoirSessionActive[0].refClient,
            consigneData: this.comptoirSessionActive[0].consigneData,
            consigneNonData: this.comptoirSessionActive[0].consigneNonData,
            dsvCheck: this.comptoirSessionActive[0].dsvCheck,
          });
          // await this.updateactionarticlesession({
          //   Name: response.data.Name,
          //   Group: response.data.Group.Name,
          //   _id: response.data._id,
          //   TransportCart: true,
          // });

          if (closeOption) {
            if (
              this.comptoirSessionActive[0]?.documentType ==
                "Bon de commande" ||
              this.comptoirSessionActive[0]?.documentType == "Facturation"
            ) {
              this.checkPaymentAndProceed();
            } else {
              this.$emit("closeModalSelectClientSession", "valider");
            }
          }
        }
        this.loadingSelection = false;
      } else {
        this.toast.warning("Aucun client sélectionné !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    checkPaymentAndProceed() {
      const hasSelectedPaymentMethod =
        this.comptoirSessionActive[0]?.reglementTab?.length > 0;

      if (hasSelectedPaymentMethod) {
        this.$emit("closeModalSelectClientSession", "valider");
      } else {
        this.showPaymentModal = true;
      }
    },
    async searchClient() {
      if (this.Clients.length > 0) {
        this.inputClient = this.Clients[0].Name;
      } else {
        await this.newClient();
      }
    },
    async newClient() {
      this.showClientPar = true;
    },
    suppClient(client) {
      this.clientSelectedToModify = client;
      this.showDeleteClientPar = true;
    },
    confirmDelete() {
      this.deleteClient();
      this.showDeleteClientPar = false;
    },
    deleteClient() {
      axios
        .put(
          `${process.env.VUE_APP_API}/client/removeClient/${this.clientSelectedToModify._id}`,
          this.clientSelectedToModify
        )
        .then(() => {
          this.getFavori(false);
          this.toast.success("Client supprimé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    editClient(client) {
      this.clientSelectedToModify = client;
      this.showEditClientPar = true;
    },

    // Reglement
    setReglement(method) {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");

      let montant = 0.0;

      if (this.setTotalTTC >= 0.0) {
        montant =
          this.setRestant > 0.0 ? parseFloat(this.setRestant).toFixed(2) : 0.0;
      } else if (this.setTotalTTC < 0.0) {
        montant =
          this.setRendu > 0.0
            ? -parseFloat(this.setRendu).toFixed(2)
            : parseFloat(this.setRestant).toFixed(2);
      } else {
        montant = parseFloat(this.setTotalTTC).toFixed(2);
      }

      switch (method) {
        case "es":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/ESP.png"),
            icon: "payments",
            placeholder: "Espèce",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cb":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/CB.png"),
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cc":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/CHQ.png"),
            icon: "money",
            placeholder: "Chèque Comptant",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "vi":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/Virement.png"),
            icon: "account_balance",
            placeholder: "Virement",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "lc":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/LCR.png"),
            icon: "account_balance",
            placeholder: "LCR",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        default:
          this.errorPaymentMethod = true;
          break;
      }

      this.savecomptoirsession({
        id: 4,
        clientFocus: this.comptoirSessionActive[0].Client,
        reglementTab: this.comptoirSessionActive[0].reglementTab,
      });
    },
    removeReglement(index) {
      this.comptoirSessionActive[0].reglementTab.splice(index, 1);
      this.savecomptoirsession({
        id: 4,
        clientFocus: this.comptoirSessionActive[0].Client,
        reglementTab: this.comptoirSessionActive[0].reglementTab,
      });
    },

    // Autre
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    handleOuiClick() {
      if (this.isProcessing) return;
      this.isProcessing = true;
      this.$emit("closeModalSelectClientSession", "valider");
      this.showPaymentModal = false;
    },
  },
  beforeMount() {
    // this.setRestant = this.setRest;
    // this.setRendu = this.setRend;
    this.setTotalTTC = this.setTTC;
    this.sectionClient = this.selectionClient;
    this.sectionPaiement = this.selectionPaiement;
    console.log(this.sectionClient, this.sectionPaiement);
  },
};
</script>
