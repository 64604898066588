const ORDER_KEYS = Object.freeze({
  ORDER_ID: "order_id",
  TYPE: "type",
  ORDER_SOURCE: "order_source",
  ORDER_SOURCE_APPLICATION: "order_source_application",
  SOURCE_VALIDATION: "source_validation",
  EXTERNAL_ORDER_ID: "external_order_id",
  EXTERNAL_CLIENT_ID: "external_client_id",
  EXTERNAL_PLATFORM_ID: "external_platform_id",
  CLIENT_ID: "client_id",
  CLIENT_NAME: "client_name",
  CLIENT_TYPE: "client_type",
  BL_ID: "bl_id",
  BC_ID: "bc_id",
  BC_OBJECT_ID: "bc_object_id",
  TOTAL_PRICE_HT: "total_price_ht",
  TOTAL_PRICE_TTC: "total_price_ttc",
  CLIENT_NOTE: "client_note",
  VENDOR_NOTE: "vendor_note",
  STATUS: "status",
  STATUS_PERCENTAGE: "status_percentage",
  PREPARED_BY: "prepared_by",
  ORDER_REFERENCE: "order_reference",
  IS_ARCHIVED: "is_archived",
  CLIENT_DETAILS: "client_details",
  ARTICLES: "articles",
  DELIVERY_PRICE: "delivery_price",
  PAIEMENT_DETAILS: "paiement_details",
  REMAINED_ARTICLES: "remained_articles",
  PARENT_ORDER_ID: "parent_order_id",
  REMAINED_ORDER_ID: "remained_order_id",
  TRANSFERRED_FROM_ID: "transferred_from_id",
  TRANSFERRED_TO_ID: "transferred_to_id",
  TRANSFER_PLATFORM: "transfer_platform",
  STATUS_HISTORY: "status_history",
  STEP_DURATION: "step_duration",
  PREDICTED_COMPLETION_TIME: "predicted_completion_time",
  REAL_TIME_METRICS: "real_time_metrics",
  PROFITABILITY: "profitability",
  CUSTOMER_BEHAVIOR: "customer_behavior",
  PLATFORM_PERFORMANCE: "platform_performance",
  SUPPLIER_ORDER: "supplier_order",
});

module.exports = {
  ORDER_KEYS,
};
