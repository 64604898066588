<template>
  <div id="retour" class="flex justify-start">
    <backButton :route="'/configurationhub'" />
  </div>
  <div class="mt-2 space-y-4">
    <div class="flex flex-row items-center space-x-2">
      <input
        type="Number"
        v-model="searchGAID"
        placeholder="Rechercher un ID"
        v-on:keyup.enter="getOneGenArtID()"
        class="focus:outline-2 outline-sky-300 border rounded-md shadow mb-1 px-1"
      />
      <input
        type="text"
        v-model="searchGA"
        placeholder="Rechercher un terme"
        v-on:keyup.enter="getOneGenArt()"
        class="focus:outline-2 outline-sky-300 border rounded-md shadow mb-1 px-1"
      />
    </div>

    <div class="grid grid-cols-7 gap-5" v-if="GenArtTab.length > 0">
      <div
        v-for="(GA, indexGA) in GenArtTab"
        :key="indexGA"
        class="bg-white rounded flex flex-row items-center justify-between p-1"
        @click="setConfGenArtSelected(GA.genericArticleId)"
      >
        <span class="ml-1 cursor-pointer text-sm">
          {{ GA.designation }}
        </span>
        <span>
          {{ GA.genericArticleId }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
import backButton from "@/components/Button/back.vue";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      GenArtTab: [],
      searchGA: "",
      searchGAID: 0,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setConfGenArtSelected"]),
    async getGenArtLimit(skip, limit) {
      const getGenArtLimit = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getGenArtLimit`,
        {
          skip: skip,
          limit: limit,
        }
      );
      this.GenArtTab = getGenArtLimit.data;
    },
    async getOneGenArt() {
      if (this.searchGA != "") {
        const getOneGenArt = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/getOneGenArt`,
          {
            searchQuery: this.searchGA,
          }
        );
        this.GenArtTab = getOneGenArt.data;
      }
    },
    async getOneGenArtID() {
      if (this.searchGAID != "") {
        const getOneGenArtID = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/getOneGenArtID`,
          {
            searchQuery: this.searchGAID,
          }
        );
        this.GenArtTab = getOneGenArtID.data;
      }
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getGenArtLimit(0, 100);
  },
  components: {
    backButton,
  },
};
</script>
<style></style>
