<template>
  <div class="flex flex-col space-y-8">
    <div class="flex justify-between mb-10">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/configurationCatalogueHub'" />
      </div>
      <titrePages
        class="flex flex-1 justify-center"
        :title="'Configuration des conditions'"
      />
    </div>
    <div class="flex justify-center"><menuConfiguration /></div>
    <div v-show="errorMaxCondition" class="flex justify-center text-red-500">
      Vous avez atteint le nombre maximum de conditions!
    </div>
    <div class="grid grid-cols-6 gap-3">
      <!-- <div
        class="flex flex-col space-y-2 bg-white rounded shadow p-2"
        v-for="(cond, indexCond) in conditionList"
        :key="indexCond"
      > -->
      <div
        class="flex flex-col space-y-2 bg-white rounded-[18px] border-2 border-gray-200 shadow-lg p-6"
        v-for="(cond, index) in conditionList"
        :key="index"
        draggable="true"
        @dragstart="onDragStart(index)"
        @dragover.prevent
        @drop="onDrop(index)"
      >
        <!-- <div class="border border-gray-400"> -->
        <div class="relative">
          <input
            type="text"
            :id="cond._id + Name"
            v-model="cond.Name"
            @change="updateCondition(cond._id, cond)"
            class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 shadow-md bg-transparent rounded-lg border border-blue-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
          <label
            :for="cond._id + Name"
            class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >{{ cond.Position + 1 }}</label
          >
        </div>
        <div class="relative mt-6">
          <input
            type="text"
            :id="cond._id + Description"
            v-model="cond.Description"
            @change="updateCondition(cond._id, cond)"
            class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 shadow-md bg-transparent rounded-lg border border-blue-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
          <label
            :for="cond._id + Description"
            class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >Description</label
          >
        </div>
        <div class="flex justify-between">
          <div class="flex flex-row space-x-1 items-center">
            <input
              v-model="cond.default"
              @change="checkDefault(cond._id, cond, cond.default, index)"
              type="checkbox"
            /><span class="text-sm">Par defaut</span>
          </div>

          <button
            class="text-white bg-red-500 hover:bg-red-300 rounded-lg shadow-lg px-3 py-1 mt-2"
            @click="deleteCondition(cond._id, cond.Position)"
          >
            supprimer
          </button>
        </div>
        <!-- </div> -->
      </div>
      <div
        class="flex flex-col space-y-2 bg-white border-2 border-gray-200 rounded-[18px] shadow-lg p-4"
      >
        <div class="relative mt-4">
          <input
            type="text"
            id="floating_nom"
            v-model="addName"
            class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-blue-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
          <label
            for="floating_nom"
            class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >{{ conditionList.length + 1 }}</label
          >
        </div>
        <div class="relative mt-6">
          <input
            type="text"
            id="floating_desc"
            v-model="addDescription"
            class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-blue-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
          <label
            for="floating_desc"
            class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >Description</label
          >
        </div>
        <div class="flex justify-end">
          <button
            class="text-white bg-bluevk hover:bg-blue-400 rounded-lg shadow-lg px-3 py-1 mt-2"
            @click="addCondition(conditionList.length)"
          >
            ajouter
          </button>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import backButton from "@/components/Button/back.vue";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
import { mapGetters } from "vuex";
import titrePages from "@/components/Text/grandeTitre.vue";
export default {
  data() {
    return {
      addName: "",
      addDescription: "",
      conditionList: [],
      errorMaxCondition: false,
      dragIndex: null, // Sürükleme başlangıç pozisyonu
    };
  },
  components: {
    backButton,
    menuConfiguration,
    titrePages,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async addCondition(position) {
      if (this.conditionList.length <= 10) {
        const addCond = await axios.post(
          `${process.env.VUE_APP_API}/configurationCondition`,
          {
            plateform: this.user.proprietaire,
            Name: this.addName,
            Description: this.addDescription,
            Position: position,
          }
        );
        this.getCondtion();
        this.addName = "";
        this.addDescription = "";
        this.errorMaxCondition = false;
      } else {
        this.errorMaxCondition = true;
      }
    },
    async getCondtion() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.conditionList = condition.data;
    },
    async deleteCondition(id, Position) {
      const deleteCond = await axios.post(
        `${process.env.VUE_APP_API}/configurationCondition/deleteCondition/${id}`,
        { Position: Position, plateform: this.user.proprietaire }
      );
      this.getCondtion();
    },
    async updateCondition(id, condition) {
      const updateCond = await axios.post(
        `${process.env.VUE_APP_API}/configurationCondition/updateCondition/${id}`,
        {
          Name: condition.Name,
          Description: condition.Description,
          default: condition.default,
        }
      );
      this.getCondtion();
    },
    onDragStart(index) {
      this.dragIndex = index;
    },
    async onDrop(index) {
      try {
        if (this.dragIndex !== null && this.dragIndex !== index) {
          const draggedItem = this.conditionList.splice(this.dragIndex, 1)[0];
          this.conditionList.splice(index, 0, draggedItem);

          for (let i = 0; i < this.conditionList.length; i++) {
            const condition = this.conditionList[i];
            await axios.post(
              `${process.env.VUE_APP_API}/configurationCondition/updateCondition/${condition._id}`,
              {
                Name: condition.Name,
                Description: condition.Description,
                Position: i,
              }
            );
          }
          this.dragIndex = null;
          this.getCondtion();
        }
      } catch (error) {
        console.error("Error reordering conditions:", error);
      }
    },
    async checkDefault(_id, cond, default_value, indexCond) {
      if (default_value) {
        for (const [index, condition] of this.conditionList.entries()) {
          if (index !== indexCond) {
            condition.default = false;
            this.updateCondition(condition._id, condition);
          } else {
            condition.default = true;
            this.updateCondition(condition._id, condition);
          }
        }
      } else {
        let defaultExist = false;
        for (const condition of this.conditionList) {
          if (condition.default) {
            defaultExist = true;
          }
        }
        if (!defaultExist) {
          this.conditionList[0].default = true;
          this.updateCondition(
            this.conditionList[0]._id,
            this.conditionList[0]
          );
        }
        this.updateCondition(cond._id, cond);
      }
    },
  },
  mounted() {
    this.getCondtion();
  },
};
</script>
<style></style>
