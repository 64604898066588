<template>
  <!-- modal info article -->
  <div
    v-if="openModalArticle"
    :class="`modal ${
      !openModalArticle && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openModalArticle = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openModalArticle = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <article-info :key="articleInfo_componentKey" />
      </div>
    </div>
  </div>
  <!-- fin modal info article -->

  <div
    v-if="openConfirmRetour"
    :class="`modal ${
      !openConfirmRetour && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openConfirmRetour = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 42rem"
    >
      <div
        @click="openConfirmRetour = false"
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-center modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold mx-4">
            Confirmation de réception du retour et validation de l'état des
            marchandises reçues
          </p>
        </div>
        <div class="flex justify-end">
          <button
            @click="openConfirmRetour = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="validerRetour()"
            :disabled="loadingValiderRetour"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          >
            Confirmer et valider
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- modal pour refus de quantité -->
  <div
    v-if="showRefusQuantite"
    :class="`modal ${
      !showRefusQuantite && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showRefusQuantite = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 50rem; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Raison du refus de la quantité</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showRefusQuantite = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="flex flex-col">
            <p>
              Quantité refusée :
              {{
                tabDetailRetour.requestedArticles[indexRefusQty]
                  .requestedQuantity -
                tabDetailRetour.requestedArticles[indexRefusQty].quantity
              }}
            </p>
            <p class="text-sm mt-2">Veuillez préciser la raison du refus :</p>
            <h2>
              <textarea
                rows="2"
                cols="40"
                class="w-full border border-2 px-1 py-0.5 focus:outline-2 outline-sky-300"
                placeholder="Ex. : Article non accepté en retour."
                v-model="
                  tabDetailRetour.requestedArticles[indexRefusQty]
                    .refusalQuantity.reason
                "
              ></textarea>
            </h2>
            <p class="text-xs" style="font-style: italic; color: #888">
              * Ce message sera visible par le client.
            </p>
            <div
              v-if="
                tabDetailRetour.requestedArticles[indexRefusQty].refusalQuantity
                  .user
              "
            >
              Refusé par :
              {{
                tabDetailRetour.requestedArticles[indexRefusQty].refusalQuantity
                  .user
              }}, le
              {{
                tabDetailRetour.requestedArticles[indexRefusQty].refusalQuantity
                  .date
              }}.
            </div>
          </div>
        </div>

        <div class="flex justify-end my-2">
          <button
            @click="showRefusQuantite = false"
            class="px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            class="px-6 py-3 mr-2 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white border border-bluevk hover:border-transparent rounded"
            @click="saveRaisonRetour(indexRefusQty)"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal pour refus de quantité -->

  <!-- modal pour raison relicat -->
  <div
    v-if="showRaisonReliquat"
    :class="`modal ${
      !showRaisonReliquat && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showRaisonReliquat = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 50rem; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Raison du reliquat</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showRaisonReliquat = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="flex flex-col">
            <p class="text-sm mt-2">
              Veuillez préciser la raison du reliquat :
            </p>
            <h2>
              <textarea
                rows="2"
                cols="40"
                class="w-full border border-2 px-1 py-0.5 focus:outline-2 outline-sky-300"
                placeholder="Ex. : Article non accepté en retour."
                v-model="tabDetailRetour.remainder.reason"
              ></textarea>
            </h2>
            <p class="text-xs" style="font-style: italic; color: #888">
              * Ce message sera visible par le client.
            </p>
            <div v-if="tabDetailRetour.remainder.value != ''">
              Reliquat créé par :
              {{ tabDetailRetour.remainder.user }}, le
              {{ tabDetailRetour.remainder.date }}.
            </div>
          </div>
        </div>

        <div class="flex justify-end my-2">
          <button
            @click="showRaisonReliquat = false"
            class="px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            :disabled="loadingValiderRetour"
            class="px-6 py-3 mr-2 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white border border-bluevk hover:border-transparent rounded"
            @click="validerRetour()"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal pour raison relicat -->

  <!-- BODY -->
  <div>
    <div class="flex justify-between">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/Retour'" />
      </div>

      <div class="flex flex-row space-x-24">
        <div class="flex flex-col px-2">
          <p>
            Retour demandé par : {{ tabDetailRetour.Client }} /
            {{ tabDetailRetour.Name }}
          </p>

          <!-- total avec quantité accepté -->
          <div
            v-if="
              tabDetailRetour.retourstate[1] || tabDetailRetour.retourstate[2]
            "
          >
            <div
              v-if="tabDetailRetour.requestedTotalretour"
              class="flex space-x-2 items-center"
            >
              <p>Montant du retour demandé :</p>
              <h4 class="font-medium text-xl">
                {{ financial(tabDetailRetour.requestedTotalretour) }} € HT
              </h4>
              <p>soit</p>
              <h4>
                {{
                  financial(
                    tabDetailRetour.requestedTotalretour +
                      tabDetailRetour.requestedTotalretour * 0.2
                  )
                }}
                € TTC
              </h4>
            </div>
            <div class="flex space-x-2 items-center">
              <p class="text-green-600">Montant du retour accepté :</p>
              <h4 class="font-medium text-xl">
                {{ financial(setTotal) }} € HT
              </h4>
              <p>soit</p>
              <h4>{{ financial(setTotalTTC) }} € TTC</h4>
            </div>
          </div>

          <!-- total de base -->
          <div v-else>
            <div
              v-if="tabDetailRetour.requestedTotalretour"
              class="flex space-x-2 items-center"
            >
              <p>Montant du retour demandé :</p>
              <h4 class="font-medium text-xl">
                {{ financial(tabDetailRetour.requestedTotalretour) }} € HT
              </h4>
              <p>soit</p>
              <h4>
                {{
                  financial(
                    tabDetailRetour.requestedTotalretour +
                      tabDetailRetour.requestedTotalretour * 0.2
                  )
                }}
                € TTC
              </h4>
            </div>
            <div
              v-if="!tabDetailRetour.retourstate[0]"
              class="flex space-x-2 items-center"
            >
              <p class="text-green-600">Montant du retour accepté :</p>
              <h4 class="font-medium text-xl">
                {{ parseFloat(tabDetailRetour.Totalretour).toFixed(2) }} € HT
              </h4>
              <p>soit</p>
              <h4>
                {{
                  (
                    parseFloat(tabDetailRetour.Totalretour) +
                    parseFloat(tabDetailRetour.Totalretour) * 0.2
                  ).toFixed(2)
                }}
                € TTC
              </h4>
            </div>
          </div>
          <p>Commentaire : {{ tabDetailRetour.Note }}</p>
        </div>
        <div class="text-right">
          <span
            v-if="tabDetailRetour.clientInfo && tabDetailRetour.clientInfo[0]"
          >
            {{ tabDetailRetour.clientInfo[0].Adresse }},
            {{ tabDetailRetour.clientInfo[0].PostalCode }}
            <p v-if="tabDetailRetour.clientInfo[0].Complement != ''">
              {{ tabDetailRetour.clientInfo[0].Complement }},
            </p>
            {{ tabDetailRetour.clientInfo[0].City }},
            {{ tabDetailRetour.clientInfo[0].Country }} <br />
            {{ tabDetailRetour.clientInfo[0].telephone }} <br />
            <p
              v-if="
                tabDetailRetour.clientInfo[0].transporteur &&
                tabDetailRetour.clientInfo[0].transporteur != ''
              "
            >
              Transporteur : {{ tabDetailRetour.clientInfo[0].transporteur }}
            </p>
            <br />
            <p>Transport : {{ tabDetailRetour.Transport }} €</p>
          </span>
          <p v-if="tabDetailRetour.remainder.value" class="uppercase font-bold">
            Reliquat du BR n°{{ tabDetailRetour.remainder.number }}
          </p>
        </div>
      </div>
    </div>

    <!-- Boutons d'action -->
    <div class="flex justify-between">
      <div class="flex flex-col my-auto">
        <p>Progression :</p>
        <progressBar
          v-if="tabDetailRetour.status == 'Retour annulé'"
          :pourcentageStatus="parseInt(tabDetailRetour.PourcentageStatus)"
          :isRed="true"
          class="w-80"
        />
        <progressBar
          v-else
          :pourcentageStatus="parseInt(tabDetailRetour.PourcentageStatus)"
          :isRed="false"
          class="w-80"
        />
      </div>

      <div class="mt-6 flex flex-row justify-end space-x-2">
        <!-- <div v-if="tabDetailRetour.Archived == false" class="flex items-center">
          <button
            class="flex w-fit px-2 py-1 h-fit text-blue-600 rounded-md shadow bg-gray-50 hover:bg-gray-100 focus:outline-none items-center"
            @click="archiveRetour(true)"
          >
            <span class="material-icons-outlined m-auto">archive</span>
            <span class="m-auto">Archiver</span>
          </button>
        </div>
        <div v-else class="flex items-center">
          <button
            class="flex w-fit px-2 py-1 h-fit text-blue-600 rounded-md shadow bg-gray-50 hover:bg-gray-100 focus:outline-none items-center"
            @click="archiveRetour(false)"
          >
            <span class="material-icons-outlined m-auto">unarchive</span>
            <span class="m-auto">Reprendre</span>
          </button>
        </div> -->

        <!-- <button
          v-if="!tabDetailRetour.numeroAvoir && !tabDetailRetour.retourstate[3]"
          class="p-2 mx-8 bg-red-400 text-white rounded-full shadow"
          @click="attenteCommande"
        >
          Mettre en attente
        </button> -->

        <button
          :class="[
            'p-2 ml-2 px-4 text-white rounded-full shadow w-48',
            loadingTraiterRetour == true ? 'bg-gray-400' : 'bg-btnAction',
          ]"
          @click="traiterRetour()"
          v-show="tabDetailRetour.retourstate[0]"
          :disabled="loadingTraiterRetour"
        >
          <span>Traiter le retour</span>
        </button>

        <button
          :class="[
            'p-2 ml-2 px-4 text-white rounded-full shadow w-48',
            loadingAccepterRetour == true ? 'bg-gray-400' : 'bg-btnAction',
          ]"
          @click="accepterRetour()"
          v-show="tabDetailRetour.retourstate[1]"
          :disabled="loadingAccepterRetour"
        >
          <span>Accepter le retour</span>
        </button>

        <button
          class="flex justify-center p-2 ml-2 px-4 bg-btnDownload text-white rounded-full shadow items-center"
          @click="openBR(tabDetailRetour)"
          v-if="
            (tabDetailRetour.retourstate[2] ||
              tabDetailRetour.retourstate[3]) &&
            tabDetailRetour.NumeroBR
          "
        >
          <span>Bon de retour n° {{ tabDetailRetour.NumeroBR }}</span>
          <button class="material-icons-round text-orange-500 my-auto">
            download
          </button>
        </button>

        <button
          class="flex justify-center p-2 ml-2 px-4 bg-btnDownload text-white rounded-full shadow items-center"
          v-else-if="
            (tabDetailRetour.retourstate[2] ||
              tabDetailRetour.retourstate[3]) &&
            !tabDetailRetour.NumeroBR
          "
        >
          <span>Bon de retour n'existe pas</span>
        </button>

        <button
          :class="[
            'p-2 ml-2 px-4 text-white rounded-full shadow w-48',
            loadingValiderRetour == true ? 'bg-gray-400' : 'bg-btnAction',
          ]"
          @click="verifRelicatValiderRetour()"
          v-show="tabDetailRetour.retourstate[2]"
          :disabled="loadingValiderRetour"
        >
          <span>Valider le retour</span>
        </button>

        <button
          class="flex justify-center p-2 ml-2 px-4 bg-btnDownload text-white rounded-full shadow items-center"
          @click="openAvoir(tabDetailRetour)"
          v-show="tabDetailRetour.retourstate[3]"
        >
          <span>BL Avoir n° {{ tabDetailRetour.numeroAvoir }}</span>
          <button class="material-icons-round text-orange-500 my-auto">
            download
          </button>
        </button>
      </div>
    </div>

    <!-- Partie scan -->
    <input
      v-show="tabDetailRetour.retourstate[1]"
      v-model="scanEAN"
      type="text"
      placeholder="Scanner"
      class="p-2 sm:w-1/4 w-full focus:outline-2 outline-sky-300 rounded shadow border border-blue-600 placeholder:text-blue-400"
      v-on:keyup.enter="addByScan"
    />
    <div v-if="tabDetailRetour.remainder.value">
      <p>Raison reliquat : {{ tabDetailRetour.remainder.reason }}</p>
      <p>Créé par : {{ tabDetailRetour.remainder.user }}</p>
      <p>Le : {{ tabDetailRetour.remainder.date }}</p>
    </div>

    <!-- Tableau des articles -->
    <div class="flex flex-col mt-4">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Réf. / Code EAN
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Raison du retour / Date BL
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div
                    v-if="tabDetailRetour.retourstate[1]"
                    class="flex space-x-8"
                  >
                    <div class="flex space-x-1">
                      <p class="m-auto">Qté acceptée / Qté demandée</p>
                    </div>
                    <button
                      class="material-icons-round rounded hover:bg-blue-500 hover:text-white"
                      @click="fillQtyOk()"
                    >
                      task_alt
                    </button>
                  </div>
                  <div
                    v-else-if="
                      tabDetailRetour.retourstate[2] ||
                      tabDetailRetour.retourstate[3]
                    "
                    class="flex space-x-8"
                  >
                    <div class="flex space-x-1">
                      <p class="m-auto">Qté acceptée / Qté demandée</p>
                    </div>
                  </div>
                  <span v-else>Quantité</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Stock
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  PPC
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Prix
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in tabDetailRetour.requestedArticles"
                :key="index"
                class="hover:bg-gray-100 h-fit"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-row">
                    <div class="flex flex-col">
                      <div class="text-sm leading-5 text-gray-900">
                        <div class="flex flex-row space-x-2 items-center">
                          <span
                            class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                            >{{ u.Ref_fournisseur }}</span
                          >
                          <span
                            class="hidden md:flex material-icons-outlined m-auto cursor-pointer text-blue-500 hover:bg-gray-100 rounded hover:shadow"
                            style="font-size: 12px"
                            @click="plusInfo(u)"
                          >
                            info
                          </span>
                        </div>
                      </div>
                      <div
                        class="text-sm leading-5 text-gray-600"
                        v-if="u.Ref_complementaire"
                      >
                        <span
                          class="inline-flex px-2 text-xs leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                          >Comp : {{ u.Ref_complementaire }}</span
                        >
                      </div>
                      <div class="text-sm leading-5 text-gray-900">
                        <span
                          class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                          >{{ u.Code_EAN }}</span
                        >
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Code_marque }}
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.Description }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 whitespace-normal">
                    {{ u.raisonRetour }}
                    <p v-if="u.raisonRetourAutre && u.raisonRetourAutre != ''">
                      {{ u.raisonRetourAutre }}
                    </p>
                  </div>
                  <div class="flex flex-col text-xs leading-5 text-gray-900">
                    <p v-if="u.typeDoc">{{ u.typeDoc }} n° {{ u.numBL }}</p>
                    <p>Date : {{ getFRDate(u.dateBL) }}</p>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-row">
                    <!-- 25% 75% -->
                    <div
                      v-if="
                        tabDetailRetour.retourstate[1] ||
                        tabDetailRetour.retourstate[2]
                      "
                      class="my-auto inline-flex px-2 font-semibold leading-5 text-gray-800 font-medium"
                    >
                      <div class="flex flex-col">
                        <div class="flex">
                          <p class="my-auto mr-4">
                            <input
                              type="number"
                              v-model="
                                tabDetailRetour.requestedArticles[index]
                                  .quantity
                              "
                              class="w-16 p-1 focus:outline-2 outline-sky-300 rounded shadow border border-blue-600 font-medium"
                              @input="filterInputRtrQty"
                              :max="0"
                              :min="u.requestedQuantity"
                              @change="
                                handleQuantityInput(
                                  tabDetailRetour.requestedArticles[index]
                                    .quantity,
                                  tabDetailRetour.requestedArticles[index]
                                    .requestedQuantity,
                                  index
                                )
                              "
                              @keyup.enter="
                                handleEnterKeyQuantityInput(
                                  tabDetailRetour.requestedArticles[index]
                                    .quantity,
                                  tabDetailRetour.requestedArticles[index]
                                    .requestedQuantity,
                                  index
                                )
                              "
                            />
                          </p>

                          <p
                            class="my-auto inline-flex px-2 font-semibold leading-5 text-gray-800"
                          >
                            / {{ u.requestedQuantity }}
                          </p>
                        </div>

                        <div
                          v-if="
                            tabDetailRetour.retourstate[2] &&
                            u.refusalQuantity &&
                            u.refusalQuantity.value == true
                          "
                          class="flex flex-col text-xs mt-1"
                        >
                          <p
                            class="bg-orange-500 text-white w-fit font-bold px-0.5"
                          >
                            Qté demandée :
                            {{ u.refusalQuantity.requestedQuantity }}
                          </p>
                          <p class="bg-red-500 text-white w-fit px-0.5 mt-1">
                            Qté refusée : {{ u.refusalQuantity.quantity }}
                          </p>
                          <p>
                            Par:
                            {{ u.refusalQuantity.user }}
                          </p>
                          <p class="bg-blue-500 text-white w-fit px-0.5 mt-1">
                            Raison :
                            {{ u.refusalQuantity.reason }}
                          </p>
                          <p>
                            Le:
                            {{ u.refusalQuantity.date }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- 100% -->
                    <div
                      v-else-if="tabDetailRetour.retourstate[3]"
                      class="my-auto flex flex-col font-medium inline-flex px-2 font-semibold leading-5 text-gray-800"
                    >
                      <p>{{ u.quantity }} / {{ u.requestedQuantity }}</p>

                      <div
                        v-if="
                          u.refusalQuantity && u.refusalQuantity.value == true
                        "
                        class="flex flex-col text-xs"
                      >
                        <p class="bg-red-500 text-white w-fit">
                          Qté refusée : {{ u.refusalQuantity.quantity }}
                        </p>
                        <p>
                          Par:
                          {{ u.refusalQuantity.user }}
                        </p>
                        <p class="bg-blue-500 text-white w-fit px-0.5 mt-1">
                          Raison :
                          {{ u.refusalQuantity.reason }}
                        </p>
                        <p>
                          Le:
                          {{ u.refusalQuantity.date }}
                        </p>
                      </div>
                    </div>
                    <!-- 10% -->
                    <p
                      v-else
                      class="my-auto font-medium inline-flex px-2 font-semibold leading-5 text-gray-800"
                    >
                      {{ u.quantity }}
                    </p>

                    <!-- 25% -->
                    <div
                      class="flex my-auto"
                      v-show="tabDetailRetour.retourstate[1]"
                    >
                      <button
                        v-if="u.quantity != u.requestedQuantity"
                        @click="
                          openRefusQuantite(
                            tabDetailRetour.requestedArticles[index].quantity,
                            tabDetailRetour.requestedArticles[index]
                              .requestedQuantity,
                            index
                          )
                        "
                        title="Indiquer la raison du refus de la quantité."
                        class="material-icons-round text-orangevk hover:text-orange-500 drop-shadow-lg"
                      >
                        rate_review
                      </button>
                      <p v-else class="w-8"></p>
                      <p
                        class="ml-9 my-auto inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-green-100 rounded-full"
                        v-if="u.quantity == u.requestedQuantity"
                      >
                        Complet
                      </p>
                      <p
                        class="ml-9 my-auto inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-red-100 rounded-full"
                        v-else
                      >
                        Incomplet
                      </p>
                      <button
                        class="inline-flex material-icons-round rounded-full hover:bg-red-400 hover:text-white"
                        @click="removeOne(index)"
                      >
                        remove
                      </button>
                      <button
                        class="inline-flex material-icons-round rounded-full hover:bg-green-500 hover:text-white"
                        @click="addOne(index)"
                      >
                        add
                      </button>
                      <button
                        class="inline-flex material-icons-round rounded hover:text-blue-500 hover:text-white"
                        @click="fillQty(index)"
                      >
                        task_alt
                      </button>
                    </div>
                  </div>
                </td>

                <!-- stock -->
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap w-52"
                >
                  <stockvue
                    :quantity="u.quantity"
                    :Article="u"
                    :key="u.Code_EAN"
                  />

                  <miniMaxiArticle
                    v-if="tabDetailRetour.PourcentageStatus != '100'"
                    :reference="u.Ref_fournisseur"
                    :ean="u.Code_EAN"
                    :marque="u.Code_marque"
                    :quantity="
                      tabDetailRetour.requestedArticles[index].quantity
                    "
                    :dispoArticle="dispoArticle[index]"
                    :index="index"
                  />
                  <miniMaxiArticle
                    v-else
                    :reference="u.Ref_fournisseur"
                    :ean="u.Code_EAN"
                    :marque="u.Code_marque"
                    :quantity="0"
                    :dispoArticle="dispoArticle[index]"
                    :index="index"
                  />
                </td>

                <!-- prix vente -->
                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.Prix_euro).toFixed(2) }} €
                </td>

                <!-- remise / prix net -->
                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex text-sm leading-5 text-gray-900">
                    <div class="flex">
                      <p v-if="u.PrixFournisseurType != 'prixnet'">
                        {{ financial(u.remise) }} %
                      </p>
                      <p v-else>{{ u.PrixFournisseurType }}</p>
                    </div>
                  </div>
                </td>

                <!-- prix final -->
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex space-x-1 text-sm leading-5 text-gray-900">
                    <p v-if="u.PrixFournisseurType != 'prixnet'">
                      {{
                        financial(u.Prix_euro - u.Prix_euro * (u.remise / 100))
                      }}
                    </p>
                    <p v-else>
                      {{ financial(u.Prix_vente) }}
                    </p>

                    <p>€</p>
                  </div>
                </td>

                <!-- total -->
                <td
                  class="px-6 py-4 font-medium text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex space-x-1 text-sm leading-5 text-gray-900">
                    <span v-if="u.PrixFournisseurType != 'prixnet'">
                      {{
                        financial(
                          (u.Prix_euro - u.Prix_euro * (u.remise / 100)) *
                            tabDetailRetour.requestedArticles[index].quantity
                        )
                      }}
                    </span>
                    <span v-else>
                      {{
                        financial(
                          u.Prix_vente *
                            tabDetailRetour.requestedArticles[index].quantity
                        )
                      }}
                    </span>
                    <span>€</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
import ProgressBar from "@/components/Commandes/progressBar.vue";
import { createretour } from "../../hooks/pdf/retour.js";
import { createlivraison } from "@/hooks/pdf/livraison";
// import {
//   createfactureAvoir,
//   createfactureAvoirDuplicata,
// } from "@/hooks/pdf/facture";
import { removeStock } from "@/hooks/stock/stock";
import stockvue from "@/components/Article/stock.vue";
import miniMaxiArticle from "@/components/articleInfo/miniMaxiArticle.vue";
import backButton from "@/components/Button/back.vue";
import { getFrDateSansH } from "@/hooks/tools/date.ts";
import { getFrDate } from "@/hooks/tools/date.ts";
import articleInfo from "@/components/articleInfo/articleInfo.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyRetour",
  data: () => ({
    scanEAN: "",
    dispoArticle: [],
    indexRefusQty: 0,

    openConfirmRetour: false,
    showRefusQuantite: false,
    showRaisonReliquat: false,

    loadingTraiterRetour: false,
    loadingAccepterRetour: false,
    loadingValiderRetour: false,

    articleInfo_componentKey: 0,
    openModalArticle: false,
  }),
  computed: {
    ...mapGetters(["user", "tabDetailRetour", "plateform"]),
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      // var qty = 0;
      this.tabDetailRetour.requestedArticles.forEach((element) => {
        // qty = element.quantity;
        if (element.PrixFournisseurType == "prixnet") {
          totalRemise = parseFloat(
            parseFloat(element.Prix_vente * element.quantity).toFixed(2)
          );
        } else {
          totalRemise = parseFloat(
            parseFloat(
              (element.Prix_euro - element.Prix_euro * (element.remise / 100)) *
                element.quantity
            ).toFixed(2)
          );
        }
        total = total + totalRemise;
        this.tabDetailRetour.Totalretour = total;
      });
      // this.tabDetailRetour.pdfData.forEach((element) => {
      //   element.quantite = qty;
      // });
      return this.tabDetailRetour.Totalretour;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
  },
  components: {
    ProgressBar,
    stockvue,
    miniMaxiArticle,
    backButton,
    articleInfo,
  },
  methods: {
    ...mapActions(["addDetailRetour", "setArticleInfo"]),
    getFRDate(date) {
      return getFrDate(date);
    },
    // bouton retour
    setRetour() {
      this.$router.push("./Retour");
    },
    // récupérer le document retour avec _id
    async getOneRetour() {
      var type;
      if (this.tabDetailRetour.typeRetour == "piece") {
        type = "retour";
      } else if (this.tabDetailRetour.typeRetour == "consigne") {
        type = "retourConsigne";
      }
      const tabRetour = await axios.get(
        `${process.env.VUE_APP_API}/${type}/getone/${this.tabDetailRetour._id}`
      );
      console.log(tabRetour);
      await this.addDetailRetour(tabRetour.data[0]);
    },
    // enregistrer les modifications
    async saveRetour() {
      var type;
      if (this.tabDetailRetour.typeRetour == "piece") {
        type = "retour";
      } else if (this.tabDetailRetour.typeRetour == "consigne") {
        type = "retourConsigne";
      }
      await axios.put(
        `${process.env.VUE_APP_API}/${type}/${this.tabDetailRetour._id}`,
        this.tabDetailRetour
      );
    },
    // traiter retour
    async traiterRetour() {
      this.loadingTraiterRetour = true;
      var type;
      if (this.tabDetailRetour.typeRetour == "piece") {
        type = "retour";
      } else if (this.tabDetailRetour.typeRetour == "consigne") {
        type = "retourConsigne";
      }
      await axios.put(
        `${process.env.VUE_APP_API}/${type}/${this.tabDetailRetour._id}`,
        {
          status: "En cours de traitement",
          PourcentageStatus: "25",
          retourstate: [false, true, false, false],
        }
      );
      // this.creerNumeroBR();
      await this.getOneRetour();
      this.loadingTraiterRetour = false;
      this.successMsg("Retour en cours de traitement !");
    },
    // accepter retour
    async accepterRetour() {
      this.loadingAccepterRetour = true;
      this.tabDetailRetour.Totalretour = this.setTotal;
      await this.deleteRefusalArticles();
      await this.saveRetour();
      var type; // type du chemin retour ou retourConsigne
      if (this.tabDetailRetour.typeRetour == "piece") {
        type = "retour";
      } else if (this.tabDetailRetour.typeRetour == "consigne") {
        type = "retourConsigne";
      }
      await axios.put(
        `${process.env.VUE_APP_API}/${type}/${this.tabDetailRetour._id}`,
        {
          status: "Retour accepté",
          PourcentageStatus: "75",
          retourstate: [false, false, true, false],
        }
      );
      await this.getOneRetour();

      if (this.tabDetailRetour.pdfData.length > 0) {
        await this.creerNumeroBR(); // créer le numéro de BR
      }
      this.loadingAccepterRetour = false;
      this.successMsg("Retour accepté et BR créé !");
    },
    // valider retour
    async validerRetour() {
      this.loadingValiderRetour = true;
      // this.openConfirmRetour = false;
      this.showRaisonReliquat = false;

      // supprimer du tableau requestedPdfData quand quantity == 0
      for (const [
        index,
        element,
      ] of this.tabDetailRetour.requestedArticles.entries()) {
        if (element.refusalQuantity && element.refusalQuantity.value === true) {
          if (element.quantity === 0) {
            this.tabDetailRetour.requestedPdfData.splice(index, 1);
          }
        }
      }

      // Reliquat
      await this.creerReliquat();

      //
      var type;
      if (this.tabDetailRetour.typeRetour == "piece") {
        type = "retour";
      } else if (this.tabDetailRetour.typeRetour == "consigne") {
        type = "retourConsigne";
      }
      await axios.put(
        `${process.env.VUE_APP_API}/${type}/${this.tabDetailRetour._id}`,
        {
          status: "Retour validé",
          PourcentageStatus: "100",
          retourstate: [false, false, false, true],
        }
      );
      await this.getOneRetour();
      await this.creerAvoir();
      await this.archiveRetour(true);
      this.loadingValiderRetour = false;
      this.successMsg("Retour validé !");
      // await this.archiveRetour();
      this.openAvoir(this.tabDetailRetour);
      this.setRetour();
    },
    // archiver retour
    async archiveRetour(data) {
      var type;
      if (this.tabDetailRetour.typeRetour == "piece") {
        type = "retour";
      } else if (this.tabDetailRetour.typeRetour == "consigne") {
        type = "retourConsigne";
      }
      await axios.put(
        `${process.env.VUE_APP_API}/${type}/${this.tabDetailRetour._id}`,
        {
          Archived: data,
        }
      );

      await this.getOneRetour();
      this.successMsg("Retour archivé !");
    },

    plusInfo(art) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo(art);
    },

    handleQuantityInput(qty, requestedQty, index) {
      if (this.tabDetailRetour.retourstate[1]) {
        this.saveQuantity(qty, requestedQty, index);
      } else if (this.tabDetailRetour.retourstate[2]) {
        this.saveQuantity2(qty, index);
      }
    },
    handleEnterKeyQuantityInput(qty, requestedQty, index) {
      if (this.tabDetailRetour.retourstate[1]) {
        this.openRefusQuantite(qty, requestedQty, index);
      }
    },
    // PARTIE POUR REFUS ARTICLE
    async deleteRefusalArticles() {
      const indicesToDelete = [];

      for (const [
        index,
        element,
      ] of this.tabDetailRetour.requestedArticles.entries()) {
        if (element.refusalQuantity && element.refusalQuantity.value === true) {
          if (element.quantity === 0) {
            indicesToDelete.push(index);
          }

          var prixRemiseRetour = 0.0;
          if (element.PrixFournisseurType == "prixnet") {
            prixRemiseRetour = parseFloat(element.Prix_vente);
          } else {
            prixRemiseRetour =
              element.Prix_euro - element.Prix_euro * (element.remise / 100);
          }
          this.tabDetailRetour.requestedArticles[index].requestedQuantity =
            element.quantity;
          this.tabDetailRetour.Articles[index].requestedQuantity =
            element.quantity;
          this.tabDetailRetour.Articles[index].quantity = element.quantity;
          this.tabDetailRetour.pdfData[index].quantite = element.quantity;
          this.tabDetailRetour.pdfData[index].prixU = prixRemiseRetour;
          this.tabDetailRetour.pdfData[index].total =
            prixRemiseRetour * element.quantity;
          this.tabDetailRetour.requestedPdfData[index].quantite =
            element.quantity;
          this.tabDetailRetour.requestedPdfData[index].prixU = prixRemiseRetour;
          this.tabDetailRetour.requestedPdfData[index].total =
            prixRemiseRetour * element.quantity;
        }
      }
      for (let i = indicesToDelete.length - 1; i >= 0; i--) {
        const indexToDelete = indicesToDelete[i];
        this.tabDetailRetour.Articles.splice(indexToDelete, 1);
        this.tabDetailRetour.pdfData.splice(indexToDelete, 1);
      }
      console.log("TABB");
      console.log(this.tabDetailRetour);
    },
    saveQuantity(qty, requestedQty, index) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      if (!this.tabDetailRetour.requestedArticles[index].refusalQuantity) {
        this.tabDetailRetour.requestedArticles[index].refusalQuantity = {};
      }

      if (qty > requestedQty) {
        this.tabDetailRetour.requestedArticles[
          index
        ].refusalQuantity.value = true;
        this.tabDetailRetour.requestedArticles[index].refusalQuantity.user =
          this.user.username;
        this.tabDetailRetour.requestedArticles[index].refusalQuantity.date =
          today;
        this.tabDetailRetour.requestedArticles[
          index
        ].refusalQuantity.requestedQuantity =
          this.tabDetailRetour.requestedArticles[index].requestedQuantity;
        this.tabDetailRetour.requestedArticles[index].refusalQuantity.quantity =
          this.tabDetailRetour.requestedArticles[index].requestedQuantity -
          this.tabDetailRetour.requestedArticles[index].quantity;
      } else {
        this.tabDetailRetour.requestedArticles[
          index
        ].refusalQuantity.value = false;
        this.tabDetailRetour.requestedArticles[index].refusalQuantity.user = "";
        this.tabDetailRetour.requestedArticles[index].refusalQuantity.date = "";
        this.tabDetailRetour.requestedArticles[
          index
        ].refusalQuantity.requestedQuantity = "";
        this.tabDetailRetour.requestedArticles[index].refusalQuantity.quantity =
          "";
      }

      this.saveRetour();
    },
    openRefusQuantite(qty, requestedQty, index) {
      if (qty > requestedQty) {
        this.indexRefusQty = index;
        if (!this.tabDetailRetour.requestedArticles[index].refusalQuantity) {
          this.tabDetailRetour.requestedArticles[index].refusalQuantity = {};
        }
        this.showRefusQuantite = true;
      }
    },
    saveRaisonRetour(index) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      this.tabDetailRetour.requestedArticles[
        index
      ].refusalQuantity.value = true;
      this.tabDetailRetour.requestedArticles[index].refusalQuantity.user =
        this.user.username;
      this.tabDetailRetour.requestedArticles[index].refusalQuantity.date =
        today;
      this.tabDetailRetour.requestedArticles[
        index
      ].refusalQuantity.requestedQuantity =
        this.tabDetailRetour.requestedArticles[index].requestedQuantity;
      this.tabDetailRetour.requestedArticles[index].refusalQuantity.quantity =
        this.tabDetailRetour.requestedArticles[index].requestedQuantity -
        this.tabDetailRetour.requestedArticles[index].quantity;
      console.log(this.tabDetailRetour);
      this.saveRetour();
    },
    // PARTIE POUR RELIQUAT
    saveQuantity2(qty, index) {
      var prixRemiseRetour = 0.0;
      if (
        this.tabDetailRetour.requestedArticles[index].PrixFournisseurType ==
        "prixnet"
      ) {
        prixRemiseRetour = parseFloat(
          this.tabDetailRetour.requestedArticles[index].Prix_vente
        );
      } else {
        prixRemiseRetour =
          this.tabDetailRetour.requestedArticles[index].Prix_euro -
          this.tabDetailRetour.requestedArticles[index].Prix_euro *
            (this.tabDetailRetour.requestedArticles[index].remise / 100);
      }
      this.tabDetailRetour.requestedPdfData[index].quantite = qty;
      this.tabDetailRetour.requestedPdfData[index].prixU = prixRemiseRetour;
      this.tabDetailRetour.requestedPdfData[index].total =
        prixRemiseRetour * qty;
      this.saveRetour();
    },
    verifRelicatValiderRetour() {
      this.loadingValiderRetour = true;
      // Vérification de reliquat
      let doReliquat = false;
      for (const [
        index,
        element,
      ] of this.tabDetailRetour.requestedArticles.entries()) {
        if (
          this.tabDetailRetour.requestedArticles[index].quantity !==
          this.tabDetailRetour.requestedArticles[index].requestedQuantity
        ) {
          doReliquat = true;
        }
      }
      if (doReliquat) {
        this.showRaisonReliquat = true;
        this.loadingValiderRetour = false;
      } else {
        this.validerRetour();
      }
    },
    async creerReliquat() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      let doReliquat = false;
      let tabReliquat = [];
      let indicesASupprimer = [];

      for (const [
        index,
        element,
      ] of this.tabDetailRetour.requestedArticles.entries()) {
        if (
          this.tabDetailRetour.requestedArticles[index].quantity !==
          this.tabDetailRetour.requestedArticles[index].requestedQuantity
        ) {
          doReliquat = true;
          // element.quantity = element.requestedQuantity - element.quantity;
          tabReliquat.push({
            ...element,
            quantity: element.requestedQuantity - element.quantity,
            requestedQuantity: element.requestedQuantity - element.quantity,
          });
          indicesASupprimer.push(index);
        }
      }
      if (doReliquat) {
        for (let i = indicesASupprimer.length - 1; i >= 0; i--) {
          console.log(indicesASupprimer[i]);
          if (
            this.tabDetailRetour.requestedArticles[indicesASupprimer[i]]
              .quantity == 0
          ) {
            this.tabDetailRetour.requestedArticles.splice(
              indicesASupprimer[i],
              1
            );
            this.tabDetailRetour.requestedPdfData.splice(
              indicesASupprimer[i],
              1
            );
          }
        }

        var type;
        if (this.tabDetailRetour.typeRetour == "piece") {
          if (this.tabDetailRetour.typeRetour == "piece") {
            type = "retour";
          } else if (this.tabDetailRetour.typeRetour == "consigne") {
            type = "retourConsigne";
          }
        }

        var pdfinfoRetour = [];
        var prixRemiseRetour = 0.0;
        var totalRetour = 0.0;
        var totalRemiseRetour = 0.0;

        await tabReliquat.forEach((element, index) => {
          if (element.PrixFournisseurType == "prixnet") {
            prixRemiseRetour = parseFloat(element.Prix_vente);
          } else {
            prixRemiseRetour =
              element.Prix_euro - element.Prix_euro * (element.remise / 100);
          }
          pdfinfoRetour[index] = {
            description: element.Description,
            ref: element.Ref_fournisseur,
            quantite: element.quantity,
            remise: element.remise,
            commentaire: element.commentaire,
            prixU: prixRemiseRetour,
            total: prixRemiseRetour * element.quantity,
            totalSansRemise: this.setTotalSansRemise,
          };

          // total tabReliquat
          if (element.PrixFournisseurType == "prixnet") {
            totalRemiseRetour = parseFloat(
              parseFloat(element.Prix_vente * element.quantity).toFixed(2)
            );
          } else {
            totalRemiseRetour = parseFloat(
              parseFloat(
                (element.Prix_euro -
                  element.Prix_euro * (element.remise / 100)) *
                  element.quantity
              ).toFixed(2)
            );
          }
          totalRetour = totalRetour + totalRemiseRetour;
        });

        await axios.post(`${process.env.VUE_APP_API}/${type}`, {
          plateform: this.tabDetailRetour.plateform,
          Client: this.tabDetailRetour.Client,
          Name: this.tabDetailRetour.Name,
          // Date: today,
          clientInfo: this.tabDetailRetour.clientInfo,
          requestedArticles: tabReliquat,
          Articles: tabReliquat,
          requestedPdfData: pdfinfoRetour,
          pdfData: pdfinfoRetour,
          reglement: [],
          Note: this.tabDetailRetour.Note,
          status: "Retour demandé",
          Archived: false,
          Totalretour: totalRetour,
          requestedTotalretour: totalRetour,
          PourcentageStatus: "10",
          retourstate: [true, false, false, false],
          remainder: {
            value: true,
            reason: this.tabDetailRetour.remainder.reason,
            user: this.user.username,
            date: today,
            number: this.tabDetailRetour.NumeroBR,
          },
        });
      }
    },
    // Partie quantité
    filterInputRtrQty(event) {
      event.preventDefault();
      const inputValue = event.target.value;
      const filteredValue = inputValue.replace(/[,/.]/g, "");
      event.target.value = parseInt(filteredValue);
      if (inputValue == "") {
        event.target.value = parseInt(0);
      }
    },
    removeOne(index) {
      console.log(
        parseInt(this.tabDetailRetour.requestedArticles[index].quantity) - 1
      );
      if (
        parseInt(this.tabDetailRetour.requestedArticles[index].quantity) - 1 <=
          0 &&
        parseInt(this.tabDetailRetour.requestedArticles[index].quantity) - 1 >=
          parseInt(
            this.tabDetailRetour.requestedArticles[index].requestedQuantity
          )
      ) {
        this.tabDetailRetour.requestedArticles[index].quantity =
          parseInt(this.tabDetailRetour.requestedArticles[index].quantity) - 1;
      }
      this.saveRetour();
    },
    addOne(index) {
      if (
        parseInt(this.tabDetailRetour.requestedArticles[index].quantity) + 1 <=
          0 &&
        parseInt(this.tabDetailRetour.requestedArticles[index].quantity) + 1 >=
          parseInt(
            this.tabDetailRetour.requestedArticles[index].requestedQuantity
          )
      ) {
        this.tabDetailRetour.requestedArticles[index].quantity =
          parseInt(this.tabDetailRetour.requestedArticles[index].quantity) + 1;
      }
      this.saveRetour();
    },
    fillQty(index) {
      this.tabDetailRetour.requestedArticles[index].quantity = parseInt(
        this.tabDetailRetour.requestedArticles[index].requestedQuantity
      );
      this.saveRetour();
    },
    fillQtyOk() {
      this.tabDetailRetour.requestedArticles.forEach((element, index) => {
        this.tabDetailRetour.requestedArticles[index].quantity = parseInt(
          element.requestedQuantity
        );
      });
      this.saveRetour();
    },
    addByScan() {
      var ean = this.tabDetailRetour.requestedArticles.find(
        (element) => element.Code_EAN == this.scanEAN
      );
      if (ean != []) {
        var index = this.tabDetailRetour.requestedArticles.indexOf(ean);
        this.addOne(index);
        this.scanEAN = "";
      }
    },
    // Créer NumeroBR et DateBR
    async creerNumeroBR() {
      // var today = new Date();
      // var dd = String(today.getDate()).padStart(2, "0");
      // var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      // var yyyy = today.getFullYear();
      // var hour = today.getHours();
      // var seconds = today.getSeconds();
      // var minutes = today.getMinutes();
      // today =
      //   dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      if (this.tabDetailRetour.remainder.value == true) {
        this.tabDetailRetour.NumeroBR =
          "R" + this.tabDetailRetour.remainder.number;
      } else {
        var type;
        if (this.tabDetailRetour.typeRetour == "piece") {
          type = "retour";
        } else if (this.tabDetailRetour.typeRetour == "consigne") {
          type = "retourConsigne";
        }
        const numBR = await axios.post(
          `${process.env.VUE_APP_API}/${type}/getlast`,
          {
            uid: this.user.proprietaire,
          }
        );
        console.log("numBR : ", numBR);
        this.tabDetailRetour.NumeroBR = numBR.data + 1;
      }
      this.tabDetailRetour.DateBR = new Date();
      await this.saveRetour();
      await this.getOneRetour();
    },
    // Ouvrir BR
    openBR(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.DateBR);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createretour(objet, objet.typeRetour, false);
      } else {
        createretour(objet, objet.typeRetour, true);
      }
    },
    // creer l'avoir en fonction du type
    async creerAvoir() {
      var type;
      if (this.tabDetailRetour.typeRetour == "piece") {
        type = "retour";
      } else if (this.tabDetailRetour.typeRetour == "consigne") {
        type = "retourConsigne";
      }
      const numDoc = await axios.post(
        `${process.env.VUE_APP_API}/bdl/getlast`,
        {
          uid: this.user.proprietaire,
        }
      );
      await this.creerBdl(numDoc);
      console.log("Numero BL ", numDoc.data + 1);
      await axios.put(
        `${process.env.VUE_APP_API}/${type}/${this.tabDetailRetour._id}`,
        {
          Date: new Date(),
          numeroAvoir: numDoc.data + 1,
        }
      );
      this.tabDetailRetour.numeroAvoir = numDoc.data + 1;
      // await this.saveRetour();
      // await this.getOneRetour();
    },
    // creer /bdl et /bdl des dsv
    async creerBdl(numDoc) {
      for (const [
        index,
        art,
      ] of this.tabDetailRetour.requestedArticles.entries()) {
        if (
          art.refusalQuantity &&
          art.refusalQuantity.value == true &&
          art.refusalQuantity.reason
        ) {
          this.tabDetailRetour.requestedPdfData[index].commentaire +=
            " " + art.refusalQuantity.reason;
        }
      }
      await axios.post(`${process.env.VUE_APP_API}/bdl`, {
        Name: this.user.username,
        Date: new Date(),
        Numero: numDoc.data + 1,
        TotalPrice: this.tabDetailRetour.Totalretour,
        TotalPriceTTC:
          this.tabDetailRetour.Totalretour +
          this.tabDetailRetour.Totalretour * 0.2,
        Note: this.tabDetailRetour.Note,
        Client: this.tabDetailRetour.Client,
        Owner: this.user.proprietaire,
        IsProcessed: false,
        Articles: this.tabDetailRetour.requestedArticles,
        reglement: [],
        pdfData: this.tabDetailRetour.requestedPdfData,
        clientInfo: this.tabDetailRetour.clientInfo,
        Transport: this.tabDetailRetour.Transport,
      });

      // bdl dsv + ajout stock
      var pdfinfoCons = [];
      var tempDsv = [];
      var totalRemiseConsigne = 0.0;
      var totalConsigne = 0.0;
      for (const [
        index,
        element,
      ] of this.tabDetailRetour.requestedArticles.entries()) {
        if (!element.Ref_fournisseur.startsWith("C : ")) {
          await removeStock(
            this.user._id,
            this.user.proprietaire,
            element._id,
            element.Ref_fournisseur,
            element.Code_marque,
            element.Code_EAN,
            0,
            0,
            10,
            "",
            this.user.username,
            "BR n°" +
              this.tabDetailRetour.NumeroBR +
              ", BL Avoir n°" +
              (parseInt(numDoc.data) + 1) +
              " crée par " +
              this.user.username,
            element.Prefixe_tarif,
            this.tabDetailRetour.Client,
            parseInt(numDoc.data) + 1,
            "BL Avoir",
            "retour",
            parseInt(this.tabDetailRetour.requestedArticles[index].quantity)
          );
        }

        if (
          this.plateform.structure_depot == true &&
          this.tabDetailRetour.typeRetour == "piece" &&
          element.Prefixe_tarif == "Dsv" &&
          element.Code_marque == "DA SILVA" &&
          !element.Ref_fournisseur.startsWith("C : ")
        ) {
          tempDsv.push(element);
          pdfinfoCons.push({
            description: element.Description,
            ref: element.Ref_fournisseur,
            quantite: this.tabDetailRetour.requestedArticles[index].quantity,
            remise: element.remise,
            remisegenerale: 0,
            commentaire: "",
            // remiseSupp: this.remiseTabSCD[index],
            prixU:
              element.Prix_euro - element.Prix_euro * (element.remise / 100),
            // total: prixRemiseSupp * this.qtyTab[index],
            total:
              element.Prix_euro *
              this.tabDetailRetour.requestedArticles[index].quantity,
          });
          totalRemiseConsigne =
            parseFloat(element.Prix_euro) -
            parseFloat(element.Prix_euro) * (element.remise / 100);
          totalConsigne =
            totalConsigne +
            totalRemiseConsigne *
              this.tabDetailRetour.requestedArticles[index].quantity;
        }
      }

      if (tempDsv.length > 0) {
        var numDocConsigne;
        await axios
          .post(`${process.env.VUE_APP_API}/bdl/getlast`, {
            uid: this.user.proprietaire,
          })
          .then((response) => {
            numDocConsigne = response.data + 1 + "D";
          });

        await axios.post(`${process.env.VUE_APP_API}/bdl`, {
          Name: this.user.username,
          Date: new Date(),
          Numero: numDocConsigne,
          TotalPrice: totalConsigne,
          TotalPriceTTC: totalConsigne + totalConsigne * 0.2,
          Note: this.tabDetailRetour.Note,
          Client: this.tabDetailRetour.Client,
          Owner: this.user.proprietaire,
          IsProcessed: false,
          Articles: tempDsv,
          reglement: [],
          pdfData: pdfinfoCons,
          clientInfo: this.tabDetailRetour.clientInfo,
          Transport: this.tabDetailRetour.Transport,
          Fournisseur: "DA SILVA",
          textFile: {
            consignataire: this.plateform.dasilva.consignataire,
            typeBL: "A",
            codeClient: this.plateform.dasilva.codeClient,
            numeroCmd: numDocConsigne,
            commentaire: "",
          },
        });
      }
    },
    // dispo de l'article
    checkDispoArticle(data, index) {
      this.dispoArticle[index] = data;
    },
    openAvoir(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var datepdf = da + "/" + mm + "/" + yyyy;

      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: objet.numeroAvoir,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          var dateedition = getFrDateSansH(response.data[0].Date);
          if (dateedition == datepdf) {
            createlivraison(response.data[0], false, true);
          } else {
            createlivraison(response.data[0], true, true);
          }
        });
    },
    successMsg(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  mounted() {
    this.getOneRetour();
  },
};
</script>

<style scoped>
.modal {
  transition: opacity 0.25s ease;
}
.bg-vk1:hover {
  background-color: #ff914d;
}
.bg-vk2:hover {
  background-color: #2262b3;
}
.bg-btnDownload {
  background-color: #1e7bf5;
}
.bg-btnDownload:hover {
  background-color: #4d9aff;
}
.bg-btnAction {
  background-color: #22c55e;
}
.bg-btnAction:hover {
  background-color: #58d686e8;
}
</style>
