<template>
  <div id="retour" class="flex justify-start">
    <backButton />
  </div>
  <div>Liste des commerciaux</div>
</template>
<script>
import backButton from "@/components/Button/back.vue";
export default {
  components: {
    backButton,
  },
};
</script>
<style></style>
