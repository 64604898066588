<template>
  <div class="flex flex-col space-y-2 h-80 md:h-85 w-full">
    <div
      v-if="openModalArticle"
      :class="`modal ${
        !openModalArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openModalArticle = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 68vw; max-height: 80vh"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 pt-4 text-left modal-content">
          <div class="flex items-center justify-end pb-3">
            <!-- <p class="text-2xl font-bold">Titre</p> -->
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openModalArticle = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <article-info
            :key="articleInfo_componentKey"
            @checkCross="checkCross"
          />
        </div>
      </div>
    </div>
    <div
      :class="`modal ${
        !showCompareModal && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showCompareModal = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 72rem; max-height: 52rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <div class="flex items-center justify-end pb-3">
            <!-- <p class="text-2xl font-bold">Titre</p> -->
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showCompareModal = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="w-full">
            <compareStructure :compareTab="compareTab" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col space-y-2">
      <back />
      <div class="md:hidden">
        <div
          class="w-full bg-white p-1 rounded-t shadow cursor-pointer"
          @click="showFiltre = !showFiltre"
        >
          Filtres
        </div>
        <div
          v-show="showFiltre"
          class="lg:flex lg:flex-row lg:space-x-2 lg:items-center grid grid-cols-2 gap-1 md:grid-cols-4 md:gap-2 p-2 bg-white rounded-b shadow"
        >
          <check text="Promotions" @updateCheckbox="filterPromotion" />

          <filterButton
            text="Marque"
            @manageFilter="filterMarque"
            :filter_list="response.Marque"
          />
          <!-- <filterButton
            text="Famille"
            @manageFilter="filterFamille"
            :filter_list="response.Famille"
          /> -->
          <!-- <filterButton
            text="Systeme"
            @manageFilter="filterSysteme"
            :filter_list="response.Systeme"
          />
          <filterButton
            text="Emplacement"
            @manageFilter="filterLocation"
            :filter_list="response.Location"
          />
          <filterButton
            text="Prix"
            @manageFilter="filterPrice"
            :filter_list="['Croissant', 'Decroissant']"
          /> -->
          <compareButton
            class="hidden md:flex"
            v-if="compareTab.length >= 2"
            @click="compareArticles()"
          />
          <check text="Stock" @updateCheckbox="filterStock" />
        </div>
      </div>
      <div
        class="lg:flex lg:flex-row lg:space-x-2 lg:items-center hidden md:grid md:grid-cols-4 md:gap-2 p-2 bg-white rounded shadow"
      >
        <compareButton
          class="hidden md:flex"
          v-if="compareTab.length >= 2"
          @click="compareArticles()"
        />
        <check text="Promotions" @updateCheckbox="filterPromotion" />

        <filterButton
          text="Marque"
          @manageFilter="filterMarque"
          :filter_list="response.Marque"
        />
        <!-- <filterButton
          text="Famille"
          @manageFilter="filterFamille"
          :filter_list="response.Famille"
        /> -->
        <!-- <filterButton
          text="Systeme"
          @manageFilter="filterSysteme"
          :filter_list="response.Systeme"
        />
        <filterButton
          text="Emplacement"
          @manageFilter="filterLocation"
          :filter_list="response.Location"
        />
        <filterButton
          text="Prix"
          @manageFilter="filterPrice"
          :filter_list="['Croissant', 'Decroissant']"
        /> -->
        <check text="Stock" @updateCheckbox="filterStock" />
      </div>
    </div>
    <div class="flex flex-row items-center space-x-2">
      <div
        v-for="(marque, indexM) in marqueTab"
        :key="indexM"
        class="p-1 border border-bluevk text-bluevk text-sm rounded flex flex-row items-center space-x-2"
      >
        {{ marque }}
      </div>
      <div
        v-for="(famille, indexF) in familleTab"
        :key="indexF"
        class="p-1 border border-bluevk text-bluevk text-sm rounded flex flex-row items-center space-x-2"
      >
        {{ famille }}
      </div>
      <div
        v-for="(systeme, indexS) in systemeTab"
        :key="indexS"
        class="p-1 border border-bluevk text-bluevk text-sm rounded flex flex-row items-center space-x-2"
      >
        {{ systeme }}
      </div>
      <div
        v-for="(location, indexL) in locationTab"
        :key="indexL"
        class="p-1 border border-bluevk text-bluevk text-sm rounded flex flex-row items-center space-x-2"
      >
        {{ location }}
      </div>
    </div>
    <div v-show="loadingSearch">
      <div class="flex flex-row">
        <div class="w-1/6">
          <familleListLoading />
        </div>
        <div class="w-5/6">
          <article-catalogue-loading />
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="w-1/6">
        <familleList
          :familleTab="response.Famille"
          :selectedFamille="familleTab"
          @selectFamille="filterFamille"
        />
      </div>
      <div
        class="w-5/6 max-h-[72vh] overflow-y-auto"
        v-if="response.type == 'article'"
      >
        <div
          class="space-y-2"
          v-for="(famille, indexFamille) in response.data"
          :key="indexFamille"
        >
          <section-title
            :titre="famille.designation"
            @click="famille.show = !famille.show"
          />
          <article-catalogue
            v-show="famille.show"
            v-for="(article, indexArticle) in famille.data"
            :key="article.dbArticle._id + reloadkey.toString()"
              :tdArticle="article.tdArticle"
            :article="article.dbArticle"
            :Ref_fournisseur="article.dbArticle.Ref_fournisseur"
            :Code_marque="article.dbArticle.Code_marque"
            :Fournisseur="article.dbArticle.Prefixe_tarif"
            :genericArticleId="
              article.tdArticle.genericArticles[0].genericArticleId
            "
            :Description="article.dbArticle.Description"
            :partsList="article.partsList"
            :Images="article.image"
            :Location="[]"
            :Systeme="[]"
            :Stock="article.stock"
            :Prix="article.prix"
            :quantity="article.quantity"
            @checkedArticle="checkArticle(indexArticle, indexFamille)"
            :compareTabLength="compareTab.length"
            @infoArticle="plusInfo(article.dbArticle)"
            @checkCross="checkCross"
            :partsListTab="localpartsListTab"
            @displayPartsList="displayPartsList"
            @deletePartsList="deletePartsList"
            :savedSearch="response.savedSearch"
            @setFraisdePort="article.dbArticle['fraisdeport'] = $event"
          />
          <article-catalogue-loading v-show="famille.loading" />
        </div>
      </div>
      <div
        class="w-5/6 max-h-[72vh] overflow-y-auto"
        v-else-if="response.type == 'articleOrigin'"
      >
        <div class="space-x-1 flex flex-row items-center justify-center">
          <span class="text-xl text-bluevk"
            >Nous avons trouvé plusieurs références se nommant</span
          >
          <span class="text-2xl text-bluevk">{{ Ref_fournisseur_search }}</span>
          <span class="text-xl text-bluevk">:</span>
        </div>
        <div v-for="(famille, indexF) in response.data" :key="indexF">
          <!-- <section-title
            :titre="famille.designation"
            @click="famille.show = !famille.show"
          /> -->
          <div v-if="famille.designation == 'Autres articles'" class="mt-4">
            <span class="flex text-xl mb-4 justify-center text-bluevk"
              >Cela peut aussi vous intéresser :</span
            >
            <articleCatalogueExact
              class="flex mb-2"
              v-show="famille.show"
              v-for="(article, indexA) in famille.data"
              :key="indexA"
              :article="article.dbArticle"
              :Ref_fournisseur="article.dbArticle.Ref_fournisseur"
              :Code_marque="article.dbArticle.Code_marque"
              :Description="article.dbArticle.Description"
              :genericArticleId="article.tdArticle.genericArticleId"
              :Stock="article.stock"
              :Prix="article.prix"
              :quantity="article.quantity"
              :Images="[]"
              :Location="[]"
              :Systeme="[]"
            />
          </div>
          <div v-else>
            <div class="flex justify-center relative overflow-x-auto">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 w-3/5"
              >
                <tbody>
                  <tr
                    class="bg-white border-b"
                    v-for="(article, indexA) in famille.data"
                    :key="indexA"
                  >
                    <th
                      class="px-6 py-4 whitespace-nowrap dark:text-white w-1/4"
                    >
                      <button
                        class="font-medium underline text-blue-500 text-lg"
                        @click="
                          searchRefFournisseurGen({
                            Ref_fournisseur: article.dbArticle.Ref_fournisseur,
                            genArt: article.tdArticle.genericArticleId,
                          })
                        "
                      >
                        {{ article.dbArticle.Ref_fournisseur }}
                      </button>
                    </th>
                    <td class="px-6 py-4 w-1/4">
                      <button
                        class="font-medium underline text-blue-500 text-lg"
                        @click="
                          searchRefFournisseurGen({
                            Ref_fournisseur: article.dbArticle.Ref_fournisseur,
                            genArt: article.tdArticle.genericArticleId,
                          })
                        "
                      >
                        {{ article.dbArticle.Code_marque }}
                      </button>
                    </td>
                    <td class="px-6 py-4 w-2/4 text-base">
                      <button
                        class="font-medium underline text-blue-500 text-lg"
                        @click="
                          searchRefFournisseurGen({
                            Ref_fournisseur: article.dbArticle.Ref_fournisseur,
                            genArt: article.tdArticle.genericArticleId,
                          })
                        "
                      >
                        {{ famille.designation }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="w-5/6 max-h-[72vh] overflow-y-auto" v-else>
        <div
          class="space-y-2"
          v-for="(vehicule, indexV) in response.data"
          :key="indexV"
        >
          <oe-catalogue
            :Ref_fournisseur="vehicule.match"
            :Code_marque="vehicule.mfrName"
            :Description="vehicule.Description"
            :Images="[]"
            :Location="[]"
            :Systeme="[]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import back from "@/components/Button/back.vue";
import check from "@/components/Button/check.vue";
import filter from "@/components/Button/filter.vue";
import sectionTitle from "@/components/Text/SectionTitle.vue";
import articleCatalogue from "@/components/Article/articleCatalogue.vue";
import articleCatalogueLoading from "@/components/Article/articleCatalogueLoading.vue";
import oeCatalogue from "@/components/Article/oeCatalogue.vue";
import articleCatalogueExact from "@/components/Article/articleCatalogueExact.vue";
import compareButton from "@/components/Button/compare.vue";
import compareStructure from "@/components/Article/compareStructure.vue";
import articleInfo from "@/components/articleInfo/articleInfo.vue";
import familleList from "@/components/Catalogue/familleList.vue";
import familleListLoading from "@/components/Catalogue/familleListLoading.vue";
import { checkStock } from "@/hooks/stock/stock.ts";
export default {
  name: "searchArticleNew",
  data() {
    return {
      loadingSearch: false,
      response: [],
      response2: [],
      response3: [],
      filterSetMarque: false,
      filterSetFamille: false,
      filterSetPromo: false,
      filterSetStock: false,
      marqueTab: [],
      familleTab: [],
      systemeTab: [],
      locationTab: [],
      compareTab: [],
      reloadkey: 0,
      showCompareModal: false,
      showFiltre: false,
      openModalArticle: false,
      articleInfo_componentKey: 0,
      localpartsListTab: [],
    };
  },
  components: {
    back,
    check,
    filterButton: filter,
    sectionTitle,
    articleCatalogue,
    articleCatalogueLoading,
    oeCatalogue,
    articleCatalogueExact,
    compareButton,
    compareStructure,
    articleInfo,
    familleList,
    familleListLoading,
  },
  computed: {
    ...mapGetters([
      "user",
      "Ref_fournisseur_search",
      "comptoirSessionActive",
      "plateform",
    ]),
  },
  methods: {
    ...mapActions([
      "searchRefFournisseur",
      "setArticleInfo",
      "searchRefFournisseurGen",
    ]),
    plusInfo(art) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo(art);
    },
    async searchArticleSim() {
      this.loadingSearch = true;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      const article = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/searchArticleSimDetailForceOE`,
        {
          Ref_fournisseur: this.Ref_fournisseur_search,
          plateform: plateform_mere,
          Client: this.user.proprietaire,
          User: this.user.username,
        }
      );
      this.loadingSearch = false;
      this.response = JSON.parse(JSON.stringify(article.data));
      this.response2 = JSON.parse(JSON.stringify(article.data));
      this.response3 = JSON.parse(JSON.stringify(article.data));
      // await this.searchAdditionnalArticleByOE();
      // await this.searchAdditionnalArticleByVH();
      await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/updateSavedSearchByID`,
        {
          id: this.response.savedSearch,
          data: { data: this.response.data },
        }
      );
    },
    async searchAdditionnalArticleByOE() {
      for (const [indexGenArt, genArt] of this.response.data.entries()) {
        genArt.loading = true;
        const additionnalArticle = await axios.post(
          `${process.env.VUE_APP_API}/searchEngine/searchAdditionnalArticleByOE`,
          {
            articleData: genArt.data,
            firstArticle: genArt.data[0],
            plateform: this.user.proprietaire,
          }
        );
        for (const res of additionnalArticle.data) {
          this.response.data[indexGenArt].data.push(res);
        }
        genArt.loading = false;
      }
    },
    async searchAdditionnalArticleByVH() {
      for (const [indexGenArt, genArt] of this.response.data.entries()) {
        genArt.loading = true;
        const additionnalArticle = await axios.post(
          `${process.env.VUE_APP_API}/searchEngine/searchAdditionnalArticleByVH`,
          {
            articleData: genArt.data,
            Ref_fournisseur: this.Ref_fournisseur_search,
            plateform: this.user.proprietaire,
          }
        );
        for (const res of additionnalArticle.data) {
          this.response.data[indexGenArt].data.push(res);
        }
        genArt.loading = false;
      }
    },
    async filterMarque(marque) {
      if (!this.marqueTab.includes(marque)) {
        this.marqueTab.push(marque);
      } else {
        this.marqueTab.splice(this.marqueTab.indexOf(marque), 1);
      }

      if (this.marqueTab.length === 0) {
        this.response = JSON.parse(JSON.stringify(this.response3));
        this.filterSetMarque = false;
      } else {
        if (
          this.filterSetFamille === true ||
          this.filterSetPromo === true ||
          this.filterSetStock === true
        ) {
          for (const [indexA, article] of this.response.data.entries()) {
            this.response.data[indexA].data = article.data.filter((art) =>
              this.marqueTab.includes(art.dbArticle.Code_marque)
            );
          }
        } else {
          for (const [indexA, article] of this.response2.data.entries()) {
            this.response.data[indexA].data = article.data.filter((art) =>
              this.marqueTab.includes(art.dbArticle.Code_marque)
            );
          }
        }
        this.filterSetMarque = true;
      }

      this.reloadkey++;
    },
    async filterFamille(famille) {
      let oldTab = this.response2;
      let dataTab = [];
      if (!this.familleTab.includes(famille)) {
        this.familleTab.push(famille);
      } else {
        this.familleTab.splice(this.familleTab.indexOf(famille), 1);
      }

      if (this.familleTab.length === 0) {
        this.response = JSON.parse(JSON.stringify(this.response3));
        this.filterSetFamille = false;
      } else {
        if (
          this.filterSetMarque === true ||
          this.filterSetPromo === true ||
          this.filterSetStock === true
        ) {
          for (const [indexA, article] of this.response.data.entries()) {
            this.response.data[indexA].data = article.data.filter((art) =>
              this.familleTab.includes(
                art.tdArticle.genericArticles[0].genericArticleDescription
              )
            );
          }
        } else {
          for (const [indexA, article] of this.response2.data.entries()) {
            this.response.data[indexA].data = article.data.filter((art) =>
              this.familleTab.includes(
                art.tdArticle.genericArticles[0].genericArticleDescription
              )
            );
          }
        }
        this.filterSetFamille = true;
      }
      this.reloadkey++;
    },
    async filterSysteme(systeme) {
      let oldTab = this.response2;
      let dataTab = [];
      if (!this.systemeTab.includes(systeme)) {
        this.systemeTab.push(systeme);
      } else {
        this.systemeTab.splice(this.systemeTab.indexOf(systeme), 1);
      }
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterSystemeSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          systemeTab: this.systemeTab,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    async filterLocation(location) {
      let oldTab = this.response2;
      let dataTab = [];
      if (!this.locationTab.includes(location)) {
        this.locationTab.push(location);
      } else {
        this.locationTab.splice(this.locationTab.indexOf(location), 1);
      }
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterLocationSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          locationTab: this.locationTab,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    async filterPromotion(promotionState) {
      let newTab = JSON.parse(JSON.stringify(this.response));
      if (promotionState === true) {
        this.filterSetPromo = true;
        for (const [indexA, article] of this.response.data.entries()) {
          newTab.data[indexA].data = [];
          for (const [index, art] of article.data.entries()) {
            let getPrix = await axios.post(
              `${process.env.VUE_APP_API}/searchEngine/getPrix`,
              {
                article: art.dbArticle,
                plateform: this.user.proprietaire,
                Client: this.comptoirSessionActive[0].Client[0].Name,
                groupeClient:
                  this.comptoirSessionActive[0].Client[0].Group.Name,
                quantity: art.quantity,
              }
            );
            if (getPrix.data.data[getPrix.data.indexSelected].promo) {
              newTab.data[indexA].data.push(art);
              this.filterSetPromo = true;
            }
          }
        }
      } else {
        newTab = JSON.parse(JSON.stringify(this.response2));
        this.filterSetPromo = false;
      }
      this.response = newTab;
      this.reloadkey++;
    },
    async filterStock(stockstate) {
      let newTab = JSON.parse(JSON.stringify(this.response));
      if (stockstate === true) {
        this.filterSetStock = true;
        for (const [indexA, article] of this.response.data.entries()) {
          newTab.data[indexA].data = [];
          for (const [index, art] of article.data.entries()) {
            let stock = await checkStock(
              this.user.proprietaire,
              art.dbArticle.Ref_fournisseur,
              art.dbArticle._id,
              art.dbArticle.Code_marque,
              art.dbArticle.Prefixe_tarif,
              art.quantity,
              this.user._id
            );
            if (stock.state !== "not available") {
              newTab.data[indexA].data.push(art);
              this.filterSetStock = true;
            }
          }
        }
      } else {
        newTab = JSON.parse(JSON.stringify(this.response2));
        this.filterSetStock = false;
      }
      this.response = newTab;
      this.reloadkey++;
    },
    async filterPrice(prix) {
      let oldTab = this.response2;
      let dataTab = [];
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterPriceSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          prix: prix,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    checkArticle(indexArticle, indexFamille) {
      //check if article is already in compareTab
      let article = this.response.data[indexFamille].data[indexArticle];
      let index = this.compareTab.findIndex(
        (element) => element.dbArticle._id === article.dbArticle._id
      );
      if (index === -1) {
        if (this.compareTab.length < 5) {
          this.compareTab.push(article);
        }
      } else {
        this.compareTab.splice(index, 1);
      }
    },
    checkCross() {
      if (this.$route.path == "/searchArticleExact") {
        this.openModalArticle = false;
        this.searchArticleSim();
      }
    },
    compareArticles() {
      this.showCompareModal = true;
    },
    displayPartsList(partsList) {
      this.localpartsListTab.push(partsList);
    },
    deletePartsList(index) {
      this.localpartsListTab.splice(index, 1);
    },
  },
  mounted() {
    this.searchArticleSim();
  },
};
</script>
<style></style>
