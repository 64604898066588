<template>
  <div class="flex flex-col space-y-2">
    <div class="flex justify-between mb-10">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/configurationCatalogueHub'" />
      </div>
      <titrePages class="flex flex-1 justify-center" :title="'Connexions'" />
    </div>
    <div class="flex justify-center"><menuConfiguration /></div>
    <div class="flex flex-row space-x-2">
      <div
        class="flex flex-row space-x-2 p-2 bg-white rounded shadow items-center cursor-pointer"
        @click="setConnexionToAdd('TecCom')"
      >
        <div class="shadow h-8 w-8">
          <img class="w-full h-full" src="../assets/teccom.png" />
        </div>
        <div>TecCom</div>
      </div>
    </div>
    <div v-show="showAddConnexion">
      <div class="flex flex-col space-y-2 p-2 bg-white rounded shadow">
        <div>{{ connexionToAddName }}</div>
        <div class="flex flex-row space-x-4">
          <span>Utilisateur :</span><input type="text" v-model="username" />
        </div>
        <div class="flex flex-row space-x-4">
          <span>Mot de passe : </span>
          <input type="password" v-model="password" />
        </div>
        <div>
          <button
            class="p-2 rounded shadow bg-blue-500 text-white"
            @click="updatePlateform()"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
    <div>
      <div v-if="plateform.connexion.length > 0">
        <div>
          <div
            v-for="(connex, indexConnex) in plateform.connexion"
            :key="indexConnex"
            class="p-2 bg-white rounded shadow flex flex-col space-y-2"
          >
            <div>{{ connex.name }}</div>
            <div class="flex flex-row space-x-4">
              <span>Nom d'utilisateur</span>
              <input class="w-1/5" type="text" v-model="connex.username" />
            </div>
            <div class="flex flex-row space-x-4">
              <span>Mot de passe</span>
              <input type="password" v-model="connex.password" />
            </div>
            <div>
              <button
                class="p-2 rounded shadow bg-blue-500 text-white"
                @click="modifyPlateform(connex)"
              >
                modifier
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>Aucune connexion n'est configuré</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import backButton from "@/components/Button/back.vue";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  data() {
    return {
      connexionToAddName: "",
      username: "",
      password: "",
      showAddConnexion: false,
    };
  },
  components: {
    backButton,
    menuConfiguration,
    titrePages,
  },
  computed: {
    ...mapGetters(["plateform"]),
  },
  methods: {
    setConnexionToAdd(name) {
      this.connexionToAddName = name;
      this.showAddConnexion = true;
    },
    updatePlateform() {
      axios
        .post(`${process.env.VUE_APP_API}/plateform/updateConnexion`, {
          plateform: this.plateform.Name,
          data: {
            name: this.connexionToAddName,
            username: this.username,
            password: this.password,
          },
        })
        .then(() => {
          this.$forceUpdate();
        });
    },
    modifyPlateform(connexionData) {
      axios
        .post(`${process.env.VUE_APP_API}/plateform/modifyConnexion`, {
          plateform: this.plateform.Name,
          data: connexionData,
        })
        .then(() => {
          this.$forceUpdate();
        });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
};
</script>
<style></style>
