<template>
  <div class="flex flex-row items-center space-x-2">
    <span
      v-for="(star, index) in 5"
      :key="index"
      :class="[
        'material-symbols-rounded cursor-pointer text-2xl',
        rating >= star ? starColors[star - 1] : 'text-gray-300',
      ]"
      @click="updateRating(star)"
    >
      {{ star <= rating ? smileys[index] : smileys[index] }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      starColors: [
        "text-red-400",
        "text-orange-400",
        "text-yellow-400",
        "text-green-400",
        "text-green-500",
      ],
      smileys: [
        "sentiment_sad",
        "sentiment_dissatisfied",
        "sentiment_neutral",
        "sentiment_satisfied",
        "sentiment_very_satisfied",
      ],
    };
  },
  methods: {
    updateRating(star) {
      this.$emit("update:rating", star);
    },
  },
};
</script>

<style></style>
