<template>
  <div
    v-if="showImportValidation"
    :class="`modal ${
      !showImportValidation && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="closeImportation()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="closeImportation()"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div>
          <h1 class="text-2xl font-bold">Validation de l'importation</h1>
          <span class="text-gray-500"
            >les données sont-elles rangées correctement ?</span
          >
        </div>
        <!-- Body -->
        <div class="flex flex-col space-y-4">
          <div
            class="mt-4 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Ref. principale
                    </th>
                    <th
                      class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Marque
                    </th>
                    <th
                      class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    ></th>
                    <th
                      class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Ref. croisée
                    </th>
                    <th
                      class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Marque croisée
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <tr v-for="(ref, indexRef) in reducedFile" :key="indexRef">
                    <td
                      class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ ref[0] }}
                    </td>

                    <td
                      class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ ref[1] }}
                    </td>

                    <td
                      class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <span class="material-symbols-rounded text-gray-500">
                        arrow_right_alt
                      </span>
                    </td>

                    <td
                      class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ ref[2] }}
                    </td>

                    <td
                      class="px-4 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ ref[3] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex flex-row items-center space-x-2 justify-end">
            <button
              :disabled="loadingImport"
              :class="
                loadingImport
                  ? 'p-2 bg-gray-500 text-white rounded shadow'
                  : 'p-2 bg-blue-500 text-white rounded shadow hover:bg-blue-400'
              "
              @click="importCrossFile()"
            >
              Valider
            </button>
            <button
              :disabled="loadingImport"
              @click="closeImportation()"
              class="p-2 bg-gray-500 text-white rounded shadow"
            >
              Annuler
            </button>
          </div>
        </div>

        <!-- End Body -->
      </div>
    </div>
  </div>
  <div class="flex flex-col space-y-4">
    <div class="flex justify-between">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/articleHub'" />
      </div>
      <titrePages :title="'Ref croisées'" />
      <div class="flex flex-row items-center space-x-4">
        <button @click="exportExcel()" class="flex flex-col align-center">
          <span class="material-symbols-rounded text-gray-500 text-2xl mx-auto"
            >description</span
          >
          <span class="text-xs text-gray-500">Template</span>
        </button>
        <button @click="downloadCross()" class="flex flex-col align-center">
          <span class="material-symbols-rounded text-green-500 text-2xl mx-auto"
            >download</span
          >
          <span class="text-xs text-green-500">Télécharger</span>
        </button>
        <div class="flex flex-col align-center">
          <input
            type="file"
            id="file-input"
            class="file-input hidden"
            accept=".xlsx, .xls"
            @change="startImportation()"
          />
          <label
            for="file-input"
            class="flex flex-col align-center cursor-pointer"
          >
            <span
              class="material-symbols-rounded text-blue-500 text-2xl mx-auto"
              >upload_file</span
            >
            <span class="text-xs text-blue-500">Importer</span>
          </label>
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full items-center space-x-2 h-10">
      <div
        class="flex flex-col items-center mr-8 px-2 py-1 shadow rounded border w-fit hidden sm:block text-xs sm:text-xs lg:text-base"
      >
        <div class="flex flex-row items-center space-x-2">
          <select
            v-model="selectValue"
            class="px-2 py-1 text-gray-600 focus:outline-2 outline-sky-300 bg-transparent"
          >
            <option value="Ref_fournisseur">Ref_fournisseur</option>
            <option value="Code_marque">Marque</option>
          </select>
          <input
            type="text"
            v-model="searchTerm"
            placeholder="Rechercher..."
            class="px-2 py-1 focus:outline-2 outline-sky-300 bg-transparent"
            @keyup.enter="search(1, 10, searchTerm)"
          />
          <span
            class="material-icons-outlined cursor-pointer text-xs lg:text-base xl:text-xl"
            @click="search(1, 10, searchTerm)"
          >
            search
          </span>
        </div>
      </div>

      <span
        v-if="searchRef"
        id="badge-dismiss-default"
        class="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300"
      >
        Ref_fournisseur : {{ searchRef }}
        <button
          type="button"
          class="inline-flex items-center p-1 ms-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
          data-dismiss-target="#badge-dismiss-default"
          aria-label="Remove"
          @click="searchRef = ''"
        >
          <svg
            class="w-2 h-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Remove badge</span>
        </button>
      </span>
      <span
        v-if="searchMarque"
        id="badge-dismiss-default"
        class="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300"
      >
        Code_marque : {{ searchMarque }}
        <button
          type="button"
          class="inline-flex items-center p-1 ms-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
          data-dismiss-target="#badge-dismiss-default"
          aria-label="Remove"
          @click="searchMarque = ''"
        >
          <svg
            class="w-2 h-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Remove badge</span>
        </button>
      </span>
      <div
        class="flex flex-row items-center space-x-4"
        v-if="searchRef && searchMarque"
      >
        <span class="material-symbols-rounded text-gray-500">
          arrow_right_alt
        </span>
        <input
          type="text"
          placeholder="Ref. croisée"
          v-model="refToAdd"
          class="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-900 dark:text-gray-300"
        />
        <input
          type="text"
          placeholder="Marque"
          v-model="brandToAdd"
          class="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-900 dark:text-gray-300"
        />
        <button
          @click="addOneCross()"
          class="transition ease-in-out delay-100 hover:bg-gray-200 bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-900 dark:text-gray-300"
        >
          Valider
        </button>
      </div>
    </div>
    <div
      class="mt-4 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Ref. principale
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Marque
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Ref. croisée
              </th>
              <th
                class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Marque croisée
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr v-for="(cross, indexCross) in crossTab" :key="indexCross">
              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ cross.Ref_fournisseur }}
              </td>

              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ cross.Code_marque }}
              </td>

              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                <span class="material-symbols-rounded text-gray-500">
                  arrow_right_alt
                </span>
              </td>
              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ cross.Ref_cross }}
              </td>

              <td class="px-4 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="flex flex-row items-center space-x-4">
                  <span>{{ cross.Code_marque_cross }}</span>
                  <button
                    @click="deleteCross(cross._id)"
                    class="material-symbols-rounded text-red-500"
                  >
                    backspace
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <pageNumber
          v-if="!searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="crossLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(crossLength / 10)"
          :clickFunction="getAllCross"
          :currentPage="currentPage"
          :typeList="'Ref croisées'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <pageNumber
          v-if="searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 10)"
          :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
          :currentPage="currentPage"
          :typeList="'Ref croisées'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>
<script>
import backButton from "@/components/Button/back.vue";
import pageNumber from "@/components/pageNumber.vue";
import * as XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
import { mapGetters } from "vuex";
import axios from "axios";
import titrePages from "@/components/Text/grandeTitre.vue";
export default {
  name: "CrossArticle",
  data() {
    return {
      showImportValidation: false,
      crossTab: [],
      searchActive: false,
      searchTerm: "",

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,

      crossLength: 0,
      searchLength: 0,
      readFile: null,
      reducedFile: [],
      searchRef: "",
      searchMarque: "",
      selectValue: "Ref_fournisseur",
      loadingImport: false,
      refToAdd: "",
      brandToAdd: "",
    };
  },
  components: {
    backButton,
    pageNumber,
    titrePages,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getAllCross(skipValue, limitValue) {
      this.currentPage = skipValue;
      let res = await axios.post(
        `${process.env.VUE_APP_API}/articlecross/getAllCross`,
        {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        }
      );

      this.crossTab = res.data.data;
      this.crossLength = res.data.count;
    },
    async startImportation() {
      let file = event.target.files ? event.target.files[0] : null;
      this.readFile = await readXlsxFile(file);
      this.reducedFile = this.readFile.slice(1, 5);
      this.showImportValidation = true;
    },
    async importCrossFile() {
      this.loadingImport = true;
      let res = await axios.post(
        `${process.env.VUE_APP_API}/articlecross/importCrossFile`,
        {
          plateform: this.user.proprietaire,
          data: this.readFile,
          user: this.user.username,
        }
      );
      this.getAllCross(1, 10);
      this.closeImportation();
      this.loadingImport = false;
    },
    closeImportation() {
      this.showImportValidation = false;
      this.readFile = null;
      this.reducedFile = [];
    },
    async exportExcel() {
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet([
        {
          "Ref. principale": "",
          Marque: "",
          "Ref. croisée": "",
          "Marque croisée": "",
        },
      ]);
      XLSX.utils.book_append_sheet(wb, ws, "Cross");

      XLSX.writeFile(wb, "Cross_" + this.user.proprietaire + ".xlsx");
    },
    async search(skipValue, limitValue, searchTerm) {
      if (searchTerm == "") {
        this.getAllCross(1, 10);
        this.searchActive = false;
      } else {
        this.searchActive = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        this.selectValue == "Ref_fournisseur"
          ? (this.searchRef = searchTerm)
          : (this.searchMarque = searchTerm);
        let res = await axios.post(
          `${process.env.VUE_APP_API}/articlecross/searchCross`,
          {
            plateform: this.user.proprietaire,
            Ref_fournisseur: this.searchRef,
            Code_marque: this.searchMarque,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        );
        this.crossTab = res.data.data;
        this.searchLength = res.data.count;
      }
    },
    async deleteCross(id) {
      let res = await axios.post(
        `${process.env.VUE_APP_API}/articlecross/deleteCross`,
        {
          id: id,
        }
      );
      this.getAllCross(this.currentPage, 10);
    },
    async addOneCross() {
      if (this.refToAdd != "" || this.brandToAdd != "") {
        let Ref = this.searchRef.replace(/\s+/g, "");
        let Marque = this.searchMarque.replace(/\s+/g, "");
        let Ref_cross = this.refToAdd.replace(/\s+/g, "");
        let brand_cross = this.brandToAdd.replace(/\s+/g, "");
        let res = await axios.post(
          `${process.env.VUE_APP_API}/articlecross/addOneCross`,
          {
            plateform: this.user.proprietaire,
            Ref_fournisseur: Ref.toUpperCase(),
            Code_marque: Marque.toUpperCase(),
            Ref_cross: Ref_cross.toUpperCase(),
            Code_marque_cross: brand_cross.toUpperCase(),
            created_by: this.user.username,
            created_at: new Date(),
          }
        );
        this.getAllCross(1, 10);
      }
    },
    async downloadCross() {
      let res = await axios.post(
        `${process.env.VUE_APP_API}/articlecross/downloadCross`,
        {
          plateform: this.user.proprietaire,
        }
      );
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(res.data);
      XLSX.utils.book_append_sheet(wb, ws, "Cross");
      XLSX.writeFile(wb, "Cross_" + this.user.proprietaire + ".xlsx");
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  mounted() {
    this.getAllCross(this.currentPage, 10);
    this.pageSwitch();
  },
};
</script>
<style></style>
