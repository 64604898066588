<template>
  <div>
    <div
      v-if="open"
      :class="`modal ${
        !open && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="open = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 40rem; max-height: 45rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Quantité à réceptionner</p>
            <div class="z-50 cursor-pointer modal-close" @click="open = false">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="flex mt-2 space-x-2 sm:justify-center">
            <!-- PARTIE GAUCHE -->
            <div class="flex flex-col">
              <div class="flex flex-col items-center">
                <span class="text-blue-800">+</span>
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    v-model="stateNumber"
                    class="sr-only peer"
                  />
                  <div
                    class="h-7 w-4 bg-blue-700 peer-focus:outline-none text-left dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-800 peer-checked:after:translate-y-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-blue-800 peer-checked:bg-orange-500"
                  ></div>
                </label>
                <span class="text-orange-500">=</span>
              </div>
              <div
                class="flex flex-col space-y-1 overflow-y-auto border border-gray-200 p-0.5 pr-1.5 rounded-md shadow-lg"
                style="max-height: 200px"
              >
                <div v-for="(num, index) in numbers" :key="index">
                  <button>
                    <p
                      class="w-8 border rounded-md shadow-lg font-medium text-white bg-gray-500 hover:bg-gray-800 border border-gray-800"
                      @click="setNumberStock(num)"
                    >
                      {{ num }}
                    </p>
                    <p v-if="index < numbers.length - 1" class="text-center">
                      --
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <!-- PARTIE DROITE -->
            <div class="flex my-auto">
              <div class="flex flex-col space-y-2 align-center">
                <p class="font-medium"></p>
                <div
                  class="flex bg-green-500 w-full rounded shadow cursor-pointer"
                  @click="increaseValue"
                >
                  <span
                    class="material-symbols-rounded text-7xl text-white mx-auto"
                  >
                    keyboard_arrow_up
                  </span>
                </div>
                <div class="w-full relative rounded-md shadow-sm">
                  <input
                    type="text"
                    class="w-full text-center focus:outline-2 outline-sky-300 py-4 pl-2 pr-12 text-white text-5xl bg-bluevk shadow rounded"
                    v-model="
                      commandeReception.Articles[indexScanned].quantity_received
                    "
                  />
                </div>
                <div
                  class="flex bg-red-500 w-full rounded shadow cursor-pointer"
                  @click="decreaseValue"
                >
                  <span
                    class="material-symbols-rounded text-7xl text-white mx-auto"
                  >
                    keyboard_arrow_down
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex space-x-2 justify-end pt-2">
            <button
              @click="open = false"
              class="p-3 px-6 py-3 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
              @click="addByScanFull()"
            >
              Full
            </button>
            <!-- <button
              @click="eqByScan()"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Remplacer
            </button> -->
            <!-- <button
              @click="addByScan(inputValue)"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Valider
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-12">
      <button
        @click="$router.back()"
        class="flex flex-row space-x-2 items-center bg-blue-400 text-white px-2 py-1 rounded-md"
      >
        <span class="material-icons-outlined text-sm"> arrow_back_ios </span>
        <span>retour</span>
      </button>
    </div>
    <div class="flex space-x-4 mb-2" v-if="!commandeReception.etat">
      <input
        v-model="scanEAN"
        type="text"
        placeholder="Scanner"
        @input="errorEAN = false"
        class="p-2 sm:w-1/4 w-full focus:outline-2 outline-sky-300 rounded shadow border border-blue-600 placeholder:text-blue-400"
        v-on:keyup.enter="getScanQuantity"
      />
      <p v-if="errorEAN" class="my-auto text-red-600">Article non trouvé !</p>
    </div>

    <div>
      <button
        v-if="!loadingExport"
        class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
        @click="exportExcel()"
      >
        <span class="material-icons-outlined text-xl mr-2"> download </span>
        <span>Exporter en Excel</span>
      </button>
      <button
        v-else
        class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
      >
        <PulseLoader color="#ffff" class="mr-2" />
        <span>Export en cours </span>
      </button>
    </div>

    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Code EAN
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Marque
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Description
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                v-if="user.accountType == 'Administrateur'"
              >
                Prix d'achat
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Quantité réceptionnée
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Quantité commandée
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                <div class="flex flex-row space-x-2 items-center">
                  <button
                    class="p-1 text-xs bg-green-500 text-white rounded shadow"
                    @click="fillAllQty()"
                  >
                    Tout valider
                  </button>
                  <button
                    class="p-1 text-xs bg-red-500 text-white rounded shadow"
                    @click="unfillAllQty()"
                  >
                    Tout annuler
                  </button>
                </div>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(rec, index) in commandeReception.Articles"
              :key="index"
              class="cursor-pointer hover:bg-gray-100"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ rec.Ref_fournisseur }}</span
                  >
                </div>
                <div class="text-sm leading-5 text-gray-900">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ rec.Code_EAN }}</span
                  >
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ rec.Code_marque }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ rec.Description }}
                </div>
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                v-if="user.accountType == 'Administrateur'"
              >
                <div class="text-sm leading-5 text-gray-900">
                  <input
                    class="p-1"
                    type="number"
                    @change="saveData()"
                    v-model="commandeReception.Articles[index].prix_net"
                  />
                  <span> €</span>
                </div>
              </td>
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                {{ rec.quantity_received }}
              </td>
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                {{ rec.quantity_asked }}
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-row items-center">
                  <span
                    v-if="rec.quantity_asked == rec.quantity_received"
                    class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400"
                    >Complet</span
                  >
                  <span
                    class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300"
                    v-else-if="rec.quantity_asked < rec.quantity_received"
                  >
                    Surplus
                  </span>
                  <span
                    class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400"
                    v-else
                  >
                    Incomplet
                  </span>
                  <div v-if="!commandeReception.etat">
                    <span
                      class="inline-flex material-icons-round rounded-full hover:bg-red-400 hover:text-white"
                      @click="removeOne(index)"
                    >
                      remove
                    </span>
                    <span
                      class="inline-flex material-icons-round rounded-full hover:bg-green-500 hover:text-white"
                      @click="addOne(index)"
                    >
                      add
                    </span>
                    <span
                      class="inline-flex material-icons-round rounded hover:bg-blue-500 hover:text-white"
                      @click="fillQty(index)"
                    >
                      task_alt
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex flex-row justify-end items-center space-x-2">
      <span v-show="errorRecep" class="text-red-500"
        >Les articles n'ont pas tous été récéptionné !</span
      >
      <button
        class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none mt-4"
        v-if="!commandeReception.etat && !loadingReception"
        @click="saveCommand()"
      >
        Valider
      </button>
      <button
        class="flex flex-row items-center space-x-2 px-6 py-3 font-medium tracking-wide text-white bg-gray-400 rounded-md mt-4"
        v-else-if="loadingReception"
        disabled
      >
        <span>Valider</span>
        <span class="material-icons-round animate-spin"> cloud_sync </span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import * as XLSX from "xlsx";
import { validRecep } from "@/hooks/stock/reception.ts";
import { addStock, unbackorderStock } from "@/hooks/stock/stock.ts";
import { createReception } from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import sonTest1 from "../assets/sontest1.mp3";
import sonTest2 from "../assets/sontest2.mp3";

export default {
  data: () => ({
    scanEAN: "",
    open: false,
    errorRecep: false,
    loadingExport: false,
    loadingReception: false,
    numbers: [2, 5, 10, 20, 50, 100],
    stateNumber: false,
    indexScanned: null,
    errorEAN: false,
  }),
  computed: {
    ...mapGetters(["commandeReception", "user"]),
  },
  components: { PulseLoader },
  methods: {
    ...mapActions(["addcommandereception"]),
    async getScanQuantity() {
      this.errorEAN = false;
      var index = this.commandeReception.Articles.findIndex(
        (article) => article.Code_EAN === this.scanEAN
      );

      if (index !== -1) {
        this.indexScanned = index;
        const son1 = new Audio(sonTest1);
        son1.play();
        this.commandeReception.Articles[index].quantity_received += 1;
        this.saveQuantity();
        this.scanEAN = "";
        this.open = true;
      } else {
        this.indexScanned = null;
        const son2 = new Audio(sonTest2);
        son2.play();
        this.errorEAN = true;
        this.open = false;
        console.log("L'élément n'a pas été trouvé.");
      }
    },
    addByScanFull() {
      if (this.indexScanned !== -1) {
        this.fillQty(this.indexScanned);
        this.open = false;
        this.scanEAN = "";
      }
    },
    // addByScan() {
    //   var index = this.commandeReception.Articles.findIndex(
    //     (article) => article.Code_EAN === this.scanEAN
    //   );

    //   if (index !== -1) {
    //     this.commandeReception.Articles[index].quantity_received = parseInt(
    //       this.inputValue
    //     );
    //     this.open = false;
    //     this.saveQuantity();
    //     this.scanEAN = "";
    //   }
    // },
    // eqByScan() {
    //   var ean = this.commandeReception.Articles.find(
    //     (element) => element.Code_EAN == this.scanEAN
    //   );
    //   if (ean != []) {
    //     var index = this.commandeReception.Articles.indexOf(ean);
    //     this.commandeReception.Articles[index].quantity_received ==
    //       parseInt(this.scanquantity);
    //     this.open = false;
    //     this.saveQuantity();
    //     this.scanEAN = "";
    //   }
    // },
    removeOne(index) {
      if (this.commandeReception.Articles[index].quantity_received > 0) {
        this.commandeReception.Articles[index].quantity_received =
          this.commandeReception.Articles[index].quantity_received - 1;
        this.saveQuantity();
      }
    },
    addOne(index) {
      if (
        this.commandeReception.Articles[index].quantity_received <
        this.commandeReception.Articles[index].quantity_asked
      ) {
        this.commandeReception.Articles[index].quantity_received =
          this.commandeReception.Articles[index].quantity_received + 1;
        this.saveQuantity();
      }
    },
    fillQty(index) {
      this.commandeReception.Articles[index].quantity_received =
        this.commandeReception.Articles[index].quantity_asked;
      this.saveQuantity();
    },
    fillAllQty() {
      for (const art of this.commandeReception.Articles) {
        if (art.quantity_received != art.quantity_asked) {
          art.quantity_received = art.quantity_asked;
        }
      }
      this.saveQuantity();
    },
    unfillAllQty() {
      for (const art of this.commandeReception.Articles) {
        if (art.quantity_received == art.quantity_asked) {
          art.quantity_received = 0;
        }
      }
      this.saveQuantity();
    },
    saveQuantity() {
      axios.put(
        `${process.env.VUE_APP_API}/commandeFournisseur/${this.commandeReception._id}`,
        this.commandeReception
      );
    },
    async saveData() {
      await axios.put(
        `${process.env.VUE_APP_API}/commandeFournisseur/${this.commandeReception._id}`,
        this.commandeReception
      );

      this.addcommandereception(this.commandeReception);
    },
    async exportExcel() {
      this.loadingExport = true;
      // await this.getAllClients();

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.commandeReception.Articles);
      XLSX.utils.book_append_sheet(wb, ws, "Reception");

      XLSX.writeFile(wb, "Liste_Reception" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      // this.success("Le fichier a été téléchargé");
    },
    async saveCommand() {
      this.loadingReception = true;
      let recepState = false;
      let RelicatArticles = [];
      for (const art of this.commandeReception.Articles) {
        if (art.quantity_received < art.quantity_asked) {
          recepState = true;
          RelicatArticles.push({
            _id: art._id,
            Ref_fournisseur: art.Ref_fournisseur,
            Code_EAN: art.Code_EAN,
            Code_marque: art.Code_marque,
            Description: art.Description,
            Prefixe_tarif: art.Prefixe_tarif,
            Ref_similaire: art.Ref_similaire,
            checked: art.checked,
            fournisseur: art.fournisseur,
            quantity_asked: art.quantity_asked - art.quantity_received,
            quantity_received: 0,
            prix_net: art.prix_net,
            prix_brut: art.prix_brut,
            remise: art.remise,
          });
        }
        if (art.quantity_received >= 0) {
          recepState = false;
        }
      }
      if (!recepState) {
        await validRecep(
          this.user.proprietaire,
          this.commandeReception._id,
          this.user.username,
          this.commandeReception.Articles
        );
        if (RelicatArticles.length > 0) {
          await createReception(
            this.user.username,
            this.user.proprietaire,
            this.commandeReception.fournisseur,
            RelicatArticles,
            "R" + this.commandeReception.Name,
            "Relicat",
            this.commandeReception.Document,
            this.commandeReception.Regels
          );
        }
        this.errorRecep = false;
        for (const art of this.commandeReception.Articles) {
          // await addStock(
          //   this.user._id,
          //   this.user.proprietaire,
          //   art._id,
          //   art.Ref_fournisseur,
          //   art.Code_marque,
          //   art.Code_EAN,
          //   0,
          //   0,
          //   10,
          //   "",
          //   this.user.username,
          //   "Réception de stock : " + this.commandeReception.fournisseur,
          //   art.Prefixe_tarif,
          //   this.user.proprietaire,
          //   "",
          //   "NA",
          //   "réception de stock",
          //   parseInt(art.quantity_received)
          // );
          if (art.quantity_received > 0) {
            await unbackorderStock(
              this.user._id,
              this.user.proprietaire,
              art._id,
              art.Ref_fournisseur,
              art.Code_marque,
              art.Code_EAN,
              0,
              0,
              10,
              "",
              "Réception de stock : " + this.commandeReception.fournisseur,
              art.Prefixe_tarif,
              this.user.proprietaire,
              this.commandeReception.Name,
              "NA",
              "réception de stock",
              parseInt(art.quantity_received)
            );
          }
          // await incrementStock(
          //   this.user.proprietaire,
          //   art.Ref_fournisseur,
          //   art.Code_EAN,
          //   art.Prefixe_tarif,
          //   art.Code_marque,
          //   parseInt(art.quantity_received),
          //   this.user.username,
          //   "Reception de stock : " + this.commandeReception.fournisseur,
          //   "NA",
          //   "0"
          // );
        }
        this.$router.push("/hubReceptionFournisseur");
      } else {
        this.errorRecep = true;
      }
      this.loadingReception = false;
    },
    setNumberStock(num) {
      if (!this.stateNumber) {
        this.commandeReception.Articles[this.indexScanned].quantity_received +=
          num;
      } else {
        this.commandeReception.Articles[this.indexScanned].quantity_received =
          num;
      }
      this.saveQuantity();
    },
    delNumberStock() {
      this.commandeReception.Articles[this.indexScanned].quantity_received = "";
      this.saveQuantity();
    },
    increaseValue() {
      this.commandeReception.Articles[this.indexScanned].quantity_received++;
      this.saveQuantity();
    },
    decreaseValue() {
      this.commandeReception.Articles[this.indexScanned].quantity_received--;
      this.saveQuantity();
    },
  },
};
</script>
<style></style>
