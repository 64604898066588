const COMMON_KEYS = Object.freeze({
  PLATFORM: "platform",
  CREATED_AT: "createdAt",
  UPDATED_AT: "updatedAt",
  UPDATED_BY: "updated_by",
  CREATED_BY: "created_by",
});

module.exports = {
  COMMON_KEYS,
};
