<template>
  <div class="flex flex-col align-center w-12">
    <button
      @mouseenter="showLabel = true"
      @mouseleave="showLabel = false"
      class="relative"
    >
      <span
        id="iconButton"
        class="material-symbols-rounded text-blue-500 text-2xl mx-auto"
      >
        add_circle
      </span>
      <transition
        name="slide-fade"
        enter-active-class="transition-all duration-200"
        leave-active-class="transition-all duration-200"
      >
        <span
          v-show="showLabel"
          class="text-xs text-blue-500 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full"
        >
          Ajouter
        </span>
      </transition>
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddButton",
  data() {
    return {
      showLabel: false,
    };
  },
  methods: {},
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
