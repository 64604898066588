import axios from "axios";

// Créer un panier transport
export async function createPanierTransport(data: null) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport`,
    data
  );
  return panierTransport.data;
}

// Récupérer tout les paniers transport par client au mois
export async function getByClientMonthly(
  plateform: string,
  client: string,
  selectedMonth: string,
  selectedYear: string
) {
  const getByClientMonthly = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/getByClientMonthly`,
    {
      plateform: plateform,
      client: client,
      selectedMonth: selectedMonth,
      selectedYear: selectedYear,
    }
  );
  return getByClientMonthly.data;
}

// Récupérer un panier transport par client par le bon cutoff
export async function getPanierTransport(plateform: string, clientId: string) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/getPanierTransport`,
    {
      plateform: plateform,
      clientId: clientId,
    }
  );
  return panierTransport.data;
}

// Récupérer un panier transport par client
export async function getPanierTransportByClient(
  plateform: string,
  client: string
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/getByClient`,
    {
      plateform: plateform,
      client: client,
    }
  );
  return panierTransport.data;
}

// Modifier un panier transport
export async function updatePanierTransport(data: null) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/update`,
    data
  );
  return panierTransport.data;
}

// Modifier un panier transport par client
export async function updatePanierTransportByClient(
  plateform: string,
  client: string,
  type: string,
  numero: number,
  montant: number
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/updateByClient`,
    {
      plateform: plateform,
      client: client,
      type: type,
      numero: numero,
      montant: montant,
    }
  );
  return panierTransport.data;
}

// Supprimer un panier transport par id
export async function deletePanierTransportById(id: string) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/deleteById`,
    {
      id: id,
    }
  );
  return panierTransport.data;
}

export async function deleteByBLNumber(
  plateform: string,
  clientId: string,
  BLnumber: string
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/deleteBL`,
    {
      plateform: plateform,
      clientId: clientId,
      BLnumber: BLnumber,
    }
  );

  return panierTransport.data;
}

export async function updateByBCTotal(
  plateform: string,
  clientId: string,
  BCnumber: string,
  BCtotalPrice: number
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/updateBCtotalPrice`,
    {
      plateform: plateform,
      clientId: clientId,
      BCnumber: BCnumber,
      BCtotalPrice: BCtotalPrice,
    }
  );
  return panierTransport.data;
}

export async function cancelCommand(
  plateform: string,
  clientId: string,
  BCnumber: string
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/cancelCommand`,
    {
      plateform: plateform,
      clientId: clientId,
      BCnumber: BCnumber,
    }
  );
  return panierTransport.data;
}

export async function resumeCommand(
  plateform: string,
  clientId: string,
  user: string,
  document: object
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/resumeCommand`,
    {
      plateform: plateform,
      clientId: clientId,
      user: user,
      document: document,
    }
  );
  return panierTransport.data;
}

export async function addRelicatPanier(
  plateform: string,
  clientId: string,
  user: string,
  document: object,
  BCnumber: string
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/addRelicatPanier`,
    {
      plateform: plateform,
      clientId: clientId,
      user: user,
      document: document,
      BCnumber: BCnumber,
    }
  );
  return panierTransport.data;
}


export async function getPanier(
  plateform: string,
  clientId: string,
  archive: boolean,
  skip: number,
  limit: number
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/getPanier`,
    {
      plateform: plateform,
      clientId: clientId,
      archive: archive,
      skip: skip,
      limit: limit
    }
  );
  return panierTransport.data;
}

export async function getByID(
  id: string,
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/getByID`,
    {
      id: id,
    }
  );
  return panierTransport.data;
}

export async function updateByBLTotal(
  plateform: string,
  clientId: string,
  BLnumber: string,
  BLtotalPrice: number
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/updateBLtotalPrice`,
    {
      plateform: plateform,
      clientId: clientId,
      BLnumber: BLnumber,
      BLtotalPrice: BLtotalPrice
    }
  );
  return panierTransport.data;
}


export async function updateLineBC(
  plateform: string,
  clientId: string,
  document: object,
  BCnumber: string
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/updateLineBC`,
    {
      plateform: plateform,
      clientId: clientId,
      document: document,
      BCnumber: BCnumber,
    }
  );
  return panierTransport.data;
}


export async function getInfoBLByNumber(
  plateform: string,
  BLnumber: string
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/bdl/getone`,
    {
      uid: {
        plateform: plateform,
        BLNumber: BLnumber,
      }
    }
  );
  return panierTransport.data;
}


export async function getInfoBCByNumber(
  plateform: string,
  BCnumber: string
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/commandes/getone`,
    {
      uid: {
        plateform: plateform,
        BCNumber: BCnumber,
      }
    }
  );
  return panierTransport.data;
}



// Créer ou update un panier transport
export async function manageTransportCart(
  plateform: string,
  clientId: string,
  user: string,
  document: object
) {
  const panierTransport = await axios.post(
    `${process.env.VUE_APP_API}/panierTransport/manageTransportCart`,
    {
      plateform: plateform,
      clientId: clientId,
      user: user,
      document: document,
    }
  );
  return panierTransport.data;
}
