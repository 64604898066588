<template>
  <div
    v-if="openFournisseur"
    :class="`modal ${
      !openFournisseur && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openFournisseur = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 70rem; width: 70rem; max-height: 30rem; height: 30rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">
            Disponibilité chez les fournisseurs pour
            {{ Article.Ref_fournisseur }}
          </p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openFournisseur = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div class="flex flex-col">
          <div
            class="flex flex-row items-center justify-between animate-pulse"
            v-show="loadingFournisseur"
          >
            <div>Recherche fournisseur...</div>
          </div>

          <div
            v-show="!loadingFournisseur"
            class="relative py-2 -my-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="inline-block h-72 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead class="text-xs text-white uppercase bg-blue-500">
                  <tr>
                    <th scope="col" class="px-6 py-3">Fournisseur</th>
                    <th scope="col" class="px-6 py-3">PPC</th>
                    <th scope="col" class="px-6 py-3">Prix de vente</th>
                    <th scope="col" class="px-6 py-3">
                      Marge (seuil
                      <input
                        type="number"
                        class="w-12 text-black"
                        v-model="seuil_pourcent"
                      />
                      %)
                    </th>
                    <th scope="col" class="px-6 py-3">Prix d'Achat</th>
                    <th scope="col" class="px-6 py-3">Dispo</th>

                    <th scope="col" class="px-6 py-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(fourn, indexFourn) in fournisseurTab"
                    :key="indexFourn"
                    :class="
                      indexFourn % 2 === 0
                        ? 'odd:bg-white  even:bg-gray-50 border-b '
                        : 'odd:bg-white  even:bg-gray-50 border-b '
                    "
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {{ fourn.fournisseur }}
                    </th>
                    <td class="px-6 py-4">
                      <div>
                        {{ parseFloat(fourn.prix.prix_brut).toFixed(2) }}
                        €
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div>
                        {{ parseFloat(fourn.prix.prix_net).toFixed(2) }}
                        €
                      </div>
                    </td>

                    <td class="px-6 py-4">
                      <div class="flex flex-row space-x-2">
                        <div
                          v-if="fourn.prix.marge_pourcent < seuil_pourcent"
                          class="text-red-700"
                        >
                          {{
                            parseFloat(fourn.prix.marge_pourcent).toFixed(2)
                          }}%
                        </div>
                        <div v-else class="text-green-700">
                          {{
                            parseFloat(fourn.prix.marge_pourcent).toFixed(2)
                          }}%
                        </div>
                        <div>
                          {{ parseFloat(fourn.prix.marge_euro).toFixed(2) }}
                          €
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div class="font-bold text-black">
                        {{ parseFloat(fourn.prix.prix_achat).toFixed(2) }}
                        €
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div v-if="fourn.loadingDispo">...</div>
                      <div v-else>
                        <div
                          v-if="
                            fourn.dispo.qtyJour + fourn.dispo.qtySoir >=
                            fourn.quantity
                          "
                          class="relative flex flex-row items-center space-x-2 text-blue-500"
                        >
                          <div>
                            <div
                              @mouseover="handleMouseOver()"
                              @mouseleave="handleMouseLeave()"
                            >
                              <span class="material-icons-round"
                                >check_circle</span
                              >
                            </div>

                            <div
                              v-if="hoverText"
                              class="-ml-12 absolute bg-gray-200 text-black p-1.5 rounded-md text-xs mt-1 shadow-lg"
                            >
                              <div v-if="fourn.dispo.qtyJour">
                                <p>Livraison jour :</p>
                                <p>
                                  Quantité :
                                  {{ fourn.dispo.qtyJour }} <br />
                                  Date : {{ fourn.dispo.dateJour }}
                                </p>
                              </div>
                              <div v-if="fourn.dispo.qtySoir" class="mt-2">
                                <p>Livraison nuit :</p>
                                <p>
                                  Quantité : {{ fourn.dispo.qtySoir }}<br />
                                  Date : {{ fourn.dispo.dateSoir }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            {{ fourn.dispo.qtyJour + fourn.dispo.qtySoir }}
                          </div>
                        </div>
                        <div
                          v-else
                          class="relative flex flex-row items-center space-x-2 text-orange-500"
                        >
                          <div>
                            <div
                              @mouseover="handleMouseOver()"
                              @mouseleave="handleMouseLeave()"
                            >
                              <span class="material-icons-round">help</span>
                            </div>

                            <div
                              v-if="hoverText"
                              class="-ml-12 absolute bg-gray-200 text-black p-1.5 rounded-md text-xs mt-1 shadow-lg"
                            >
                              <div v-if="fourn.dispo.qtyJour">
                                <p>Livraison jour :</p>
                                <p>
                                  Quantité :
                                  {{ fourn.dispo.qtyJour }} <br />
                                  Date : {{ fourn.dispo.dateJour }}
                                </p>
                              </div>
                              <div v-if="fourn.dispo.qtySoir" class="mt-2">
                                <p>Livraison nuit :</p>
                                <p>
                                  Quantité : {{ fourn.dispo.qtySoir }}<br />
                                  Date : {{ fourn.dispo.dateSoir }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            {{ fourn.dispo.qtyJour + fourn.dispo.qtySoir }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div class="flex flex-row">
                        <input
                          type="number"
                          v-model="fourn.quantity"
                          @change="ajustDispoFournisseur(fourn, indexFourn)"
                          class="w-8 border border-gray-200 rounded"
                        />
                        <button
                          v-if="fourn.inCard && !loadingCommandeFournisseur"
                          class="material-icons-round text-green-500"
                          @click="
                            addToCommandeFournisseur(
                              fourn.article,
                              indexFourn,
                              fourn.quantity
                            )
                          "
                        >
                          add_shopping_cart
                        </button>
                        <button
                          v-else-if="!loadingCommandeFournisseur"
                          class="material-icons-round text-bluevk"
                          @click="
                            addToCommandeFournisseur(
                              fourn.article,
                              indexFourn,
                              fourn.quantity
                            )
                          "
                        >
                          add_shopping_cart
                        </button>
                        <span
                          v-else-if="loadingCommandeFournisseur"
                          class="material-icons-round text-bluevk animate-spin"
                        >
                          sync
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="openFillialeCommande"
    :class="`modal ${
      !openFillialeCommande && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openFillialeCommande = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 30vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Commande Filliale</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openFillialeCommande = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div>
          <div class="w-full bg-white flex flex-col">
            <div v-show="!loadingCommandeFilliale">
              Souhaitez-vous commander
              <input
                type="Number"
                class="w-10"
                v-model="infosCommandeFilliale.stock"
              />
              pièces chez {{ infosCommandeFilliale.plateform }} ?
            </div>
            <div v-show="loadingCommandeFilliale" class="animate-pulse">
              Commande en cours d'envoi...
            </div>
            <div class="flex justify-end mb-2">
              <button
                class="p-2 bg-blue-500 text-white rounded shadow"
                @click="reserveStockFilliale()"
              >
                Commander
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col">
    <div
      v-if="Stock"
      class="flex flex-row space-x-2 item-center justify-center text-xs"
    >
      <span>Adr : </span><span>{{ Stock.address }}</span>
    </div>
    <div class="flex flex-row w-full items-center space-x-4">
      <div v-if="Stock.stock >= quantity && quantity > 0">
        <span class="material-icons-round text-2xl text-green-500">
          check_circle
        </span>
      </div>
      <div v-else>
        <span
          v-if="
            !stateFournisseur && (Stock.stock || Number.isInteger(Stock.stock))
          "
          class="material-icons-round text-2xl"
          :class="
            (Stock.reserved &&
              parseInt(Stock.stock) + parseInt(Stock.reserved) > 0) ||
            parseInt(Stock.stock) > 0
              ? 'text-green-500'
              : 'text-orange-500'
          "
        >
          help
        </span>
        <span
          v-else-if="stateFournisseur"
          class="material-icons-round text-2xl"
          :class="
            (Stock.reserved &&
              parseInt(Stock.stock) + parseInt(Stock.reserved) > 0) ||
            parseInt(Stock.stock) > 0
              ? 'text-green-500'
              : 'text-blue-500'
          "
        >
          {{
            (Stock.reserved &&
              parseInt(Stock.stock) + parseInt(Stock.reserved) > 0) ||
            parseInt(Stock.stock) > 0
              ? "help"
              : "check_circle"
          }}
        </span>
      </div>
      <div>
        <div
          class="flex flex-row items-center cursor-pointer space-x-4"
          @click="displayFournisseur()"
        >
          <div class="text-xs">
            {{ user.proprietaire.substring(0, 4) }}
            {{ user.proprietaire.slice(-1) }}
          </div>
          <div class="flex flex-row items-center space-x-2">
            <div class="text-right">{{ Stock.stock }}</div>
          </div>
          <div
            :class="
              'flex flex-row items-center space-x-1 px-1 ' +
              componentColor +
              ' text-gray-900 border border-green-700 rounded-lg'
            "
          >
            <div class="flex flew-row items-center space-x-1">
              <span
                v-if="Stock.stock || Number.isInteger(Stock.stock)"
                class="material-symbols-rounded text-sm"
                :class="
                  (Stock.reserved &&
                    parseInt(Stock.stock) + parseInt(Stock.reserved) > 0) ||
                  parseInt(Stock.stock) > 0
                    ? 'text-white'
                    : ''
                "
              >
                inventory
              </span>
              <span v-else class="material-symbols-rounded text-sm">
                inventory
              </span>
              <div
                class="text-sm"
                v-if="Stock.stock || Number.isInteger(Stock.stock)"
              >
                <span
                  v-if="Stock.reserved"
                  :class="{
                    'text-white':
                      parseInt(Stock.stock) + parseInt(Stock.reserved) > 0,
                  }"
                  >{{ parseInt(Stock.stock) + parseInt(Stock.reserved) }}</span
                ><span
                  v-else
                  :class="{
                    'text-white': parseInt(Stock.stock) > 0,
                  }"
                  >{{ parseInt(Stock.stock) }}</span
                >
              </div>

              <span class="text-sm" v-else>0</span>
            </div>
            <div class="flex flew-row items-center space-x-1">
              <span class="material-symbols-rounded text-sm">
                deployed_code_history
              </span>
              <span v-if="Stock.reserved">{{ Stock.reserved }}</span>
              <span v-else>0</span>
            </div>
            <div class="flex flew-row items-center space-x-1">
              <span class="material-symbols-rounded text-sm">
                local_shipping
              </span>
              <span>{{ Stock.backorder }}</span>
            </div>
          </div>
        </div>

        <!-- <div
          v-if="Stock.reserved && Stock.reserved != ''"
          class="flex justify-between text-white text-xs"
          title="Réservé"
            >
          <p></p>
          <p class="p-0.5 bg-green-600 text-right">
            Rsr : {{ Stock.reserved }}
          </p>
        </div> -->

        <div class="w-full" v-if="filialeTab.length > 0">
          <div
            v-for="(filliale, indexFilliale) in filialeTab"
            class="w-full"
            :key="indexFilliale"
          >
            <div
              class="w-full flex flex-row items-center justify-between"
              v-if="filliale.stock != null"
            >
              <div
                class="text-xs cursor-pointer"
                @click="setTransfert(filliale)"
              >
                {{ filliale.plateform.substring(0, 4) }}
                {{ filliale.plateform.slice(-1) }}
              </div>
              <div class="flex flex-row items-center space-x-2">
                <div class="mx-auto text-sm">
                  {{ filliale.stock }}
                </div>
              </div>
              <div
                :class="
                  filliale.stock >= quantity
                    ? 'flex flex-row items-center space-x-1 px-1 bg-green-500 text-gray-900 border border-green-700 rounded-lg'
                    : 'flex flex-row items-center space-x-1 px-1 ' +
                      componentColor +
                      ' text-gray-900 border border-green-700 rounded-lg'
                "
              >
                <div class="flex flew-row items-center space-x-1">
                  <span
                    v-if="filliale.stock"
                    class="material-symbols-rounded text-sm"
                    :class="{
                      'text-white':
                        parseInt(filliale.stock) + parseInt(filliale.reserved) >
                        0,
                    }"
                  >
                    inventory
                  </span>
                  <span v-else class="material-symbols-rounded text-sm">
                    inventory
                  </span>
                  <span
                    class="text-sm"
                    v-if="filliale.stock"
                    :class="{
                      'text-white':
                        parseInt(filliale.stock) + parseInt(filliale.reserved) >
                        0,
                    }"
                    >{{
                      parseInt(filliale.stock) + parseInt(filliale.reserved)
                    }}</span
                  >
                  <span class="text-sm" v-else>0</span>
                </div>
                <div class="flex flew-row items-center space-x-1">
                  <span class="material-symbols-rounded text-sm">
                    deployed_code_history
                  </span>
                  <span v-if="filliale.reserved">{{ filliale.reserved }}</span>
                  <span v-else>0</span>
                </div>
                <div class="flex flew-row items-center space-x-1">
                  <span class="material-symbols-rounded text-sm">
                    local_shipping
                  </span>
                  <span>{{ filliale.backorder }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFournisseurList } from "@/hooks/articles/articles.ts";
import { mapGetters, mapActions } from "vuex";
import { addPanierInst } from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import {
  getStockFournisseur,
  checkStock,
  backorderStock,
  reserveStock,
  addStock,
} from "@/hooks/stock/stock.ts";
import axios from "axios";
import { ORDER_KEYS } from "@/util/OrderSchemaConstants.js";
import { COMMON_KEYS } from "@/util/CommonSchemaConstants.js";

export default {
  name: "componentStock",
  data() {
    return {
      fournisseurTab: [],
      loadingFournisseur: false,
      loadingCommandeFournisseur: false,
      loadingDispoFournisseur: false,
      stateFournisseur: null,
      colorFournisseur: null,
      Stock: {},
      openFournisseur: false,
      seuil_pourcent: 30,
      stockFilliale: {},
      openFillialeCommande: false,
      infosCommandeFilliale: {},
      loadingCommandeFilliale: false,
      componentColor: "bg-orange-500",
      filialeTab: [],
      hoverText: false,

      reference: "",
      tabArticle: "",
    };
  },
  computed: {
    ...mapGetters(["user", "plateform", "comptoirSessionActive"]),
  },
  props: {
    Article: { type: Object, default: () => ({}) },
    quantity: { type: Number, default: 1 },
  },
  methods: {
    async displayFournisseur() {
      this.fournisseurTab = [];
      this.loadingFournisseur = true;
      this.openFournisseur = true;
      this.fournisseurTab = await getFournisseurList(
        this.user.proprietaire,
        this.tabArticle,
        this.comptoirSessionActive[0]
          ? this.comptoirSessionActive[0].Client[0].Name
          : "DIVERS",
        this.comptoirSessionActive[0]
          ? this.comptoirSessionActive[0].Client[0].Group.Name
          : "DIVERS",
        this.quantity
      );
      this.loadingFournisseur = false;
      for (const fourn of this.fournisseurTab) {
        fourn.dispo = await getStockFournisseur(
          fourn.article,
          this.reference,
          this.Article.Code_marque,
          fourn.Prefixe_tarif,
          this.plateform.grossierID,
          this.quantity,
          this.plateform.grossierID,
          this.user.proprietaire
        );
        fourn.article["fraisdeport"] = fourn.dispo.fraisdeport;
        fourn.loadingDispo = false;
      }
    },
    async setTransfert(fl) {
      this.$emit("setStateGroupement", {
        plateform: fl.plateform,
        stockToTransfert:
          this.quantity - (this.Stock.stock >= 0 ? this.Stock.stock : 0),
        force: true,
      });
    },
    async addToCommandeFournisseur(article, index, quantity) {
      this.loadingCommandeFournisseur = true;
      await addPanierInst(this.user.proprietaire, article, quantity);
      this.fournisseurTab[index].inCard = true;
      this.loadingCommandeFournisseur = false;
    },
    async ajustDispoFournisseur(fourn, index) {
      this.fournisseurTab[index].loadingDispo = true;
      this.fournisseurTab[index].dispo = await getStockFournisseur(
        fourn.article,
        this.reference,
        this.Article.Code_marque,
        fourn.Prefixe_tarif,
        this.plateform.grossierID,
        fourn.quantity,
        this.plateform.grossierID,
        this.user.proprietaire
      );
      this.fournisseurTab[index].loadingDispo = false;
    },
    //TODO REMOVE IF NOT USED
    async setFillialeCommande(data) {
      this.openFillialeCommande = true;
      let stockToTransfert = data.stock;
      data.stock > this.Article.quantity
        ? (stockToTransfert = this.Article.quantity)
        : (stockToTransfert = data.stock);
      this.infosCommandeFilliale = {
        plateform: data.plateform,
        stock: stockToTransfert,
      };
    },
    //TODO REMOVE IF NOT USED
    async reserveStockFilliale() {
      this.loadingCommandeFilliale = true;
      let ArticlesTab = [];
      let pdfinfo = [];
      const filialeInformation = await axios.get(
        `${process.env.VUE_APP_API}/plateform/getone/${this.infosCommandeFilliale.plateform}`
      );
      const getCoef = await axios.post(
        `${process.env.VUE_APP_API}/plateform/getCoef`,
        {
          plateform: this.infosCommandeFilliale.plateform,
          plateform_receiver: this.user.proprietaire,
        }
      );
      let art_data = { ...this.Article };
      art_data.quantity = parseInt(this.infosCommandeFilliale.stock);
      art_data.PrixFournisseurType = "prixnet";
      art_data.Prix_vente = art_data.Prix_achat * getCoef.data;
      art_data.prix_net = art_data.Prix_achat * getCoef.data;
      art_data.quantity_received = 0;
      art_data.quantity_asked = parseInt(this.infosCommandeFilliale.stock);

      ArticlesTab.push(art_data);

      pdfinfo.push({
        description: art_data.Description,
        ref: art_data.Ref_fournisseur,
        quantite: art_data.quantity,
        remise: 0,
        commentaire: art_data.commentaire,
        remisegenerale: 0,
        prixU: art_data.Prix_vente,
        total: art_data.Prix_vente * art_data.quantity,
        totalSansRemise: art_data.Prix_vente * art_data.quantity,
      });

      let numDoc = 0;
      await axios
        .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
          uid: this.infosCommandeFilliale.plateform,
        })
        .then((response) => {
          numDoc = response.data + 1;
        });

      var dataCommande = {
        Name: this.user.username,
        Owner: this.infosCommandeFilliale.plateform,
        // Date: today,
        Numero: numDoc,
        TotalPrice: art_data.Prix_vente,
        TotalPriceTTC: art_data.Prix_vente + art_data.Prix_vente * 0.2,
        Client: this.user.proprietaire,
        IsProcessed: false,
        Note: "Commande inter depot",
        Articles: ArticlesTab,
        reglement: [],
        pdfData: pdfinfo,
        clientInfo: filialeInformation.data,
        Transport: 0,
      };
      await axios.post(`${process.env.VUE_APP_API}/commandes`, dataCommande);
      await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
        [ORDER_KEYS.TYPE]: "TRANSFER",
        [ORDER_KEYS.CLIENT_TYPE]: filialeInformation.data[0].professionnel
          ? "PROFESSIONAL"
          : "INDIVIDUAL",
        [ORDER_KEYS.CLIENT_NAME]: this.user.proprietaire,
        [COMMON_KEYS.CREATED_BY]: this.user.username,
        [COMMON_KEYS.UPDATED_BY]: this.user.username,
        [COMMON_KEYS.PLATFORM]: this.infosCommandeFilliale.plateform,
        [ORDER_KEYS.TOTAL_PRICE_HT]: art_data.Prix_vente,
        [ORDER_KEYS.TOTAL_PRICE_TTC]:
          art_data.Prix_vente + art_data.Prix_vente * 0.2,
        [ORDER_KEYS.CLIENT_NOTE]: "Commande inter depot",
        [ORDER_KEYS.VENDOR_NOTE]: "Commande Automatique inter depot",
        [ORDER_KEYS.CLIENT_DETAILS]: filialeInformation.data,
        [ORDER_KEYS.BC_ID]: numDoc,
        [ORDER_KEYS.ARTICLES]: ArticlesTab,
        [ORDER_KEYS.STATUS]: "CREATED",
        [ORDER_KEYS.STATUS_PERCENTAGE]: 10,
        [ORDER_KEYS.DELIVERY_PRICE]: 0,
      });
      if (art_data.Prefixe_tarif) {
        await reserveStock(
          this.user._id,
          this.infosCommandeFilliale.plateform,
          art_data._id,
          art_data.Ref_fournisseur,
          art_data.Code_marque,
          art_data.Code_EAN,
          0,
          0,
          10,
          "",
          this.user.username,
          "BC n°" + numDoc + "pour transfert édité par " + this.user.username,
          art_data.Prefixe_tarif,
          this.user.proprietaire,
          numDoc,
          "BC",
          "comptoir",
          art_data.quantity
        );

        //transfert stock direct
        await addStock(
          this.user._id,
          this.user.proprietaire,
          art_data._id,
          art_data.Ref_fournisseur,
          art_data.Code_marque,
          art_data.Code_EAN,
          0,
          0,
          10,
          "",
          this.user.username,
          "BC transfert n°" +
            numDoc +
            " : transfert stock filiale par " +
            this.user.username,
          art_data.Prefixe_tarif,
          this.user.proprietaire,
          "",
          "NA",
          "transfert automatique",
          art_data.quantity
        );
      }

      this.loadingCommandeFilliale = false;
      this.openFillialeCommande = false;
    },
    handleMouseOver() {
      this.hoverText = true;
    },
    handleMouseLeave() {
      this.hoverText = false;
    },
  },
  async mounted() {
    if (this.Article.Ref_fournisseur.startsWith("C : ")) {
      this.reference = this.Article.Ref_fournisseur.replace("C : ", "");
      this.tabArticle = { ...this.Article, Ref_fournisseur: this.reference };
    } else {
      this.reference = this.Article.Ref_fournisseur;
      this.tabArticle = this.Article;
    }

    let chkStock = await checkStock(
      this.user.proprietaire,
      this.reference,
      this.Article._id,
      this.Article.Code_marque,
      this.Article.Prefixe_tarif,
      this.quantity,
      this.user._id
    );
    if (chkStock.state === "available") {
      this.Stock = chkStock.data;
      this.$emit("stockstate", "En stock");
      this.componentColor = "bg-green-500";
    } else if (
      chkStock.state === "plateform + subsidiary" ||
      chkStock.state === "subsidiary"
    ) {
      this.Stock = chkStock.data.plateform;
      this.filialeTab = chkStock.data.filiale.division;
      for (const fl of chkStock.data.filiale.division) {
        //TODO update the filiale management to send BL to the filiale
        if (fl.stock) {
          if (fl.stock >= this.quantity) {
            let stockPlateform = fl.stock;
            this.$emit("setStateGroupement", {
              plateform: fl.plateform,
              stockToTransfert:
                this.quantity - (this.Stock.stock >= 0 ? this.Stock.stock : 0),
            });
          }
        }
      }
    } else if (chkStock.state === "not available") {
      this.Stock = chkStock.data;
      this.Stock.state = "not available";
    } else if (chkStock.state === "not available + no subsidiary") {
      this.Stock = chkStock.data.plateform;
      this.Stock.state = "not available";
    }
  },
  async updated() {
    if (this.Stock.state === "not available") {
      const dispoFournisseur = await getStockFournisseur(
        this.tabArticle,
        this.reference,
        this.Article.Code_marque,
        this.Article.Prefixe_tarif,
        this.plateform.grossierID,
        this.quantity,
        this.plateform.grossierID,
        this.user.proprietaire
      );
      this.stateFournisseur = dispoFournisseur.dispoFournisseur;
      if (dispoFournisseur.dispoFournisseur) {
        this.$emit("fraisdeportfournisseur", dispoFournisseur.fraisdeport);
        this.$emit("stockstate", "En fournisseur");
        this.componentColor = "bg-blue-500";
      } else {
        this.$emit("fraisdeportfournisseur", dispoFournisseur.fraisdeport);
        this.$emit("stockstate", "En rupture");
        this.componentColor = "bg-orange-500";
      }
      if (dispoFournisseur.color) {
        this.colorFournisseur = dispoFournisseur.color;
      }
    } else {
      this.$emit("stockstate", "En stock");
      this.$emit("fraisdeportfournisseur", 0);
    }
    // else {
    //   if (this.Stock.stock >= this.quantity) {
    //     this.$emit("stockstate", "En stock");
    //   } else {
    //     this.$emit("stockstate", "En rupture");
    //   }
    // }
  },
};
</script>
<style></style>
