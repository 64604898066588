<template>
  <div
    v-if="modalDoc"
    :class="`modal ${
      !modalDoc && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="modalDoc = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 70rem"
      class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Récapitulatif du document</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="modalDoc = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-1/3 ml-2">
            Référence client:
            {{ detailDoc.Note }}
          </div>

          <div class="flex flex-col my-auto items-center ml-auto mx-10">
            <span
              class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
              style="color: blue"
              @click="createPDF(detailDoc)"
            >
              download
            </span>
            <div>{{ detailDoc.typeDoc }} n° {{ detailDoc.Numero }}</div>
          </div>
        </div>

        <table class="min-w-full mt-4">
          <div class="overflow-y-auto max-h-[25rem]">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
              </tr>
            </thead>

            <tbody class="bg-white w-fit">
              <tr
                v-for="(r, index) in detailDoc.pdfData"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row items-center">
                    <span class="ml-4 text-sm leading-5 text-gray-900">
                      {{ r.ref }}
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="text-sm leading-5 text-gray-500">
                    {{ r.description }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-sm leading-5">
                    {{ r.quantite }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{
                    parseFloat(detailDoc.Articles[index].Prix_euro).toFixed(2)
                  }}
                  €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div
                    v-if="
                      detailDoc.Articles[index].PrixFournisseurType != 'prixnet'
                    "
                  >
                    {{ r.remise }} %
                  </div>
                  <div v-else>prix net</div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div
                    v-if="
                      detailDoc.Articles[index].PrixFournisseurType != 'prixnet'
                    "
                  >
                    {{
                      parseFloat(
                        detailDoc.Articles[index].Prix_euro -
                          detailDoc.Articles[index].Prix_euro * (r.remise / 100)
                      ).toFixed(2)
                    }}
                    €
                  </div>

                  <div v-else>
                    {{
                      parseFloat(detailDoc.Articles[index].Prix_vente).toFixed(
                        2
                      )
                    }}
                    €
                  </div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(r.total).toFixed(2) }}€
                </td>
              </tr>
            </tbody>
          </div>
          <thead class="tableHEADTR mt-12 border-t">
            <tr class="tableHEADTR">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="text-sm leading-5 text-gray-900">
                  Total HT :
                  {{ parseFloat(detailDoc.TotalPrice).toFixed(2) }} €
                </div>
                <div
                  v-if="detailDoc.TotalPriceTTC"
                  class="text-sm leading-5 text-gray-500"
                >
                  Total TTC :
                  {{ parseFloat(detailDoc.TotalPriceTTC).toFixed(2) }} €
                </div>
                <div v-else class="text-sm leading-5 text-gray-500">
                  Total TTC :
                  {{
                    parseFloat(
                      detailDoc.TotalPrice + detailDoc.TotalPrice * 0.2
                    ).toFixed(2)
                  }}
                  €
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>

  <div
    id="retour"
    class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    @click="$router.push('/paniersClient')"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>

  <div class="mt-2">
    <div class="text-right">
      <div class="flex justify-between font-medium text-left">
        <div class="flex flex-col">
          <p>{{ panier.client }}</p>
          <p class="text-gray-600 text-xs">Créé par :</p>
          <p class="text-left">{{ panier.user }}</p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Panier du :</p>
          <p>{{ getFRDateSansH(panier.cartDate) }}</p>
          <p class="text-bluevk">{{ panier.cutoff }}</p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Créé le :</p>
          <p>{{ getFRDate(panier.date) }}</p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Frais de port appliqué :</p>
          <p>{{ panier.shippingCosts }} € / {{ panier.freeShippingAt }} €</p>
          <p class="text-gray-400 text-xs">
            Frais de port du client : {{ panier.savedShippingCosts }} €
          </p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Total HT BC :</p>
          <p>{{ financial(panier.BCtotalPrice) }} €</p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Total HT BL :</p>
          <p>{{ financial(panier.BLtotalPrice) }} €</p>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Type / Lieu de création
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Numéro BC / Date / Créé par
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total HT BC
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Numéro BL / Date / Créé par
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total HT BL
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in panier.document" :key="index">
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u.type }}</span
                  >
                  <div class="text-sm leading-5 text-gray-900">
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                      >{{ u.created_here }}</span
                    >
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex justify-between">
                    <div class="flex flex-col">
                      <p v-if="u.BCnumber">
                        BC {{ u.BCnumber }} : {{ getFRDate(u.BCdate) }}
                      </p>
                      <p class="text-orangevk">{{ u.BCuser }}</p>
                    </div>

                    <button
                      v-if="u.BCnumber"
                      class="material-icons-round text-bluevk hover:text-opacity-60"
                      @click="showInfoBC(u.BCnumber)"
                    >
                      info
                    </button>
                  </div>
                </td>

                <td
                  class="px-6 py-4 font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ financial(u.BCtotalPrice) }} €
                </td>

                <td
                  class="px-6 py-4 text-sm border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex justify-between">
                    <div class="flex flex-col">
                      <p v-if="u.BLnumber">
                        BL {{ u.BLnumber }} : {{ getFRDate(u.BLdate) }}
                      </p>
                      <p class="text-orangevk">{{ u.BLuser }}</p>
                    </div>

                    <button
                      v-if="u.BLnumber"
                      class="material-icons-round text-bluevk hover:text-opacity-60"
                      @click="showInfoBL(u.BLnumber)"
                    >
                      info
                    </button>
                  </div>
                </td>

                <td
                  class="px-6 py-4 font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ financial(u.BLtotalPrice) }} €
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getByID,
  getInfoBCByNumber,
  getInfoBLByNumber,
} from "@/hooks/transport/panierTransport.ts";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import { createlivraison } from "@/hooks/pdf/livraison";
import { createcommande } from "@/hooks/pdf/commande";

export default {
  name: "MyPanierClient",
  data() {
    return {
      panier: [],

      modalDoc: false,
      detailDoc: {},
    };
  },
  computed: {
    ...mapGetters(["user", "idDetailPanierClient"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getFRDateSansH(date) {
      return getFrDateSansH(date);
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    async showInfoBC(numero) {
      const response = await getInfoBCByNumber(this.user.proprietaire, numero);
      this.detailDoc = response[0];
      console.log(this.detailDoc);
      this.detailDoc.typeDoc = "BC";

      this.modalDoc = true;
    },
    async showInfoBL(numero) {
      const response = await getInfoBLByNumber(this.user.proprietaire, numero);
      this.detailDoc = response[0];
      this.detailDoc.typeDoc = "BL";
      console.log(this.detailDoc);

      this.modalDoc = true;
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      
      if (dateedition == datepdf) {
        objet.typeDoc == "BC"
          ? createcommande(objet, false)
          : createlivraison(objet, false, false);
      } else {
        objet.typeDoc == "BC"
          ? createcommande(objet, true)
          : createlivraison(objet, true, false);
      }
    },
  },
  async beforeMount() {
    this.panier = await getByID(this.idDetailPanierClient);
  },
};
</script>
