<template>
  <div class="relative" ref="dropdown">
    <input
      type="text"
      @click="isOpen = !isOpen"
      v-model="selectedClient"
      placeholder="Choisir un client"
      class="w-full flex flex-row items-center space-x-2 px-4 py-2 text-black border shadow rounded-md focus:outline-2 outline-sky-300"
    />

    <div
      v-if="isOpen"
      class="absolute z-10 mt-2 max-h-[25vh] bg-white overflow-y-auto rounded-md shadow-lg"
    >
      <ul class="py-1">
        <li
          v-for="(client, indexClient) in clientList"
          :key="indexClient"
          @click="selectOption(client)"
          class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
        >
          {{ client.Name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DataListClientVue",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedClient: "",
      selectedClientObject: {},
      clientList: [],
    };
  },
  methods: {
    selectOption(option) {
      this.selectedClient = option.Name;
      this.selectedClientObject = option;
      this.isOpen = false;
      this.$emit("vmodel", option);
      this.$emit("vmodelname", option.Name);
    },
    async getClientFav() {
      const clients = await axios.post(
        `${process.env.VUE_APP_API}/client/favoriClient/get`,
        {
          plateform: this.user.proprietaire,
          pro: true,
        }
      );

      this.clientList = clients.data;
    },
    handleClickOutside(event) {
      // Vérifie si le clic est en dehors du dropdown
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },
  mounted() {
    this.getClientFav();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style>
/* Add any additional styles here */
</style>
