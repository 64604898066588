import { String } from "core-js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "../../store";
import axios from "axios";
import { getFrDate } from "@/hooks/tools/date.ts";

export function sendemaillivraison(objet, avoir, emailFrom, emailTo) {
  var today = new Date();
  var da = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var hour = today.getHours();
  var minutes = today.getMinutes();
  var edition =
    "Edité le " + da + "/" + mm + "/" + yyyy + " à " + hour + ":" + minutes;
  var datepdf = "Le " + getFrDate(objet.Date) + ",";

  // let colDuplicata;
  // if (duplicata) {
  //   colDuplicata = {
  //     columns: [
  //       {
  //         text: "Duplicata",
  //         style: "invoiceTitleDetail",
  //         margin: [0, 0, 0, 0],
  //         width: 390,
  //       },
  //     ],
  //   };
  // }

  let imagePlateforme = {
    image: "imgg",
    width: 100,
    height: 100,
  };
  let titrePlateforme = {
    text: store.state.plateform.Titre1 + "\n",
    style: "invoiceBillingTitle",
    width: 245,
    margin: [0, 5, 0, 0],
  };
  let titre2Plateforme = {
    text: store.state.plateform.Titre2,
    style: "invoiceBillingSubTitle",
    width: 245,
    margin: [40, 0, 0, 0],
  };
  let adressePlateforme = {
    address: {
      text: store.state.plateform.Adresse,
      style: "invoiceBillingDetails",
      width: 250,
      margin: [0, 10, 0, 0],
    },
    city: {
      text:
        store.state.plateform.Code_postale + " " + store.state.plateform.Ville,
      style: "invoiceBillingDetails",
      width: 250,
      margin: [0, 0, 0, 0],
    },
    country: {
      text: store.state.plateform.Pays,
      style: "invoiceBillingDetails",
      width: 250,
      margin: [0, 0, 0, 0],
    },
  };
  let contactPlateforme = {
    telephone: {
      text: store.state.plateform.Telephone,
      style: "invoiceBillingContactCompany",
      width: 210,
      margin: [0, 5, 0, 0],
    },
    email: {
      text: store.state.plateform.Email,
      style: "invoiceBillingContactCompany",
      width: 210,
      margin: [0, 0, 0, 0],
    },
    siret: {
      text:
        store.state.plateform.Siret +
        " - " +
        store.state.plateform.Tva +
        " - " +
        store.state.plateform.Naf,
      style: "invoiceBillingDetailsCompany",
      width: 210,
      margin: [0, 0, 0, 0],
    },
  };
  if (objet.orderByDsv && objet.Fournisseur == "DA SILVA") {
    imagePlateforme = {
      image: "imggDsv",
      width: 100,
      height: 100,
    };
    titrePlateforme = {
      text: "DA SILVA" + "\n",
      style: "invoiceBillingTitle",
      width: 245,
      margin: [0, 5, 0, 0],
    };
    titre2Plateforme = {
      text: "",
      style: "invoiceBillingSubTitle",
      width: 245,
      margin: [40, 0, 0, 0],
    };
    adressePlateforme = {
      address: {
        text: "280 Rue Léon Joulin",
        style: "invoiceBillingDetails",
        width: 250,
        margin: [0, 10, 0, 0],
      },
      city: {
        text: "31100 Toulouse",
        style: "invoiceBillingDetails",
        width: 250,
        margin: [0, 0, 0, 0],
      },
      country: {
        text: "France",
        style: "invoiceBillingDetails",
        width: 250,
        margin: [0, 0, 0, 0],
      },
    };
    contactPlateforme = {
      telephone: {
        text: "05 62 20 66 70",
        style: "invoiceBillingContactCompany",
        width: 210,
        margin: [0, 5, 0, 0],
      },
      email: {
        text: "commandes@groupe-dasilva.com",
        style: "invoiceBillingContactCompany",
        width: 210,
        margin: [0, 0, 0, 0],
      },
      siret: {
        text: "34741845100022 - FR65347418451 - 4531Z",
        style: "invoiceBillingDetailsCompany",
        width: 210,
        margin: [0, 0, 0, 0],
      },
    };
  } else if (objet.Fournisseur == "FRIGAIR") {
    imagePlateforme = {
      image: "imggFrig",
      width: 100,
      height: 100,
    };
    titrePlateforme = {
      text: "SARL FRIGAIR FRANCE" + "\n",
      style: "invoiceBillingTitle",
      width: 245,
      margin: [0, 5, 0, 0],
    };
    titre2Plateforme = {
      text: "",
      style: "invoiceBillingSubTitle",
      width: 245,
      margin: [40, 0, 0, 0],
    };
    adressePlateforme = {
      address: {
        text: "166, rue jean vilar",
        style: "invoiceBillingDetails",
        width: 250,
        margin: [0, 10, 0, 0],
      },
      city: {
        text: "26000 Valence",
        style: "invoiceBillingDetails",
        width: 250,
        margin: [0, 0, 0, 0],
      },
      country: {
        text: "France",
        style: "invoiceBillingDetails",
        width: 250,
        margin: [0, 0, 0, 0],
      },
    };
    contactPlateforme = {
      telephone: {
        text: "04 76 26 06 27",
        style: "invoiceBillingContactCompany",
        width: 210,
        margin: [0, 5, 0, 0],
      },
      email: {
        text: "m.teyssier@frigair-france.com",
        style: "invoiceBillingContactCompany",
        width: 210,
        margin: [0, 0, 0, 0],
      },
      siret: {
        text: "41974860300067 - FR66419748603 - 4531Z",
        style: "invoiceBillingDetailsCompany",
        width: 210,
        margin: [0, 0, 0, 0],
      },
    };
  }

  var produits = [];

  produits.push([
    {
      text: "Qté",
      style: ["itemsHeader", "center"],
      border: [true, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: "Produit",
      style: "itemsHeader",
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text:
        "Servi par : " +
        objet.Name +
        "            " +
        "réf. cl. : " +
        objet.Note,
      style: "itemsHeader",
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: "Px brut HT",
      style: ["itemsHeader", "center"],
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: "Remise",
      style: ["itemsHeader", "center"],
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: "Px net HT",
      style: ["itemsHeader", "center"],
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: "Mt HT",
      style: ["itemsHeader", "center"],
      border: [false, true, true, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
  ]);
  objet.pdfData.forEach((element, index) => {
    var prixTTC = objet.Articles[index].Prix_euro;
    var prixRemise;
    var remise;
    if (objet.Articles[index].PrixFournisseurType == "prixnet") {
      prixRemise = objet.Articles[index].Prix_vente;
      objet.Articles[index].remise = "prix net";
      objet.pdfData[index].remise = "prix net";
      remise = "prix net";
    } else {
      prixRemise = parseFloat(
        prixTTC - prixTTC * (element.remise / 100)
      ).toFixed(2);
      remise = element.remise + "%";
    }
    produits.push([
      {
        text: element.quantite,
        style: "itemNumber",
        border: [true, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#FAFAFA",
      },
      {
        text: element.ref,
        style: "itemTitle",
        border: [true, false, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#F7F7F7",
      },
      {
        text:
          element.commentaire != ""
            ? element.description + "\n \n" + "Note : " + element.commentaire
            : element.description,
        style: "itemTitle",
        border: [false, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#F7F7F7",
      },
      {
        text: parseFloat(prixTTC).toFixed(2),
        style: "itemNumber",
        border: [true, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#FAFAFA",
      },
      {
        text: remise,
        style: "itemNumber",
        border: [true, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#F7F7F7",
      },
      {
        text: parseFloat(prixRemise).toFixed(2),
        style: "itemNumber",
        border: [true, false, false, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#FAFAFA",
      },
      {
        text: parseFloat(prixRemise * element.quantite).toFixed(2),
        style: "itemNumber",
        border: [false, false, true, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#F7F7F7",
      },
    ]);
  });
  var footerBody = {};
  if (
    objet.pdfData[0]?.remisegenerale &&
    objet.pdfData[0]?.remisegenerale != 0
  ) {
    footerBody = {
      headerRows: 0,
      widths: [180, 80, "*", 80, "*"],

      body: [
        [
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Transport : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            // text: objet.dataTransport
            //   ? parseFloat(objet.Transport).toFixed(2) +
            //     " € " +
            //     objet.dataTransport.transporteur
            //   : parseFloat(objet.Transport).toFixed(2) + " €",
            text: parseFloat(objet.Transport).toFixed(2) + " €",
            style: "itemsFooterTotalValue",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Total HT : ",
            style: "itemsFooterTotalTitle",
          },
          {
            text:
              parseFloat(objet.pdfData[0].totalSansRemise).toFixed(2) + " €",
            style: "itemsFooterTotalValue1",
          },
        ],
        [
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Remise : ",
            style: "itemsFooterTotalTitle",
          },
          {
            text: parseFloat(objet.pdfData[0].remisegenerale) + " %",
            style: "itemsFooterTotalValue",
          },
          {
            text: "Total HT remisé : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: parseFloat(objet.TotalPrice).toFixed(2) + " €",
            style: "itemsFooterTotalValue1",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
        ],
        [
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "TVA : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: parseFloat(objet.TotalPrice * 0.2).toFixed(2) + " €",
            style: "itemsFooterTotalValue",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Total TTC : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text:
              parseFloat(objet.TotalPrice + objet.TotalPrice * 0.2).toFixed(2) +
              " €",
            style: "itemsFooterTotalValue1",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
        ],
      ],
    };
  } else {
    footerBody = {
      headerRows: 0,
      widths: [180, 80, "*", 80, "*"],

      body: [
        [
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Transport : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            // text: objet.dataTransport
            //   ? parseFloat(objet.Transport).toFixed(2) +
            //     " € " +
            //     objet.dataTransport.transporteur
            //   : parseFloat(objet.Transport).toFixed(2) + " €",
            text: parseFloat(objet.Transport).toFixed(2) + " €",
            style: "itemsFooterTotalValue",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Total HT : ",
            style: "itemsFooterTotalTitle",
          },
          {
            text: parseFloat(objet.TotalPrice).toFixed(2) + " €",
            style: "itemsFooterTotalValue1",
          },
        ],
        [
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "TVA : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: parseFloat(objet.TotalPrice * 0.2).toFixed(2) + " €",
            style: "itemsFooterTotalValue",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Total TTC : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text:
              parseFloat(objet.TotalPrice + objet.TotalPrice * 0.2).toFixed(2) +
              " €",
            style: "itemsFooterTotalValue1",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
        ],
      ],
    };
  }

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let infoCompClient = "";
  if (objet.clientInfo[0].professionnel == true && objet.clientInfo[0].siret) {
    infoCompClient =
      objet.clientInfo[0].siret +
      " - " +
      objet.clientInfo[0].numerotva +
      " - " +
      objet.clientInfo[0].nafape;
  }
  let moyendepaiement = "";
  if (
    objet.clientInfo[0].professionnel == true &&
    objet.clientInfo[0].moyendepaiement
  ) {
    moyendepaiement =
      "Moyen de paiement : " +
      objet.clientInfo[0].moyendepaiement +
      "\n" +
      objet.clientInfo[0].NumeroClient
        ? "N° Client : " + objet.clientInfo[0].NumeroClient
        : "";
  }

  var dd = {
    header: [
      {
        columns: [
          {
            text: edition,
            style: "documentHeaderRight",
          },
        ],
      },
      // Header
      {
        margin: [40, 0, 0, 0],
        columns: [
          imagePlateforme,
          // margin: [left, top, right, bottom]
          [
            {
              columns: [
                {
                  text: avoir ? "Bon de livraison Avoir" : "Bon de livraison",
                  style: "invoiceTitle",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            {
              columns: [
                {
                  text: "n°" + "100000" + objet.Numero,
                  style: "invoiceTitleDetail",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            // colDuplicata,
            {
              stack: [
                {
                  columns: [
                    {
                      text: "",
                      style: "invoiceSubValue",
                      width: 180,
                    },
                    {
                      text: datepdf,
                      style: "invoiceSubValue",
                      margin: [0, 40, 0, 0],
                      width: 190,
                    },
                  ],
                },
              ],
            },
          ],
        ],
      },
      // Billing Headers
      {
        margin: [40, 0, 0, 0],
        columns: [
          titrePlateforme,
          {
            text: objet.Client + "\n",
            style: "invoiceBillingTitle1",
            width: 245,
            margin: [25, 0, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          titre2Plateforme,
          {
            text: objet.clientInfo[0].Adresse
              ? objet.clientInfo[0].Adresse
              : "",
            style: "invoiceBillingDetails",
            width: 210,
            margin: [24, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          adressePlateforme.address,
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 10, 0, 0],
          },
          {
            text: objet.clientInfo[0].Complement
              ? objet.clientInfo[0].Complement
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [-1, -9, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          adressePlateforme.city,
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo[0].PostalCode && objet.clientInfo[0].City
                ? objet.clientInfo[0].PostalCode +
                  " " +
                  objet.clientInfo[0].City
                : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          adressePlateforme.country,
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].Country
              ? objet.clientInfo[0].Country
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          contactPlateforme.telephone,
          {
            text: "",
            style: "invoiceBillingContactCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].telephone
              ? objet.clientInfo[0].telephone
              : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          contactPlateforme.email,
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].email ? objet.clientInfo[0].email : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          contactPlateforme.siret,
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: infoCompClient,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 2, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: moyendepaiement,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -8, 0, 0],
          },
        ],
      },
    ],
    footer: function (currentPage, pageCount) {
      return [
        {
          columns: [
            {
              text: `${store.state.plateform.Forme_sociale} ${store.state.plateform.Titre1} au capital de ${store.state.plateform.Capital} € dont le siège social est situé à ${store.state.plateform.Adresse} ${store.state.plateform.Code_postale} ${store.state.plateform.Ville}. \n En cas de retard de paiement, taux de penalité appliqué égal à 3 fois le taux d'intérêt en vigueur. Pas d'escompte en cas de paiement anticipé. Echange ou retour sous 15 jours.`,
              style: "documentFooterCenter",
            },
          ],
        },
        {
          columns: [
            {
              text: `Page ${currentPage} / ${pageCount}`,
              style: "documentFooterCenter1",
            },
          ],
        },
      ];
    },
    content: [
      // Items
      {
        table: {
          headerRows: 1,
          widths: [30, 50, 210, 40, 40, 40, 40],
          text: objet.clientInfo[0].nomcommercial,
          body: produits,
        },
      },
      "\n\n",
      {
        table: footerBody,
        layout: "lightHorizontalLines",
      },
    ],
    images: {
      imgg: {
        url: `${store.state.plateform.lien_logo}`,
        headers: {
          "Cache-Control": "no-cache",
        },
      },
      imggDsv: {
        url: "https://vekteurbucket.s3.amazonaws.com/Logo/Logo-DaSilva.png",
        headers: {
          "Cache-Control": "no-cache",
        },
      },
      imggFrig: {
        url: "https://vekteurbucket.s3.amazonaws.com/Logo/Logo_FrigAir.png",
        headers: {
          "Cache-Control": "no-cache",
        },
      },
    },
    styles: {
      // Document Header
      documentHeaderRight: {
        fontSize: 10,
        margin: [0, 10, 15, 5],
        alignment: "right",
      },
      documentHeaderCenter: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        fontSize: 6,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterCenter1: {
        fontSize: 9,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
      // Invoice Title
      invoiceTitle: {
        fontSize: 22,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      invoiceTitleDetail: {
        fontSize: 10,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      // Invoice Details
      invoiceSubValue: {
        fontSize: 9,
        alignment: "right",
      },
      // Billing Headers
      invoiceBillingTitle: {
        fontSize: 14,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingTitle1: {
        fontSize: 10,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle1: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      // Billing Details
      invoiceBillingDetails: {
        margin: [0, 0, 0, 0],
        fontSize: 11,
        alignment: "left",
      },
      invoiceBillingContactCompany: {
        fontSize: 8,
      },
      invoiceBillingDetailsCompany: {
        fontSize: 7,
      },
      // Items Header
      itemsHeader: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        bold: true,
        fillColor: "#CECECE",
      },
      // Item Title
      itemTitle: {
        fontSize: 8,
      },
      itemNumber: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        alignment: "center",
      },
      itemTotal: {
        margin: [0, 2, 0, 2],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },

      // Items Footer (Subtotal, Total, Tax, etc)
      itemsFooterSubTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterSubValue: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },
      itemsFooterTotalTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue: {
        margin: [-30, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue1: {
        margin: [-10, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterResteTitle: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
        fillColor: "#CECECE",
      },
      itemsFooterReste: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
      },
      //signaturePlaceholder: {
      //    margin: [0,70,0,0],
      //},
      //signatureName: {
      //    bold: true,
      //    alignment:'center',
      //},
      //signatureJobTitle: {
      //    italics: true,
      //    fontSize: 10,
      //    alignment:'center',
      //},
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
      center: {
        alignment: "center",
      },
    },
    pageMargins: [40, 260, 40, 40],
    defaultStyle: {
      columnGap: 20,
    },
  };

  if (avoir) {
    pdfMake.createPdf(dd).getBase64((b64) => {
      axios
        .post(`${process.env.VUE_APP_API}/email/sendEmail`, {
          data: {
            b64: b64,
            Numero: objet.Numero,
            from: emailFrom,
            to: emailTo,
            type: "Avoir Bon de livraison",
            Client: objet.clientInfo[0],
          },
        })
        .catch((error) => {
          console.log(error);
        });
    });
  } else {
    pdfMake.createPdf(dd).getBase64((b64) => {
      axios
        .post(`${process.env.VUE_APP_API}/email/sendEmail`, {
          data: {
            b64: b64,
            Numero: objet.Numero,
            from: emailFrom,
            to: emailTo,
            type: "Bon de livraison",
            Client: objet.clientInfo[0],
          },
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
