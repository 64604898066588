<template>
  <div class="grid grid-cols-4 gap-y-4 gap-x-16 w-full">
    <div class="font-bold flex">VIN</div>
    <div class="relative flex">
      <div class="mr-2">{{ vehicule[0].data.AWN_VIN }}</div>
      <div>
        <button
          class="text-[16px] font-bold material-icons-outlined text-black hover:text-gray-400 shadow-lg shadow-gray-400"
          @click="copyToWin(vehicule[0].data.AWN_VIN)"
          title="Copier"
        >
          content_copy
        </button>
      </div>

      <div
        v-if="winCopyMessage"
        class="absolute bottom-[-25px] left-0 text-xs bg-gray-400 text-white rounded px-2 py-1 shadow-lg"
        style="transition: opacity 0.3s ease-in-out"
      >
        {{ winCopyMessage }}
      </div>
    </div>
    <div class="font-bold">Mise en circulation</div>
    <div>{{ vehicule[0].data.AWN_date_mise_en_circulation }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
    <div class="font-bold">Type mines</div>
    <div>{{ vehicule[0].data.AWN_type_mine }}</div>
    <div class="font-bold">Carburant</div>
    <div>{{ vehicule[0].data.AWN_energie }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
    <div class="font-bold">Numéro de série</div>
    <div>{{ vehicule[0].data.AWN_numero_de_serie }}</div>
    <div class="font-bold">Puissance fiscale</div>
    <div>{{ vehicule[0].data.AWN_nbr_vitesses }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
    <div class="font-bold">CNIT</div>
    <div class="whitespace-normal break-words">
      {{ vehicule[0].data.AWN_type_variante_version }}
    </div>
    <div class="font-bold">Puissance kW</div>
    <div>{{ vehicule[0].data.AWN_puissance_KW }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
    <div class="font-bold">Type carrosserie</div>
    <div>{{ vehicule[0].data.AWN_carrosserie }}</div>
    <div class="font-bold">Code moteur</div>
    <div>{{ vehicule[0].data.AWN_code_moteur }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
    <div class="font-bold">Nombre portes</div>
    <div>{{ vehicule[0].data.AWN_nbr_portes }}</div>
    <div class="font-bold">Nombre cylindre</div>
    <div>{{ vehicule[0].data.AWN_nbr_cylindres }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
    <div class="font-bold">Couleur</div>
    <div>{{ vehicule[0].data.AWN_couleur }}</div>
    <div class="font-bold">Cylindrée</div>
    <div>{{ vehicule[0].data.AWN_nbr_cylindre_energie }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
    <div class="font-bold">Genre carte grise</div>
    <div>{{ vehicule[0].data.AWN_genre_carte_grise }}</div>
    <div class="font-bold">Type de boite</div>
    <div>{{ vehicule[0].data.AWN_type_boite_vites }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
    <div class="font-bold">Poids a vide</div>
    <div>{{ vehicule[0].data.AWN_poids_vide }}</div>
    <div class="font-bold">Transmission</div>
    <div>{{ vehicule[0].data.AWN_propulsion }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
    <div class="font-bold">Masse en service</div>
    <div>{{ vehicule[0].data.AWN_poids_total_roulant }}</div>
    <div class="font-bold">Pneus</div>
    <div>{{ vehicule[0].data.AWN_pneus }}</div>
    <hr />
    <hr />
    <hr />
    <hr />
  </div>
</template>

<script>
export default {
  props: ["vehicule"],
  data() {
    return {
      winCopyMessage: null,
    };
  },
  methods: {
    copyToWin(text) {
      if (!text) {
        this.showWinCopyMessage("Aucune donnée à copier !");
        return;
      }
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.showWinCopyMessage("Donnée copiée avec succès !");
        })
        .catch(() => {
          this.showWinCopyMessage("Erreur lors de la copie !");
        });
    },

    showWinCopyMessage(message) {
      this.winCopyMessage = message;
      setTimeout(() => {
        this.winCopyMessage = null;
      }, 2000);
    },
  },
};
</script>

<style scoped></style>
