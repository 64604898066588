<template>
  <div class="flex space-x-4 mb-4">
    <div>
      <label for="start-date" class="block text-sm font-medium text-gray-700"
        >Date de début</label
      >
      <input
        type="date"
        id="start-date"
        v-model="startDate"
        class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
    <div>
      <label for="end-date" class="block text-sm font-medium text-gray-700"
        >Date de fin</label
      >
      <input
        type="date"
        id="end-date"
        v-model="endDate"
        class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
    <button
      @click="applyFilter"
      class="mt-6 px-4 py-2 bg-bluevk text-white rounded-md hover:bg-blue-700"
    >
      Appliquer
    </button>
  </div>

  <div class="flex space-x-12">
    <div class="flex flex-col">
      <h3 class="flex justify-center font-medium">
        Chiffre d'affaires du client (par mois)
      </h3>
      <Chart
        type="bar"
        :data="chartData"
        :options="chartOptions"
        class="h-[20rem] flex justify-center"
      />
    </div>
    <div v-if="totalEuros" class="flex flex-col my-auto">
      <p v-if="showTurnover">
        Total HT : {{ parseFloat(totalEuros.totalAllHT).toFixed(2) }} €
      </p>
      <p v-if="showTurnover">
        Total TTC : {{ parseFloat(totalEuros.totalAllTTC).toFixed(2) }} €
      </p>
      <p v-if="showMarge">
        Total Marge : {{ parseFloat(totalEuros.totalAllMarge).toFixed(2) }} €
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Chart from "primevue/chart";
import { getTurnoverAndMargin } from "@/hooks/statistiques/ventes.ts";

export default {
  name: "ClientChart",
  components: { Chart },
  data() {
    return {
      chartData: null,
      // {
      // labels: ["01/24", "02/24", "03/24", "04/24", "05/24"], // Ajustable selon vos données
      // datasets: [
      //   {
      //     label: "Chiffre d'affaires (€)",
      //     backgroundColor: "#42A5F5",
      //     data: [400, 2500, 1500, 3000, 1500], // Données dynamiques
      //   },
      //   {
      //     label: "Bénéfices (€)",
      //     backgroundColor: "#FFC726", // Couleur des barres du troisième dataset
      //     data: [3000, 1500, 1000, 2000, 4000], // Données pour le troisième dataset
      //   },
      // ],
      // },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            onClick: (e, legendItem, legend) => this.toggleDataset(legendItem),
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
        scales: {
          x: {
            stacked: true, // Empiler les datasets sur l'axe X
          },
          y: {
            stacked: true, // Empiler les datasets sur l'axe Y
          },
        },
      },
      startDate: null,
      endDate: null,

      totalEuros: null,
      showMarge: true,
      showTurnover: true,
    };
  },
  props: ["clientID"],
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async applyFilter() {
      axios
        .post(`${process.env.VUE_APP_API}/venteStat/getSalesByMonth`, {
          plateform: this.user.proprietaire,
          client: this.clientID,
          dateDebut: this.startDate,
          dateFin: this.endDate,
        })
        .then((response) => {
          this.chartData = response.data.chartData;
          this.totalEuros = response.data.total;
          console.log(response);
        });
    },
    calculateLastSixMonths() {
      const now = new Date();
      const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - 5,
        1
      );
      this.startDate = startDate.toISOString().split("T")[0];
      this.endDate = endDate.toISOString().split("T")[0];
    },
    toggleDataset(legendItem) {
      const datasetIndex = this.chartData.datasets.findIndex(
        (dataset) => dataset.label === legendItem.text
      );

      if (datasetIndex !== -1) {
        const dataset = this.chartData.datasets[datasetIndex];

        if (legendItem.text === "Bénéfices (€)") {
          this.showMarge = !this.showMarge;
          dataset.hidden = !this.showMarge;
        }

        if (legendItem.text === "Chiffre d'affaires (€)") {
          this.showTurnover = !this.showTurnover;
          dataset.hidden = !this.showTurnover;
        }
      }
    },
  },
  mounted() {
    this.calculateLastSixMonths();
    this.applyFilter();
  },
};
</script>
