import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export function createEcheancier(data, objet) {
  var date = new Date();
  var dateFormatted = date.toLocaleDateString();
  var hourFormatted = date.toLocaleTimeString();

  var doc = [];
  doc.push([
    { text: "N° doc", style: "tableHeader" },
    { text: "Servi par", style: "tableHeader" },
    { text: "Date", style: "tableHeader" },
    { text: "Client", style: "tableHeader" },
    { text: "Montant TTC", style: "tableHeader" },
    { text: "LCR", style: "tableHeader" },
    { text: "ESP", style: "tableHeader" },
    { text: "VIR", style: "tableHeader" },
    { text: "CB", style: "tableHeader" },
    { text: "CHQ", style: "tableHeader" },
    { text: "Reste dû", style: "tableHeader" },
    { text: "Rendu", style: "tableHeader" },
  ]);
  for (const line of data) {
    var LCR = [];
    var CHQ = [];
    var CB = [];
    var ESP = [];
    var VIR = [];
    var Reste = [];
    var Rendu = [];
    for (const reglement of line.reglement) {
      if (reglement.placeholder == "LCR") {
        LCR = reglement.model;
      } else if (reglement.placeholder == "Chèque Comptant") {
        CHQ = reglement.model;
      } else if (reglement.placeholder == "Carte Bancaire") {
        CB = reglement.model;
      } else if (reglement.placeholder == "Espèce") {
        ESP = reglement.model;
      } else if (reglement.placeholder == "Virement") {
        VIR = reglement.model;
      } else if (reglement.placeholder == "Reste dû") {
        Reste = reglement.model;
      } else if (reglement.placeholder == "Rendu") {
        Rendu = reglement.model;
      }
    }
    var type = "";
    if (line.type == "Facture") {
      type = "Fact n°" + line.Numero;
    } else {
      type = "BC n°" + line.Numero;
    }
    doc.push([
      { text: type, style: "subheader" },
      { text: line.Name, style: "subheader" },
      { text: getFrDateSansH(line.Date), style: "subheader" },
      { text: line.Client, style: "subheader" },
      {
        text: parseFloat(line.TotalPrice + line.TotalPrice * 0.2).toFixed(2),
        style: "subheader",
      },
      { text: LCR, style: "subheader" },
      { text: ESP, style: "subheader" },
      { text: VIR, style: "subheader" },
      { text: CB, style: "subheader" },
      { text: CHQ, style: "subheader" },
      { text: Reste, style: "subheader" },
      { text: Rendu, style: "subheader" },
    ]);
  }
  doc.push([
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    { text: "", style: "subheader", border: [false, true, false, false] },
    // Montant TTC
    {
      text: parseFloat(objet.totalReal).toFixed(2),
      style: "subheader",
      border: [false, true, false, false],
    },
    // LCR
    {
      text: parseFloat(objet.payment[3].total).toFixed(2),
      style: "subheader",
      border: [false, true, false, false],
    },
    // ESP
    {
      text: parseFloat(objet.payment[0].total).toFixed(2),
      style: "subheader",
      border: [false, true, false, false],
    },
    // VIR
    {
      text: parseFloat(objet.payment[4].total).toFixed(2),
      style: "subheader",
      border: [false, true, false, false],
    },
    // CB
    {
      text: parseFloat(objet.payment[1].total).toFixed(2),
      style: "subheader",
      border: [false, true, false, false],
    },
    // CHQ
    {
      text: parseFloat(objet.payment[2].total).toFixed(2),
      style: "subheader",
      border: [false, true, false, false],
    }, 
    // Reste du
    {
      text: parseFloat(objet.payment[5].total).toFixed(2),
      style: "subheader",
      border: [false, true, false, false],
    },
    // Rendu
    {
      text: parseFloat(objet.payment[6].total).toFixed(2),
      style: "subheader",
      border: [false, true, false, false],
    },
  ]);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  var dd = {
    pageSize: "A4",

    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: "landscape",
    widths: ["*"],
    content: [
      {
        columns: [
          {
            text: "Echéancier des règlements",
            alignment: "left",
            style: "header",
          },
          [
            {
              text: objet.plateform,
              alignment: "left",
              style: "header",
            },
            {
              text: "Date éch : " + getFrDate(objet.date),
              alignment: "left",
              style: "header",
            },
          ],
          [
            {
              text: "Date : " + dateFormatted,
              alignment: "right",
              style: "header",
            },
            {
              text: "Heure : " + hourFormatted,
              alignment: "right",
              style: "header",
            },
          ],
        ],
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: doc,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "gray";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "black" : "gray";
          },
        },
      },
      {
        text:
          "Total encaissé : " + parseFloat(objet.totalCashed).toFixed(2) + " €",
        style: "footer",
      },
      {
        text:
          "Total caisse : " + parseFloat(objet.totalToday).toFixed(2) + " €",
        style: "footer",
      },
    ],
    styles: {
      header: {
        fontSize: 9,
        color: "black",
      },
      subheader: {
        fontSize: 9,
      },
      tableExample: {
        margin: [0, 5, 0, 5],
      },
      tableHeader: {
        fontSize: 9,
        color: "black",
      },
      footer: {
        fontSize: 10,
        alignment: "right",
      },
    },
    defaultStyle: {
      color: "black",
    },
  };
  pdfMake.createPdf(dd).open();
}
