<template>
  <div id="body" class="bg-white p-2 rounded-md" v-show="showFile === false">
    <div class="flex justify-between">
      <div class="text-2xl font-medium ml-2">
        <h2>Créer un client</h2>
      </div>
      <router-link to="/Clients">
        <div class="flex justify-end">
          <span
            style="font-size: 30px"
            class="material-icons-outlined cursor-pointer rounded-full text-red-700 hover:text-red-600"
            >close</span
          >
        </div>
      </router-link>
    </div>

    <div class="flex justify-center mt-8" id="someid">
      <div class="flex justify-center my-72 space-x-10" v-if="showChooseClient">
        <div
          style="width: 40em"
          class="flex space-x-4 border rounded-md p-1 cursor-pointer hover:shadow-lg border-blue-600"
          @click="
            (showChooseClient = false),
              (showAjoutPar = false),
              (showAjoutPro = true)
          "
        >
          <span
            class="material-icons-outlined border rounded-full text-blue-600 m-4 p-2.5 w-11 h-11"
          >
            diversity_3
          </span>
          <span class="flex flex-col py-4 px-8">
            <span class=""> Professionnel </span>
            <span class="text-gray-600 pt-2">
              Ajouter un client professionnel
            </span>
          </span>
        </div>
        <div
          style="width: 40em"
          class="flex space-x-4 border rounded-md p-1 cursor-pointer hover:shadow-lg border-blue-600"
          @click="
            (showChooseClient = false),
              (showAjoutPro = false),
              (showAjoutPar = true)
          "
        >
          <span
            class="material-icons-outlined border rounded-full text-blue-600 m-4 p-2.5 w-11 h-11"
          >
            person
          </span>
          <span class="flex flex-col py-4 px-8">
            <span class=""> Particulier </span>
            <span class="text-gray-600 pt-2">
              Ajouter un client particulier
            </span>
          </span>
        </div>
      </div>

      <div class="flex flex-col" v-else>
        <!-- Back Button -->
        <div class="flex justify-between">
          <div id="retour" class="flex justify-start mb-3">
            <backButton
              :route="'/creerClient'"
              @click="showChooseClient = !showChooseClient"
            />
          </div>
        </div>

        <div id="creerClientPro" v-show="showAjoutPro">
          <div class="flex flex-end">
            <h2 class="text-center my-2 uppercase"></h2>
            <label for="dropzone-file">
              <span
                class="px-2 py-1 rounded-[8px] bg-orangevk hover:bg-orange-200 focus:outline-none text-white rounded shadow cursor-pointer"
              >
                <span class="material-icons-outlined align-middle">print</span>
                Importer
              </span>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                accept=".xlsx, .xls, .csv"
                @change="readExcel(), (clientPro = true)"
              />
            </label>
          </div>
          <menuCreerClient />
          <ul class="flex flex-col align-center space-y-20">
            <!-- INFORMATIONS GENERALES -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showInformationsGenerales = !showInformationsGenerales),
                    (showTransport = false),
                    (showFinance = false),
                    (showPersonnel = false),
                    (showParametreAvance = false)
                "
              >
                <a class="text-xl"> Informations générales </a>
                <span
                  v-show="!showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showInformationsGenerales"
                  class="bloc-couleur couleur-remplissage"
                  id="informationsgenerales"
                >
                  <div class="flex justify-between">
                    <div
                      class="w-56 px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    >
                      <label class="text-xs">Groupe de remise</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="Group"
                          name="Group"
                          ref="Group"
                          @keydown.enter="this.$refs.NumeroClient.focus()"
                          class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-transparent rounded-md"
                        >
                          <option value="T1" selected>T1</option>
                          <option value="T2">T2</option>
                          <option value="T3">T3</option>
                          <option value="T4">T4</option>
                          <option value="T5">T5</option>
                          <option value="T6">T6</option>
                        </select>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Numéro</label>

                      <div class="w-24 relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="NumeroClient"
                          type="text"
                          ref="NumeroClient"
                          @keydown.enter="this.$refs.statutjuridique.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Statut juridique</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="statutjuridique"
                          type="text"
                          ref="statutjuridique"
                          @keydown.enter="this.$refs.Name.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div
                      class="w-full px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    >
                      <label
                        for="siegeClientName"
                        class="flex space-x-2 text-xs"
                        ><p>Nom</p>
                        <p class="text-red-600">*</p></label
                      >

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="Name"
                          type="text"
                          ref="Name"
                          @keydown.enter="this.$refs.Adresse.focus()"
                          class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Adresse</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="Adresse"
                        ref="Adresse"
                        @keydown.enter="this.$refs.Complement.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Complément d'adresse</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="Complement"
                        ref="Complement"
                        @keydown.enter="this.$refs.PostalCode.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Code Postal</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="PostalCode"
                          ref="PostalCode"
                          @keydown.enter="this.$refs.City.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Ville</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="City"
                          ref="City"
                          @keydown.enter="this.$refs.Country.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label for="Country" class="text-xs">Pays</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          name="Country"
                          v-model.trim="Country"
                          ref="Country"
                          @keydown.enter="this.$refs.telephone.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                        >
                          <option value="ALLEMAGNE">ALLEMAGNE</option>
                          <option value="AUTRICHE">AUTRICHE</option>
                          <option value="BELGIQUE">BELGIQUE</option>
                          <option value="ESPAGNE">ESPAGNE</option>
                          <option selected value="FRANCE">FRANCE</option>
                          <option value="ITALIE">ITALIE</option>
                          <option value="LUXEMBOURG">LUXEMBOURG</option>
                          <option value="MAROC">MAROC</option>
                          <option value="PAYS-BAS">PAYS-BAS</option>
                          <option value="POLOGNE">POLOGNE</option>
                          <option value="SUISSE">SUISSE</option>
                          <option value="TURQUIE">TURQUIE</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tél n°1</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="tel"
                          v-model.trim="telephone"
                          ref="telephone"
                          @keydown.enter="this.$refs.telephone2.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tél n°2</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="telephone2"
                          type="text"
                          ref="telephone2"
                          @keydown.enter="this.$refs.fax.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Fax</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="fax"
                          type="text"
                          ref="fax"
                          @keydown.enter="this.$refs.email.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Email</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="email"
                        v-model.trim="email"
                        ref="email"
                        @keydown.enter="this.$refs.siret.focus()"
                        @change="addEmailClient(email)"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Siret</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="siret"
                          type="text"
                          ref="siret"
                          @keydown.enter="this.$refs.nafape.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">NAF-APE</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="nafape"
                          type="text"
                          ref="nafape"
                          @keydown.enter="this.$refs.numerotva.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Numéro T.V.A.</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="numerotva"
                          type="text"
                          ref="numerotva"
                          @keydown.enter="this.$refs.rcsrm.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">RCS-RM</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="rcsrm"
                          type="text"
                          ref="rcsrm"
                          @keydown.enter="this.$refs.adresselivraison.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Adresse de livraison</label>

                      <div class="flex relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="adresselivraison"
                          ref="adresselivraison"
                          @keydown.enter="this.$refs.nomcommercial.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          v-model.trim="adresselivraison"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="grid grid-cols-3 justify-between"
                    style="margin-top: -10px"
                  >
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Nom/Prénom du commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="nomcommercial"
                          ref="nomcommercial"
                          @keydown.enter="this.$refs.telcommercial.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          v-model="nomcommercial"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tél. commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="telcommercial"
                          ref="telcommercial"
                          @keydown.enter="this.$refs.emailcommercial.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          v-model="telcommercial"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Email commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="emailcommercial"
                          ref="emailcommercial"
                          @keydown.enter="
                            this.$refs.nomcommercialplateform.focus()
                          "
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          v-model="emailcommercial"
                        />
                      </div>
                    </div>
                  </div>

                  <h5 class="px-5 py-2 text-gray-700 bg-gray-200 underline">
                    Commercial attitré
                  </h5>
                  <div
                    class="grid grid-cols-3 justify-between"
                    style="margin-top: -10px"
                  >
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Nom/Prénom du commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="Commercial.nom"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          ref="nomcommercialplateform"
                          @keydown.enter="
                            this.$refs.telcommercialplateform.focus()
                          "
                        >
                          <option
                            v-for="(select, indexSelect) in commerciaux"
                            :key="indexSelect"
                            :value="select.prenom + ' ' + select.nom"
                          >
                            {{ select.prenom }} {{ select.nom }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tél. commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="Commercial.tel"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          ref="telcommercialplateform"
                          @keydown.enter="
                            this.$refs.emailcommercialplateform.focus()
                          "
                        >
                          <option
                            v-for="(select, indexSelect) in commerciaux"
                            :key="indexSelect"
                            :value="select.telephone"
                          >
                            {{ select.telephone }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Email commercial</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="Commercial.email"
                          ref="emailcommercialplateform"
                          @keydown.enter="
                            (showParametreAvance = true),
                              (showInformationsGenerales = false)
                          "
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <option
                            v-for="(select, indexSelect) in commerciaux"
                            :key="indexSelect"
                            :value="select.email"
                          >
                            {{ select.email }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- PARAMETRES AVANCEES -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showParametreAvance = !showParametreAvance),
                    (showInformationsGenerales = false),
                    (showTransport = false),
                    (showFinance = false),
                    (showPersonnel = false)
                "
              >
                <a class="text-xl"> Paramètres avancées </a>
                <span
                  v-show="!showParametreAvance"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showParametreAvance"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showParametreAvance"
                  class="bloc-couleur couleur-remplissage"
                  id="informationsgenerales"
                >
                  <!-- SIEGE -->
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Siège
                  </h1>
                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Siège ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="checkbox"
                            v-model="siege"
                            id="siege"
                            name="siege"
                            :input="isSiege()"
                          />
                          <label for="siege" class="pl-2">{{
                            siege ? "Oui" : "Non"
                          }}</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Facture au siège ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="checkbox"
                            v-model="factureSiege"
                            id="factureSiege"
                            name="factureSiege"
                            :disabled="siege"
                          />
                          <label for="siege" class="pl-2">{{
                            siege ? "Oui" : "Non"
                          }}</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label
                        for="siegeClientName"
                        class="flex space-x-2 text-xs"
                        ><p>Client siège</p>
                        <p class="text-red-600">*</p></label
                      >

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          name="siegeClientName"
                          v-model="siegeClientName"
                          ref="siegeClientName"
                          @input="searchClient(siegeClientName)"
                          :disabled="siege"
                          class="focus:outline-2 outline-sky-300 w-full p-2 rounded-md"
                        />
                        <p class="text-xs">{{ siegeClientID }}</p>
                        <div
                          v-if="Clients.length > 0"
                          class="absolute z-20 shadow-lg bg-white border border-gray-300 rounded-md overflow-y-auto max-h-32 w-full"
                        >
                          <ul class="space-y-1">
                            <li
                              v-for="(client, indexclient) in Clients"
                              :key="indexclient"
                              class="cursor-pointer px-4 py-2 hover:bg-gray-200"
                              @click="selectClient(client)"
                            >
                              {{ client.Name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />

                  <!-- COMPTOIR -->
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Comptoir
                  </h1>
                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Autoriser à commander ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="commander"
                            id="commander"
                            name="commander"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="commander"
                            id="commander"
                            name="commander"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs"
                        >Afficher la remise au comptoir client ?</label
                      >

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="affichageRemise"
                            id="affichageRemise"
                            name="affichageRemise"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="affichageRemise"
                            id="affichageRemise"
                            name="affichageRemise"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs"
                        >Editer la remise au comptoir distrib ?</label
                      >

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="editionRemise"
                            id="editionRemise"
                            name="editionRemise"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="editionRemise"
                            id="editionRemise"
                            name="editionRemise"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Encours Max. autorisé</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="encoursmaxautorise"
                          name="encoursmaxautorise"
                          ref="encoursmaxautorise"
                          @keydown.enter="this.$refs.min_marge.focus()"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Marge minimum (en %)</label>
                      <input
                        v-model.trim="min_marge"
                        type="Number"
                        ref="min_marge"
                        @keydown.enter="this.$refs.min_coef.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Coef minimum (en Decimal)</label>
                      <input
                        v-model.trim="min_coef"
                        type="Float"
                        ref="min_coef"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs"
                        >Afficher au comptoir TTC avant HT ?</label
                      >

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="checkbox"
                            v-model="stateTTC"
                            id="stateTTC"
                            name="stateTTC"
                            value="true"
                          />
                          <label for="stateTTC" class="pl-2">{{
                            stateTTC ? "Oui" : "Non"
                          }}</label>
                        </div>
                      </div>
                    </div>

                    <div
                      class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    ></div>

                    <div
                      class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    ></div>
                  </div>

                  <br />

                  <!-- DOCUMENT AU COMPTOIR -->
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Document au comptoir
                  </h1>
                  <div class="grid grid-cols-6">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Devis </label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="devisRadio"
                            id="devisRadio"
                            name="devisRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="devisRadio"
                            id="devisRadio"
                            name="devisRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Bon de commande</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="commandeRadio"
                            id="commandeRadio"
                            name="commandeRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="commandeRadio"
                            id="commandeRadio"
                            name="commandeRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Bon de livraison</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="livraisonRadio"
                            id="livraisonRadio"
                            name="livraisonRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="livraisonRadio"
                            id="livraisonRadio"
                            name="livraisonRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Bon de livraison avoir</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="livraisonAvoirRadio"
                            id="livraisonAvoirRadio"
                            name="livraisonAvoirRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="livraisonAvoirRadio"
                            id="livraisonAvoirRadio"
                            name="livraisonAvoirRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Facture</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="factureRadio"
                            id="factureRadio"
                            name="factureRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="factureRadio"
                            id="factureRadio"
                            name="factureRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Retour</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="retourRadio"
                            id="retourRadio"
                            name="retourRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="retourRadio"
                            id="retourRadio"
                            name="retourRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />

                  <!-- FACTURATION -->
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Facturation
                  </h1>
                  <div class="grid grid-cols-3">
                    <div
                      class="hidden px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    >
                      <label class="text-xs">Professionnel ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            disabled
                            type="radio"
                            v-model.trim="professionnel"
                            value="true"
                            data-val="true"
                            data-val-required="Ce champ est obligatoire."
                            id="professionnel"
                            name="professionnel"
                            data-enable="True"
                            data-for-field="professionnel"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            disabled
                            type="radio"
                            v-model.trim="professionnel"
                            value="false"
                            id="professionnel"
                            name="professionnel"
                            data-enable="false"
                            data-for-field="professionnel"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Exonéré de la T.V.A. ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="tva"
                            id="tva"
                            name="tva"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="tva"
                            id="tva"
                            name="tva"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Facturation fin de mois</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="facturation"
                            id="facturation"
                            name="facturation"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="facturation"
                            id="facturation"
                            name="facturation"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Créer facture après BL ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="billAfterBL"
                            id="billAfterBL"
                            name="billAfterBL"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="billAfterBL"
                            id="billAfterBL"
                            name="billAfterBL"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Facturer les consignes</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="facturation_consigne"
                            id="facturation_consigne"
                            name="facturation_consigne"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="facturation_consigne"
                            id="facturation_consigne"
                            name="facturation_consigne"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />

                  <!-- SUITE PARAMETRE -->
                  <h1 class="px-5 pt-2 text-gray-800 text-center font-medium">
                    Plus de paramètre
                  </h1>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">N° de compte DA SILVA</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="numcomptedasilva"
                          name="numcomptedasilva"
                          ref="numcomptedasilva"
                          @keydown.enter="this.$refs.mgid.focus()"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">mgid (CarCat)</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="mgid"
                          type="number"
                          name="mgid"
                          ref="mgid"
                          @keydown.enter="this.$refs.catalogue_carcat.focus()"
                          class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs"
                        >Connexion catalogue CarCat ?</label
                      >

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="catalogue_carcat"
                            id="catalogue_carcat"
                            name="catalogue_carcat"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="catalogue_carcat"
                            id="catalogue_carcat"
                            name="catalogue_carcat"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Tableau des remises</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="TableauRemiseState"
                            id="TableauRemiseState"
                            name="TableauRemiseState"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="TableauRemiseState"
                            id="TableauRemiseState"
                            name="TableauRemiseState"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- TRANSPORT -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showTransport = !showTransport),
                    (showFinance = false),
                    (showPersonnel = false),
                    (showInformationsGenerales = false),
                    (showParametreAvance = false)
                "
              >
                <a class="text-xl"> Transport </a>
                <span
                  v-show="!showTransport"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showTransport"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showTransport"
                  class="bloc-couleur couleur-remplissage"
                  id="transport"
                >
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Livré par transporteur ?</label>

                    <div
                      class="flex relative mt-2 rounded-md shadow-sm bg-white"
                    >
                      <div
                        class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input
                          type="checkbox"
                          v-model="livrepartransporteur"
                          id="livrepartransporteur"
                          name="livrepartransporteur"
                          value="true"
                        />
                        <label for="livrepartransporteur" class="pl-2">{{
                          livrepartransporteur ? "Oui" : "Non"
                        }}</label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Frais de port</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="fraisdeport"
                          type="text"
                          ref="fraisdeport"
                          :disabled="!livrepartransporteur"
                          @keydown.enter="this.$refs.francodeport.focus()"
                          class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="grid grid-cols-2">
                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">Franco de port journalier</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <input
                            v-model.trim="francodeport"
                            name="francodeport"
                            ref="francodeport"
                            type="text"
                            :disabled="!livrepartransporteur"
                            @keydown.enter="this.$refs.francodeportMois.focus()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          />
                        </div>
                      </div>

                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">Franco de port mensuel</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <input
                            v-model.trim="francodeportMois"
                            name="francodeportMois"
                            ref="francodeportMois"
                            type="text"
                            :disabled="!livrepartransporteur"
                            @keydown.enter="this.$refs.transporteur.focus()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="bg-gray-200 border-t border-gray-500">
                      <button
                        style="
                          width: 188px;
                          height: 33px;
                          flex-shrink: 0;
                          border-radius: 6px;
                          background: #3b62b4;
                          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        "
                        class="text-white my-2 ml-6"
                        title="Ajouter un transporteur"
                        @click="
                          tabCutOff.push({
                            transporteur: '',
                            schematransport: '',
                            cutoff: '',
                          })
                        "
                        :disabled="!livrepartransporteur"
                      >
                        Ajouter un transporteur
                      </button>
                      <div
                        v-for="(line, index) in tabCutOff"
                        :key="index"
                        class="flex"
                      >
                        <button
                          class="material-icons-outlined text-red-600 my-auto"
                          @click="tabCutOff.splice(index, 1)"
                        >
                          delete
                        </button>

                        <div class="w-full grid grid-cols-3">
                          <div
                            class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                          >
                            <label class="text-xs">Transporteur</label>

                            <div class="relative mt-2 rounded-md shadow-sm">
                              <select
                                v-model.trim="tabCutOff[index].transporteur"
                                class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                ref="transporteur"
                                @keydown.enter="
                                  this.$refs.schematransport.focus()
                                "
                                @change="
                                  getSchemasTransporteur(
                                    index,
                                    tabCutOff[index].transporteur
                                  ),
                                    getCutOffsTransporteur(
                                      index,
                                      tabCutOff[index].transporteur
                                    )
                                "
                              >
                                <option
                                  v-for="(select, indexSelect) in transporteurs"
                                  :key="indexSelect"
                                  :value="select._id"
                                >
                                  {{ select.numero }} -
                                  {{ select.transporteur }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                          >
                            <label class="text-xs">Schéma de transport</label>

                            <div class="relative mt-2 rounded-md shadow-sm">
                              <select
                                v-model.trim="tabCutOff[index].schematransport"
                                class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                ref="schematransport"
                                @keydown.enter="this.$refs.cutoff.focus()"
                              >
                                <option
                                  v-for="(
                                    select, indexSelect
                                  ) in schematransports[index]"
                                  :key="indexSelect"
                                  :value="select.reference"
                                >
                                  {{ select.reference }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                          >
                            <label class="text-xs">Cut-Off</label>

                            <div class="relative mt-2 rounded-md shadow-sm">
                              <select
                                v-model.trim="tabCutOff[index].cutoff"
                                class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                ref="cutoff"
                              >
                                <option
                                  v-for="(select, indexSelect) in cutoffs[
                                    index
                                  ]"
                                  :key="indexSelect"
                                  :value="select.hour"
                                  type="time"
                                >
                                  {{ select.hour }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- FINANCE -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showFinance = !showFinance),
                    (showPersonnel = false),
                    (showInformationsGenerales = false),
                    (showTransport = false),
                    (showParametreAvance = false)
                "
              >
                <a class="text-xl"> Finance </a>
                <span
                  v-show="!showFinance"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showFinance"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showFinance"
                  class="bloc-couleur couleur-remplissage"
                  id="finance"
                >
                  <div class="container">
                    <div class="grid grid-cols-2">
                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">IBAN</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <input
                            v-model.trim="iban"
                            type="text"
                            ref="iban"
                            @keydown.enter="this.$refs.swift.focus()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          />
                        </div>
                      </div>

                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">SWIFT/BIC</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <input
                            v-model.trim="swift"
                            type="text"
                            ref="swift"
                            @keydown.enter="this.$refs.moyendepaiement.focus()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="grid grid-cols-2">
                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">Moyen de paiement</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <select
                            v-model.trim="moyendepaiement"
                            ref="moyendepaiement"
                            @keydown.enter="this.$refs.delaidepaiement.focus()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          >
                            <option value="LCR">LCR</option>
                            <option value="Virement">Virement</option>
                            <option value="Chèque">Chèque</option>
                            <option value="CB">CB</option>
                            <option value="Espèce">Espèce</option>
                          </select>
                        </div>
                      </div>

                      <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                        <label class="text-xs">Délai de paiement</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <select
                            v-model="delaidepaiement"
                            ref="delaidepaiement"
                            @keydown.enter="this.$refs.nomdelabanque.focus()"
                            class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                          >
                            <option value="0">IMMEDIAT</option>
                            <option value="30">30 JOURS FIN DE MOIS</option>
                            <option value="45">45 JOURS FIN DE MOIS</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Nom de la banque</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="nomdelabanque"
                          type="text"
                          ref="nomdelabanque"
                          @keydown.enter="this.$refs.ncomptecomptable.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">N° compte comptable</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="ncomptecomptable"
                          type="text"
                          ref="ncomptecomptable"
                          @keydown.enter="
                            (showFinance = false), (showPersonnel = true)
                          "
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- PERSONNEL -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showPersonnel = !showPersonnel),
                    (showFinance = false),
                    (showInformationsGenerales = false),
                    (showTransport = false),
                    (showParametreAvance = false)
                "
              >
                <a class="text-xl"> Personnel </a>
                <span
                  v-show="!showPersonnel"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showPersonnel"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showPersonnel"
                  class="bloc-couleur couleur-remplissage"
                  id="personnel"
                >
                  <div class="flex flex-col mt-8">
                    <div
                      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                    >
                      <div
                        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
                      >
                        <table class="min-w-full">
                          <thead>
                            <tr>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                <button class="">
                                  <span
                                    class="w-6 h-6 material-icons-round rounded-full"
                                    style="color: orange"
                                  >
                                    add_circle_outline
                                  </span>
                                </button>
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Poste/service
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Prénom
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Nom
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Tél n°1
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Tél n°2
                              </th>
                              <th
                                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                              >
                                Email
                              </th>
                            </tr>
                          </thead>

                          <tbody class="bg-white">
                            <tr>
                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              ></td>

                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="personnelposte"
                                  ref="personnelposte"
                                  @keydown.enter="
                                    this.$refs.personnelprenom.focus()
                                  "
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="personnelprenom"
                                  ref="personnelprenom"
                                  @keydown.enter="
                                    this.$refs.personnelnom.focus()
                                  "
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="personnelnom"
                                  ref="personnelnom"
                                  @keydown.enter="
                                    this.$refs.personneltel1.focus()
                                  "
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="personneltel1"
                                  ref="personneltel1"
                                  @keydown.enter="
                                    this.$refs.personneltel2.focus()
                                  "
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="personneltel2"
                                  ref="personneltel2"
                                  @keydown.enter="
                                    this.$refs.personnelemail.focus()
                                  "
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>

                              <td
                                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                              >
                                <input
                                  v-model.trim="personnelemail"
                                  ref="personnelemail"
                                  @keydown.enter="
                                    (showPersonnel = false),
                                      (showConfigEmail = true)
                                  "
                                  class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>

            <!-- CONFIG EMAIL -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="
                  (showConfigEmail = !showConfigEmail),
                    (showFinance = false),
                    (showPersonnel = false),
                    (showInformationsGenerales = false),
                    (showTransport = false),
                    (showParametreAvance = false)
                "
              >
                <a class="text-xl"> Configuration e-mail </a>
                <span
                  v-show="!showConfigEmail"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showConfigEmail"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showConfigEmail"
                  class="bloc-couleur couleur-remplissage"
                  id="configemail"
                >
                  <div class="container">
                    <div class="flex flex-col space-y-2">
                      <div
                        v-if="addemail == true"
                        class="flex flex-col space-y-2 px-5 py-2 text-gray-700 bg-gray-200 border-b"
                      >
                        <p v-if="errors.length">
                          <span
                            v-for="(error, index) in errors"
                            :key="index"
                            class="text-red-600"
                            >{{ error }}</span
                          >
                        </p>
                        <div
                          v-for="(email, index) in emails"
                          :key="index"
                          @change="checkForm(email.address)"
                          class="flex space-x-2"
                        >
                          <div class="flex space-x-1 m-auto">
                            <span
                              class="material-icons-outlined text-red-600 m-auto"
                              @click="removeEmail(index)"
                            >
                              delete
                            </span>

                            <div style="width: 30rem">
                              <label class="text-xs">E-mail</label>
                              <div class="relative mt-2 rounded-md shadow-sm">
                                <input
                                  @change="errors == []"
                                  type="email"
                                  v-model.trim="email.address"
                                  ref="emailaddress"
                                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                />
                              </div>
                            </div>
                          </div>

                          <!-- devis -->
                          <div style="width: 8rem">
                            <label class="text-xs">Devis ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.devis"
                                  id="devis"
                                  name="devis"
                                  :value="true"
                                />
                                <label
                                  v-if="email.devis == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.devis == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- bondecommande -->
                          <div style="width: 8rem">
                            <label class="text-xs">Bon de commande ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.bondecommande"
                                  id="bondecommande"
                                  name="bondecommande"
                                  :value="true"
                                />
                                <label
                                  v-if="email.bondecommande == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.bondecommande == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- bondetransport -->
                          <div style="width: 8rem">
                            <label class="text-xs">Bon de transport ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.bondetransport"
                                  id="bondetransport"
                                  name="bondetransport"
                                  :value="true"
                                />
                                <label
                                  v-if="email.bondetransport == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.bondetransport == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- bondelivraison -->
                          <div style="width: 8rem">
                            <label class="text-xs">Bon de livraison ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.bondelivraison"
                                  id="bondelivraison"
                                  name="bondelivraison"
                                  :value="true"
                                />
                                <label
                                  v-if="email.bondelivraison == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.bondelivraison == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- facture -->
                          <div style="width: 8rem">
                            <label class="text-xs">Facture ?</label>

                            <div
                              class="flex relative mt-2 rounded-md shadow-sm bg-white"
                            >
                              <div
                                class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                              >
                                <input
                                  type="checkbox"
                                  v-model.trim="email.facture"
                                  id="facture"
                                  name="facture"
                                  :value="true"
                                />
                                <label
                                  v-if="email.facture == true"
                                  class="pl-2 checkbox"
                                  >Oui</label
                                >
                                <label
                                  v-if="email.facture == false"
                                  class="pl-2 checkbox"
                                  >Non</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex justify-start mt-2">
                        <button
                          @click="
                            addEmail(), errors.push('E-mail obligatoire.')
                          "
                          class="px-2 py-2 font-medium tracking-wide text-white bg-cyan-600 rounded-md hover:bg-cyan-500 focus:outline-none"
                        >
                          Ajouter un e-mail
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>
          </ul>
          <div class="flex justify-end mt-8">
            <button
              @click="saveNewClientPro()"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
        <!-- fin créer client pro -->

        <div id="creerClientPar" v-show="showAjoutPar">
          <div class="flex flex-end">
            <h2 class="text-center my-2 uppercase"></h2>
            <label for="dropzone-file">
              <span
                @change="clientPro == false"
                class="p-2 bg-blue-500 text-white rounded shadow cursor-pointer"
              >
                Importer
              </span>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                accept=".xlsx, .xls, .csv"
                @change="readExcel()"
              />
            </label>
          </div>
          <menuCreerClient />
          <ul class="flex flex-col align-center space-y-20">
            <!-- INFORMATIONS GENERALES -->
            <li
              style="width: 80rem"
              class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
            >
              <div
                class="flex justify-between hover:text-blue-400"
                @click="showInformationsGenerales = !showInformationsGenerales"
              >
                <a class="text-xl"> Informations générales </a>
                <span
                  v-show="!showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_more
                </span>
                <span
                  v-show="showInformationsGenerales"
                  class="material-icons-outlined inset-y-0 right-0 flex items-center"
                >
                  expand_less
                </span>
              </div>
              <transition>
                <div
                  v-show="showInformationsGenerales"
                  class="bloc-couleur couleur-remplissage"
                  id="informationsgenerales"
                >
                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Professionnel ?</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            disabled
                            type="radio"
                            v-model.trim="professionnelParticulier"
                            value="true"
                            data-val="true"
                            data-val-required="Ce champ est obligatoire."
                            id="professionnelParticulier"
                            name="professionnelParticulier"
                            data-enable="True"
                            data-for-field="professionnelParticulier"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            disabled
                            type="radio"
                            v-model.trim="professionnelParticulier"
                            checked
                            value="false"
                            id="professionnelParticulier"
                            name="professionnelParticulier"
                            data-enable="false"
                            data-for-field="professionnelParticulier"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Groupe de client</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="groupeClient"
                          class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-transparent rounded-md"
                        >
                          <option value="T1" selected>T1</option>
                          <option value="T2">T2</option>
                          <option value="T3">T3</option>
                          <option value="T4">T4</option>
                          <option value="T5">T5</option>
                          <option value="T6">T6</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-6">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Devis </label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="devisParRadio"
                            id="devisParRadio"
                            name="devisParRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="devisParRadio"
                            id="devisParRadio"
                            name="devisParRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Bon de commande</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="commandeParRadio"
                            id="commandeParRadio"
                            name="commandeParRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="commandeParRadio"
                            id="commandeParRadio"
                            name="commandeParRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Bon de livraison</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="livraisonParRadio"
                            id="livraisonParRadio"
                            name="livraisonParRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="livraisonParRadio"
                            id="livraisonParRadio"
                            name="livraisonParRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Bon de livraison avoir</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="livraisonAvoirParRadio"
                            id="livraisonAvoirParRadio"
                            name="livraisonAvoirParRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="livraisonAvoirParRadio"
                            id="livraisonAvoirParRadio"
                            name="livraisonAvoirParRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Facture</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="factureParRadio"
                            id="factureParRadio"
                            name="factureParRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="factureParRadio"
                            id="factureParRadio"
                            name="factureParRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Retour</label>

                      <div
                        class="flex relative mt-2 rounded-md shadow-sm bg-white"
                      >
                        <div
                          class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="retourParRadio"
                            id="retourParRadio"
                            name="retourParRadio"
                            value="true"
                          />
                          <label class="pl-2 checkbox">Oui</label>
                        </div>

                        <div
                          class="pl-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        >
                          <input
                            type="radio"
                            v-model.trim="retourParRadio"
                            id="retourParRadio"
                            name="retourParRadio"
                            value="false"
                          />
                          <label class="pl-2 checkbox">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-between">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Numéro</label>

                      <div class="w-24 relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="NumeroClientParticulier"
                          type="text"
                          ref="NumeroClientParticulier"
                          @keydown.enter="this.$refs.nomParticulier.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div
                      class="w-full px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    >
                      <label class="text-xs">Nom</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="nomParticulier"
                          type="text"
                          ref="nomParticulier"
                          @keydown.enter="this.$refs.adresseParticulier.focus()"
                          class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Adresse</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="adresseParticulier"
                        ref="adresseParticulier"
                        @keydown.enter="
                          this.$refs.complementParticulier.focus()
                        "
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Complément d'adresse</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model.trim="complementParticulier"
                        ref="complementParticulier"
                        @keydown.enter="
                          this.$refs.codepostalParticulier.focus()
                        "
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="grid grid-cols-3">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Code Postal</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="codepostalParticulier"
                          ref="codepostalParticulier"
                          @keydown.enter="this.$refs.villeParticulier.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Ville</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="textfield"
                          v-model.trim="villeParticulier"
                          ref="villeParticulier"
                          @keydown.enter="this.$refs.paysParticulier.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label for="paysParticulier" class="text-xs">Pays</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          name="paysParticulier"
                          v-model.trim="paysParticulier"
                          ref="paysParticulier"
                          @keydown.enter="
                            this.$refs.telephoneParticulier.focus()
                          "
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                        >
                          <option value="ALLEMAGNE">ALLEMAGNE</option>
                          <option value="AUTRICHE">AUTRICHE</option>
                          <option value="BELGIQUE">BELGIQUE</option>
                          <option value="ESPAGNE">ESPAGNE</option>
                          <option selected value="FRANCE">FRANCE</option>
                          <option value="ITALIE">ITALIE</option>
                          <option value="LUXEMBOURG">LUXEMBOURG</option>
                          <option value="MAROC">MAROC</option>
                          <option value="PAYS-BAS">PAYS-BAS</option>
                          <option value="SUISSE">SUISSE</option>
                          <option value="TURQUIE">TURQUIE</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Téléphone</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="tel"
                          v-model.trim="telephoneParticulier"
                          ref="telephoneParticulier"
                          @keydown.enter="this.$refs.emailParticulier.focus()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Email</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="emailParticulier"
                          v-model.trim="emailParticulier"
                          ref="emailParticulier"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>
          </ul>

          <div class="flex justify-end mt-8">
            <button
              @click="saveNewClientPar()"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
        <!-- fin créer client par -->
      </div>
    </div>
  </div>
  <div
    v-show="showFile === true"
    class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
  >
    <div
      class="flex flex-row items-center cursor-pointer w-24 rounded-full hover:shadow-lg px-2 text-gray-600 mb-2"
      @click="$router.go(-1)"
    >
      <span class="material-icons-round"> arrow_back </span>
      <span class="p-2">Retour</span>
    </div>
    <div
      class="flex justify-end mb-4"
      v-if="tabHeader.length > 4"
      v-show="showAjoutPro"
    >
      <div
        class="p-2 bg-blue-500 text-white rounded shadow cursor-pointer"
        @click="importFile()"
      >
        Importer Client Pro
      </div>
    </div>

    <div
      class="flex justify-end mb-4"
      v-if="tabHeader.length > 4"
      v-show="showAjoutPar"
    >
      <div
        class="p-2 bg-blue-500 text-white rounded shadow cursor-pointer"
        @click="importFilePar()"
      >
        Importer Client Par
      </div>
    </div>

    <div
      class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
    >
      <table class="min-w-full">
        <thead>
          <tr v-show="showAjoutPro">
            <th
              v-for="(header, index) in headers"
              :key="index"
              class="px-6 py-3 border-b border-gray-200 bg-vk text-left text-xs leading-4 font-base text-gray-500 uppercase tracking-wider"
            >
              <select
                class="bg-vk text-white font-base"
                v-model.trim="tabHeader[index]"
              >
                <option
                  class="bg-white text-gray-500"
                  v-for="(select, indexSelect) in selectValue"
                  :key="indexSelect"
                  :value="select"
                >
                  {{ select }}
                </option>
              </select>
            </th>
            <!-- <th class="left-0"> Nom du client </th>
              <th class="right-0"> Adresse du client </th> -->
          </tr>
          <tr v-show="showAjoutPar">
            <th
              v-for="(header, index) in headers"
              :key="index"
              class="px-6 py-3 border-b border-gray-200 bg-vk text-left text-xs leading-4 text-gray-500 uppercase tracking-wider"
            >
              <select
                class="bg-vk text-white font-base"
                v-model.trim="tabHeader[index]"
              >
                <option
                  class="bg-white text-gray-500"
                  v-for="(select, indexSelect) in selectValuePar"
                  :key="indexSelect"
                  :value="select"
                >
                  {{ select }}
                </option>
              </select>
            </th>
            <!-- <th class="left-0"> Nom du client </th>
              <th class="right-0"> Adresse du client </th> -->
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(row, index) in readFile" :key="index">
            <td
              v-for="(cell, index) in row"
              :key="index"
              class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
            >
              {{ cell }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import readXlsxFile from "read-excel-file";
import { useToast } from "vue-toastification";
import menuCreerClient from "../components/Client/menuCreerClient.vue";
// import loadingImport from "../components/general/importFiles/loadingImport.vue";
import {
  getTransporteurs,
  getAllTarifs,
  getTransporteurById,
} from "@/hooks/transport/transporteur";
import { getCommerciaux } from "@/hooks/commercial/commercial";
import backButton from "@/components/Button/back.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showInformationsGenerales: true,
      showParametreAvance: false,
      showTransport: false,
      showFinance: false,
      showPersonnel: false,
      showConfigEmail: false,

      showAjoutPro: false,
      showAjoutPar: false,
      showChooseClient: true,

      Commerciaux: [],

      // paramete siege
      siege: true,
      factureSiege: true,
      siegeClientName: "",
      siegeClientID: null,
      Clients: [],

      professionnel: true,
      tva: false,
      commander: true,
      facturation: true,
      affichageRemise: true,
      editionRemise: true,
      stateTTC: true,
      billAfterBL: false,
      facturation_consigne: true,
      catalogue_carcat: false,
      TableauRemiseState: false,

      // parametre comptoir professionnel
      devisRadio: true,
      commandeRadio: true,
      livraisonRadio: true,
      livraisonAvoirRadio: true,
      factureRadio: true,
      retourRadio: true,
      // parametre comptoir particulier
      devisParRadio: true,
      commandeParRadio: true,
      livraisonParRadio: false,
      livraisonAvoirParRadio: false,
      factureParRadio: true,
      retourParRadio: false,

      Group: "T1",
      encoursmaxautorise: "",
      numcomptedasilva: "",
      mgid: "",
      statutjuridique: "",
      Name: "",
      NumeroClient: "",
      Adresse: "",
      Complement: "",
      PostalCode: "",
      City: "",
      Country: "FRANCE",
      telephone: "",
      telephone2: "",
      fax: "",
      email: "",
      siret: "",
      nafape: "",
      numerotva: "",
      rcsrm: "",
      adresselivraison: "",
      Commercial: { nom: "", tel: "", email: "" },
      nomcommercial: "",
      telcommercial: "",
      emailcommercial: "",
      livrepartransporteur: false,
      fraisdeportchoix: false,
      fraisdeport: "0",
      francodeport: "150",
      francodeportMois: "",
      // transporteur: "",
      // schematransport: "",
      // cutoff: "00:00",
      tabCutOff: [],
      iban: "",
      swift: "",
      moyendepaiement: "",
      delaidepaiement: "",
      nomdelabanque: "",
      ncomptecomptable: "",
      personnelposte: "",
      personnelprenom: "",
      personnelnom: "",
      personneltel1: "",
      personneltel2: "",
      personnelemail: "",
      emails: [],

      professionnelParticulier: false,
      groupeClient: "P1",
      nomParticulier: "",
      NumeroClientParticulier: "",
      adresseParticulier: "",
      complementParticulier: "",
      codepostalParticulier: "",
      villeParticulier: "",
      paysParticulier: "FRANCE",
      telephoneParticulier: "",
      emailParticulier: "",

      addemail: false,
      errors: [],

      clientPro: false,
      tabHeader: [],
      headers: [],
      loadingFiles: false,
      readFile: null,
      showFile: false,
      selectValue: [
        "tva",
        "commander",
        "facturation",
        "affichageRemise",
        "editionRemise",
        "stateTTC",
        "billAfterBL",
        "facturation_consigne",
        "catalogue_carcat",
        "TableauRemiseState",
        "Group",
        "encoursmaxautorise",
        "numcomptedasilva",
        "mgid",
        "statutjuridique",
        "NumeroClient",
        "Name",
        "Adresse",
        "Complement",
        "PostalCode",
        "City",
        "Country",
        "telephone",
        "telephone2",
        "fax",
        "email",
        "siret",
        "nafape",
        "numerotva",
        "rcsrm",
        "adresselivraison",
        "Commercial.nom",
        "Commercial.tel",
        "Commercial.email",
        "nomcommercial",
        "telcommercial",
        "emailcommercial",
        "livrepartransporteur",
        "fraisdeportchoix",
        "fraisdeport",
        "francodeport",
        "francodeportMois",
        "iban",
        "swift",
        "moyendepaiement",
        "delaidepaiement",
        "nomdelabanque",
        "ncomptecomptable",
        "personnelposte",
        "personnelprenom",
        "personnelnom",
        "personneltel1",
        "personneltel2",
        "personnelemail",
        "emails.0.address",
        "emails.0.devis",
        "emails.0.bondecommande",
        "emails.0.bondetransport",
        "emails.0.bondelivraison",
        "emails.0.facture",
      ],
      selectValuePar: [
        "Group",
        "NumeroClient",
        "Name",
        "Adresse",
        "Complement",
        "PostalCode",
        "City",
        "Country",
        "telephone",
        "email",
      ],

      transporteurs: [],
      schematransports: [],
      cutoffs: [],
      commerciaux: [],
      min_marge: "",
      min_coef: "",
    };
  },
  computed: {
    ...mapGetters(["user", "clientChoosedTab", "plateform"]),
  },
  components: {
    menuCreerClient,
    backButton,
  },
  methods: {
    ...mapActions(["getclientchoosedtab"]),
    async readExcel() {
      var file = event.target.files ? event.target.files[0] : null;
      if (file.size < 1000000) {
        this.loadingFiles = true;
        this.showFile = true;
        this.readFile = await readXlsxFile(file);
        this.initHeader(this.readFile[0]);
        this.readFile.splice(0, 1);
        this.loadingFiles = false;
      } else {
        this.error("Le fichier est trop volumineux");
      }
    },
    initHeader(tab) {
      this.headers = [];
      tab.forEach(() => {
        this.headers.push("test");
      });
    },
    async importFile() {
      if (this.controlHeaderTab(this.tabHeader)) {
        this.loadingFiles = true;
        if (this.readFile.length > 0) {
          let plateform_mere = this.user.proprietaire;
          if (this.plateform.plateform_mere) {
            plateform_mere = this.plateform.plateform_mere;
          }
          await axios
            .post(`${process.env.VUE_APP_API}/client/importClients`, {
              Data: this.readFile,
              plateform: plateform_mere,
              headerTab: this.tabHeader,
            })
            .then((res) => {
              console.log(res.data);
              this.loadingFiles = false;
              this.showFile = false;
              this.clientChoosedTab = res.data;
              this.getclientchoosedtab(res.data);
              this.success("Le Client à été importé avec succès");
            });
          this.$router.push("/Clients");
        } else {
          this.error("Le fichier est vide");
          this.loadingFiles = false;
        }
      } else {
        this.error("Les colonnes ne doivent pas être identiques");
      }
    },
    async importFilePar() {
      if (this.controlHeaderTab(this.tabHeader)) {
        this.loadingFiles = true;
        if (this.readFile.length > 0) {
          let plateform_mere = this.user.proprietaire;
          if (this.plateform.plateform_mere) {
            plateform_mere = this.plateform.plateform_mere;
          }
          await axios
            .post(`${process.env.VUE_APP_API}/client/importClientsPar`, {
              Data: this.readFile,
              plateform: plateform_mere,
              headerTab: this.tabHeader,
            })
            .then((res) => {
              console.log(res.data);
              this.loadingFiles = false;
              this.showFile = false;
              this.clientChoosedTab = res.data;
              this.getclientchoosedtab(res.data);
              this.success("Le Client à été importé avec succès");
            });
          this.$router.push("/Clients");
        } else {
          this.error("Le fichier est vide");
          this.loadingFiles = false;
        }
      } else {
        this.error("Les colonnes ne doivent pas être identiques");
      }
    },
    controlHeaderTab(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    },
    closeImport() {
      this.showFile = false;
      this.file = null;
      this.readFile = null;
    },
    async getClients() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .get(`${process.env.VUE_APP_API}/client/${plateform_mere}`)
        .then((response) => {
          console.log(response.data);
          this.Clients = response.data;
        });
    },
    async getCommercial() {
      this.commerciaux = await getCommerciaux(this.user.proprietaire);
      console.log(this.commerciaux);
    },
    async getTransporteurs() {
      this.transporteurs = await getTransporteurs(this.user.proprietaire);
      console.log(this.transporteurs);
    },
    async getSchemasTransporteur(index, tr) {
      const schema = await getAllTarifs(this.user.proprietaire, tr);
      this.schematransports[index] = schema;
    },
    async getCutOffsTransporteur(index, tr) {
      const transporteur = await getTransporteurById(
        this.user.proprietaire,
        tr
      );
      this.cutoffs[index] = transporteur[0].cutoffs;
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(emailEnvoi) {
      this.errors = [];
      if (!emailEnvoi) {
        this.errors = [];
        this.errors.push("E-mail obligatoire.");
      } else if (!this.validEmail(emailEnvoi)) {
        this.errors = [];
        this.errors.push("E-mail incorrect.");
      } else {
        this.errors = [];
      }
    },
    addEmailClient(email) {
      this.emails.push({
        address: email,
        devis: false,
        bondecommande: false,
        bondetransport: false,
        bondelivraison: false,
        facture: false,
      });
      console.log(this.emails);
      this.addemail = true;
    },
    addEmail() {
      this.emails.push({
        address: "",
        devis: false,
        bondecommande: false,
        bondetransport: false,
        bondelivraison: false,
        facture: false,
      });
      console.log(this.emails);
      this.addemail = true;
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    async saveNewClientPro() {
      if (
        this.Name != "" &&
        (this.siege == true ||
          (this.siege == false && this.siegeClientID != null))
      ) {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        await axios
          .post(`${process.env.VUE_APP_API}/client`, {
            Id: this.Id,
            RowVersion: this.RowVersion,
            StringValue: this.StringValue,
            professionnel: this.professionnel,
            Group: {
              FreeOfCharge: this.FreeOfCharge,
              Name: this.Group,
              Id: this.Id,
            },
            Name: this.Name.toUpperCase(),
            statutjuridique: this.statutjuridique,
            NumeroClient: this.NumeroClient,
            Adresse: this.Adresse,
            adresselivraison: this.adresselivraison,
            Country: this.Country,
            City: this.City,
            PostalCode: this.PostalCode,
            Complement: this.Complement,
            Archived: this.Archived,
            CanOrder: this.CanOrder,
            telephone: this.telephone,
            email: this.email,
            siret: this.siret,
            nafape: this.nafape,
            numerotva: this.numerotva,
            rcsrm: this.rcsrm,
            tva: this.tva,
            commander: this.commander,
            facturationFDM: this.facturation,
            affichageRemise: this.affichageRemise,
            editionRemise: this.editionRemise,
            stateTTC: this.stateTTC,
            billAfterBL: this.billAfterBL,
            facturation_consigne: this.facturation_consigne,
            catalogue_carcat: this.catalogue_carcat,
            TableauRemiseState: this.TableauRemiseState,
            nomcommercial: this.nomcommercial,
            telcommercial: this.telcommercial,
            emailcommercial: this.emailcommercial,
            encoursmaxautorise: this.encoursmaxautorise,
            numcomptedasilva: this.numcomptedasilva,
            telephone2: this.telephone2,
            fax: this.fax,
            livrepartransporteur: this.livrepartransporteur,
            fraisdeportchoix: this.fraisdeportchoix,
            fraisdeport: this.fraisdeport,
            fraisdeportsave: this.fraisdeport,
            francodeport: this.francodeport,
            francodeportMois: this.francodeportMois,
            // transporteur: this.transporteur,
            // schematransport: this.schematransport,
            // cutoff: this.cutoff,
            // tabCutOff: this.tabCutOff,
            iban: this.iban,
            swift: this.swift,
            nomdelabanque: this.nomdelabanque,
            moyendepaiement: this.moyendepaiement,
            delaidepaiement: parseInt(this.delaidepaiement),
            ncomptecomptable: this.ncomptecomptable,
            personnelposte: this.personnelposte,
            personnelprenom: this.personnelprenom,
            personnelnom: this.personnelnom,
            personneltel1: this.personneltel1,
            personneltel2: this.personneltel2,
            personnelemail: this.personnelemail,
            plateform: this.user.proprietaire,
            Emails: this.emails,
            agenceName: this.user.proprietaire,
            Commercial: this.Commercial,
            counter: {
              devis: this.devisRadio,
              bondecommande: this.commandeRadio,
              bondelivraison: this.livraisonRadio,
              bondelivraisonavoir: this.livraisonAvoirRadio,
              facture: this.factureRadio,
              retour: this.retourRadio,
            },
            min_marge: this.min_marge,
            min_coef: this.min_coef,
            siege: this.siege,
            factureSiege: this.factureSiege,
            parentCompanyID: this.siegeClientID,
            parentCompanyName: this.siegeClientName,
            created_by: this.user.username,
          })
          .then(() => {
            this.showInformationsGenerales = false;
            this.showTransport = false;
            this.showFinance = false;
            this.showPersonnel = false;
            this.showParametreAvance = false;
            this.showConfigEmail = false;
            this.toast.success("Client ajouté !", {
              position: "bottom-right",
              timeout: 1000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.$router.push("/Clients");
          });
      } else {
        this.error("Veuillez remplir les champs obligatoires !");
      }
    },
    async saveNewClientPar() {
      if (
        this.nomParticulier != ""
      ) {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        axios
          .post(`${process.env.VUE_APP_API}/client`, {
            Id: this.Id,
            RowVersion: this.RowVersion,
            StringValue: this.StringValue,
            professionnel: this.professionnelParticulier,
            Group: {
              FreeOfCharge: this.FreeOfCharge,
              Name: this.groupeClient,
              Id: this.Id,
            },
            Name: this.nomParticulier.toUpperCase(),
            NumeroClient: this.NumeroClientParticulier,
            Adresse: this.adresseParticulier,
            Country: this.paysParticulier,
            City: this.villeParticulier,
            PostalCode: this.codepostalParticulier,
            Complement: this.complementParticulier,
            telephone: this.telephoneParticulier,
            email: this.emailParticulier,
            plateform: plateform_mere,
            agenceName: this.user.proprietaire,
            counter: {
              devis: this.devisParRadio,
              bondecommande: this.commandeParRadio,
              bondelivraison: this.livraisonParRadio,
              bondelivraisonavoir: this.livraisonAvoirParRadio,
              facture: this.factureParRadio,
              retour: this.retourParRadio,
            },
          })
          .then(() => {
            this.showInformationsGenerales = false;
            this.showTransport = false;
            this.showFinance = false;
            this.showPersonnel = false;
            this.showParametreAvance = false;
            this.showConfigEmail = false;
            this.toast.success("Client ajouté !", {
              position: "bottom-right",
              timeout: 1000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.$router.push("/Clients");
          });
      } else {
        this.error("Veuillez remplir les champs obligatoires !");
      }
    },
    searchClient(client) {
      this.siegeClientID = null;
      if (this.siegeClientName != "") {
        axios
          .post(`${process.env.VUE_APP_API}/client/searchClient`, {
            plateform: this.user.proprietaire,
            searchTerm: client,
            pro: true,
            archived: false,
          })
          .then((res) => {
            this.Clients = res.data;
          });
      }
    },
    selectClient(clientChoosed) {
      if (clientChoosed?.siege) {
        this.siegeClientName = clientChoosed.Name;
        this.siegeClientID = clientChoosed._id;
        this.Clients = [];
      } else {
        this.error("Ce n'est pas un siège !");
      }
    },
    isSiege() {
      if (this.siege == true) {
        this.siegeClientName = "";
        this.siegeClientID = null;
        this.factureSiege = true;
      }
    },
    success(message) {
      this.toast.success(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getTransporteurs();
    this.getCommercial();
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.75s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.bg-vk {
  background-color: #2262b3;
}
</style>
