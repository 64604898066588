<template>
  <div
    v-if="openAjoutClient"
    :class="`modal ${
      !openAjoutClient && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openAjoutClient = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 72rem; height: 40rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Ajouter un client aux favoris</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openAjoutClient = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!-- Résultats de la recherche -->
        <div class="flex justify-center border rounded-md shadow-lg">
          <table class="" style="width: 430px">
            <thead>
              <tr>
                <th
                  class="px-24 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Type client
                </th>
                <th
                  class="px-24 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Client
                </th>
                <th
                  class="px-24 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Statut
                </th>
                <th
                  class="px-24 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in searchResults" :key="index">
                <td
                  v-if="searchResults.length > 0"
                  class="text-orangevk px-24 py-1 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.professionnel ? "Professionnel" : "Particulier" }}
                </td>

                <td
                  v-if="searchResults.length > 0"
                  class="px-24 py-1 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    {{ u.favoriteRanking }}
                    <p>{{ u.Name }}</p>
                    <p class="text-xs">
                      {{ u.NumeroClient }}
                    </p>
                  </div>
                </td>

                <td
                  v-if="searchResults.length > 0"
                  class="px-24 py-1 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.archived ? "Archivé" : "Actif" }}
                </td>

                <td
                  v-if="searchResults.length > 0"
                  class="px-24 py-1 border-b border-gray-200 whitespace-nowrap"
                >
                  <button
                    class="material-icons-round text-bluevk hover:text-opacity-60"
                    @click="ajouterFavori(u)"
                  >
                    add_circle
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white rounded-md border p-4 shadow-lg">
    <div class="flex justify-between">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/clients'" />
      </div>
    </div>
    <div class="flex space-x-12 mt-3">
      <form @submit.prevent="searchClient">
        <div class="flex space-x-8">
          <!-- Barre de recherche -->
          <div class="relative mt-1">
            <label
              for="search"
              class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >Rechercher un client</label
            >
            <input
              type="text"
              id="search"
              v-model="searchQuery"
              required
              class="block text-xl px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
            />
          </div>

          <div class="relative">
            <label
              for="pro"
              class="text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >Professionnel ?</label
            >
            <div class="flex px-2 space-x-2">
              <input
                type="checkbox"
                id="pro"
                v-model="pro"
                class="my-auto w-full border rounded"
              />
              <p v-if="pro">Oui</p>
              <p v-else>Non</p>
            </div>
          </div>

          <div class="relative">
            <label
              for="archived"
              class="text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >Archivé ?</label
            >
            <div class="flex px-2 space-x-2">
              <input
                type="checkbox"
                id="archived"
                v-model="archived"
                class="my-auto w-full border rounded"
              />
              <p v-if="archived">Oui</p>
              <p v-else>Non</p>
            </div>
          </div>

          <div class="mt-1">
            <button
              type="submit"
              class="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-bluevk hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bluevk"
            >
              Rechercher
            </button>
          </div>
        </div>
      </form>
    </div>

    <!--  -->
    <div class="mt-8 flex justify-between">
      <div
        class="table-container1 overflow-container border rounded-md shadow-lg"
      >
        <p class="bg-orangevk text-white p-1 shadow rounded">Professionnel</p>
        <table class="">
          <thead>
            <tr>
              <th
                class="px-3 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Rang
              </th>
              <th
                style="width: 600px"
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Client
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Statut
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(u, index) in tabFavPro"
              :key="index"
              @drop="onAGDrop($event, index, true)"
              @dragover.prevent
              @dragenter.prevent
              draggable="true"
              @dragstart="startAGDrag(index)"
              class="cursor-pointer odd:bg-white even:bg-slate-50"
            >
              <td class="px-3 py-1 border-b border-gray-200 whitespace-nowrap">
                {{ u.favoriteRanking }}
              </td>

              <td
                style="width: 600px"
                class="px-6 py-1 border-b border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col">
                  <p class="text-bluevk font-bold">{{ u.Name }}</p>
                  <p class="text-xs">
                    {{ u.NumeroClient }}
                  </p>
                </div>
              </td>

              <td class="px-6 py-1 border-b border-gray-200 whitespace-nowrap">
                {{ u.archived ? "Archivé" : "Actif" }}
              </td>

              <td class="px-6 py-1 border-b border-gray-200 whitespace-nowrap">
                <span
                  class="material-icons-round text-red-500 hover:text-opacity-60 cursor-pointer"
                  @click="deleteFavori(u)"
                >
                  delete
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="table-container1 overflow-container border rounded-md shadow-lg"
      >
        <p class="bg-orangevk text-white p-1 shadow rounded">Particulier</p>
        <table class="">
          <thead>
            <tr>
              <th
                class="px-3 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Rang
              </th>
              <th
                style="width: 600px"
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Client
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Statut
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(u, index) in tabFavPart"
              :key="index"
              @drop="onAGDrop($event, index, false)"
              @dragover.prevent
              @dragenter.prevent
              draggable="true"
              @dragstart="startAGDrag(index)"
              class="cursor-pointer odd:bg-white even:bg-slate-50"
            >
              <td class="px-3 py-1 border-b border-gray-200 whitespace-nowrap">
                {{ u.favoriteRanking }}
              </td>

              <td
                style="width: 600px"
                class="px-6 py-1 border-b border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col">
                  <p class="text-bluevk font-bold">{{ u.Name }}</p>
                  <p class="text-xs">
                    {{ u.NumeroClient }}
                  </p>
                </div>
              </td>

              <td class="px-6 py-1 border-b border-gray-200 whitespace-nowrap">
                {{ u.archived ? "Archivé" : "Actif" }}
              </td>

              <td class="px-6 py-1 border-b border-gray-200 whitespace-nowrap">
                <span
                  class="material-icons-round text-red-500 hover:text-opacity-60 cursor-pointer"
                  @click="deleteFavori(u)"
                >
                  delete
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import backButton from "@/components/Button/back.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openAjoutClient: false,

      searchQuery: "",
      archived: false,
      pro: true,
      searchResults: [],
      tabFavPart: [],
      tabFavPro: [],

      dragAGIndex: 0,
    };
  },
  components: {
    backButton,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getFavoris() {
      axios
        .post(`${process.env.VUE_APP_API}/client/favoriClient/get`, {
          plateform: this.user.proprietaire,
          pro: false,
        })
        .then((res) => {
          this.tabFavPart = res.data;
        });
      axios
        .post(`${process.env.VUE_APP_API}/client/favoriClient/get`, {
          plateform: this.user.proprietaire,
          pro: true,
        })
        .then((res) => {
          this.tabFavPro = res.data;
        });
    },
    searchClient() {
      axios
        .post(`${process.env.VUE_APP_API}/client/searchClient`, {
          plateform: this.user.proprietaire,
          searchTerm: this.searchQuery,
          pro: this.pro,
          archived: this.archived,
        })
        .then((res) => {
          this.searchResults = res.data;
          this.openAjoutClient = true;
        });
    },
    ajouterFavori(client) {
      axios
        .post(`${process.env.VUE_APP_API}/client/favoriClient/create`, {
          data: {
            plateform: this.user.proprietaire,
            id: client._id,
            Client: client.Name,
            professionnel: client.professionnel,
            archived: client.archived,
          },
        })
        .then((res) => {
          res.data == "Existe déjà !"
            ? this.warning(res.data)
            : this.success(res.data);
          this.getFavoris();
        });
    },
    async deleteFavori(client) {
      await axios.post(
        `${process.env.VUE_APP_API}/client/favoriClient/delete`,
        {
          data: {
            plateform: this.user.proprietaire,
            id: client._id,
            Client: client.Name,
            professionnel: client.professionnel,
            archived: client.archived,
          },
        }
      );
      this.success("Supprimé avec succès");
      this.getFavoris();
    },
    async saveFavori(pro, data) {
      await axios.post(`${process.env.VUE_APP_API}/client/favoriClient/save`, {
        data: data,
      });
      this.getFavoris();
    },
    startAGDrag(index) {
      this.dragAGIndex = index;
    },
    async onAGDrop(event, index, pro) {
      if (index !== this.dragAGIndex) {
        if (pro) {
          this.tabFavPro.splice(
            index,
            0,
            this.tabFavPro.splice(this.dragAGIndex, 1)[0]
          );
          await this.saveFavori(pro, this.tabFavPro);
        } else {
          this.tabFavPart.splice(
            index,
            0,
            this.tabFavPart.splice(this.dragAGIndex, 1)[0]
          );
          await this.saveFavori(pro, this.tabFavPart);
        }
      }
    },
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(msg) {
      this.toast.warning(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getFavoris();
  },
};
</script>

<style scoped>
.table-container {
  width: 430px;
  height: 300px;
}

.table-container1 {
  width: 800px;
  height: 750px;
}

.overflow-container {
  overflow-x: hidden;
  cursor: pointer;
}

.overflow-container::-webkit-scrollbar {
  width: 12px;
}

.overflow-container::-webkit-scrollbar-thumb {
  background-color: #727272;
  border-radius: 4px;
}

.overflow-container::-webkit-scrollbar-thumb:hover {
  background-color: #979797;
}
</style>
