<template>
  <div
    v-if="openInfoVehicule"
    :class="`modal ${
      !openInfoVehicule && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openInfoVehicule = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      v-if="vehiculeSet"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 55rem; max-width: 60rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div class="px-6 py-4 modal-content">
        <div class="flex justify-between pb-">
          <div class="flex">
            <div class="flex flex-col">
              <p class="text-2xl font-bold">Informations pour:</p>
              <p class="text-2xl font-bold uppercase text-left ml-4">
                {{ vehicule[0].data.AWN_immat }}
              </p>
            </div>
            <div>
              <p class="text-2xl ml-96">
                {{ vehicule[0].data.AWN_marque_carrosserie }}
              </p>
              <div class="text-right">
                <p class="text-2xl font-bold uppercase ">
                  {{ vehicule[0].data.AWN_modele_etude }}
                </p>
                <p class="text-2xl font-bold uppercase ">
                  {{ vehicule[0].data.AWN_version }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openInfoVehicule = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div v-if="vehiculeSet" class="mt-8">
          <infosVehicule :vehicule="vehicule" />
        </div>

        <div class="flex justify-end pt-2">
          <button
            @click="openInfoVehicule = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-gray-300 rounded-lg hover:bg-red-500 hover:text-white shadow-lg focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <Sidenav v-if="openMenu" @closeMenu="openMenu = false" />

  <header
    class="z-10 flex items-center justify-between px-6 py-2 bg-white drop-shadow-md"
  >
    <div class="flex items-center">
      <button
        @click="$store.state.isOpen = true"
        class="mr-2 text-gray-500 focus:outline-none hidden"
      >
        <svg
          class="w-6 h-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H20M4 12H20M4 18H11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div class="hidden 2xl:block mr-6">
        <div
          @click="openMenu = true"
          class="relative rounded-full border w-10 h-10 text-center cursor-pointer shadow hover:shadow-lg"
        >
          <!-- <img class="rounded-full" :src="store.state.plateform.lien_logo" /> -->
          <span class="material-icons-outlined p-1.5 text-orange-500"
            >menu</span
          >
        </div>
      </div>

      <!-- SESSIONS -->
      <div class="-mt-1">
        <div class="flex items-center">
          <sessionActive />
        </div>
      </div>
    </div>

    <div class="flex items-center">
      <div class="flex flex-row items-center">
        <div class="relative -mr-6 z-10 hidden sm:block">
          <router-link to="/tecdocsearch">
            <div class="flex flex-col">
              <span
                style="margin-left: -1px"
                class="material-icons-outlined hover:bg-blue-400 hover:bg-opacity-25 text-white rounded-full cursor-pointer"
              >
                directions_car
              </span>
            </div>
          </router-link>
        </div>

        <div class="relative hidden sm:block" v-if="vehiculeSet">
          <div class="plate-immat2 relative">
            <i class="plate-flag2 fx-center-center wlf wlf-plate-FR"></i>
            <div class="relative flex items-center">
              <input
                type="text"
                :placeholder="vehicule[0].data.AWN_immat"
                name="plate-number"
                v-model="plaque"
                class="uppercase plate-number focus:outline-2 outline-sky-300"
                v-on:keyup.enter="getPlaque()"
              />
              <div
                class="absolute right-1 my-auto flex flex-col items-center space-y-1"
              >
                <button
                  class="text-[12px] plate-plaque-copy material-icons-outlined text-gray-900 hover:text-gray-400"
                  @click="copyToClipboard(vehicule[0].data.AWN_immat)"
                  title="Copier la plaque"
                >
                  content_copy
                </button>
              </div>
            </div>
            <i class="plate-flag2 fx-center-center wlf wlf-plate-FR"></i>
            <!-- tooltip -->
            <div
              v-if="copyMessage"
              class="absolute top-full right-3 mt-2 bg-gray-600 text-white text-[11px] px-3 py-2 rounded shadow"
            >
              {{ copyMessage }}
            </div>
          </div>
        </div>
        <div class="relative hidden sm:block" v-else>
          <div class="plate-immat1">
            <i class="plate-flag1 fx-center-center wlf wlf-plate-FR"></i>
            <input
              type="text"
              placeholder="AB-123-CD"
              name="plate-number"
              class="uppercase plate-number focus:outline-2 outline-sky-300"
              v-model="plaque"
              v-on:keyup.enter="getPlaque()"
            />
            <i class="plate-flag1 fx-center-center wlf wlf-plate-FR"></i>
          </div>
        </div>
        <div class="relative -ml-6 z-10 hidden sm:block">
          <router-link to="/historiqueplaque">
            <div class="flex">
              <span
                style="margin-left: -1px"
                class="material-icons-outlined hover:bg-blue-400 hover:bg-opacity-25 text-white rounded-full cursor-pointer"
              >
                history
              </span>
            </div>
          </router-link>
        </div>

        <!-- info vehicule -->
        <div class="relative">
          <PulseLoader color="#2d74da" v-show="isLoading" />
        </div>
        <div
          class="flex text-black text-xs border border-sky-400 rounded-md w-fit mx-2 hidden md:flex"
          v-if="vehiculeSet"
        >
          <span
            class="text-left m-auto material-icons-round text-sky-500 cursor-pointer"
            style="font-size: 15px"
            @click="clearvehicule()"
          >
            highlight_off
          </span>
          <div class="flex flex-col px-1">
            <div class="flex space-x-2">
              <span class="hidden lg:block"
                >{{ vehicule[0].data.AWN_marque }}
              </span>
              <span class="">{{ vehicule[0].data.AWN_modele }} </span>
            </div>
            <div class="flex space-x-2 hidden xl:block">
              <span class="">{{ vehicule[0].data.AWN_version }} </span>
              <span class=""
                >{{ vehicule[0].data.AWN_date_mise_en_circulation }}
              </span>
            </div>
          </div>
          <div class="text-right z-10">
            <span
              class="text-right material-icons-round text-sky-500 cursor-pointer"
              style="font-size: 20px"
              @click="openInfoVehicule = true"
            >
              info
            </span>
          </div>
        </div>
        <div
          class="flex text-black text-xs border border-sky-400 rounded-md w-fit mx-2 hidden md:flex"
          v-else-if="vehiculeTD"
        >
          <span
            class="text-left m-auto material-icons-round text-sky-500 cursor-pointer"
            style="font-size: 15px"
            @click="clearvehicule()"
          >
            highlight_off
          </span>
          <div class="flex flex-col px-1" v-if="vehiculeTDData">
            <div class="flex space-x-2">
              <span class="hidden lg:block">{{ vehiculeTDData.marque }} </span>
              <span class="">{{ vehiculeTDData.modele }} </span>
            </div>
            <div class="flex space-x-2 hidden xl:block">
              <span class="">{{ vehiculeTDData.version }} </span>
              <span class="">{{ vehiculeTDData.date }} </span>
            </div>
          </div>
          <div class="text-right z-10">
            <span
              class="text-right material-icons-round text-sky-500 cursor-pointer"
              style="font-size: 20px"
              @click="openInfoVehicule = true"
            >
              info
            </span>
          </div>
        </div>
        <!-- <div class="mx-6 relative">
          <router-link to="/historiqueplaque">
            <div class="flex flex-col">
              <span
                class="material-icons-outlined hover:bg-blue-400 hover:bg-opacity-25 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
              >
                history
              </span>
              <span style="font-size: 9px"> historique plaque </span>
            </div>
          </router-link>
        </div> -->
        <div class="hidden 2xl:block relative ml-4">
          <router-link to="/catalogueHubNew" v-if="vehiculeSet">
            <div class="flex flex-col">
              <span
                class="material-icons-outlined hover:bg-blue-400 hover:bg-opacity-25 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
              >
                auto_stories
              </span>
              <span class="mt-0.5 m-auto" style="font-size: 9px">
                catalogue
              </span>
            </div>
          </router-link>
          <router-link to="/tecdocsearch" v-else>
            <div class="flex flex-col">
              <span
                class="material-icons-outlined hover:bg-blue-400 hover:bg-opacity-25 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
              >
                auto_stories
              </span>
              <span class="mt-0.5 m-auto" style="font-size: 9px">
                catalogue
              </span>
            </div>
          </router-link>
        </div>
        <div class="relative ml-4" v-if="carcatToken.access_token != ''">
          <div class="flex">
            <div class="flex flex-col">
              <span
                class="material-icons-outlined p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
                @click="openCarCat()"
              >
                <img alt="" src="../assets/CarCat.png" />
              </span>
              <div
                @click="closeCarCat()"
                style="font-size: 9px"
                class="bg-sky-500 px-1 mt-0.5 cursor-pointer hover:bg-sky-400 text-white rounded-full"
              >
                CarCat
              </div>
            </div>
          </div>
        </div>

        <div class="relative ml-4" v-if="carcatToken.access_token == ''">
          <a
            class="m-auto"
            href="https://lkq-carsys-fr-production.auth.eu-central-1.amazoncognito.com/login?client_id=1q5491gklchf6dil2njt62d77f&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://distrib.vekteur.fr"
          >
            <span
              class="material-icons-outlined p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
            >
              login
            </span>
            <div style="font-size: 9px">CarCat</div>
          </a>
          <!-- <div
            v-show="showBackCarcat"
            class="absolute w-screen h-screen left-0 z-0 bg-gray-800 bg-opacity-25"
            style="left: -410px; top: -18px"
          ></div>
          <div class="relative flex justify-center -mt-2.5">
            <div class="flex flex-col">
              <menuCarcat @click="showBackCarcat = !showBackCarcat" />
              <span class="m-auto mt-8" style="font-size: 9px">CarCat</span>
            </div>
          </div> -->
        </div>

        <!-- <div
          class="flex flex-col relative ml-6 -mt-4"
          style="width: 30px; height: 30px"
          v-else
        >
          <a
            class="m-auto"
            href="https://lkq-carsys-fr-production.auth.eu-central-1.amazoncognito.com/login?client_id=1q5491gklchf6dil2njt62d77f&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://distrib.vekteur.fr"
          >
            <img src="@/assets/CarCat.png" alt="" />
          </a>
          <span class="m-auto mt-0.5" style="font-size: 9px"> CarCat </span>
        </div> -->

        <div class="hidden 2xl:block flex ml-6 relative z-10">
          <div class="flex flex-col" @click="$router.push('/cataloguedepneus')">
            <img
              src="../assets/tire_icon.png"
              class="hover:bg-blue-400 hover:bg-opacity-25 bg-white p-1.5 w-8 h-8 m-auto border rounded-full text-blue-500 cursor-pointer"
            />
            <span class="m-auto" style="font-size: 9px"> pneus </span>
          </div>
        </div>
        <div class="hidden 2xl:block flex ml-6 relative z-10">
          <div class="flex flex-col" @click="$router.push('/PromoMosaique')">
            <span
              class="material-icons-round hover:bg-blue-400 hover:bg-opacity-25 bg-white p-1 w-8 h-8 m-auto border rounded-full text-blue-500 cursor-pointer"
            >
              percent
            </span>
            <span class="m-auto" style="font-size: 9px"> Promos </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center">
      <div
        class="flex flex-col items-center mr-8 px-2 py-1 shadow rounded border hidden sm:block text-xs sm:text-xs lg:text-base"
      >
        <div class="flex flex-row items-center space-x-2">
          <div>
            <input
              type="text"
              v-model="search_brand"
              @focus="showAllBrands"
              @input="checkBrandTop(), (show_search_brand = true)"
              placeholder="Marque"
              class="sm:w-36 px-2 py-1 focus:outline-2 outline-sky-300"
            />
            <div
              v-if="
                lineAddArticle_marque_List.length > 0 &&
                show_search_brand &&
                search_brand != ''
              "
              class="absolute z-20 bg-white border border-gray-300 max-h-40 overflow-y-auto"
            >
              <ul>
                <li
                  v-for="(brand, indexBrand) in lineAddArticle_marque_List"
                  :key="indexBrand"
                  @click="(search_brand = brand), (show_search_brand = false)"
                  class="cursor-pointer hover:bg-sky-100 px-4 py-1"
                >
                  {{ brand }}
                </li>
                <li
                  class="cursor-pointer bg-gray-400 hover:bg-sky-100 px-4 py-1"
                  @click="show_search_brand = false"
                >
                  Fermer
                </li>
              </ul>
            </div>
          </div>

          <input
            type="text"
            v-model="searchInput"
            placeholder="Rechercher un article"
            class="sm:w-36 2xl:w-full px-2 py-1 focus:outline-2 outline-sky-300"
            @keyup.enter="
              searchRefFournisseur({
                Ref_fournisseur: searchInput,
                Code_marque: search_brand,
              })
            "
          />
          <span
            class="material-icons-outlined cursor-pointer text-xs lg:text-base xl:text-xl hover:bg-orange-500 hover:text-white hover:rounded"
            @click="
              searchRefFournisseur({
                Ref_fournisseur: searchInput,
                Code_marque: search_brand,
              })
            "
          >
            search
          </span>
          <span
            class="material-icons-round cursor-pointer text-xs hover:bg-blue-500 hover:text-white hover:rounded lg:text-base xl:text-xl"
            @click="
              searchRefFournisseurDetail({ Ref_fournisseur: searchInput })
            "
          >
            manage_search
          </span>
        </div>
      </div>

      <!-- panier -->
      <div class="flex items-center hidden md:block">
        <div class="relative">
          <button
            class="ml-4 mr-6 relative z-10 block w-8 h-8 focus:outline-none"
          >
            <div class="flex flex-col">
              <span
                @click="documentOpenPanier = !documentOpenPanier"
                class="material-icons-round hover:bg-blue-400 hover:bg-opacity-25 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
              >
                shopping_cart
              </span>
              <span
                class="bg-cyan-600 px-1 cursor-pointer hover:bg-cyan-500 text-white rounded-full"
                @click="getPanier()"
                style="font-size: 9px"
              >
                panier
              </span>
            </div>
          </button>

          <div
            style="margin-left: 42px; font-size: 10px; margin-top: -35px"
            class="absolute z-10 border rounded-full w-5 h-5 bg-red-700 text-white"
          >
            <span class="m-auto px-1.5">{{ articleTotal }}</span>
          </div>

          <!-- <div class="relative">
            <span
              @click=" getPanier(), (documentOpenPanier = false)"
              class="material-icons-outlined absolute -mt-4 ml-12 p-0.5 w-6 rounded-full text-orange-500 cursor-pointer hover:animate-bounce"
            >
              shopping_cart_checkout
            </span>
          </div> -->

          <div
            v-show="documentOpenPanier"
            @click="documentOpenPanier = false"
            class="fixed inset-0 h-full w-full z-10"
          ></div>

          <div
            v-show="documentOpenPanier"
            class="absolute right-0 mt-4 w-80 bg-white rounded-lg shadow-xl overflow-hidden z-10"
            style="width: 20rem; max-height: 32rem"
          >
            <router-link to="/actionclient" @click="documentOpenPanier = false">
              <div
                class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
                v-for="(article, index) in actionArticle"
                :key="index"
                @click="documentOpenPanier = false"
              >
                <span class="material-icons-round"> article </span>
                <p class="text-sm mx-2">
                  <span class="font-bold" href="#">{{
                    article.Code_marque
                  }}</span>
                  :
                  <span class="font-bold text-blue-400" href="#"
                    >N° {{ article.Ref_fournisseur }}</span
                  >
                  : {{ parseFloat(article.Prix_euro).toFixed(2) }} €
                </p>
              </div>
              <div
                class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
              >
                <span class="material-icons-round"> local_offer </span>
                <p class="text-sm mx-2">
                  <span class="font-bold" href="#">Total TTC : </span>
                  <span class="font-bold text-blue-400" href="#">
                    {{ parseFloat(setTotalTTC).toFixed(2) }} €</span
                  >
                </p>
              </div>
            </router-link>
            <div class="grid grid-cols-2">
              <div
                class="flex w-28 cursor-pointer items-center my-1 px-2 py-2 text-gray-600 border rounded-md hover:text-white hover:bg-blue-500 hover:opacity-40 m-auto"
                @click="clearPanier(), (documentOpenPanier = false)"
              >
                <span class="material-icons-outlined" style="color: red"
                  >delete_forever</span
                >
                <button class="text-xs">
                  <span class="font-bold" href="#">Vider le panier</span>
                </button>
              </div>
              <router-link
                to="/actionclient"
                @click="documentOpenPanier = false"
              >
                <div
                  class="flex w-28 cursor-pointer items-center my-1 px-2 py-2 text-gray-600 border rounded-md hover:text-white hover:bg-blue-500 hover:opacity-40 m-auto"
                >
                  <button class="text-xs">
                    <span class="font-bold" href="#">Passer la commande</span>
                  </button>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <!-- <div class="relative">
          <button
            @click="documentOpen = !documentOpen"
            class="flex mx-4 text-gray-600 focus:outline-none"
          >
            <span class="material-icons-round">article</span>
          </button>

          <div
            v-show="documentOpen"
            @click="documentOpen = false"
            class="fixed inset-0 h-full w-full z-10"
          ></div>

          <div
            v-show="documentOpen"
            class="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl overflow-hidden z-10"
            style="width: 20rem"
          >
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">verified</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 1</span> Pièce reçu
                <span class="font-bold text-blue-400" href="#"
                  >N° 1276523H</span
                >
                . 2m
              </p>
            </a>
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">notifications_active</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 6</span> A signé le
                devis . 45m
              </p>
            </a>
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">error</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 3</span> Prépa prête
                <span class="font-bold text-blue-400" href="#"
                  >N° 238723D</span
                >
                . 1h
              </p>
            </a>
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">notifications_active</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 2</span>
                Prépa lancé . 3h
              </p>
            </a>
          </div>
        </div> -->

        <!-- notification -->
        <!-- <div class="relative">
          <button
            @click="notificationOpen = !notificationOpen"
            class="mx-6 relative z-10 block w-8 h-8 focus:outline-none m-auto"
          >
            <div class="flex flex-col">
              <span
                class="material-icons-round hover:bg-blue-400 hover:bg-opacity-25 ml-1 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
              >
                notifications
              </span>
              <span style="font-size: 9px"> notification </span>
            </div>
          </button>

          <div
            v-show="notificationOpen"
            @click="notificationOpen = false"
            class="fixed inset-0 h-full w-full z-10"
          ></div>

          <div
            v-show="notificationOpen"
            class="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl overflow-hidden z-10"
            style="width: 20rem"
          >
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">verified</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 1</span> Pièce reçu
                <span class="font-bold text-blue-400" href="#"
                  >N° 1276523H</span
                >
                . 2m
              </p>
            </a>
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">notifications_active</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 6</span> A signé le
                devis . 45m
              </p>
            </a>
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">error</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 3</span> Prépa prête
                <span class="font-bold text-blue-400" href="#">N° 238723D</span>
                . 1h
              </p>
            </a>
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">notifications_active</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 2</span>
                Prépa lancé . 3h
              </p>
            </a>
          </div> 
        </div>-->
      </div>

      <!-- compte -->
      <div class="relative mr-4">
        <button
          @click="dropdownOpen = !dropdownOpen"
          class="ml-4 relative z-10 block w-8 h-8 focus:outline-none"
        >
          <div class="flex flex-col m-auto items-center">
            <span
              class="material-icons-round hover:bg-blue-400 hover:bg-opacity-25 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
            >
              person
            </span>
            <span style="font-size: 9px"> {{ user.username }} </span>
          </div>
        </button>

        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 z-10 w-full h-full"
        ></div>

        <transition
          enter-active-class="transition duration-150 ease-out transform"
          enter-from-class="scale-95 opacity-0"
          enter-to-class="scale-100 opacity-100"
          leave-active-class="transition duration-150 ease-in transform"
          leave-from-class="scale-100 opacity-100"
          leave-to-class="scale-95 opacity-0"
        >
          <div
            v-show="dropdownOpen"
            class="absolute right-0 z-20 w-48 py-1 mt-2 bg-white rounded-lg shadow-xl"
          >
            <a
              href="#"
              @click="getProfil()"
              class="px-4 py-2 flex rounded-md text-sm text-gray-700 hover:bg-blue-500 hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              Profil
            </a>
            <div
              class="flex px-4 py-2 rounded-md text-sm text-gray-700 hover:bg-blue-500 hover:text-white"
              style="cursor: pointer"
              @click="deconnexion()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              Déconnexion
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import { mapActions, mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import Sidenav from "../components/Sidenav.vue";
import sessionActive from "../components/BarSession/sessionActive.vue";
import infosVehicule from "@/components/vehicule/infosVehicule.vue";

export default {
  name: "MyHeader",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openModalSearch: false,
      openModalSearchVanHeck: false,
      dropdownOpen: false,
      notificationOpen: false,
      documentOpen: false,
      isLoading: false,
      plaque: "",
      copyMessage: null,
      documentOpenPanier: false,
      errorImmat: false,
      searchInput: "",
      openCarCatModal: false,
      index: 0,
      showIconDispoA: [],
      showIconDispoB: [],
      showIconFiliale1A: [],
      showIconFiliale1B: [],
      showIconFiliale2A: [],
      showIconFiliale2B: [],
      showIconCmdA: [],
      showIconCmdB: [],
      tabRefVH: [],
      showCatRef: true,
      autoCompleteTab: [],
      showAutoComplete: false,
      showBackCarcat: false,
      indexRef: 0,
      refSim: "",
      openModalArticle: false,
      articleInfo_componentKey: 0,
      openInfoVehicule: false,

      activeClient: "pro", // par défaut, affiche les sessions du client pro
      sessions: {
        pro: [
          { id: 1, nom: "Session 1 (Pro)" },
          { id: 2, nom: "Session 2 (Pro)" },
          // Ajoutez d'autres sessions professionnelles au besoin
        ],
        particulier: [
          { id: 1, nom: "Session 1 (Particulier)" },
          { id: 2, nom: "Session 2 (Particulier)" },
          // Ajoutez d'autres sessions particulières au besoin
        ],
      },
      activeSession: null,
      openMenu: false,

      search_brand: "",
      show_search_brand: false,
      originalBrandList: [],
      lineAddArticle_marque_List: [],
    };
  },
  components: {
    infosVehicule,
    PulseLoader,
    Sidenav,
    sessionActive,
  },
  computed: {
    ...mapGetters([
      "vehicule",
      "actionArticle",
      "crossoetab",
      "generalloading",
      "prixtab",
      "carcatToken",
      "carCatLink",
      "carCatReference",
      "vehicule",
      "vehiculeSet",
      "user",
      "clientFocus",
      "comptoirSession",
      "vehiculeTD",
      "vehiculeTDData",
      "comptoirSessionActive"
    ]),
    articleTotal() {
      return this.actionArticle.length;
    },
    setTotal() {
      var total = 0.0;
      this.actionArticle.forEach((element) => {
        total = total + parseFloat(element.Prix_euro);
      });
      return total;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
  },
  methods: {
    ...mapActions([
      "deconnecter",
      "addvehicule",
      "clearvehicule",
      "addactionArticle",
      "suppactionArticle",
      "clearactionArticle",
      "searchCross",
      "searchDirect",
      "getPrix",
      "searchTranslatedDirect",
      "searchTranslatedDirectAdd",
      "storecarcattoken",
      "clearcarcattoken",
      "addpaniercarcat",
      "setcarcatlinks",
      "setArticleInfo",
      "getallcomptoirsession",
      "searchRefFournisseur",
      "storerefreshcarcattoken",
      "searchRefFournisseurDetail",
      "getplateform",
      "savecomptoirsession",
    ]),
    switchClient(clientType) {
      this.activeClient = clientType;
      this.activeSession = null; // Réinitialiser la session active lors du changement de client
    },
    deconnexion() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          console.log("deconnexion success");
          this.clearcarcattoken();
          this.deconnecter();
          this.$router.push("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async closeCarCat() {
      await axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/retrieve-orders`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier: this.carCatReference,
            PerPage: 1000,
            Page: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          this.addpaniercarcat({
            lines: result.data.Data.Workorders[0].Lines,
          });
        })
        .catch((error) => {
          console.log(error);
          this.clearcarcattoken();
        });
    },
    getPanier() {
      this.$router.push("/actionclient");
    },
    clearPanier() {
      this.clearactionArticle();
    },
    getProfil() {
      this.$router.push("/profil");
    },
    getPlaque() {
      this.clearvehicule();
      this.isLoading = true;
      // this.vehiculeSet = true;
      console.log(this.plaque);
      axios
        .post(`${process.env.VUE_APP_API}/plaque`, {
          plaque: this.plaque.toUpperCase(),
          Owner: this.user.proprietaire,
        })
        .then((response) => {
          // this.addvehicule(response.data);
          console.log(response);
          if (response.data.error) {
            this.toast.error("La plaque n'existe pas !", {
              position: "bottom-right",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else {
            this.addvehicule(response.data);
            // this.savecomptoirsession({
            //   clientFocus: this.comptoirSessionActive[0]?.Client,
            //   refClient:
            //     this.comptoirSessionActive[0]?.refClient != ""
            //       ? this.comptoirSessionActive[0]?.refClient +
            //         " / " +
            //         this.plaque
            //       : this.plaque,
            //   consigneData: this.comptoirSessionActive[0]?.consigneData,
            //   consigneNonData: this.comptoirSessionActive[0]?.consigneNonData,
            //   dsvCheck: this.comptoirSessionActive[0]?.dsvCheck,
            // });
            // this.$router.push("/catalogueHubNew");
            if (this.$route.path != "/ControlePreliminaire") {
              this.$router.push("/catalogueHub");
            }
            this.plaque = "";
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async initCarCatLogin() {
      await this.$router.isReady();
      if (this.$route.query.code) {
        // axios
        //   .get(
        //     `${process.env.VUE_APP_API}/carcat/getToken/${this.$route.query.code}`
        //   )
        //   .then((res) => {
        //     console.log(res.data);
        //   });
        await axios
          .post(
            `https://lkq-carsys-fr-production.auth.eu-central-1.amazoncognito.com/oauth2/token`,
            `grant_type=authorization_code&code=${this.$route.query.code}&client_id=1q5491gklchf6dil2njt62d77f&redirect_uri=https://distrib.vekteur.fr`,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Basic ${btoa(
                  "1q5491gklchf6dil2njt62d77f:k1m3ri382088l9qo9s04mpadn7if35vqmea0k3hreauj4psa4p2"
                )}`,
              },
            }
          )
          .then((result) => {
            this.storecarcattoken(result.data);
          })
          .catch((error) => {
            console.log(error);
            this.clearcarcattoken();
          });

        // this.$router.push("/carcat");
        this.openCarCat();
      }
    },
    openCarCat() {
      axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/v2/ticket`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier:
              "referenceVekteur" + Math.floor(Math.random() * 1000000000),
            // OrderCreationBehaviour: "force_new_order",
            ShowNavigation: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          this.setcarcatlinks({
            link: result.data.Data.TicketURL,
            ref: result.data.Data.ReferenceIdentifier,
          });
          // this.openCarCatModal = true;
          this.$router.push("/carcat");
        })
        .catch((error) => {
          this.refreshCarCatToken();
        });
    },
    async refreshCarCatToken() {
      await axios
        .post(
          `https://lkq-carsys-fr-production.auth.eu-central-1.amazoncognito.com/oauth2/token`,
          `grant_type=refresh_token&refresh_token=${this.carcatToken.refresh_token}&client_id=1q5491gklchf6dil2njt62d77f&redirect_uri=https://distrib.vekteur.fr`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Basic ${btoa(
                "1q5491gklchf6dil2njt62d77f:k1m3ri382088l9qo9s04mpadn7if35vqmea0k3hreauj4psa4p2"
              )}`,
            },
          }
        )
        .then((result) => {
          this.storerefreshcarcattoken(result.data);
          this.openCarCat();
        })
        .catch((error) => {
          console.log(error);
          this.clearcarcattoken();
        });
    },
    async getPluginArticles() {
      await this.$router.isReady();
      if (this.$route.query.codeArticle) {
        this.searchInput = this.$route.query.codeArticle;
        this.searchRefFournisseur({
          Ref_fournisseur: this.$route.query.codeArticle,
          Code_marque: "",
        });
      }
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },autoComplete(ref) {
      if (this.searchInput != "" && this.searchInput.length > 0) {
        axios
          .get(`${process.env.VUE_APP_API}/article/autocomplete/${ref}`)
          .then((response) => {
            this.showAutoComplete = true;
            this.autoCompleteTab = response.data;
          });
      } else {
        this.showAutoComplete = false;
      }
    },
    setAutoComplete(ref) {
      this.searchInput = ref;
      this.showAutoComplete = false;
      this.searchInput = "";
    },
    copyToClipboard(text) {
      if (!text) {
        this.showCopyMessage("Aucune plaque à copier n'a été trouvée !");
        return;
      }
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.showCopyMessage("La plaque a été copiée ✓");
        })
        .catch(() => {
          this.showCopyMessage("La plaque n'a pas pu être copiée !");
        });
    },
    updateToRefCl() {
      this.savecomptoirsession({
        clientFocus: this.comptoirSessionActive[0]?.Client,
        refClient:
          this.comptoirSessionActive[0]?.refClient != ""
            ? this.comptoirSessionActive[0]?.refClient + " / " + this.vehicule[0].data.AWN_immat
            : this.vehicule[0].data.AWN_immat,
        consigneData: this.comptoirSessionActive[0]?.consigneData,
        consigneNonData: this.comptoirSessionActive[0]?.consigneNonData,
        dsvCheck: this.comptoirSessionActive[0]?.dsvCheck,
      });
      // this.plaque = "";
    },
    showCopyMessage(message) {
      this.copyMessage = message;
      setTimeout(() => {
        this.copyMessage = null;
      }, 2000);
    },
    async fetchAllBrands() {
      const chkBrand = await axios.post(
        `${process.env.VUE_APP_API}/inventaire/checkBrand`,
        {
          plateform: this.user.proprietaire,
          brand: "",
        }
      );
      if (chkBrand.data) {
        this.originalBrandList = chkBrand.data
          .map((brand) => brand.mfrName)
          .sort((a, b) => {
            const startsWithNumberA = /^\d/.test(a);
            const startsWithNumberB = /^\d/.test(b);

            if (startsWithNumberA && !startsWithNumberB) return 1;
            if (!startsWithNumberA && startsWithNumberB) return -1;
            return a.localeCompare(b);
          });
        this.lineAddArticle_marque_List = this.originalBrandList;
      }
    },
    showAllBrands() {
      if (this.originalBrandList.length === 0) {
        this.fetchAllBrands();
      } else {
        this.lineAddArticle_marque_List = this.originalBrandList;
      }
    },
    async checkBrandTop() {
      const searchTerm = this.search_brand.toUpperCase();
      if (searchTerm !== "") {
        this.lineAddArticle_marque_List = this.originalBrandList.filter(
          (brand) => brand.toUpperCase().includes(searchTerm)
        );
      }
    },
  },
  mounted() {
    this.getplateform();
    this.initCarCatLogin();
    this.getPluginArticles();
  },
};
</script>
<style scoped>
.plate-immat2 {
  max-width: 260px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  --tw-border-opacity: 1;
  border: 1px solid rgb(45 116 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(45 116 218 / var(--tw-bg-opacity));
}
.plate-immat1 {
  max-width: 253px;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  --tw-border-opacity: 1;
  border: 1px solid rgb(45 116 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(45 116 218 / var(--tw-bg-opacity));
}
.plate-number {
  height: 100%;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgb(45 116 218 / var(--tw-border-opacity));
  padding-left: 0.5rem;
  padding-right: 1rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}
.plate-plaque-copy {
  width: 1rem;
  --tw-border-opacity: 1;
  border-color: rgb(45 116 218 / var(--tw-border-opacity));
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}
.plate-ref-copy {
  width: 1rem;
  --tw-border-opacity: 1;
  border-color: rgb(45 116 218 / var(--tw-border-opacity));
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}
.plate-flag2 {
  width: 3.5rem;
  text-align: center;
  font-size: 2rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.plate-flag1 {
  width: 2rem;
  text-align: center;
  font-size: 2rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
</style>
