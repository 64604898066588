<template>
  <titrePages class="flex flex-1 justify-center mb-8" :title="'Retours'" />
  <!-- modal détail cmd -->
  <div
    v-if="infoInRetour"
    :class="`modal ${
      !infoInRetour && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="infoInRetour = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 70rem"
      class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Récapitulatif du retour</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="infoInRetour = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-1/3 ml-2">
            Référence client:
            {{ detailRetour.Note }}
          </div>

          <div class="flex space-x-2">
            <div
              v-if="detailRetour.NumeroBR"
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="createPDFretour(detailRetour)"
              >
                download
              </span>
              <div>BR n° {{ detailRetour.NumeroBR }}</div>
            </div>
            <div
              v-if="detailRetour.numeroAvoir"
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="createPDF(detailRetour)"
              >
                download
              </span>
              <div>BL n° {{ detailRetour.numeroAvoir }}</div>
            </div>
          </div>
        </div>

        <table class="min-w-full mt-4">
          <div class="overflow-y-auto max-h-[25rem]">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="w-44 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="w-80 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody class="bg-white w-fit">
              <tr
                v-for="(rtr, index) in detailRetour.Articles"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="w-44 px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row items-center">
                    <span class="ml-4 text-sm leading-5 text-gray-900">
                      {{ rtr.Ref_fournisseur }}
                    </span>
                  </div>
                </td>

                <td
                  class="w-80 px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="text-sm leading-5 text-gray-500 overflow-y-auto">
                    {{ rtr.Description }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-sm leading-5">
                    {{ rtr.quantity }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(rtr.Prix_euro).toFixed(2) }}
                  €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div v-if="rtr.PrixFournisseurType != 'prixnet'">
                    {{ rtr.remise }} %
                  </div>
                  <div v-else>prix net</div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="rtr.PrixFournisseurType == 'prixnet'">
                    {{ parseFloat(rtr.Prix_vente).toFixed(2) }}
                    €
                  </span>
                  <span v-else>
                    {{
                      parseFloat(
                        rtr.Prix_euro - (rtr.Prix_euro * rtr.remise) / 100
                      ).toFixed(2)
                    }}
                    €
                  </span>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="rtr.PrixFournisseurType == 'prixnet'">
                    {{ parseFloat(rtr.Prix_vente * rtr.quantity).toFixed(2) }}
                    €
                  </span>
                  <span v-else>
                    {{
                      parseFloat(
                        (rtr.Prix_euro - (rtr.Prix_euro * rtr.remise) / 100) *
                          rtr.quantity
                      ).toFixed(2)
                    }}
                    €
                  </span>
                </td>
              </tr>
            </tbody>
          </div>
          <thead class="tableHEADTR mt-12 border-t">
            <tr class="tableHEADTR">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="text-sm leading-5 text-gray-900">
                  Total HT :
                  {{ parseFloat(detailRetour.Totalretour).toFixed(2) }} €
                </div>
                <div
                  v-if="detailRetour.TotalretourTTC"
                  class="text-sm leading-5 text-gray-500"
                >
                  Total TTC :
                  {{ parseFloat(detailRetour.TotalretourTTC).toFixed(2) }} €
                </div>
                <div v-else class="text-sm leading-5 text-gray-500">
                  Total TTC :
                  {{
                    parseFloat(
                      detailRetour.Totalretour + detailRetour.Totalretour * 0.2
                    ).toFixed(2)
                  }}
                  €
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
  <!-- fin modal détail cmd -->

  <!-- modal pour annuler ou reprendre -->
  <div
    v-if="openCancelRtr || openDesarchivedRtr"
    :class="`modal ${
      (!openCancelRtr && openDesarchivedRtr) ||
      (!openDesarchivedRtr && openCancelRtr)
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openCancelRtr = false), (openDesarchivedRtr = false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 50rem; max-width: 38rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div class="px-6 py-4 modal-content">
        <div class="flex justify-between pb-3">
          <div v-if="dataToChangeStatus">
            <p class="text-xl" v-if="openCancelRtr == true">
              Confirmation d'annulation du retour
            </p>
            <p class="text-xl" v-if="openDesarchivedRtr == true">
              Confirmation pour reprendre le retour
            </p>
          </div>
          <!-- <div
              class="z-50 cursor-pointer modal-close"
              @click="openCancelRtr = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div> -->
        </div>

        <div v-if="dataToChangeStatus">
          <div v-if="openCancelRtr == true">
            <h1>
              Êtes-vous sûr(e) de vouloir annuler définitivement le retour n°{{
                dataToChangeStatus.NumeroBR
              }}
              ?
            </h1>
            <p class="mt-2">Veuillez indiquer la raison de l'annulation :</p>
            <h2>
              <textarea
                rows="2"
                cols="40"
                class="w-full border border-2 px-1 py-0.5 focus:outline-2 outline-sky-300"
                placeholder="Ex. : Rupture de stock"
                v-model="dataToChangeStatus.cancellation.reason"
              ></textarea>
            </h2>
            <p class="text-xs" style="font-style: italic; color: #888">
              * Ce message sera visible par le client.
            </p>
          </div>
          <div v-if="openDesarchivedRtr == true">
            Êtes-vous sûr(e) de vouloir reprendre le retour n°{{
              dataToChangeStatus.NumeroBR
            }}
            ?
          </div>
        </div>

        <div class="flex justify-end pt-2">
          <button
            @click="(openCancelRtr = false), (openDesarchivedRtr = false)"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            v-if="openCancelRtr == true && openDesarchivedRtr == false"
            @click="
              changeStatusRetour(
                dataToChangeStatus,
                true,
                'Retour annulé',
                false,
                dataToChangeStatus.PourcentageStatus,
                'Retour annulé !',
                dataToChangeStatus.cancellation.reason
              )
            "
            :disabled="loadingToChangeStatus"
            class="p-3 px-6 py-3 mr-2 text-red-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-red-400 focus:outline-none"
          >
            Valider pour annuler
          </button>
          <button
            v-if="openCancelRtr == false && openDesarchivedRtr == true"
            @click="
              changeStatusRetour(
                dataToChangeStatus,
                false,
                dataToChangeStatus.status,
                true,
                dataToChangeStatus.PourcentageStatus,
                'Retour activé !',
                ''
              )
            "
            :disabled="loadingToChangeStatus"
            class="p-3 px-6 py-3 mr-2 text-red-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-red-400 focus:outline-none"
          >
            Valider pour reprendre
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal pour annuler ou reprendre -->

  <div class="flex flex-row justify-between p-1 rounded-md items-center">
    <div class="flex space-x-2">
      <!-- Actif -->
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-44 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'Actif'"
        @click="setRetourType()"
      >
        <span class="m-auto"> Actif </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          notifications
        </span>
      </button>
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-44 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="setRetourType()"
      >
        <span class="m-auto"> Actif </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          notifications
        </span>
      </button>

      <!-- Retour demandé -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'Retour demandé'"
        @click="getByStatus(1, 10, 'Retour demandé', 'Retour demandé')"
      >
        <span class="m-auto"> Retour demandé </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          question_mark
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'Retour demandé', 'Retour demandé')"
      >
        <span class="m-auto"> Retour demandé </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          question_mark
        </span>
      </button>

      <!-- En cours de traitement -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'En cours de traitement'"
        @click="
          getByStatus(1, 10, 'En cours de traitement', 'En cours de traitement')
        "
      >
        <span class="m-auto"> En cours de traitement </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          published_with_changes
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="
          getByStatus(1, 10, 'En cours de traitement', 'En cours de traitement')
        "
      >
        <span class="m-auto"> En cours de traitement </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          published_with_changes
        </span>
      </button>

      <!-- Retour accepté -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'Retour accepté'"
        @click="getByStatus(1, 10, 'Retour accepté', 'Retour accepté')"
      >
        <span class="m-auto"> Retour accepté </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          local_shipping
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'Retour accepté', 'Retour accepté')"
      >
        <span class="m-auto"> Retour accepté </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          local_shipping
        </span>
      </button>

      <!-- Retour validé -->
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-44 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'Retour validé'"
        @click="getByStatus(1, 10, 'Retour validé', 'Retour validé')"
      >
        <span class="m-auto"> Retour validé </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          done_all
        </span>
      </button>
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-44 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'Retour validé', 'Retour validé')"
      >
        <span class="m-auto"> Retour validé </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          done_all
        </span>
      </button>

      <!-- Retour annulé -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'Retour annulé'"
        @click="getByStatus(1, 10, 'Retour annulé', 'Retour annulé')"
      >
        <span class="m-auto"> Retour annulé </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          close
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'Retour annulé', 'Retour annulé')"
      >
        <span class="m-auto"> Retour annulé </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          close
        </span>
      </button>

      <!-- Retour archivé -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'Archive'"
        @click="getArchived()"
      >
        <span class="m-auto"> Retour archivé </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory_2
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-44 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getArchived()"
      >
        <span class="m-auto"> Retour archivé </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory_2
        </span>
      </button>
    </div>
  </div>

  <div>
    <div class="flex justify-between">
      <searchInput
        v-if="tabState == 'Actif'"
        :placeholder="'Rechercher dans ' + tabState"
        @searchWord="search(1, 10, false, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
        class="mt-2"
      />
      <searchInput
        v-else-if="tabState == 'Archive'"
        :placeholder="'Rechercher dans ' + tabState"
        @searchWord="search(1, 10, true, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
        class="mt-2"
      />
      <searchInput
        v-if="tabState != 'Actif' && tabState != 'Archive'"
        :placeholder="'Rechercher dans ' + tabState"
        @searchWord="searchByStatus(1, 10, tabState, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
        class="mt-2"
      />

      <div class="flex items-center space-x-3">
        <span class="text-blue-800">Pièces</span>
        <label class="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            v-model="stateTypeRetour"
            @change="setActifArchive()"
            class="sr-only peer"
          />
          <div
            class="w-11 h-6 bg-blue-700 peer-focus:outline-none text-left dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-800 peer-checked:bg-orange-500"
          ></div>
          <span class="ml-3 text-orange-500">Consignes</span>
        </label>
      </div>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Type de retour
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Client
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Progression
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  HT
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  TTC
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  BR
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  BL
                </th>
              </tr>
            </thead>

            <div
              v-show="loadingFile"
              class="flex justify-center w-full bg-white items-center space-x-2 mt-4"
            >
              <span>En cours de chargement</span>
              <PulseLoader color="#2d74da" />
            </div>

            <tbody class="bg-white" v-show="!loadingFile">
              <tr
                v-for="(u, index) in infosRetour"
                :key="index"
                class="cursor-pointer hover:bg-gray-100"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex justify-between items-center">
                    <div class="flex flex-col" @click="pushToDetail(u)">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ u.typeRetour }}
                      </div>
                    </div>
                    <div
                      v-if="u.Archived == false && u.status != 'Retour annulé'"
                    >
                      <button
                        class="material-icons-round text-red-600 items-center bg-gray-100 rounded-full p-1 shadow hover:bg-gray-200"
                        @click="openModalCancel(u)"
                      >
                        <span class="material-icons-rounded mt-1 m-auto">
                          highlight_off
                        </span>
                      </button>
                    </div>
                    <div
                      v-else-if="
                        u.Archived == true || u.status == 'Retour annulé'
                      "
                      title="Reprendre le retour"
                    >
                      <button
                        class="material-icons-round text-red-600 items-center bg-gray-100 rounded-full p-1 shadow hover:bg-gray-200"
                        @click="openModalDesarchived(u)"
                      >
                        <span class="material-icons-rounded mt-1 m-auto">
                          unarchive
                        </span>
                      </button>
                    </div>
                    <div
                      v-else
                      class="py-4 border-b border-gray-200 whitespace-nowrap"
                    ></div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                ></td>

                <td
                  @click="pushToDetail(u)"
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ getFRDate(u.Date) }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    @click="getDetailRetour(u)"
                    title="Plus d'informations"
                    class="material-icons-outlined text-md text-cyan-600 hover:text-cyan-700 cursor-pointer"
                  >
                    info
                  </span>
                </td>

                <td
                  @click="pushToDetail(u)"
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Client }}
                  <p v-if="u.remainder.value" class="font-bold">
                    Reliquat BR n°{{ u.remainder.number }}
                  </p>
                </td>

                <td
                  @click="pushToDetail(u)"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b text-center border-gray-200 whitespace-nowrap"
                >
                  <progressBar
                    v-if="u.status == 'Retour annulé'"
                    :pourcentageStatus="parseInt(u.PourcentageStatus)"
                    :isRed="true"
                  />
                  <progressBar
                    v-else
                    :pourcentageStatus="parseInt(u.PourcentageStatus)"
                    :isRed="false"
                  />
                  {{ u.status }}
                  <div
                    v-if="u.status == 'Retour annulé' && u.cancellation"
                    class="text-xs rounded-md bg-orange-400 text-white w-fit px-1 text-sm overflow-x-auto"
                    :title="u.cancellation.reason"
                  >
                    <h4>
                      Raison d'annulation:
                      {{
                        u.cancellation.reason.length > 11
                          ? u.cancellation.reason.substr(0, 11) + "..."
                          : u.cancellation.reason
                      }}
                    </h4>
                    <h4>Par: {{ u.cancellation.user }}</h4>
                  </div>
                </td>

                <td
                  @click="pushToDetail(u)"
                  class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  <span> {{ parseFloat(u.Totalretour).toFixed(2) }} € </span>
                </td>

                <td
                  v-if="u.TotalretourTTC"
                  @click="pushToDetail(u)"
                  class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalretourTTC).toFixed(2) }} €
                </td>
                <td
                  v-else
                  @click="pushToDetail(u)"
                  class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  {{
                    parseFloat(u.Totalretour + u.Totalretour * 0.2).toFixed(2)
                  }}
                  €
                </td>

                <td
                  v-if="u.NumeroBR"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <!-- <div
                    v-if="u.type == 'Facture'"
                    class="flex flex-col relative"
                  >
                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPdfFacture(u)"
                    >
                      download
                    </span>
                    <p>{{ u.type }} n° {{ u.NumeroBl }}</p>
                  </div>
                  <div v-if="u.type == 'BL'" class="flex flex-col relative">
                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPDF(u)"
                    >
                      download
                    </span>
                    <p>{{ u.type }} n° {{ u.NumeroBl }}</p>
                  </div> -->
                  <div>
                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPDFretour(u)"
                    >
                      download
                    </span>
                    <p class="mx-auto text-xs">BR {{ u.NumeroBR }}</p>
                  </div>
                </td>

                <td
                  v-else
                  @click="pushToDetail(u)"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                ></td>

                <td
                  v-if="u.numeroAvoir"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div>
                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPDF(u)"
                    >
                      download
                    </span>
                    <p class="mx-auto text-xs">BL {{ u.numeroAvoir }}</p>
                  </div>
                </td>

                <td
                  v-else
                  @click="pushToDetail(u)"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                ></td>
              </tr>
            </tbody>
          </table>

          <!-- Pagination actif -->
          <pageNumber
            v-if="tabState == 'Actif' && !searchActive && !stateTypeRetour"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosRetourLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosRetourLength / 10)"
            :clickFunction="getInfosRetour"
            :currentPage="currentPage"
            :typeList="'retours'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <!-- Pagination archive -->
          <pageNumber
            v-if="tabState == 'Archive' && !searchActive && !stateTypeRetour"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosRetourLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosRetourLength / 10)"
            :clickFunction="getInfosRetourArchived"
            :currentPage="currentPage"
            :typeList="'retours'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <!-- Pagination actif consigne -->
          <pageNumber
            v-if="tabState == 'Actif' && !searchActive && stateTypeRetour"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosRetourLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosRetourLength / 10)"
            :clickFunction="getInfosRetourConsigne"
            :currentPage="currentPage"
            :typeList="'retours'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <!-- Pagination archive consigne -->
          <pageNumber
            v-if="tabState == 'Archive' && !searchActive && stateTypeRetour"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosRetourLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosRetourLength / 10)"
            :clickFunction="getInfosRetourConsigneArchived"
            :currentPage="currentPage"
            :typeList="'retours'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <!-- Pagination filtre statut -->
          <pageNumber
            v-if="tabState != 'Actif' && tabState != 'Archive' && !searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="byStatusLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(byStatusLength / 10)"
            :clickFunction="(arg1, arg2) => getByStatus(arg1, arg2, tabState)"
            :currentPage="currentPage"
            :typeList="'retours'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <!-- Pagination filtre statut searchActive -->
          <pageNumber
            v-if="tabState != 'Actif' && tabState != 'Archive' && searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="
              (arg1, arg2) => searchByStatus(arg1, arg2, tabState, searchTerm)
            "
            :currentPage="currentPage"
            :typeList="'retours'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <!-- Pagination actif searchActive -->
          <pageNumber
            v-if="tabState == 'Actif' && searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="
              (arg1, arg2) => search(arg1, arg2, false, searchTerm)
            "
            :currentPage="currentPage"
            :typeList="'retours'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <!-- Pagination archive searchActive -->
          <pageNumber
            v-if="tabState == 'Archive' && searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="
              (arg1, arg2) => search(arg1, arg2, true, searchTerm)
            "
            :currentPage="currentPage"
            :typeList="'retours'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
import { createretour } from "@/hooks/pdf/retour.js";
import { createlivraison } from "@/hooks/pdf/livraison";
import { createfacture } from "@/hooks/pdf/facture";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ProgressBar from "@/components/Commandes/progressBar.vue";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyRetour",
  data: () => ({
    infosRetour: [],
    open: false,
    isProcessed: false,
    numRetour: 0,
    documentType: "Bon de livraison",
    stateTypeRetour: false,
    modalRetour: false,
    transportValue: 0,
    decoteValue: 0,
    retourSelected: [],
    commantaireAnnulation: "Votre retour a été refusé par le service retour",
    tabState: "Actif",
    loadingFile: false,
    openCancelRtr: false,
    openDesarchivedRtr: false,
    dataToChangeStatus: null,

    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    infosRetourLength: 0,
    byStatusLength: 0,
    currentPage: 1,

    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,

    detailRetour: [],
    infoInRetour: false,

    loadingToChangeStatus: false,
  }),
  components: {
    PulseLoader,
    ProgressBar,
    pageNumber,
    searchInput,
    titrePages,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["addDetailRetour"]),
    getFRDate(date) {
      return getFrDate(date);
    },
    getInfosRetour(skipValue, limitValue) {
      this.searchActive = false;
      this.tabState = "Actif";
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/retour/getByOwner/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.searchTerm = "";
          this.infosRetour = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourLength() {
      axios
        .post(`${process.env.VUE_APP_API}/retour/getByOwner/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.infosRetourLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourArchived(skipValue, limitValue) {
      this.searchActive = false;
      this.tabState = "Archive";
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/retour/getByOwner/getArchived`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.searchTerm = "";
          this.infosRetour = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourArchivedLength() {
      axios
        .post(`${process.env.VUE_APP_API}/retour/getByOwner/countArchived`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.infosRetourLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourConsigne(skipValue, limitValue) {
      this.searchActive = false;
      this.tabState = "Actif";
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/retourConsigne/getByOwner/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.searchTerm = "";
          this.infosRetour = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourConsigneLength() {
      axios
        .post(`${process.env.VUE_APP_API}/retourConsigne/getByOwner/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.infosRetourLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourConsigneArchived(skipValue, limitValue) {
      this.searchActive = false;
      this.tabState = "Archive";
      this.currentPage = skipValue;
      axios
        .post(
          `${process.env.VUE_APP_API}/retourConsigne/getByOwner/getArchived`,
          {
            plateform: this.user.proprietaire,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        )
        .then((response) => {
          this.searchTerm = "";
          this.infosRetour = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourConsigneArchivedLength() {
      axios
        .post(
          `${process.env.VUE_APP_API}/retourConsigne/getByOwner/countArchived`,
          {
            plateform: this.user.proprietaire,
          }
        )
        .then((response) => {
          this.infosRetourLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // By status
    getByStatus(skipValue, limitValue, tabState) {
      this.tabState = tabState;
      this.loadingFile = true;
      this.currentPage = skipValue;
      if (this.stateTypeRetour == false) {
        axios
          .post(`${process.env.VUE_APP_API}/retour/getByStatus/getPage`, {
            plateform: this.user.proprietaire,
            status: tabState,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchTerm = "";
            this.infosRetour = response.data;
            this.getByStatusLength(tabState);
            // this.tempInfosRetour = response.data;
            this.loadingFile = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axios
          .post(
            `${process.env.VUE_APP_API}/retourConsigne/getByStatus/getPage`,
            {
              plateform: this.user.proprietaire,
              status: tabState,
              skip: (skipValue - 1) * 10,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchTerm = "";
            this.infosRetour = response.data;
            this.getByStatusLength(tabState);
            // this.tempInfosRetour = response.data;
            this.loadingFile = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getByStatusLength(tabState) {
      if (this.stateTypeRetour == false) {
        axios
          .post(`${process.env.VUE_APP_API}/retour/getByStatus/count`, {
            plateform: this.user.proprietaire,
            status: tabState,
          })
          .then((response) => {
            this.byStatusLength = response.data;
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axios
          .post(`${process.env.VUE_APP_API}/retourConsigne/getByStatus/count`, {
            plateform: this.user.proprietaire,
            status: tabState,
          })
          .then((response) => {
            this.byStatusLength = response.data;
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // removeRetour(objet) {
    //   var today = new Date();
    //   var dd = String(today.getDate()).padStart(2, "0");
    //   var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    //   var yyyy = today.getFullYear();
    //   var hour = today.getHours();
    //   var seconds = today.getSeconds();
    //   var minutes = today.getMinutes();
    //   today =
    //     dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
    //   if (objet.typeRetour === "piece") {
    //     axios
    //       .put(`${process.env.VUE_APP_API}/retour/${objet._id}`, {
    //         status: "Retour annulé",
    //         commentaire: this.commantaireAnnulation,
    //         Date: today,
    //       })
    //       .then((response) => {
    //         this.toast.success("Retour annulé !", {
    //           position: "bottom-right",
    //           timeout: 1000,
    //           closeOnClick: true,
    //           pauseOnFocusLoss: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           draggablePercent: 1,
    //           showCloseButtonOnHover: false,
    //           hideProgressBar: true,
    //           closeButton: "button",
    //           icon: true,
    //           rtl: false,
    //         });
    //         this.getInfosRetour(this.currentPage, 10);
    //       });
    //   } else if (objet.typeRetour === "consigne") {
    //     axios
    //       .put(`${process.env.VUE_APP_API}/retourConsigne/${objet._id}`, {
    //         status: "Retour annulé",
    //         Date: today,
    //       })
    //       .then((response) => {
    //         this.toast.success("Retour annulé !", {
    //           position: "bottom-right",
    //           timeout: 1000,
    //           closeOnClick: true,
    //           pauseOnFocusLoss: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           draggablePercent: 1,
    //           showCloseButtonOnHover: false,
    //           hideProgressBar: true,
    //           closeButton: "button",
    //           icon: true,
    //           rtl: false,
    //         });
    //         this.getInfosRetourConsigne(this.currentPage, 10);
    //       });
    //   }
    // },
    getDetailRetour(u) {
      this.detailRetour = u;
      this.infoInRetour = true;
      console.log("detail retour", this.detailRetour);
    },
    // modal pour annuler
    openModalCancel(u) {
      console.log(this.openDesarchivedRtr, this.openCancelRtr);
      this.openCancelRtr = true;
      this.openDesarchivedRtr = false;
      console.log(this.openDesarchivedRtr, this.openCancelRtr);
      this.dataToChangeStatus = u;
      console.log(this.dataToChangeStatus);
    },
    // modal pour reprendre
    openModalDesarchived(u) {
      console.log(this.openDesarchivedRtr, this.openCancelRtr);
      this.openDesarchivedRtr = true;
      this.openCancelRtr = false;
      console.log(this.openDesarchivedRtr, this.openCancelRtr);
      this.dataToChangeStatus = u;
      console.log(this.dataToChangeStatus);
    },
    // changer le status
    changeStatusRetour(
      u,
      archived,
      status,
      changeStatus,
      percentage,
      info,
      reasonCancellation
    ) {
      this.loadingToChangeStatus = true;
      var statusFinal;
      var type;
      if (changeStatus) {
        if (percentage == 25) {
          statusFinal = "Retour demandé";
        } else if (percentage == 75) {
          statusFinal = "Retour accepté";
        } else if (percentage == 100) {
          statusFinal = "Retour validé";
        } else {
          percentage = 25;
          statusFinal = "Retour demandé";
        }
      } else {
        statusFinal = status;
      }
      if (u.typeRetour == "piece") {
        type = "retour";
      } else if (u.typeRetour == "consigne") {
        type = "retourConsigne";
      }
      axios
        .put(`${process.env.VUE_APP_API}/${type}/${u._id}`, {
          status: statusFinal,
          PourcentageStatus: percentage,
          Archived: archived,
          cancellation: {
            value: archived,
            reason: reasonCancellation,
            user: this.user.username,
          },
        })
        .then(() => {
          this.loadingToChangeStatus = false;
          this.openCancelRtr = false;
          this.openDesarchivedRtr = false;
          this.setActifArchive();
          this.toast.success(info, {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    createPdfFacture(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createfacture(
          {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: objet.Date,
            Numero: objet.NumeroBl,
            TotalPrice: objet.Totalretour,
            TotalPriceTTC: objet.Totalretour + objet.Totalretour * 0.2,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.requestedArticles,
            reglement: objet.reglement,
            pdfData: objet.requestedPdfData,
            clientInfo: objet.clientInfo,
            Transport: 0,
          },
          false,
          true
        );
      } else {
        createfacture(
          {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: objet.Date,
            Numero: objet.NumeroBl,
            TotalPrice: objet.Totalretour,
            TotalPriceTTC: objet.Totalretour + objet.Totalretour * 0.2,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.requestedArticles,
            reglement: objet.reglement,
            pdfData: objet.requestedPdfData,
            clientInfo: objet.clientInfo,
            Transport: 0,
          },
          true,
          true
        );
      }
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createlivraison(
          {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: objet.Date,
            Numero: objet.numeroAvoir,
            Archived: false,
            TotalPrice: objet.Totalretour,
            TotalPriceTTC: objet.Totalretour + objet.Totalretour * 0.2,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.requestedArticles,
            reglement: [],
            pdfData: objet.requestedPdfData,
            clientInfo: objet.clientInfo,
            Transport: objet.Transport,
          },
          false,
          true
        );
      } else {
        createlivraison(
          {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: objet.Date,
            Numero: objet.numeroAvoir,
            Archived: false,
            TotalPrice: objet.Totalretour,
            TotalPriceTTC: objet.Totalretour + objet.Totalretour * 0.2,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.requestedArticles,
            reglement: [],
            pdfData: objet.requestedPdfData,
            clientInfo: objet.clientInfo,
            Transport: objet.Transport,
          },
          true,
          true
        );
      }
    },
    createPDFretour(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.DateBR);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createretour(objet, objet.typeRetour, false);
      } else {
        createretour(objet, objet.typeRetour, true);
      }
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async setActifArchive() {
      if (this.tabState == "Actif") {
        await this.setRetourType();
      } else if (this.tabState == "Archive") {
        await this.getArchived();
      } else {
        await this.getByStatus(1, 10, this.tabState);
      }
    },
    async setRetourType() {
      if (this.stateTypeRetour) {
        await this.getInfosRetourConsigne(1, 10);
        await this.getInfosRetourConsigneLength();
      } else {
        await this.getInfosRetour(1, 10);
        await this.getInfosRetourLength();
      }
    },
    async getArchived() {
      if (this.stateTypeRetour) {
        await this.getInfosRetourConsigneArchived(1, 10);
        await this.getInfosRetourConsigneArchivedLength();
      } else {
        await this.getInfosRetourArchived(1, 10);
        await this.getInfosRetourArchivedLength();
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, tabstate, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        if (tabstate == false) {
          this.setRetourType();
        } else {
          this.getArchived();
        }
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        var typeRetour;
        if (this.stateTypeRetour) {
          typeRetour = "retourConsigne";
        } else {
          typeRetour = "retour";
        }
        axios
          .post(`${process.env.VUE_APP_API}/${typeRetour}/searchInPlateform`, {
            plateform: this.user.proprietaire,
            archive: tabstate,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            console.log(response.data);
            this.searchInProgress = false;
            this.infosRetour = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    searchByStatus(skipValue, limitValue, tabState, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getByStatus(skipValue, limitValue, tabState, tabState);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        var typeRetour;
        if (this.stateTypeRetour) {
          typeRetour = "retourConsigne";
        } else {
          typeRetour = "retour";
        }
        axios
          .post(`${process.env.VUE_APP_API}/${typeRetour}/searchByStatus`, {
            plateform: this.user.proprietaire,
            status: tabState,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            console.log(response.data);
            this.searchInProgress = false;
            this.infosRetour = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    pushToDetail(data) {
      console.log("1685");
      this.addDetailRetour(data);
      this.$router.push("./detailRetour");
    },
  },
  mounted() {
    this.getInfosRetour(1, 10);
    this.getInfosRetourLength();
    this.pageSwitch();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
