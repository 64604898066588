<template>
  <div class="flex justify-center">
    <titrePages :title="'Regroupement'" />
  </div>
  <div v-if="!showRegroupage" class="">
    <!-- Back Button -->

    <!-- <div class="flex justify-between">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/dashboard'" />
      </div>
    </div> -->

    <div
      class="flex justify-center items-center m-auto text-xl"
      style="height: 40rem"
    >
      <div class="flex space-x-12 bg-gray-100 rounded-md shadow my-2 p-6 w-fit">
        <div class="flex flex-col my-auto">
          <label for="type" class="text-sm">Type de document</label>
          <select
            v-model="type"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
          >
            <option
              v-for="(line, index) in tabType"
              :key="index"
              :value="line.value"
            >
              {{ line.select }}
            </option>
          </select>
        </div>

        <div class="flex flex-col space-y-2">
          <div class="flex flex-col">
            <label for="clientPar" class="text-sm">Client particulier</label>
            <div class="relative">
              <button
                class="absolute material-icons-round inset-y-0 left-0 px-2 py-1 rounded-l-md text-orange-500 hover:text-orange-600"
                @click="clientPar = null"
                title="Supprimer le client"
              >
                cancel
              </button>
              <input
                list="clientPar"
                v-model="clientPar"
                class="w-64 focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md pl-8"
                :disabled="clientPro !== null"
              />
              <datalist id="clientPar">
                <option
                  v-for="(cl, index) in clientsPar"
                  :key="index"
                  :value="cl._id"
                >
                  {{ cl.Name }}
                </option>
              </datalist>
            </div>
          </div>

          <div class="flex flex-col">
            <label for="clientPro" class="text-sm">Client professionnel</label>
            <div class="relative">
              <button
                class="absolute material-icons-round inset-y-0 left-0 px-2 py-1 rounded-l-md text-orange-500 hover:text-orange-600"
                @click="clientPro = null"
                title="Supprimer le client"
              >
                cancel
              </button>
              <input
                list="clientPro"
                v-model="clientPro"
                class="w-64 focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md pl-8"
                :disabled="clientPar !== null"
              />
              <datalist id="clientPro">
                <option
                  v-for="(cl, index) in clientsPro"
                  :key="index"
                  :value="cl._id"
                >
                  {{ cl.Name }}
                </option>
              </datalist>
            </div>
          </div>
        </div>

        <!-- <div class="flex flex-col my-auto">
            <label for="numero" class="text-sm">Numéro de document</label>
              <select
                v-model="numero"
                class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
              >
                <option
                  v-for="(line, index) in tabNum"
                  :key="index"
                  :value="line"
                >
                  {{ line }}
                </option>
              </select>
          </div> -->

        <PulseLoader color="#2d74da" class="my-auto" v-if="loading" />
        <button
          v-else
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="getDocs(type, clientPro, clientPar)"
        >
          <span class="material-icons-outlined text-xl mr-2"> forward </span>
          <span>Valider</span>
        </button>
      </div>
    </div>
  </div>

  <!-- Regroupement -->
  <div v-else class="">
    <div v-if="!showRegroupedDoc" class="flex justfiy-between">
      <button
        class="bg-bluevk hover:bg-bluevkdark text-white font-bold px-4 rounded"
        title="Cliquer pour revenir en arrière"
        @click="
          (showRegroupage = false),
            (selectAll = false),
            (selectedDoc = []),
            (loadingRegrouper = false)
        "
      >
        <p class="my-auto">&lt; Retour</p>
      </button>

      <button
        v-if="loadingRegrouper"
        class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
      >
        <PulseLoader color="#ffffff" class="my-auto" />
      </button>
      <button
        v-else
        class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
        @click="regrouper()"
      >
        <span class="material-icons-outlined text-xl mr-2"> done_all </span>
        <p>Regrouper</p>
      </button>
    </div>

    <div v-else class="flex justfiy-between">
      <button
        class="bg-bluevk hover:bg-bluevkdark text-white font-bold px-4 rounded"
        title="Cliquer pour revenir en arrière"
        @click="(showRegroupage = false), (showRegroupedDoc = false)"
      >
        <p class="my-auto">&lt; Retour</p>
      </button>
      <button
        class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
        @click="printGroupedPDF()"
      >
        <span class="material-icons-outlined text-xl mr-2"> print </span>
        <p>Imprimer</p>
      </button>
    </div>

    <div v-if="!showRegroupedDoc" class="flex justify-center text-xl mt-4">
      <div class="overflow-y-auto max-h-[55rem] w-full">
        <caption class="flex justify-center font-medium text-2xl my-1">
          {{
            type
          }}
        </caption>
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                <div class="flex space-x-0.5">
                  <input
                    v-if="!loadingSelection"
                    type="checkbox"
                    class="focus:outline-2 outline-sky-300 w-8"
                    v-model="selectAll"
                    @change="selectAllDoc"
                  />
                  <PulseLoader v-else color="#2d74da" />
                  <span>Sélect.</span>
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Note
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Date
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                N°
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Utilisateur / Client
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Total Prix
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                PDF
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(u, index) in tabDocument"
              :key="index"
              class="hover:bg-gray-100"
            >
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                style="width: 1em"
              >
                <input
                  type="checkbox"
                  class="focus:outline-2 outline-sky-300 w-8"
                  :value="u"
                  v-model="selectedDoc"
                  :checked="selectAll"
                  @click="selectAll = false"
                />
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ u.Note }}
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ getFRDate(u.Date) }}
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm font-medium leading-5 text-gray-900">
                  {{ u.Numero }}
                </div>
              </td>

              <td
                class="pl-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                <span
                  class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                  >{{ u.Name }}</span
                >
                <div class="text-sm leading-5 text-gray-900">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u.Client }}</span
                  >
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap font-medium"
              >
                {{ parseFloat(u.TotalPrice).toFixed(2) }} €
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
              >
                <button
                  class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                  style="color: blue"
                  @click="openPDF(u)"
                >
                  download
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-else>
      <table class="min-w-full">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Note
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Date
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              N°
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Utilisateur / Client
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Total Prix
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              PDF
            </th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr
            v-for="(u, index) in tabGrouped"
            :key="index"
            class="hover:bg-gray-100"
          >
            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="text-sm leading-5 text-gray-900">
                {{ u.Note }}
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="text-sm leading-5 text-gray-900">
                {{ getFRDate(u.Date) }}
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="text-sm font-medium leading-5 text-gray-900">
                {{ u.Numero }}
              </div>
            </td>

            <td
              class="pl-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
            >
              <span
                class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                >{{ u.Name }}</span
              >
              <div class="text-sm leading-5 text-gray-900">
                <span
                  class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                  >{{ u.Client }}</span
                >
              </div>
            </td>

            <td
              class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap font-medium"
            >
              {{ parseFloat(u.TotalPrice).toFixed(2) }} €
            </td>

            <td
              class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
            >
              <button
                class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                style="color: blue"
                @click="openGroupedPDF(u)"
              >
                download
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { getClientPart, getClientPro } from "@/hooks/clients/clients.ts";
import {
  getDocumentByClient,
  groupedDocByClient,
} from "@/hooks/regroupeDoc/regroupeDoc.ts";
import { createdevis } from "@/hooks/pdf/devis.js";
import { createcommande } from "@/hooks/pdf/commande.js";
// import {
//   createlivraison,
//   createlivraisonDuplicata,
// } from "@/hooks/pdf/livraison.js";
import { printGroupedDoc, createGroupedDoc } from "@/hooks/pdf/groupedDoc.js";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
// import backButton from "@/components/Button/back.vue";

import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  name: "MyRegroupement",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showRegroupage: false,
      loading: false,

      type: "devis",
      clientPro: null,
      clientPar: null,
      //   numero: null,

      tabType: [
        { value: "devis", select: "Devis" },
        { value: "commandes", select: "Bon de commande" },
        //   { value: "bdl", select: "Bon de livraison" },
      ],
      clientsPro: [],
      clientsPar: [],
      //   tabNum: [],

      tabDocument: [],
      loadingSelection: false,
      selectAll: false,
      selectedDoc: [],

      loadingRegrouper: false,
      showRegroupedDoc: false,
      tabGrouped: [],
    };
  },
  components: {
    // pageNumber,
    // searchInput,
    PulseLoader,
    titrePages,
    // backButton,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    async getClientsPar() {
      this.clientsPar = await getClientPart(this.user.proprietaire);
    },
    async getClientsPro() {
      this.clientsPro = await getClientPro(this.user.proprietaire);
    },
    // Recherche par type de doc et client
    async getDocs(type, clientPro, clientPar) {
      console.log(type, clientPro, clientPar);
      this.selectedDoc = [];
      if (clientPro != null || clientPar != null) {
        this.loading = true;
        var client;
        if (clientPro != null) {
          client = clientPro;
        } else {
          client = clientPar;
        }
        this.tabDocument = await getDocumentByClient(
          this.user.proprietaire,
          client,
          type
        );
        this.loading = false;
        console.log(this.tabDocument);
        if (this.tabDocument.length > 0) {
          this.showRegroupage = true;
        } else {
          this.error("Aucun " + type + " trouvé !");
        }
      } else {
        this.warning("Choisissez un client !");
      }
    },
    async selectAllDoc() {
      this.selectedDoc = [];
      this.loadingSelection = true;
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (this.selectAll) {
        this.selectedDoc = [...this.tabDocument];
      } else {
        this.selectedDoc = [];
      }
      this.loadingSelection = false;
    },
    openPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        if (this.type == "devis") {
          createdevis(objet, false);
        } else if (this.type == "commandes") {
          createcommande(objet, false);
        }
        // else {
        //   createlivraison(objet);
        // }
      } else {
        if (this.type == "devis") {
          createdevis(objet, true);
        } else if (this.type == "commandes") {
          createcommande(objet, true);
        }
        // else {
        //   createlivraisonDuplicata(objet);
        // }
      }
    },

    // Regroupement
    async regrouper() {
      if (this.selectedDoc.length > 1) {
        this.loadingRegrouper = true;
        this.tabGrouped = await groupedDocByClient(
          this.user.proprietaire,
          this.user.username,
          this.type,
          this.selectedDoc
        );
        console.log(this.tabGrouped);
        this.loadingRegrouper = false;
        this.showRegroupedDoc = true;
        this.success("Créé !");
      } else {
        this.warning("Sélectionnez au moins deux documents !");
      }
    },
    printGroupedPDF(objet) {
      var type;
      var type1;
      if (this.type == "devis") {
        type = "Devis";
        type1 = "Devis";
      } else if (this.type == "commandes") {
        type = "Bon de commande";
        type1 = "BC";
      }
      // else {
      //   type = "Bon de livraison";
      //   type1 = "BL";
      // }
      printGroupedDoc(objet, type, type1);
    },
    openGroupedPDF(objet) {
      var type;
      var type1;
      if (this.type == "devis") {
        type = "Devis";
        type1 = "Devis";
      } else if (this.type == "commandes") {
        type = "Bon de commande";
        type1 = "BC";
      }
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createGroupedDoc(objet, type, type1, false);
      } else {
        createGroupedDoc(objet, type, type1, true);
      }
    },

    // Message
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(msg) {
      this.toast.warning(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(msg) {
      this.toast.error(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getClientsPar();
    this.getClientsPro();
  },
};
</script>
