<template>
  <div class="flex justify-center font-bold">
    <h1>Tableau des logs envoyés par email</h1>
  </div>
  <div class="flex">
    <router-link to="/suiviDepot" class="flex w-fit">
      <div id="retour">
        <backButton />
      </div>
    </router-link>
    <!-- <div class="flex items-center space-x-2 ml-auto">
      Aligns buttons to the right
      <button
        class="w-32 p-1 py-2 text-white text-sm font-medium rounded-md shadow-lg"
        :class="typePage == 'stock' ? 'bg-orangevk' : 'bg-bluevk'"
        @click="(typePage = 'stock'), getCurrentPage(1, 10)"
      >
        Log envois
      </button>
    </div> -->
  </div>
  <!-- Table -->
  <div class="flex flex-col mt-10">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full border shadow">
          <thead class="bg-gray-100 text-gray-900">
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Date
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Utilisateur
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Description
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Fichier
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr v-for="(log, index) in logEmailTab" :key="index">
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ log.date }}
              </td>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ log.user }}
              </td>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ log.Description }}
              </td>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
  <a :href="log.infos.url" target="_blank">
    <button class="flex items-center bg-bluevk  text-white px-2 py-1 rounded shadow cursor-pointer">
      <span class="material-icons-outlined">open_in_new</span>
    </button>
  </a>
</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";

export default {
  data() {
    return {
      fournisseurTab: {},
      logEmailTab: [],
    };
  },
  components: {
    backButton,
  },
  computed: {
    ...mapGetters(["fournisseurID", "user"]),
  },
  methods: {
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
    getLogEmail() {
      axios
        .post(`${process.env.VUE_APP_API}/emailDaSilva/getLogsEmail`, {
          plateform: this.user.proprietaire,
          fournisseur: "DA SILVA",
          fournisseurID: this.fournisseurID,
        })
        .then((response) => {
          console.log(response);
          this.logEmailTab = response.data;
        });
    },
  },
  async mounted() {
    await this.getFournisseur();
    this.getLogEmail();
  },
};
</script>
