<template>
  <div
    v-if="openSave"
    :class="`modal ${
      !openSave && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openSave = false), $emit('closeModalEncaissement', false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 72rem; max-height: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span
          @click="(openSave = false), $emit('closeModalEncaissement', false)"
          class="text-sm"
          >(Esc)</span
        >
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Encaissement</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openSave = false), $emit('closeModalEncaissement', false)"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex space-x-4">
            <p class="text-[16px] font-bold text-black">Créée par:</p>
            <p class="text-[14px] font-semibold text-black">
              {{ createdBy }}
            </p>
          </div>

        <!--Body-->
        <div class="px-5 py-6 text-gray-700 bg-gray-50 shadow-md">
          <div class="relative">
            <div
              id="client_info_display"
              class="block px-2.5 pb-2.5 pt-4 w-[32rem] h-auto text-gray-900 bg-white rounded-lg border-2 border-sky-200 appearance-none shadow-md focus:outline-none focus:ring-0 focus:border-sky-300 resize-none"
            >
              <p class="text-lg font-semibold text-gray-900">
                {{ clientChoosed.Name }}
              </p>
              <p class="text-sm text-gray-800">
                {{
                  clientChoosed.NumeroClient
                    ? clientChoosed.NumeroClient + " - "
                    : ""
                }}
                {{ clientChoosed.Adresse ? clientChoosed.Adresse : "" }}
                {{
                  clientChoosed.Complement
                    ? ", " + clientChoosed.Complement
                    : ""
                }}
                {{
                  clientChoosed.PostalCode
                    ? ", " + clientChoosed.PostalCode
                    : ""
                }}
                <span class="text-base font-medium text-gray-900">
                  {{ clientChoosed.City ? ", " + clientChoosed.City : "" }}
                </span>
              </p>
            </div>
            <label
              for="client_info_display"
              class="ml-1 absolute text-base font-bold text-gray-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 px-2 peer-focus:px-2 peer-focus:text-blue-600"
              >Client</label
            >
          </div>
        </div>

        <div class="px-5 py-6 text-gray-700 bg-gray-50 shadow-md">
          <label class="text-lg font-bold">Mode de règlement</label>
          <div class="relative">
            <label class="text-[14px] text-black mt-6 font-medium"
              >Montant à payer : {{ financial(setTotalTTC) }} €</label
            >
          </div>

          <div class="relative flex justify-center space-x-8 p-1">
            <div class="" v-for="(moyen, ind) in moyenPaiement" :key="ind">
              <div class="flex flex-col">
                <img
                  class="w-[5rem] border-2 border-sky-300 rounded-md m-auto cursor-pointer hover:shadow hover:bg-gray-200 focus:outline-2 outline-sky-300 shadow-lg"
                  :src="moyen.image"
                  @click="setReglement(moyen.abbrev)"
                />
                <span style="font-size: 15px" class="m-auto mt-1 font-medium">{{
                  moyen.type
                }}</span>
              </div>
            </div>
          </div>

          <div
            class="relative mt-2 rounded-md shadow-sm"
            v-for="(reglement, index) in reglementTab"
            :key="index"
          >
            <span
              class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >
              <img
                class="w-9 h-9 rounded-md m-auto"
                :src="reglement.img"
              />
            </span>

            <input
              type="number"
              :placeholder="reglement.placeholder"
              :disabled="reglementTab[index].disabled"
              v-model="reglementTab[index].model"
              class="w-2/5 focus:outline-2 outline-sky-300 pl-16 pr-2 py-2 border-transparent rounded-md appearance-none"
            />
            <input
              @change="errorEncaissement = false"
              type="date"
              :disabled="reglementTab[index].disabled"
              v-model="reglementTab[index].date"
              :min="todayDate"
              class="ml-2 focus:outline-2 outline-sky-300 w-2/5 px-5 py-2 border-transparent rounded-md appearance-none"
            />
            <span
              v-if="!reglementTab[index].disabled"
              class="w-1/5 px-5 py-2 border-transparent rounded-md appearance-none"
            >
              <span
                class="mt-2 material-icons-outlined text-red-600 cursor-pointer"
                @click="removeReglement(index)"
              >
                clear
              </span>
            </span>
          </div>

          <div class="relative mt-3" v-show="errorPaymentMethod">
            <label class="text-xs font-medium text-red-500"
              >Cette méthode de paiement n'existe pas</label
            >
          </div>
          <div class="relative mt-3" v-show="errorEncaissement">
            <label class="text-xs font-medium text-red-500"
              >Veuillez encaisser la facture</label
            >
          </div>
          <div class="relative mt-3 mb-12">
            <label class="text-l font-medium"
              >Reste dû : {{ financial(setRestant) }} €</label
            >
            <label class="text-xs"> - </label>
            <label class="text-l font-medium"
              >A rendre : {{ financial(setRendu) }} €</label
            >
          </div>
          <div class="relative rounded-md ">
            <span
              class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >
              <span class="material-icons-round"> subtitles </span>
            </span>

            <input
              type="text"
              class="block px-2.5 pb-2.5 pt-4 pl-10 w-[42rem] text-sm text-gray-900 bg-white rounded-lg border-2 border-sky-200 appearance-none focus:outline-none focus:ring-0 focus:border-sky-300 peer cursor-pointer shadow-lg"
              v-model="commentaireEncaissement"
            /><label
              class="ml-1 absolute text-base font-bold text-gray-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >Commentaire interne</label
            >
          </div>
        </div>
        <!--Footer-->
        <div class="flex justify-end pt-2 mt-2">
          <button
            @click="(openSave = false), $emit('closeModalEncaissement', false)"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-gray-100 rounded-lg hover:bg-red-500 hover:text-white focus:outline-none shadow-md"
          >
            Fermer
          </button>
          <button
            @click="emitEncaissement()"
            class="cursor-pointer px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none shadow-md"
             :disabled="loading"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MyEncaissement",
  props: ["showModal", "infoClient", "setTotalTTC", "reglement", "infoCreatedBy"],
  data() {
    return {
      openSave: false,
      commentaireEncaissement: "",
      reglementTab: [],
      moyenPaiement: [
        {
          type: "Espèce",
          abbrev: "es",
          image: require("@/assets/icons_payments/ESP.png"),
        },
        {
          type: "Carte Bancaire",
          abbrev: "cb",
          image: require("@/assets/icons_payments/CB.png"),
        },
        {
          type: "Chèque Comptant",
          abbrev: "cc",
          image: require("@/assets/icons_payments/CHQ.png"),
        },
        {
          type: "LCR",
          abbrev: "lc",
          image: require("@/assets/icons_payments/LCR.png"),
        },
        {
          type: "Virement",
          abbrev: "vi",
          image: require("@/assets/icons_payments/Virement.png"),
        },
      ],
      errorPaymentMethod: false,
      loading: false,
      createdBy: "",
      clientChoosed: {}
    };
  },
  computed: {
    ...mapGetters(["user", "plateform", "comptoirSessionActive"]),
    setRestant() {
      var restant = 0;
      var paye = 0;
      for (const element of this.reglementTab) {
        paye = paye + parseFloat(element.model);
      }
      restant = parseFloat(this.setTotalTTC) - parseFloat(paye);
      if (restant < 0) {
        restant = 0;
      }
      return parseFloat(restant).toFixed(2);
    },
    setRendu() {
      var rendu = 0;
      var paye = 0;
      for (const element of this.reglementTab) {
        paye = paye + parseFloat(element.model);
      }
      if (paye > this.setTotalTTC) {
        rendu = Math.abs(paye - this.setTotalTTC);
      }
      return rendu;
    },
  },
  methods: {
    setReglement(method) {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      switch (method) {
        case "es":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/ESP.png"),
            icon: "payments",
            placeholder: "Espèce",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cb":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/CB.png"),
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cc":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/CHQ.png"),
            icon: "money",
            placeholder: "Chèque Comptant",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "vi":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/Virement.png"),
            icon: "account_balance",
            placeholder: "Virement",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "lc":
          this.reglementTab.push({
            img: require("@/assets/icons_payments/LCR.png"),
            icon: "account_balance",
            placeholder: "LCR",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        default:
          this.errorPaymentMethod = true;
          break;
      }
    },
    removeReglement(index) {
      this.reglementTab.splice(index, 1);
    },
    emitEncaissement() {
      this.loading = true;
      this.$emit("emitEncaissement", this.reglementTab);
      this.$emit("closeModalEncaissement", false);
      this.loading = false;
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  beforeMount() {
    this.clientChoosed = this.infoClient;
    this.createdBy = this.infoCreatedBy;
    this.openSave = this.showModal;
    this.reglementTab = this.reglement
      .filter((item) => item.placeholder !== "Reste dû")
      .map((item) => {
        // Reformater la date
        const dateObj = new Date(item.date);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Mois commence à 0
        const day = dateObj.getDate().toString().padStart(2, "0");

        return { ...item, date: `${year}-${month}-${day}`, disabled: true }; // Ajouter disabled: true à chaque élément
      });
  },
};
</script>
