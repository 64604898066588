<template>
  <h2 class="my-auto font-medium text-4xl">{{ titre }}</h2>
</template>
<script>
export default {
  name: "titrePages",
  data() {
    return {
      titre: "",
    };
  },
  props: ["title"],
  mounted() {
    this.titre = this.title;
  },
};
</script>
