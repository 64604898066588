import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import router from "../router/index.js";
import { prepareArticle } from "../hooks/searchEngine/articles.ts";
import { getPanierTransport } from "@/hooks/transport/panierTransport.ts";

export default createStore({
  state: {
    generalLoading: false,
    auth: false,
    caisse: false,
    caisseContenu: [],
    historiqueCaisse: [],
    uid: "",
    user: [],
    plateform: [],
    actionArticle: [],
    tempCommande: [],
    crossOeTab: [],
    comptoirSearchTab: [],
    prixTab: [],
    stockTab: [],
    vehicule: [],
    tabRecherchePS: [],
    vehiculeSet: false,
    vehiculeVin: false,
    vehiculeTD: false,
    vehiculeTDData: {},
    carcatToken: {
      id_token: "",
      access_token: "",
      refresh_token: "",
      expires_in: "",
      token_type: "",
    },
    carCatLink: "",
    carCatReference: "",
    clientFocus: "",
    commandeReception: [],
    VH_a02Tab: [],
    VH_articleTab: [],
    VH_cmd_Loading: [],
    VH_cmd_state: [],
    VH_PrixLot: [],
    VH_RefreshLoading: [],
    refClient: "",
    comptoirSession: [],
    comptoirSessionActive: [],
    artInfosInventory: [],
    inventaireProcessing: [],
    tabDepot: [],
    docToModify: [],
    fournisseurID: null,
    clientChoosedTab: [],
    brandSelected: [],
    genericArticleSelected: [],
    assemblyGroupSelected: [],
    articleInfoData: {},
    catalogueAGSelected: [],
    selectionCarId: null,
    selectionCarData: {},
    dataConsigne: [],
    dataNonConsigne: [],
    dsvCheck: false,
    Ref_fournisseur_search: "",
    Code_marque_search: "",
    Ref_fournisseur_gen_search: "",
    genArt_search: "",
    article: {},
    recapBdlsRegroupes: [],
    dateEditionBdlsRegroupes: "",
    dateEcheanceBdlsRegroupes: "",
    cmdPreparation: {
      cmdstate: { 0: true, 1: false, 2: false, 3: false, 4: false },
    },
    tabDetailRetour: {},
    preparationFournisseur: [],
    bdlSelected: [],
    devisSelected: [],
    bcSelected: [],
    factureSelected: [],
    facturationSelected: [],
    transporteurId: "",
    familleCatalogueSelected: {},
    familleSelected: {},
    savedFamilleSelected: {},
    carIdSelected: 0,
    confGenArtSelected: 0,
    controlePreliminaireSelected: "",
    stateCommandeGroupement: [],
    restoreTransportCart: false,
    panierTransportValue: {},
    idDetailPanierClient: "",
    prefixRegulStock: "",
    brandRegulStock: "",
    transfertData: {},
    idAppointment: "",
    sousCategoryUniverselleSelected: {},
    linkUniverselSelected: null,
  },
  mutations: {
    DECONNECTER: (state) => (state.auth = false),
    CONNECTER: (state) => (state.auth = true),
    OUVRIR_CAISSE: (state) => (state.caisse = true),
    FERMER_CAISSE: (state) => (state.caisse = false),
    UPDATE_UID: (state, uid) => (state.uid = uid),
    ADD_MODIFY_DOC: (state, data) => {
      state.docToModify = data;
    },
    SUPP_MODIFY_DOC: (state) => {
      state.docToModify = [];
    },
    ADD_VH_A02TAB: (state, data) => {
      axios
        .post(
          `${process.env.VUE_APP_API}/panierFournisseur/addpanierfournisseur/${state.user.proprietaire}`,
          {
            name: data.name,
            line: data.Regels[0].A02Regel[0],
            Articles: data.Article,
          }
        )
        .then((res) => {
          if (res.data) {
            state.VH_a02Tab = res.data.depotVH;
          }
          // state.VH_articleTab.push(data.Article);
        });
      // state.VH_a02Tab.push(data.Regels[0].A02Regel[0]);
      // state.VH_articleTab.push(data.Article);
    },
    SUPP_VH_A02TAB: (state, data) => {
      axios
        .put(
          `${process.env.VUE_APP_API}/panierFournisseur/remove/${state.user.proprietaire}`,
          {
            name: data.name,
            B1_Artikelnummer:
              state.VH_a02Tab[data.indexDepot].lines[data.index]
                .B1_Artikelnummer,
            Articles_Ref:
              state.VH_a02Tab[data.indexDepot].Articles[data.index]
                .Ref_fournisseur,
          }
        )
        .then((res) => {
          if (res.data.length > 0) {
            state.VH_a02Tab = res.data[0].depotVH;
            state.VH_articleTab.splice(data.index, 1);
          }
          // state.VH_a02Tab.splice(data.index, 1);
        });
      // state.VH_a02Tab.splice(data.index, 1);
      // state.VH_articleTab.splice(data.index, 1);
    },
    ADD_VH_PrixLot: (state, data) => {
      state.VH_PrixLot.push(data);
    },
    SUPP_VH_PrixLot: (state, data) => {
      state.VH_PrixLot.splice(data.index, 1);
    },
    CLEAR_VH_A02TAB: (state, data) => {
      axios
        .put(
          `${process.env.VUE_APP_API}/panierFournisseur/emptyPanier/${state.user.proprietaire}`,
          {
            name: data.name,
          }
        )
        .then((res) => {
          if (res.data.length > 0) {
            state.VH_a02Tab = res.data[0].depotVH;
            state.VH_articleTab = [];
            state.VH_cmd_Loading = [];
            state.VH_cmd_state = [];
            state.VH_PrixLot = [];
          }
        });
    },
    REFRESH_VH_A02TAB: async (state, data) => {
      for (const [index, element] of state.VH_a02Tab[
        data.index
      ].lines.entries()) {
        state.VH_RefreshLoading[index] = true;
        var Ref_fournisseur = element.B1_Artikelnummer[0];
        var Quantity = element.B1_LA_Aantal_response[0];
        var logCode = element.Klantnummer_bij_leverancier[0];
        await axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/prepareCommandArticle`,
            {
              Ref_fournisseur: Ref_fournisseur,
              Quantity: Quantity,
              logCode: logCode,
              login: state.plateform.grossierID,
            }
          )
          .then((res) => {
            state.VH_a02Tab[data.index].lines[index] =
              res.data.Regels[0].A02Regel[0];
            state.VH_RefreshLoading[index] = false;
          });
      }
      state.VH_RefreshLoading = [];
    },
    CLEAR_VH_LOADING_STATE: (state) => {
      state.VH_cmd_Loading = [];
      state.VH_cmd_state = [];
    },
    SUPP_VH_LOADING_STATE: (state, data) => {
      state.VH_cmd_Loading.splice(data.index, 1);
      state.VH_cmd_state.splice(data.index, 1);
    },
    CHECK_ARTICLE_VH: (state, data) => {
      if (data.Ref_fournisseur && data.Quantity) {
        state.VH_cmd_Loading[data.index] = true;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/prepareCommandArticle`,
            {
              Ref_fournisseur: data.Ref_fournisseur,
              Quantity: data.Quantity,
              logCode: data.logCode,
              login: state.plateform.grossierID,
            }
          )
          .then((res) => {
            axios
              .post(
                `${process.env.VUE_APP_API}/panierFournisseur/addpanierfournisseur/${state.user.proprietaire}`,
                {
                  name: data.name,
                  line: res.data.Regels[0].A02Regel[0],
                  Articles: res.data.Article,
                }
              )
              .then((res) => {
                state.VH_a02Tab = res.data.depotVH;
                state.VH_cmd_Loading[data.index] = false;
                state.VH_cmd_state[data.index] = true;
                // state.VH_articleTab.push(data.Article);
              });
            // state.VH_a02Tab.push(res.data.Regels[0].A02Regel[0]);
            // state.VH_articleTab.push(res.data.Article);
          });
      }
    },
    ADD_COMMANDE_RECEPTION: (state, data) => {
      state.commandeReception = data;
    },
    SET_USER: (state, data) => {
      state.user = data;
      axios
        .get(`${process.env.VUE_APP_API}/plateform/getone/${data.proprietaire}`)
        .then((res) => {
          state.plateform = res.data[0];
        });
    },
    GET_PLATEFORM: (state) => {
      axios
        .get(
          `${process.env.VUE_APP_API}/plateform/getone/${state.user.proprietaire}`
        )
        .then((res) => {
          state.plateform = res.data[0];
        });
    },
    ADD_STOCKARTICLE: (state, data) => {
      axios.put(`${process.env.VUE_APP_API}/article/${data.id}`, {
        Stock: parseInt(data.Stock) + parseInt(data.inputstock),
      });
    },
    SUPP_STOCKARTICLE: (state, data) => {
      axios.put(`${process.env.VUE_APP_API}/stock/${data.id}`, {
        stock: data.Stock - data.inputstock,
        plateform: state.user.proprietaire,
      });
    },
    SET_MVTSTOCK: (state, data) => {
      axios.post(`${process.env.VUE_APP_API}/mvStock`, {
        plateform: state.user.proprietaire,
        Date: data.Date,
        ArticleRef: data.Ref_fournisseur,
        ArticleEAN: data.Code_EAN,
        Depot: data.depot,
        User: state.user.username,
        Description: data.Description,
        OldStock: data.oldStock,
        NewStock: data.newStock,
      });
    },
    ADD_ACTIONARTICLE: (state, data) => {
      state.actionArticle.unshift(data);
    },
    SUPP_ACTIONARTICLE: (state, data) =>
      (state.actionArticle = state.actionArticle.filter(
        (item) => !data.includes(item._id)
      )),
    SUPP_CLIENT_TAB: (state) => {
      state.comptoirSession[0].Client = [];
    },
    ADD_CAISSE: (state, data) => {
      state.caisseContenu.push(data);
    },
    SUPP_CAISSE: (state, data) =>
      (state.caisseContenu = state.caisseContenu.filter(
        (item) => !data.includes(item._id)
      )),
    CLEAR_CAISSE: (state) => {
      state.caisseContenu = [];
    },
    ADD_HISTORIQUE_CAISSE: (state, data) => {
      state.historiqueCaisse.push(data);
    },
    CLEAR_HISTORIQUE_CAISSE: (state) => {
      state.historiqueCaisse = [];
    },
    VEHICULE_TRUE: (state) => {
      state.vehiculeVin = true;
    },
    VEHICULE_FALSE: (state) => {
      state.vehiculeVin = false;
    },
    ADD_VEHICULE: (state, data) => {
      state.vehicule.push(data);
      state.vehiculeSet = true;
      state.vehiculeVin = true;
      state.vehiculeTD = false;
    },
    CLEAR_VEHICULE: (state) => {
      state.vehicule = [];
      state.vehiculeSet = false;
      state.vehiculeVin = false;
      state.vehiculeTD = false;
    },
    UPDATE_ACTIONARTICLE: (state, data) => (state.actionArticle = data),
    CLEAR_ACTIONARTICLE: (state) => (state.actionArticle = []),
    UPDATE_STOCK_ACTIONARTICLE: (state, data) => {
      state.actionArticle[data.indexArt].quantity = data.quantity;
      state.actionArticle[data.indexArt].prixtotal = data.prixtotal;
    },
    UPDATE_REMISE_ACTIONARTICLE: (state, data) => {
      state.actionArticle[data.indexArt].remise = data.remise;
      state.actionArticle[data.indexArt].prixfinal = data.prixfinal;
      state.actionArticle[data.indexArt].quantity = data.quantity;
      state.actionArticle[data.indexArt].prixtotal = data.prixtotal;
    },
    ADD_TEMP_COMMANDE: (state, data) => {
      state.tempCommande.push(data);
    },
    SEARCH_CROSS_OE: (state, data) => {
      state.prixTab = [];
      state.crossOeTab = [];
      state.tabRecherchePS = [];
      var crossOeTabTemp = [];
      state.generalLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getcross`, {
          Ref_fournisseur: data,
        })
        .then((res) => {
          // state.crossOeTab = res.data;
          crossOeTabTemp = res.data;
          // state.generalLoading = false;
        })
        .then(() => {
          crossOeTabTemp.forEach((item) => {
            state.tabRecherchePS.push({
              marque: item.brandName,
              ref_f: item.articleNo,
            });
          });
        })
        .then(() => {
          axios
            .post(`${process.env.VUE_APP_API}/articlecross/getps`, {
              Ref_fournisseur: state.tabRecherchePS,
            })
            .then((response) => {
              state.prixTab = response.data;
            })
            .then(() => {
              var tabPrixTemp = [];
              var crossTabTemp = [];
              crossOeTabTemp.forEach((el, index) => {
                if (
                  state.prixTab[index].stock != "ND" &&
                  state.prixTab[index].stock > 0
                ) {
                  crossTabTemp.push(el);
                  tabPrixTemp.push(state.prixTab[index]);
                }
              });
              crossOeTabTemp.forEach((el, index) => {
                if (
                  state.prixTab[index].prix != "ND" &&
                  (state.prixTab[index].stock < 1 ||
                    !state.prixTab[index].stock)
                ) {
                  crossTabTemp.push(el);
                  tabPrixTemp.push(state.prixTab[index]);
                }
              });
              state.crossOeTab = crossTabTemp;
              state.prixTab = tabPrixTemp;
              state.generalLoading = false;
            });
        });
    },

    SEARCH_CROSS: (state, data) => {
      state.prixTab = [];
      state.crossOeTab = [];
      var crossOeTabTemp = [];
      state.tabRecherchePS = [];
      state.generalLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getdirectcross`, {
          Ref_fournisseur: data,
        })
        .then((res) => {
          // state.crossOeTab = res.data;
          crossOeTabTemp = res.data;
          // state.generalLoading = false;
        })
        .then(() => {
          crossOeTabTemp.forEach((item) => {
            state.tabRecherchePS.push({
              marque: item.brandName,
              ref_f: item.articleNo,
            });
          });
        })
        .then(() => {
          axios
            .post(`${process.env.VUE_APP_API}/articlecross/getps`, {
              Ref_fournisseur: state.tabRecherchePS,
            })
            .then((response) => {
              state.prixTab = response.data;
            })
            .then(() => {
              var tabPrixTemp = [];
              var crossTabTemp = [];
              crossOeTabTemp.forEach((el, index) => {
                if (
                  state.prixTab[index].stock != "ND" &&
                  state.prixTab[index].stock > 0
                ) {
                  crossTabTemp.push(el);
                  tabPrixTemp.push(state.prixTab[index]);
                }
              });
              crossOeTabTemp.forEach((el, index) => {
                if (
                  state.prixTab[index].prix != "ND" &&
                  (state.prixTab[index].stock < 1 ||
                    !state.prixTab[index].stock)
                ) {
                  crossTabTemp.push(el);
                  tabPrixTemp.push(state.prixTab[index]);
                }
              });
              state.crossOeTab = crossTabTemp;
              state.prixTab = tabPrixTemp;
              state.generalLoading = false;
            });
        });
    },

    SEARCH_TRANSLATED_DIRECT: (state, data) => {
      state.comptoirSearchTab = [];
      state.generalLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/gettranslateddirect`, {
          Ref_fournisseur: data,
        })
        .then((res) => {
          state.comptoirSearchTab = res.data;
          state.generalLoading = false;
        });
    },

    SEARCH_TRANSLATED_DIRECT_ADD: async (state, data) => {
      // state.crossOeTab = [];
      // state.generalLoading = true;
      var dataTemp = data;

      if (dataTemp.marqueName == "MEYLE") {
        const getRefCourte = await axios.post(
          `${process.env.VUE_APP_API}/catalogue/getRefCourte`,
          {
            Ref_fournisseur: dataTemp.artNum,
          }
        );
        dataTemp.artNum = getRefCourte.data[0].Ref_fournisseur;
      }

      var res = await axios.post(
        `${process.env.VUE_APP_API}/articlecross/gettranslateddirect`,
        {
          Ref_fournisseur: dataTemp,
        }
      );

      if (res.data[0].article) {
        if (res.data[0].article.length > 1) {
          res = await axios.post(
            `${process.env.VUE_APP_API}/searchEngine/filterTabArticleByPriority`,
            {
              articleData: res.data[0].article,
              plateform: state.user.proprietaire,
            }
          );
        }
        var existArticle = false;

        for (const [index, actArticle] of state.actionArticle.entries()) {
          if (
            actArticle.Ref_fournisseur ==
              res.data[0].article[0].Ref_fournisseur &&
            actArticle.Prefixe_tarif == res.data[0].article[0].Prefixe_tarif
          ) {
            state.actionArticle[index].quantity =
              state.actionArticle[index].quantity + 1;
            existArticle = true;
          }
        }
        var tabPrixNetFourn = [];
        var tabPrixNetClient = [];
        var tabPrixNetFournClient = "";

        if (existArticle == false) {
          const checkPromo = await axios.post(
            `${process.env.VUE_APP_API}/promo/getone`,
            {
              promoinfo: {
                plateformID: state.user.proprietaire,
                Ref_fournisseur: res.data[0].article[0].Ref_fournisseur,
                Code_marque: res.data[0].article[0].Code_marque,
              },
            }
          );
          const foundFournisseur = await axios.post(
            `${process.env.VUE_APP_API}/fournisseur/getone`,
            {
              Prefixe_tarif: res.data[0].article[0].Prefixe_tarif,
              plateform: state.user.proprietaire,
              client: state.comptoirSessionActive[0].Client[0].Name,
            }
          );
          // prix net client
          const tabClient = await axios.post(
            `${process.env.VUE_APP_API}/client/getprixnet`,
            {
              plateform: state.user.proprietaire,
              Client: state.comptoirSessionActive[0].Client[0],
              Ref_fournisseur: res.data[0].article[0].Ref_fournisseur,
              Prefixe_tarif: res.data[0].article[0].Prefixe_tarif,
            }
          );
          if (tabClient.data.tabPrixNet.length > 0)
            tabPrixNetClient.push(tabClient.data.tabPrixNet[0]);
          if (tabClient.data.client != "") {
            tabPrixNetFournClient = tabClient.data.client;
          }
          // prix net fournisseur
          const tabFournisseur = await axios.post(
            `${process.env.VUE_APP_API}/prixNetFournisseur/getprixnet`,
            {
              plateform: state.user.proprietaire,
              Prefixe_tarif: res.data[0].article[0].Prefixe_tarif,
              Ref_fournisseur: res.data[0].article[0].Ref_fournisseur,
              Client: state.comptoirSessionActive[0].Client[0],
            }
          );
          console.log("TABFOURNISSEUR", tabFournisseur.data);
          if (tabFournisseur.data.tabPrixNet.length > 0) {
            tabPrixNetFourn.push(tabFournisseur.data.tabPrixNet[0]);
          }
          if (tabFournisseur.data.client != "") {
            tabPrixNetFournClient = tabFournisseur.data.client;
          }

          const getClient = await axios.post(
            `${process.env.VUE_APP_API}/client/getone/${state.comptoirSessionActive[0].Client[0].Name}`,
            {
              plateform: state.user.proprietaire,
            }
          );

          var groupClient = getClient.data[0].Group.Name;

          var dataTab = res.data[0].article[0];

          if (foundFournisseur.data.length > 0) {
            if (tabPrixNetFourn.length > 0 || tabPrixNetClient.length > 0) {
              dataTab.PrixFournisseurType = "prixnet";
            } else {
              dataTab.PrixFournisseurType =
                foundFournisseur.data[0].typeCondition;
            }
            dataTab.FournisseurSelected = foundFournisseur.data[0].CompanyName;

            if (dataTab.PrixFournisseurType == "prixnet") {
              if (tabPrixNetFourn.length > 0 || tabPrixNetClient.length > 0) {
                switch (tabPrixNetFournClient) {
                  case "T1":
                    if (tabPrixNetFourn.length > 0) {
                      // prixnet fournisseur
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetFourn[0].T1;
                      dataTab.PNF = tabPrixNetFourn[0].Prix_achat;
                    } else if (tabPrixNetClient.length > 0) {
                      // prixnet client
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetClient[0].Prix_vente;
                    }
                    break;
                  case "T2":
                    if (tabPrixNetFourn.length > 0) {
                      // prixnet fournisseur
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetFourn[0].T2;
                      dataTab.PNF = tabPrixNetFourn[0].Prix_achat;
                    } else if (tabPrixNetClient.length > 0) {
                      // prixnet client
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetClient[0].Prix_vente;
                    }
                    break;
                  case "T3":
                    if (tabPrixNetFourn.length > 0) {
                      // prixnet fournisseur
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetFourn[0].T3;
                      dataTab.PNF = tabPrixNetFourn[0].Prix_achat;
                    } else if (tabPrixNetClient.length > 0) {
                      // prixnet client
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetClient[0].Prix_vente;
                    }
                    break;
                  case "T4":
                    if (tabPrixNetFourn.length > 0) {
                      // prixnet fournisseur
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetFourn[0].T4;
                      dataTab.PNF = tabPrixNetFourn[0].Prix_achat;
                    } else if (tabPrixNetClient.length > 0) {
                      // prixnet client
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetClient[0].Prix_vente;
                    }
                    break;
                  case "T5":
                    if (tabPrixNetFourn.length > 0) {
                      // prixnet fournisseur
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetFourn[0].T5;
                      dataTab.PNF = tabPrixNetFourn[0].Prix_achat;
                    } else if (tabPrixNetClient.length > 0) {
                      // prixnet client
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetClient[0].Prix_vente;
                    }
                    break;
                  case "T6":
                    if (tabPrixNetFourn.length > 0) {
                      // prixnet fournisseur
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetFourn[0].T6;
                      dataTab.PNF = tabPrixNetFourn[0].Prix_achat;
                    } else if (tabPrixNetClient.length > 0) {
                      // prixnet client
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                      dataTab.Prix_euro = tabPrixNetClient[0].Prix_vente;
                    }
                    break;
                  default:
                    if (tabPrixNetFourn.length > 0) {
                      // prixnet fournisseur
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                    } else if (tabPrixNetClient.length > 0) {
                      // prixnet client
                      dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                    }
                    break;
                }
              } else {
                switch (groupClient) {
                  case "T1":
                    dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                    dataTab.Prix_euro = res.data[0].article[0].PNT1;
                    break;
                  case "T2":
                    dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                    dataTab.Prix_euro = res.data[0].article[0].PNT2;
                    break;
                  case "T3":
                    dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                    dataTab.Prix_euro = res.data[0].article[0].PNT3;
                    break;
                  case "T4":
                    dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                    dataTab.Prix_euro = res.data[0].article[0].PNT4;
                    break;
                  case "T5":
                    dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                    dataTab.Prix_euro = res.data[0].article[0].PNT5;
                    break;
                  case "T6":
                    dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                    dataTab.Prix_euro = res.data[0].article[0].PNT6;
                    break;
                  default:
                    dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
                    break;
                }
              }
            } else if (foundFournisseur.data[0].typeCondition == "coef2") {
              dataTab.Prix_euro = res.data[0].article[0].Prix_ppc;
            } else if (foundFournisseur.data[0].typeCondition == "remise") {
              const clientCondition = await axios.post(
                `${process.env.VUE_APP_API}/conditionFournisseur/getClientCondition`,
                {
                  plateform: state.user.proprietaire,
                  fournisseur: res.data[0].article[0].FournisseurSelected,
                  article: res.data[0].article[0],
                }
              );

              switch (groupClient) {
                case "T1":
                  dataTab.remise = clientCondition.data[0].T1;
                  break;
                case "T2":
                  dataTab.remise = clientCondition.data[0].T2;
                  break;
                case "T3":
                  dataTab.remise = clientCondition.data[0].T3;
                  break;
                case "T4":
                  dataTab.remise = clientCondition.data[0].P1;
                  break;
                case "T5":
                  dataTab.remise = clientCondition.data[0].P2;
                  break;
                case "T6":
                  dataTab.remise = clientCondition.data[0].P3;
                  break;
                default:
                  dataTab.remise = 0;
                  break;
              }
            }
          } else {
            // dataTab.PrixFournisseurType = "None";
            dataTab.FournisseurSelected = "Aucun";
            dataTab.Prix_vente = res.data[0].article[0].Prix_euro;
            dataTab.PNT1 = res.data[0].article[0].Prix_euro;
            dataTab.PNT2 = res.data[0].article[0].Prix_euro;
            dataTab.PNT3 = res.data[0].article[0].Prix_euro;
            dataTab.PNT4 = res.data[0].article[0].Prix_euro;
            dataTab.PNT5 = res.data[0].article[0].Prix_euro;
            dataTab.PNT6 = res.data[0].article[0].Prix_euro;
            dataTab.PNF = res.data[0].article[0].Prix_euro;
          }

          checkPromo.data.length > 0
            ? (dataTab.Promo_set = true)
            : (dataTab.Promo_set = false);

          if (data.quantityAdded) {
            dataTab.quantity = data.quantityAdded;
          } else {
            dataTab.quantity = 1;
          }
          dataTab.commentaire = "";
          dataTab.prixtotal = dataTab.Prix_euro;
          dataTab.prixfinal = dataTab.Prix_euro;
          dataTab.remise = 0;

          if (res.data[0].stockData) {
            res.data[0].stockData.length > 0
              ? (dataTab.stockData = res.data[0].stockData[0].stock)
              : (dataTab.stockData = 0);
          }

          if (dataTab.Montant_consigne && dataTab.Montant_consigne > 0) {
            state.actionArticle.unshift({
              _id: dataTab._id,
              Ref_fournisseur: "C : " + dataTab.Ref_fournisseur,
              Prix_euro: dataTab.Montant_consigne,
              Prix_vente: dataTab.Montant_consigne,
              PrixFournisseurType: "prixnet",
              disabled: true,
              quantity: 1,
              remise: 0,
              prixtotal: dataTab.Prix_euro,
              prixfinal: dataTab.Prix_euro,
              Code_marque: dataTab.Code_marque,
              Description: dataTab.Description,
            });
          }
          state.actionArticle.unshift(dataTab);
        }
      }
      state.generalLoading = false;
    },

    SEARCH_DIRECT: (state, data) => {
      state.prixTab = [];
      state.crossOeTab = [];
      state.tabRecherchePS = [];
      var crossOeTabTemp = [];
      state.generalLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getdirect`, {
          Ref_fournisseur: data,
        })
        .then((res) => {
          // state.crossOeTab = res.data;
          crossOeTabTemp = res.data;
          // state.generalLoading = false;
        })
        .then(() => {
          crossOeTabTemp.forEach((item) => {
            state.tabRecherchePS.push({
              marque: item.brandName,
              ref_f: item.articleNo,
              plateform: state.user.proprietaire,
            });
          });
        })
        .then(() => {
          axios
            .post(`${process.env.VUE_APP_API}/articlecross/getps`, {
              Ref_fournisseur: state.tabRecherchePS,
            })
            .then((response) => {
              state.prixTab = response.data;
            })
            .then(() => {
              var tabPrixTemp = [];
              var crossTabTemp = [];
              crossOeTabTemp.forEach((el, index) => {
                if (state.prixTab[index]) {
                  if (
                    state.prixTab[index].stock != "ND" &&
                    state.prixTab[index].stock > 0
                  ) {
                    crossTabTemp.push(el);
                    tabPrixTemp.push(state.prixTab[index]);
                  }
                }
              });
              crossOeTabTemp.forEach((el, index) => {
                if (state.prixTab[index]) {
                  if (
                    state.prixTab[index].prix != "ND" &&
                    (state.prixTab[index].stock < 1 ||
                      !state.prixTab[index].stock)
                  ) {
                    crossTabTemp.push(el);
                    tabPrixTemp.push(state.prixTab[index]);
                  }
                }
              });
              state.crossOeTab = crossTabTemp;
              state.prixTab = tabPrixTemp;
              state.generalLoading = false;
            });
        });
    },
    GET_PRIX: async (state, data) => {
      const getprixV = await axios.post(
        `${process.env.VUE_APP_API}/article/getprix`,
        {
          Ref_fournisseur: data,
        }
      );
      return getprixV.data;
    },
    STORE_CARCAT_TOKEN: (state, data) => {
      // carcatToken: {
      //   id_token: "",
      //   access_token: "",
      //   refresh_token: "",
      //   expires_in: "",
      //   token_type: "",
      // },
      state.carcatToken.id_token = data.id_token;
      state.carcatToken.access_token = data.access_token;
      state.carcatToken.refresh_token = data.refresh_token;
      state.carcatToken.expires_in = data.expires_in;
      state.carcatToken.token_type = data.token_type;
    },
    STORE_REFRESH_CARCAT_TOKEN: (state, data) => {
      // carcatToken: {
      //   id_token: "",
      //   access_token: "",
      //   refresh_token: "",
      //   expires_in: "",
      //   token_type: "",
      // },
      state.carcatToken.id_token = data.id_token;
      state.carcatToken.access_token = data.access_token;
      state.carcatToken.expires_in = data.expires_in;
      state.carcatToken.token_type = data.token_type;
    },
    CLEAR_CARCAT_TOKEN: (state) => {
      // carcatToken: {
      //   id_token: "",
      //   access_token: "",
      //   refresh_token: "",
      //   expires_in: "",
      //   token_type: "",
      // },
      state.carcatToken.id_token = "";
      state.carcatToken.access_token = "";
      state.carcatToken.refresh_token = "";
      state.carcatToken.expires_in = "";
      state.carcatToken.token_type = "";
      state.carCatLink = "";
      state.carCatReference = "";
    },
    SET_CARCAT_LINKS: (state, data) => {
      state.carCatLink = data.link;
      state.carCatReference = data.ref;
    },
    ADD_PANIER_CARCAT: async (state, data) => {
      for (const elementVH of data.lines) {
        var response = await axios.post(
          `${process.env.VUE_APP_API}/article/getonecarcat`,
          {
            Ref_fournisseur: elementVH.LineCode,
          }
        );
        var existArticle = false;

        for (const [index, actArticle] of state.actionArticle.entries()) {
          if (
            actArticle.Ref_fournisseur == response.data[0].Ref_fournisseur &&
            actArticle.Prefixe_tarif == response.data[0].Prefixe_tarif
          ) {
            state.actionArticle[index].quantity =
              state.actionArticle[index].quantity + elementVH.Quantity;
            existArticle = true;
          }
        }
        if (existArticle == false) {
          var tabResult = [];
          let plateform_mere = state.user.proprietaire;
          if (state.plateform.plateform_mere) {
            plateform_mere = state.plateform.plateform_mere;
          }
          tabResult = await prepareArticle(
            { article: response.data[0], stockData: [] },
            plateform_mere,
            state.comptoirSessionActive[0].Client[0].Name,
            state.comptoirSessionActive[0].Client[0].Group.Name,
            elementVH.Quantity
          );
          state.actionArticle.unshift(tabResult);
        }
      }
      router.push("/actionclient");
    },
    ADD_CLIENT_FOCUS: (state, data) => {
      state.clientFocus = data;
    },
    CLEAR_CLIENT_FOCUS: (state) => {
      state.clientFocus = "";
    },
    SET_PRIX_QUANTITATIF: (state, data) => {
      state.actionArticle[data.index].Prix_de_depart =
        state.actionArticle[data.index].Prix_euro;
      state.actionArticle[data.index].Prix_euro = data.prix;
      state.actionArticle[data.index].quantity = data.quantite;
      state.actionArticle[data.index].quantity_remise = data.quantite;
      state.actionArticle[data.index].prix_quantitatif = true;
    },
    REMOVE_PRIX_QUANTITATIF: (state, data) => {
      state.actionArticle[data.index].Prix_euro =
        state.actionArticle[data.index].Prix_de_depart;
      state.actionArticle[data.index].prix_quantitatif = false;
    },
    SET_REFCLIENT: (state, data) => {
      state.refClient = data;
      state.comptoirSessionActive[0].refClient = data;
    },
    CREATE_COMPTOIR_SESSION: async (state, data) => {
      if (data.Articles) {
        await axios
          .post(`${process.env.VUE_APP_API}/comptoirSession`, {
            plateform: state.user.proprietaire,
            utilisateur: state.user.username,
            email: state.user.email,
            uid: state.user._id,
            Client: data.clientFocus,
            actionArticle: data.Articles,
            refClient: data.refClient,
            documentType: data.documentType,
            actif: true,
            fraisdeport: data.clientFocus[0].fraisdeport,
            francodeport: data.clientFocus[0].francodeport,
            fraisdeportsave: data.clientFocus[0].fraisdeport,
            consigneData: data.consigneData,
            consigneNonData: data.consigneNonData,
            dsvCheck: data.dsvCheck,
            remisegenerale: data.remisegenerale,
            repriseTypeDoc: data.repriseTypeDoc,
            repriseNumDoc: data.repriseNumDoc,
            repriseIdDoc: data.repriseIdDoc,
            reglementTab: data.reglementTab,
          })
          .then((res) => {
            state.comptoirSessionActive = [];
            state.comptoirSessionActive.push(res.data);
            state.clientFocus = res.data.Client;
            state.actionArticle = res.data.actionArticle;
            axios
              .post(`${process.env.VUE_APP_API}/comptoirSession/getall`, {
                plateform: state.user.proprietaire,
                user: state.user.username,
              })
              .then((res) => {
                state.comptoirSession = res.data;
              })
              .catch((error) => {
                console.log(error);
                state.comptoirSessionActive = [];
              });
          });

        if (data.reprise) {
          router.push("/actionclient");
        }
      } else {
        await axios
          .post(`${process.env.VUE_APP_API}/comptoirSession`, {
            plateform: state.user.proprietaire,
            utilisateur: state.user.username,
            email: state.user.email,
            uid: state.user._id,
            Client: data.clientFocus,
            actionArticle: [],
            refClient: data.refClient,
            documentType: data.documentType,
            actif: true,
            fraisdeport: data.clientFocus[0].fraisdeport,
            francodeport: data.clientFocus[0].francodeport,
            fraisdeportsave: data.clientFocus[0].fraisdeport,
            consigneData: data.consigneData,
            consigneNonData: data.consigneNonData,
            dsvCheck: data.dsvCheck,
            remisegenerale: data.remisegenerale,
            repriseTypeDoc: data.repriseTypeDoc,
            repriseNumDoc: data.repriseNumDoc,
            repriseIdDoc: data.repriseIdDoc,
            reglementTab: data.reglementTab,
          })
          .then((res) => {
            state.comptoirSessionActive = [];
            state.comptoirSessionActive.push(res.data);
            state.clientFocus = res.data.Client;
            state.actionArticle = res.data.actionArticle;
            axios
              .post(`${process.env.VUE_APP_API}/comptoirSession/getall`, {
                plateform: state.user.proprietaire,
                user: state.user.username,
              })
              .then((res) => {
                state.comptoirSession = res.data;
              })
              .catch((error) => {
                console.log(error);
                state.comptoirSessionActive = [];
              });
          });
        if (data.reprise) {
          router.push("/actionclient");
        }
      }
    },
    SAVE_COMPTOIR_SESSION: (state, data) => {
      if (state.comptoirSessionActive.length > 0) {
        axios
          .put(
            `${process.env.VUE_APP_API}/comptoirSession/${state.comptoirSessionActive[0]._id}`,
            {
              plateform: state.user.proprietaire,
              utilisateur: state.user.username,
              email: state.user.email,
              uid: state.user._id,
              Client: data.clientFocus,
              actionArticle: state.actionArticle,
              refClient: data.refClient,
              documentType: data.documentType,
              fraisdeport:
                data.clientFocus && data.clientFocus.length > 0
                  ? data.clientFocus[0].fraisdeport
                  : "",
              francodeport: data.francodeport,
              fraisdeportsave: data.fraisdeportsave,
              consigneData: data.consigneData,
              consigneNonData: data.consigneNonData,
              dsvCheck: data.dsvCheck,
              remisegenerale: data.remisegenerale,
              repriseTypeDoc: data.repriseTypeDoc,
              repriseNumDoc: data.repriseNumDoc,
              repriseIdDoc: data.repriseIdDoc,
              reglementTab: data.reglementTab,
            }
          )
          .then(() => {
            axios
              .post(`${process.env.VUE_APP_API}/comptoirSession/getall`, {
                plateform: state.user.proprietaire,
                user: state.user.username,
              })
              .then((res) => {
                state.comptoirSession = res.data;
                if (res.data.length < 1) {
                  state.comptoirSessionActive = [];
                } else {
                  axios
                    .post(
                      `${process.env.VUE_APP_API}/comptoirSession/getActif`,
                      {
                        plateform: state.user.proprietaire,
                        user: state.user.username,
                      }
                    )
                    .then((res) => {
                      state.comptoirSessionActive = [];
                      state.comptoirSessionActive.push(res.data[0]);
                    })
                    .catch((error) => {
                      console.log(error);
                      state.comptoirSessionActive = [];
                    });
                }
              });
          });
      }
    },
    SET_COMPTOIR_SESSION: (state, data) => {
      state.actionArticle = data.actionArticle;
      state.clientFocus = data.Client;
      state.refClient = data.refClient;
      state.comptoirSessionActive = [];
      state.comptoirSessionActive.push(data);
      axios
        .put(
          `${process.env.VUE_APP_API}/comptoirSession/activate/${data._id}`,
          data
        )
        .then(() => {
          axios
            .post(`${process.env.VUE_APP_API}/comptoirSession/getall`, {
              plateform: state.user.proprietaire,
              user: state.user.username,
            })
            .then((res) => {
              state.comptoirSession = res.data;
            })
            .catch((error) => {
              state.comptoirSessionActive = [];
              console.log(error);
            });
        });
    },
    REMOVE_COMPTOIR_SESSION: async (state, data) => {
      await axios.put(
        `${process.env.VUE_APP_API}/comptoirSession/remove/${data._id}`,
        {
          plateform: state.user.proprietaire,
          user: state.user.username,
        }
      );
      state.actionArticle = [];
      let res = await axios.post(
        `${process.env.VUE_APP_API}/comptoirSession/getall`,
        {
          plateform: state.user.proprietaire,
          user: state.user.username,
        }
      );
      state.comptoirSession = res.data;
      if (res.data.length < 1) {
        state.comptoirSessionActive = [];
      } else {
        try {
          let res2 = await axios.post(
            `${process.env.VUE_APP_API}/comptoirSession/getActif`,
            {
              plateform: state.user.proprietaire,
              user: state.user.username,
            }
          );
          state.comptoirSessionActive = [];
          state.comptoirSessionActive.push(res2.data[0]);
          state.actionArticle = res2.data[0].actionArticle;

          state.restoreTransportCart = true;

          if (!res2.data[0].Client[0]._id.startsWith("inconnu")) {
            let panier = await getPanierTransport(
              state.user.proprietaire,
              res2.data[0].Client[0]._id
            );
            state.panierTransportValue = panier;
          }
        } catch (error) {
          state.comptoirSessionActive = [];
        }
      }
    },
    GET_ALL_COMPTOIR: (state) => {
      axios
        .post(`${process.env.VUE_APP_API}/comptoirSession/getall`, {
          plateform: state.user.proprietaire,
          user: state.user.username,
        })
        .then((res) => {
          state.comptoirSession = res.data;
          if (res.data.length < 1) {
            state.comptoirSessionActive = [];
          } else {
            axios
              .post(`${process.env.VUE_APP_API}/comptoirSession/getActif`, {
                plateform: state.user.proprietaire,
                user: state.user.username,
              })
              .then((res) => {
                state.comptoirSessionActive = [];
                state.comptoirSessionActive.push(res.data[0]);
              })
              .catch((error) => {
                state.comptoirSessionActive = [];
                console.log(error);
              });
          }
        });
    },
    SET_FRAISDEPORT: (state, data) => {
      // state.comptoirSessionActive[0].fraisdeport = data.value;
      state.comptoirSessionActive[0].fraisdeportsave = data.value;
    },
    SET_REMISEARTICLE: (state, data) => {
      state.actionArticle[data.index].remise = data.remise;
    },
    SET_QUANTITYARTICLE: (state, data) => {
      state.actionArticle[data.index].quantity = data.quantity;
    },
    SET_ART_INVENTORY: (state, data) => {
      state.artInfosInventory = data;
    },
    REMOVE_ART_INVENTORY: (state) => {
      state.artInfosInventory = [];
    },
    SET_INVENTORY_PROCESSING: (state, data) => {
      state.inventaireProcessing = data;
    },
    REMOVE_INVENTORY_PROCESSING: (state) => {
      state.inventaireProcessing = [];
    },
    ADD_TAB_DEPOT: (state, data) => {
      var found = false;
      for (const [index, element] of state.tabDepot.entries()) {
        if (data.depot == element.depot) {
          state.tabDepot[index].lines.push(data.line);
          found = true;
        } else {
          found = false;
        }
      }
      if (found == false) {
        state.tabDepot.push({ depot: data.depot, lines: data.line });
      }
    },
    GET_PANIER_FOURNISSEUR: (state, data) => {
      axios
        .post(`${process.env.VUE_APP_API}/panierFournisseur/getall`, {
          plateform: state.user.proprietaire,
          depotName: data.name,
        })
        .then((res) => {
          if (res.data.length > 0) {
            state.VH_a02Tab = res.data[0].depotVH;
          }
        });
    },

    GET_FOURNISSEUR_ID: (state, data) => {
      state.fournisseurID = data;
      console.log(state.fournisseurID);
    },
    // GET_FOURNISSEUR_ID_PRIXNET_SAVED: (state, data) => {
    //   state.prixNetFournisseur[data.index].Ref_fournisseur =
    //     data.Ref_fournisseur;
    //   state.prixNetFournisseur[data.index].Prefixe_tarif =
    //     data.Prefixe_tarif;
    //   state.prixNetFournisseur[data.index].Prix_achat = data.Prix_achat;
    //   state.prixNetFournisseur[data.index].T1 = data.T1;
    //   state.prixNetFournisseur[data.index].T2 = data.T2;
    //   state.prixNetFournisseur[data.index].T3 = data.T3;
    // },
    // GET_FOURNISSEUR_ID_PRIXNET: (state, data) => {
    //       state.prixNetFournisseur = data;
    //   // axios
    //   //   .post(`${process.env.VUE_APP_API}/prixNetFournisseur/getall`, {
    //   //     plateform: state.user.proprietaire,
    //   //     Prefixe_tarif: state.fournisseurID.Prefixe_tarif,
    //   //   })
    //   //   .then((response) => {
    //   //     console.log("response   :");
    //   //     console.log(response);
    //   //   })
    //   //   .catch((error) => {
    //   //     console.log(error);
    //   //   });
    // },

    GET_CLIENT_CHOOSED_TAB: (state, data) => {
      state.clientChoosedTab = data;
    },
    GET_CLIENT_CHOOSED_TAB_PRIXNET_SAVED: (state, data) => {
      state.clientChoosedTab.prixNet[data.index].Ref_fournisseur =
        data.Ref_fournisseur;
      state.clientChoosedTab.prixNet[data.index].Prefixe_tarif =
        data.Prefixe_tarif;
      state.clientChoosedTab.prixNet[data.index].Prix_vente = data.Prix_vente;
    },
    GET_CLIENT_CHOOSED_TAB_PRIXNET: (state, data) => {
      state.clientChoosedTab.prixNet.push(data);
    },
    ADD_ETAT_RETOUR: (state, data) => {
      state.actionArticle[data.index].etatRetour = true;
    },
    SUPP_ETAT_RETOUR: (state, data) => {
      state.actionArticle[data.index].etatRetour = false;
    },
    UPDATE_QTY_ACTIONARTICLE: (state, data) => {
      state.actionArticle[data.indexArt].quantity = data.quantity;
    },
    SET_BRAND_SELECTED: (state, data) => {
      state.brandSelected = data;
    },
    SET_GENERICARTICLE_SELECTED: (state, data) => {
      state.genericArticleSelected = data;
    },
    SET_AG_SELECTED: (state, data) => {
      state.assemblyGroupSelected = data;
    },
    SET_CATALOGUE_AGSELECTED: (state, data) => {
      state.catalogueAGSelected = data;
    },
    SET_ARTICLE_INFO: (state, data) => {
      const article = data;
      if (article.Code_marque === "MEYLE") {
        const updatedArticle = {
          ...article,
          Ref_fournisseur: article.Ref_complementaire,
        };
        state.articleInfoData = updatedArticle;
      } else {
        state.articleInfoData = article;
      }
    },
    SET_SELECTION_CAR_ID: (state, data) => {
      state.selectionCarId = data.carId;
      state.vehiculeSet = false;
      state.vehiculeTD = true;
      state.vehiculeTDData = {
        marque: data.marque,
        modele: data.modele,
        version: data.version,
        date: data.date,
      };
    },
    SET_TD_CAR_ID: (state, data) => {
      state.selectionCarId = data.carId;
      state.vehiculeSet = true;
      state.vehiculeTD = false;
    },
    SET_SELECTION_CAR_DATA: (state, data) => {
      state.selectionCarData.manuName = data.manuName;
      state.selectionCarData.modelselected = data.modelselected;
    },
    CLEAR_CLIENT_TAB: (state) => {
      state.comptoirSession[0].Client = [];
    },
    // dataNonConsigne
    ADD_DATA_NON_CONSIGNE: (state, data) => {
      state.dataNonConsigne.unshift(data);
      state.comptoirSessionActive[0].consigneNonData.unshift(data);
    },
    SUPP_DATA_NON_CONSIGNE: (state, data) => {
      (state.dataNonConsigne = state.dataNonConsigne.filter(
        (item) => !data.includes(item._id)
      )),
        (state.comptoirSessionActive[0].consigneNonData =
          state.comptoirSessionActive[0].consigneNonData.filter(
            (item) => !data.includes(item._id)
          ));
    },
    CLEAR_DATA_NON_CONSIGNE: (state) => {
      state.dataNonConsigne = [];
      state.comptoirSessionActive[0].consigneNonData = [];
    },
    SET_DSV_CHECK: (state, data) => {
      state.dsvCheck = data;
      state.comptoirSessionActive[0].dsvCheck = data;
    },
    // dataConsigne
    ADD_DATA_CONSIGNE: (state, data) => {
      state.dataConsigne.unshift(data);
      state.comptoirSessionActive[0].consigneData.unshift(data);
    },
    UPDATE_REMISE_DATA_CONSIGNE: (state, data) => {
      state.comptoirSessionActive[0].consigneData.forEach((element) => {
        if (
          element.Ref_fournisseur == data.Articles.Ref_fournisseur &&
          element.Prefixe_tarif == data.Articles.Prefixe_tarif
        ) {
          element.remise = data.remise;
          element.prixfinal = data.prixfinal;
          element.quantity = data.quantity;
          element.prixtotal = data.prixtotal;
        }
      });
    },
    UPDATE_QTY_DATA_CONSIGNE: (state, data) => {
      state.comptoirSessionActive[0].consigneData.forEach((element) => {
        if (
          element.Ref_fournisseur == data.Articles.Ref_fournisseur &&
          element.Prefixe_tarif == data.Articles.Prefixe_tarif
        ) {
          element.quantity = data.quantity;
          element.prixtotal = element.prixtotal * data.quantity;
          element.prixfinal = -element.prixfinal;
        }
      });
    },
    SUPP_DATA_CONSIGNE: (state, data) => {
      (state.dataConsigne = state.dataConsigne.filter(
        (item) => !data.includes(item._id)
      )),
        (state.comptoirSessionActive[0].consigneData =
          state.comptoirSessionActive[0].consigneData.filter(
            (item) => !data.includes(item._id)
          ));
    },
    CLEAR_DATA_CONSIGNE: (state) => {
      state.dataConsigne = [];
      state.comptoirSessionActive[0].consigneData = [];
    },
    SEARCH_REF_FOURNISSEUR_DETAIL: (state, data) => {
      state.Ref_fournisseur_search = data.Ref_fournisseur;
      state.genArt_search = "";
      if (router.currentRoute.value.path == "/searchArticleDetail") {
        router.push("/searchArticleDetailBis");
      } else {
        router.push("/searchArticleDetail");
      }
    },
    SEARCH_REF_FOURNISSEUR: (state, data) => {
      state.Ref_fournisseur_search = data.Ref_fournisseur;
      state.Code_marque_search = data.Code_marque;
      state.genArt_search = "";
      if (router.currentRoute.value.path == "/searchArticleExact") {
        router.push("/searchArticleExactBis");
      } else {
        router.push("/searchArticleExact");
      }
    },
    SEARCH_REF_FOURNISSEUR_GEN: (state, data) => {
      state.Ref_fournisseur_gen_search = data.Ref_fournisseur;
      state.genArt_search = data.genArt;
      if (data.Prefixe_tarif == "Vhi") {
        router.push("/searchArticleVH");
      } else {
        router.push("/searchArticleNew");
      }
    },
    SET_ARTICLE: (state, article) => {
      state.article = article;
    },
    SET_BDLS_REGROUPES: (state, data) => {
      state.recapBdlsRegroupes = data.tabBdls;
      state.dateEditionBdlsRegroupes = data.dateEdition;
      state.dateEcheanceBdlsRegroupes = data.dateEcheance;
    },
    GET_CMD_PREPARATION: (state, data) => {
      state.cmdPreparation = data;
    },
    ADD_DETAIL_RETOUR: (state, data) => {
      state.tabDetailRetour = {};
      state.tabDetailRetour = data;
      console.log(state.tabDetailRetour);
    },
    SET_PREPARATION_FOURNISSEUR: (state, data) => {
      state.preparationFournisseur = data;
    },
    SET_BDL_SELECTED: (state, data) => {
      state.bdlSelected = data;
    },
    SET_DEVIS_SELECTED: (state, data) => {
      state.devisSelected = data;
    },
    SET_BC_SELECTED: (state, data) => {
      state.bcSelected = data;
    },
    SET_FACTURE_SELECTED: (state, data) => {
      state.factureSelected = data;
    },
    SET_FACTURATION_SELECTED: (state, data) => {
      state.facturationSelected = data;
    },
    GET_TRANSPORTEUR_ID: (state, data) => {
      state.transporteurId = data;
    },
    UPDATE_ACTION_ARTICLE_SESSION: async (state, data) => {
      if (state.actionArticle.length > 0) {
        for (const [indexArt, art] of state.actionArticle.entries()) {
          if (
            !art.remiseInitiale &&
            !art.quickEntry &&
            !art.Ref_fournisseur.startsWith("C :")
          ) {
            state.actionArticle[indexArt] = await prepareArticle(
              { article: art, stockData: [] },
              state.user.proprietaire,
              data.Name,
              data.Group,
              art.quantity
            );
          }
        }
      }
      if (!data._id.startsWith("inconnu")) {
        let panier = await getPanierTransport(
          state.user.proprietaire,
          data._id
        );
        state.restoreTransportCart = data.TransportCart;
        state.panierTransportValue = panier;
      }
    },
    SET_FAMILLE_CATALOGUE: async (state, data) => {
      state.familleCatalogueSelected = data;
    },
    SET_FAMILLE_SELECTED: async (state, data) => {
      state.savedFamilleSelected = state.familleSelected;
      state.familleSelected = data.famille;
      if (data.carId) {
        state.carIdSelected = data.carId;
      }
    },
    SET_CONF_GEN_ART_SELECTED: async (state, data) => {
      state.confGenArtSelected = data;
      router.push("/ConfigurationGenArtBrand");
    },
    SET_CONTROLE_PRELIMINAIRE_SELECTED: (state, data) => {
      state.controlePreliminaireSelected = data;
    },
    SEND_TO_RESTORE_TRANSPORT_CART: async (state, data) => {
      state.restoreTransportCart = data.TransportCart;
      if (!data._id.startsWith("inconnu")) {
        let panier = await getPanierTransport(
          state.user.proprietaire,
          data._id
        );
        state.panierTransportValue = panier;
      }
    },
    SET_DETAIL_PANIER_CLIENT: async (state, data) => {
      state.idDetailPanierClient = data;
    },
    SET_SEARCH_REGUL_STOCK: async (state, data) => {
      state.prefixRegulStock = data.prefix;
      state.brandRegulStock = data.brand;
    },
    SET_TRANSFERT_DATA: async (state, data) => {
      state.transfertData = data;
    },
    SET_ID_APPOINTMENT: async (state, data) => {
      state.idAppointment = data;
    },
    SELECT_SOUS_CATEGORY_UNIVERSELLE: async (state, data) => {
      state.sousCategoryUniverselleSelected = data;
    },
    SELECT_LINK_UNIVERSELLE: async (state, data) => {
      console.log(data);
      if (data.state == "add") {
        state.linkUniverselSelected = data.uni;
      } else {
        state.linkUniverselSelected = null;
      }
    },
  },
  actions: {
    deconnecter: (store) => store.commit("DECONNECTER"),
    connecter: (store) => store.commit("CONNECTER"),
    ouvrirCaisse: (store) => store.commit("OUVRIR_CAISSE"),
    fermerCaisse: (store) => store.commit("FERMER_CAISSE"),
    updateUid: (store, uid) => store.commit("UPDATE_UID", uid),
    setUser: async (store, uid) => {
      const user = await axios.get(
        `${process.env.VUE_APP_API}/user/getone/${uid}`
      );
      const userData = user.data;
      store.commit("SET_USER", userData);
    },
    clearVHloadingstate: (store, data) => {
      store.commit("CLEAR_VH_LOADING_STATE", data);
    },
    suppVHloadingstate: (store, data) => {
      store.commit("SUPP_VH_LOADING_STATE", data);
    },
    addModifyDoc: (store, data) => {
      store.commit("ADD_MODIFY_DOC", data);
    },
    suppModifyDoc: (store, data) => {
      store.commit("SUPP_MODIFY_DOC", data);
    },
    addVH_a02Tab: (store, data) => {
      store.commit("ADD_VH_A02TAB", data);
    },
    suppVH_a02Tab: (store, data) => {
      store.commit("SUPP_VH_A02TAB", data);
    },
    addVH_PrixLot: (store, data) => {
      store.commit("ADD_VH_PrixLot", data);
    },
    suppVH_PrixLot: (store, data) => {
      store.commit("SUPP_VH_PrixLot", data);
    },
    clearVH_a02Tab: (store, data) => {
      store.commit("CLEAR_VH_A02TAB", data);
    },
    refresh_a02Tab: (store, data) => {
      store.commit("REFRESH_VH_A02TAB", data);
    },
    checkArticleVH: (store, data) => {
      store.commit("CHECK_ARTICLE_VH", data);
    },
    addcommandereception: (store, data) =>
      store.commit("ADD_COMMANDE_RECEPTION", data),
    getplateform: (store, data) => {
      store.commit("GET_PLATEFORM", data);
    },
    addStockArticle: (store, data) => {
      store.commit("ADD_STOCKARTICLE", data);
    },
    suppStockArticle: (store, data) => {
      store.commit("SUPP_STOCKARTICLE", data);
    },
    setMvtStock: (store, data) => {
      store.commit("SET_MVTSTOCK", data);
    },
    addactionArticle: (store, data) => {
      store.commit("ADD_ACTIONARTICLE", data);
    },
    suppactionArticle: (store, data) => {
      store.commit("SUPP_ACTIONARTICLE", data);
    },
    clearactionArticle: (store) => {
      store.commit("CLEAR_ACTIONARTICLE");
    },
    addcaisse: (store, data) => {
      store.commit("ADD_CAISSE", data);
    },
    suppcaisse: (store, data) => {
      store.commit("SUPP_CAISSE", data);
    },
    clearcaisse: (store) => {
      store.commit("CLEAR_CAISSE");
    },
    addhistoriquecaisse: (store, data) => {
      store.commit("ADD_HISTORIQUE_CAISSE", data);
    },
    clearhistoriquecaisse: (store, data) => {
      store.commit("CLEAR_HISTORIQUE_CAISSE", data);
    },
    vehiculetrue: (store) => {
      store.commit("VEHICULE_TRUE");
    },
    vehiculefalse: (store) => {
      store.commit("VEHICULE_FALSE");
    },
    addvehicule: (store, data) => {
      store.commit("ADD_VEHICULE", data);
    },
    clearvehicule: (store) => {
      store.commit("CLEAR_VEHICULE");
    },
    updateactionArticle: (store, data) => {
      store.commit("UPDATE_ACTIONARTICLE", data);
    },
    updatestockactionArticle: (store, data) => {
      store.commit("UPDATE_STOCK_ACTIONARTICLE", data);
    },
    updateremiseactionArticle: (store, data) => {
      store.commit("UPDATE_REMISE_ACTIONARTICLE", data);
    },
    addtempCommande: (store, data) => {
      store.commit("ADD_TEMP_COMMANDE", data);
    },
    searchCrossOe: (store, data) => {
      store.commit("SEARCH_CROSS_OE", data);
    },
    searchCross: (store, data) => {
      store.commit("SEARCH_CROSS", data);
    },
    searchDirect: (store, data) => {
      store.commit("SEARCH_DIRECT", data);
    },
    getPrix: (store, data) => {
      store.commit("GET_PRIX", data);
    },
    searchTranslatedDirect: (store, data) => {
      store.commit("SEARCH_TRANSLATED_DIRECT", data);
    },
    searchTranslatedDirectAdd: (store, data) => {
      store.commit("SEARCH_TRANSLATED_DIRECT_ADD", data);
    },
    storecarcattoken: (store, data) => {
      store.commit("STORE_CARCAT_TOKEN", data);
    },
    storerefreshcarcattoken: (store, data) => {
      store.commit("STORE_REFRESH_CARCAT_TOKEN", data);
    },
    clearcarcattoken: (store, data) => {
      store.commit("CLEAR_CARCAT_TOKEN", data);
    },
    addpaniercarcat: (store, data) => {
      store.commit("ADD_PANIER_CARCAT", data);
    },
    setcarcatlinks: (store, data) => {
      store.commit("SET_CARCAT_LINKS", data);
    },
    addclientfocus: (store, data) => {
      store.commit("ADD_CLIENT_FOCUS", data);
    },
    clearclientfocus: (store, data) => {
      store.commit("CLEAR_CLIENT_FOCUS", data);
    },
    setprixquantitatif: (store, data) => {
      store.commit("SET_PRIX_QUANTITATIF", data);
    },
    removeprixquantitatif: (store, data) => {
      store.commit("REMOVE_PRIX_QUANTITATIF", data);
    },
    suppclienttab: (store, data) => {
      store.commit("SUPP_CLIENT_TAB", data);
    },
    updaterefclient: (store, data) => {
      store.commit("SET_REFCLIENT", data);
    },
    createcomptoirsession: async (store, data) => {
      store.commit("CREATE_COMPTOIR_SESSION", data);
    },
    savecomptoirsession: (store, data) => {
      store.commit("SAVE_COMPTOIR_SESSION", data);
    },
    setcomptoirsession: (store, data) => {
      store.commit("SET_COMPTOIR_SESSION", data);
    },
    setfraisdeport: (store, data) => {
      store.commit("SET_FRAISDEPORT", data);
    },
    setremisearticle: (store, data) => {
      store.commit("SET_REMISEARTICLE", data);
    },
    setquantityarticle: (store, data) => {
      store.commit("SET_QUANTITYARTICLE", data);
    },
    removecomptoirsession: async (store, data) => {
      store.commit("REMOVE_COMPTOIR_SESSION", data);
    },
    getallcomptoirsession: (store, data) => {
      store.commit("GET_ALL_COMPTOIR", data);
    },
    setartinventory: (store, data) => {
      store.commit("SET_ART_INVENTORY", data);
    },
    removeartinventory: (store, data) => {
      store.commit("REMOVE_ART_INVENTORY", data);
    },
    setinventoryprocessing: (store, data) => {
      store.commit("SET_INVENTORY_PROCESSING", data);
    },
    removeinventoryprocessing: (store, data) => {
      store.commit("REMOVE_INVENTORY_PROCESSING", data);
    },
    addtabdepot: (store, data) => {
      store.commit("ADD_TAB_DEPOT", data);
    },
    getpanierfournisseur: (store, data) => {
      store.commit("GET_PANIER_FOURNISSEUR", data);
    },
    getfournisseurID: (store, data) => {
      store.commit("GET_FOURNISSEUR_ID", data);
    },
    // getfournisseurIDprixnet: (store, data) => {
    //   store.commit("GET_FOURNISSEUR_ID_PRIXNET", data);
    // },
    // getfournisseurIDprixnetsaved: (store, data) => {
    //   store.commit("GET_FOURNISSEUR_ID_PRIXNET_SAVED", data);
    // },
    getclientchoosedtab: (store, data) => {
      store.commit("GET_CLIENT_CHOOSED_TAB", data);
    },
    getclientchoosedtabprixnet: (store, data) => {
      store.commit("GET_CLIENT_CHOOSED_TAB_PRIXNET", data);
    },
    getclientchoosedtabprixnetsaved: (store, data) => {
      store.commit("GET_CLIENT_CHOOSED_TAB_PRIXNET_SAVED", data);
    },
    addetatretour: (store, data) => {
      store.commit("ADD_ETAT_RETOUR", data);
    },
    suppetatretour: (store, data) => {
      store.commit("SUPP_ETAT_RETOUR", data);
    },
    updateqtyactionarticle: (store, data) => {
      store.commit("UPDATE_QTY_ACTIONARTICLE", data);
    },
    setbrandselected: (store, data) => {
      store.commit("SET_BRAND_SELECTED", data);
    },
    setgenericarticleselected: (store, data) => {
      store.commit("SET_GENERICARTICLE_SELECTED", data);
    },
    setagselected: (store, data) => {
      store.commit("SET_AG_SELECTED", data);
    },
    setcatalogueagselected: (store, data) => {
      store.commit("SET_CATALOGUE_AGSELECTED", data);
    },
    setArticleInfo: (store, data) => {
      store.commit("SET_ARTICLE_INFO", data);
    },
    setSelectionCarId: (store, data) => {
      store.commit("SET_SELECTION_CAR_ID", data);
    },
    setTdCarId: (store, data) => {
      store.commit("SET_TD_CAR_ID", data);
    },
    setSelectionCarData: (store, data) => {
      store.commit("SET_SELECTION_CAR_DATA", data);
    },
    clearClientTab: (store, data) => {
      store.commit("CLEAR_CLIENT_TAB", data);
    },
    adddatanonconsigne: (store, data) => {
      store.commit("ADD_DATA_NON_CONSIGNE", data);
    },
    suppdatanonconsigne: (store, data) => {
      store.commit("SUPP_DATA_NON_CONSIGNE", data);
    },
    cleardatanonconsigne: (store, data) => {
      store.commit("CLEAR_DATA_NON_CONSIGNE", data);
    },
    setdsvcheck: (store, data) => {
      store.commit("SET_DSV_CHECK", data);
    },
    adddataconsigne: (store, data) => {
      store.commit("ADD_DATA_CONSIGNE", data);
    },
    updateremisedataconsigne: (store, data) => {
      store.commit("UPDATE_REMISE_DATA_CONSIGNE", data);
    },
    updateqtydataconsigne: (store, data) => {
      store.commit("UPDATE_QTY_DATA_CONSIGNE", data);
    },
    suppdataconsigne: (store, data) => {
      store.commit("SUPP_DATA_CONSIGNE", data);
    },
    cleardataconsigne: (store, data) => {
      store.commit("CLEAR_DATA_CONSIGNE", data);
    },
    searchRefFournisseurDetail: (store, data) => {
      store.commit("SEARCH_REF_FOURNISSEUR_DETAIL", data);
    },
    searchRefFournisseur: (store, data) => {
      store.commit("SEARCH_REF_FOURNISSEUR", data);
    },
    searchRefFournisseurGen: (store, data) => {
      store.commit("SEARCH_REF_FOURNISSEUR_GEN", data);
    },
    setArticle: (store, data) => {
      store.commit("SET_ARTICLE", data);
    },
    setBdlsRegroupes: (store, data) => {
      store.commit("SET_BDLS_REGROUPES", data);
    },
    getCmdPreparation: (store, data) => {
      store.commit("GET_CMD_PREPARATION", data);
    },
    addDetailRetour: (store, data) => {
      store.commit("ADD_DETAIL_RETOUR", data);
    },
    setPreparationFournisseur: (store, data) => {
      store.commit("SET_PREPARATION_FOURNISSEUR", data);
    },
    setbdlselected: (store, data) => {
      store.commit("SET_BDL_SELECTED", data);
    },
    setdevisselected: (store, data) => {
      store.commit("SET_DEVIS_SELECTED", data);
    },
    setbcselected: (store, data) => {
      store.commit("SET_BC_SELECTED", data);
    },
    setfactureselected: (store, data) => {
      store.commit("SET_FACTURE_SELECTED", data);
    },
    setfacturationselected: (store, data) => {
      store.commit("SET_FACTURATION_SELECTED", data);
    },
    getTransporteurId: (store, data) => {
      store.commit("GET_TRANSPORTEUR_ID", data);
    },
    updateactionarticlesession: async (store, data) => {
      store.commit("UPDATE_ACTION_ARTICLE_SESSION", data);
    },
    setfamillecatalogue: async (store, data) => {
      store.commit("SET_FAMILLE_CATALOGUE", data);
    },
    setfamilleselected: async (store, data) => {
      store.commit("SET_FAMILLE_SELECTED", data);
    },
    setConfGenArtSelected: async (store, data) => {
      store.commit("SET_CONF_GEN_ART_SELECTED", data);
    },
    setcpselected: (store, data) => {
      store.commit("SET_CONTROLE_PRELIMINAIRE_SELECTED", data);
    },
    sendToRestoreTransportCart: async (store, data) => {
      store.commit("SEND_TO_RESTORE_TRANSPORT_CART", data);
    },
    setDetailPanierClient: async (store, data) => {
      store.commit("SET_DETAIL_PANIER_CLIENT", data);
    },
    setSearchRegulStock: async (store, data) => {
      store.commit("SET_SEARCH_REGUL_STOCK", data);
    },
    setTransfertData: async (store, data) => {
      store.commit("SET_TRANSFERT_DATA", data);
    },
    setIdAppointment: async (store, data) => {
      store.commit("SET_ID_APPOINTMENT", data);
    },
    selectSousCategoryUniverselle: async (store, data) => {
      store.commit("SELECT_SOUS_CATEGORY_UNIVERSELLE", data);
    },
    selectLinkUniversel: async (store, data) => {
      store.commit("SELECT_LINK_UNIVERSELLE", data);
    },
  },
  getters: {
    auth: (state) => state.auth,
    generalloading: (state) => state.generalLoading,
    caisse: (state) => state.caisse,
    caisseContenu: (state) => state.caisseContenu,
    historiqueCaisse: (state) => state.historiqueCaisse,
    uid: (state) => state.uid,
    user: (state) => state.user,
    plateform: (state) => state.plateform,
    actionArticle: (state) => state.actionArticle,
    tempCommande: (state) => state.tempCommande,
    vehicule: (state) => state.vehicule,
    vehiculeSet: (state) => state.vehiculeSet,
    vehiculeVin: (state) => state.vehiculeVin,
    vehiculeTD: (state) => state.vehiculeTD,
    vehiculeTDData: (state) => state.vehiculeTDData,
    crossoetab: (state) => state.crossOeTab,
    prixtab: (state) => state.prixTab,
    stocktab: (state) => state.stockTab,
    comptoirsearchtab: (state) => state.comptoirSearchTab,
    tabRecherchePS: (state) => state.tabRecherchePS,
    carcatToken: (state) => state.carcatToken,
    carCatLink: (state) => state.carCatLink,
    carCatReference: (state) => state.carCatReference,
    clientFocus: (state) => state.clientFocus,
    commandeReception: (state) => state.commandeReception,
    VH_a02Tab: (state) => state.VH_a02Tab,
    VH_cmd_Loading: (state) => state.VH_cmd_Loading,
    VH_cmd_state: (state) => state.VH_cmd_state,
    VH_articleTab: (state) => state.VH_articleTab,
    VH_PrixLot: (state) => state.VH_PrixLot,
    refClient: (state) => state.refClient,
    comptoirSession: (state) => state.comptoirSession,
    comptoirSessionActive: (state) => state.comptoirSessionActive,
    artInfosInventory: (state) => state.artInfosInventory,
    inventaireProcessing: (state) => state.inventaireProcessing,
    VH_RefreshLoading: (state) => state.VH_RefreshLoading,
    tabDepot: (state) => state.tabDepot,
    docToModify: (state) => state.docToModify,
    fournisseurID: (state) => state.fournisseurID,
    clientChoosedTab: (state) => state.clientChoosedTab,
    brandSelected: (state) => state.brandSelected,
    genericArticleSelected: (state) => state.genericArticleSelected,
    assemblyGroupSelected: (state) => state.assemblyGroupSelected,
    catalogueAGSelected: (state) => state.catalogueAGSelected,
    articleInfoData: (state) => state.articleInfoData,
    selectionCarId: (state) => state.selectionCarId,
    selectionCarData: (state) => state.selectionCarData,
    dataConsigne: (state) => state.dataConsigne,
    dataNonConsigne: (state) => state.dataNonConsigne,
    dsvCheck: (state) => state.dsvCheck,
    Ref_fournisseur_search: (state) => state.Ref_fournisseur_search,
    Code_marque_search: (state) => state.Code_marque_search,
    Ref_fournisseur_gen_search: (state) => state.Ref_fournisseur_gen_search,
    genArt_search: (state) => state.genArt_search,
    article: (state) => state.article,
    recapBdlsRegroupes: (state) => state.recapBdlsRegroupes,
    dateEditionBdlsRegroupes: (state) => state.dateEditionBdlsRegroupes,
    dateEcheanceBdlsRegroupes: (state) => state.dateEcheanceBdlsRegroupes,
    cmdPreparation: (state) => state.cmdPreparation,
    tabDetailRetour: (state) => state.tabDetailRetour,
    preparationFournisseur: (state) => state.preparationFournisseur,
    bdlSelected: (state) => state.bdlSelected,
    devisSelected: (state) => state.devisSelected,
    bcSelected: (state) => state.bcSelected,
    factureSelected: (state) => state.factureSelected,
    facturationSelected: (state) => state.facturationSelected,
    transporteurId: (state) => state.transporteurId,
    familleCatalogueSelected: (state) => state.familleCatalogueSelected,
    familleSelected: (state) => state.familleSelected,
    savedFamilleSelected: (state) => state.savedFamilleSelected,
    carIdSelected: (state) => state.carIdSelected,
    confGenArtSelected: (state) => state.confGenArtSelected,
    controlePreliminaireSelected: (state) => state.controlePreliminaireSelected,
    restoreTransportCart: (state) => state.restoreTransportCart,
    panierTransportValue: (state) => state.panierTransportValue,
    idDetailPanierClient: (state) => state.idDetailPanierClient,
    prefixRegulStock: (state) => state.prefixRegulStock,
    brandRegulStock: (state) => state.brandRegulStock,
    transfertData: (state) => state.transfertData,
    idAppointment: (state) => state.idAppointment,
    sousCategoryUniverselleSelected: (state) =>
      state.sousCategoryUniverselleSelected,
    linkUniverselSelected: (state) => state.linkUniverselSelected,
  },
  modules: {},
  plugins: [createPersistedState()],
});
