<template>
  <router-link to="/setarticles">
    <div
      id="retour"
      class="flex justify-start bg-slate-100 rounded-lg px-1 mb-2 w-fit hover:bg-white border border-blue-400"
    >
      <img src="../assets/RetourIcon.png" class="w-6" />
      <span style="color: #5976e8">Retour</span>
    </div>
  </router-link>

  <div class="mt-6">
    <div class="grid grid-cols-3 gap-4">
      <div class="flex flex-row justify-between p-2 bg-white rounded shadow">
        <div>Prix moyen pondéré</div>
        <div>{{ parseFloat(prixachatDistribution.pmp).toFixed(2) }} €</div>
      </div>
      <div class="flex flex-row justify-between p-2 bg-white rounded shadow">
        <div>Dernier prix d'achat</div>
        <div>{{ parseFloat(prixachatDistribution.last).toFixed(2) }} €</div>
      </div>
      <div class="flex flex-row justify-between p-2 bg-white rounded shadow">
        <div>Prix d'achat (Condtition)</div>
        <div>
          {{ parseFloat(prixachatDistribution.condition).toFixed(2) }} €
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-row w-full mt-6">
    <div class="w-2/5">
      <apexchart
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    <div class="w-3/5">
      <table class="w-full text-sm text-left text-gray-500">
        <thead class="text-xs text-white uppercase bg-bluevk">
          <tr>
            <th scope="col" class="px-6 py-3">Fournisseur</th>
            <th scope="col" class="px-6 py-3">Quantité</th>
            <th scope="col" class="px-6 py-3">Prix d'achat</th>
            <th scope="col" class="px-6 py-3">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b hover:bg-orangevk hover:text-white cursor-pointer"
            v-for="(pa, index) in prixachatTab"
            :key="index"
          >
            <td class="px-6 py-4">{{ pa.prix[0].fournisseur }}</td>

            <td class="px-6 py-4">{{ pa.prix[0].quantity }}</td>

            <td class="px-6 py-4">
              {{ parseFloat(pa.prix[0].prix_achat).toFixed(2) }} €
            </td>
            <td class="px-6 py-4">{{ pa.prix[0].date }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="flex w-full mt-6">
    <table class="w-full text-sm text-left text-gray-500">
      <thead class="text-xs text-white uppercase bg-bluevk">
        <tr>
          <th scope="col" class="px-6 py-3">N° BL</th>
          <th scope="col" class="px-6 py-3">Date</th>
          <th scope="col" class="px-6 py-3">Client</th>
          <th scope="col" class="px-6 py-3">Quantité</th>
          <th scope="col" class="px-6 py-3">Prix</th>
          <th scope="col" class="px-6 py-3">Prix de vente</th>
          <th scope="col" class="px-6 py-3">
            Marge (seuil
            <input
              type="number"
              class="w-12 text-black"
              v-model="seuil_pourcent"
            />
            %)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b hover:bg-orangevk hover:text-white cursor-pointer"
          v-for="(ma, indexMarge) in margeTab"
          :key="indexMarge"
        >
          <td class="px-6 py-4">{{ ma.Numero }}</td>

          <td class="px-6 py-4">{{ ma.Date }}</td>

          <td class="px-6 py-4">
            {{ ma.Client }}
          </td>
          <td class="px-6 py-4">{{ ma.quantity }}</td>
          <td class="px-6 py-4">{{ ma.Prix_euro }} €</td>
          <td class="px-6 py-4">
            <div v-if="ma.Prix_vente">
              <div
                v-if="ma.PrixFournisseurType == 'prixnet'"
                class="flex flex-col"
              >
                <span
                  >Prix de vente :
                  {{ parseFloat(ma.Prix_vente).toFixed(2) }} €</span
                >
                <span
                  >Prix d'achat :
                  {{ parseFloat(ma.PrixAchat).toFixed(2) }} €</span
                >
              </div>
              <div v-else class="flex flex-col">
                <span
                  >Prix de vente :
                  {{
                    parseFloat(ma.Prix_euro * (1 - ma.remise / 100)).toFixed(2)
                  }}
                  €</span
                >
                <span
                  >Prix d'achat :
                  {{ parseFloat(ma.PrixAchat).toFixed(2) }} €</span
                >
              </div>
            </div>
            <div
              v-else
              class="p-1 bg-orange-400 text-white rounded shadow w-fit"
            >
              ?
            </div>
          </td>
          <td class="px-6 py-4">
            <div
              class="flex flex-col p-1 bg-red-500 text-white rounded shadow w-fit"
              v-if="ma.marge_pourcent < seuil_pourcent"
            >
              <div>{{ ma.marge_euro }} €</div>
              <div>{{ ma.marge_pourcent }} %</div>
            </div>
            <div
              v-else
              class="flex flex-col p-1 bg-green-500 text-white rounded shadow w-fit"
            >
              <div>{{ ma.marge_euro }} €</div>
              <div>{{ ma.marge_pourcent }} %</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getPrixAchat,
  getPrixAchatPond,
  getPrixAchatDistribution,
} from "@/hooks/articles/articles.ts";
import { margeBdl } from "@/hooks/statistiques/ventes.ts";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "StockArticle",
  computed: {
    ...mapGetters(["article", "user"]),
  },
  data() {
    return {
      prixachatTab: [],
      prixachatDistribution: [],
      margeTab: [],
      seuil_pourcent: 30,
      series: [
        {
          name: "Prix d'achat",
          data: [31.98, 35.99, 34, 47],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
        },
        forecastDataPoints: {
          count: 7,
        },
        stroke: {
          width: 5,
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: ["9/4/2023", "9/4/2023", "9/5/2023", "9/5/2023"],
          tickAmount: 10,
          labels: {
            formatter: function (value, timestamp, opts) {
              return opts.dateFormatter(new Date(timestamp), "dd MMM");
            },
          },
        },
        title: {
          text: "Evolution du prix d'achat (PAS ACTIVÉ POUR LE MOMENT)",
          align: "left",
          style: {
            fontSize: "16px",
            color: "#666",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#FDD835"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        yaxis: {
          min: 0,
          max: 60,
        },
      },
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  methods: {
  },
  async mounted() {
    this.prixachatTab = await getPrixAchat(
      this.user.proprietaire,
      this.article.Prefixe_tarif,
      this.article.Ref_fournisseur,
      this.article.Code_EAN
    );
    this.prixachatDistribution = await getPrixAchatDistribution(
      this.user.proprietaire,
      this.article.Prefixe_tarif,
      this.article.Ref_fournisseur,
      this.article.Code_EAN
    );
    this.margeTab = await margeBdl(
      this.user.proprietaire,
      this.article.Ref_fournisseur,
      this.article.Prefixe_tarif,
      this.article.Code_EAN
    );
  },
};
</script>

<style scoped></style>
