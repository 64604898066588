import axios from "axios";

export async function margeBdl(
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_EAN: string
) {
  const marge = await axios.post(
    `${process.env.VUE_APP_API}/venteStat/margeBdl`,
    {
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
      Prefixe_tarif: Prefixe_tarif,
      Code_EAN: Code_EAN,
    }
  );

  return marge.data;
}

// Total chiffre d'affaires d'un client en filtrant par date
export async function getTurnoverAndMargin(
  plateform: string,
  clientID: string,
  startDate: string,
  endDate: string
) {
  const marge = await axios.post(
    `${process.env.VUE_APP_API}/venteStat/getTurnoverAndMargin`,
    {
      plateform: plateform,
      clientID: clientID,
      startDate: startDate,
      endDate: endDate,
    }
  );

  return marge.data;
}
