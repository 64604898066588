<template>
  <div class="flex items-center">
    <div
      id="retour"
      class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
      @click="$router.push('/articlehub')"
    >
      <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
      <span>Retour</span>
    </div>
    <div class="flex flex-1 justify-center">
      <titrePages :title="'Liste des articles divers'" />
    </div>
  </div>

  <div>
    <div class="flex flex-col mt-3 sm:flex-row">
      <searchInput
        placeholder="Rechercher dans articles divers"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
        class="mt-2"
      />
    </div>
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center space-x-2 mt-2">
        <div
          :class="[
            selectedCount > 0
              ? 'p-1 text-sm text-blue-800 rounded-lg bg-blue-100'
              : 'p-1 text-sm text-gray-800 rounded-lg bg-gray-100',
          ]"
        >
          {{ selectedCount }} sel.
        </div>
        <button
          @click="selectAll()"
          class="p-1 text-sm bg-green-500 text-white rounded"
        >
          Tout sel.
        </button>
        <button
          @click="unselectAll()"
          class="p-1 text-sm bg-red-500 text-white rounded"
        >
          Tout desel.
        </button>
        <button
          v-if="selectedCount > 0"
          @click="createArticles()"
          class="p-1 text-sm bg-blue-500 text-white rounded"
        >
          Créer les articles sélectionnés
        </button>
      </div>
      <div>
        <button
          @click="deleteSelection()"
          v-if="selectedCount > 0"
          class="p-1 bg-red-500 text-white text-sm rounded"
        >
          Supprimer la selection
        </button>
      </div>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  sel.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Ref
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Prix
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Article créé
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(a, index) in Articles"
                :key="index"
                :class="['', a.selected ? 'bg-blue-100' : 'hover:bg-gray-50']"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <input
                    type="checkbox"
                    class="form-checkbox"
                    v-model="a.selected"
                    @change="saveSelection(a._id, a.selected)"
                  />
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        <span>{{ a.Ref_fournisseur }}</span>
                        <span
                          class="p-1 ml-4 bg-blue-500 text-white rounded shadow"
                        >
                          {{ a.Prefixe_tarif }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    {{ a.Code_EAN }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <input
                    type="text"
                    class="p-1 border border-gray-200 rounded"
                    placeholder="à remplir"
                    v-model="a.Code_marque"
                    @change="saveArticleInfo(index)"
                  />
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <input
                    type="text"
                    class="p-1 border border-gray-200 rounded"
                    placeholder="à remplir"
                    v-model="a.Description"
                    @change="saveArticleInfo(index)"
                  />
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    <input
                      type="number"
                      class="p-1 border border-gray-200 rounded"
                      placeholder="à remplir"
                      v-model="a.Prix_euro"
                      @change="saveArticleInfo(index)"
                    />
                    €
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <span v-if="a.transfered" class="text-green-500">Oui</span>
                  <span v-else class="text-red-500">Non</span>
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="articlesLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(articlesLength / 10)"
            :clickFunction="getArticles"
            :currentPage="currentPage"
            :typeList="'articles'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'articles'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import pageNumber from "../components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "ArticleDivers",
  data() {
    return {
      Articles: [],
      Familles: [],
      selectedArticle: [],
      articleSearch: "",
      errorrecherchearticle: false,
      selectedCount: 0,

      showArticle: false,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      articlesLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,
    };
  },
  components: {
    pageNumber,
    searchInput,
    titrePages,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getArticles(skipValue, limitValue) {
      this.currentPage = skipValue;
      let response = await axios.post(
        `${process.env.VUE_APP_API}/saisieRapide/getAll`,
        {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
          sort_by: "_id",
          sort_order: -1,
        }
      );
      this.Articles = response.data.articles;
      this.articlesLength = response.data.count;
    },
    async countSelected() {
      let countS = await axios.post(
        `${process.env.VUE_APP_API}/saisieRapide/countSelected`,
        {
          plateform: this.user.proprietaire,
        }
      );
      this.selectedCount = countS.data;
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    async selectArticle(index) {
      this.Articles[index].selected = !this.Articles[index].selected;
      await this.saveSelection(
        this.Articles[index]._id,
        this.Articles[index].selected
      );
    },
    async saveArticleInfo(index) {
      await axios.post(`${process.env.VUE_APP_API}/saisieRapide/update`, {
        _id: this.Articles[index]._id,
        data: {
          Code_marque: this.Articles[index].Code_marque,
          Description: this.Articles[index].Description,
          Prix_euro: this.Articles[index].Prix_euro,
        },
      });
    },
    async saveSelection(_id, value) {
      await axios.post(`${process.env.VUE_APP_API}/saisieRapide/update`, {
        _id: _id,
        data: {
          selected: value,
        },
      });

      await this.countSelected();
    },
    async deleteSelection() {
      await axios.post(`${process.env.VUE_APP_API}/saisieRapide/delete`, {
        plateform: this.user.proprietaire,
      });
      this.countSelected();
      this.getArticles(this.currentPage, 10);
    },
    async selectAll() {
      await axios.post(`${process.env.VUE_APP_API}/saisieRapide/selectAll`, {
        plateform: this.user.proprietaire,
      });
      this.countSelected();
      this.getArticles(this.currentPage, 10);
    },
    async unselectAll() {
      await axios.post(`${process.env.VUE_APP_API}/saisieRapide/unselectAll`, {
        plateform: this.user.proprietaire,
      });
      this.countSelected();
      this.getArticles(this.currentPage, 10);
    },
    async createArticles() {
      await axios.post(
        `${process.env.VUE_APP_API}/saisieRapide/transfertToArticle`,
        {
          plateform: this.user.proprietaire,
        }
      );
      this.countSelected();
      this.getArticles(this.currentPage, 10);
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getArticles(skipValue, limitValue);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/saisieRapide/search`, {
            plateform: this.user.proprietaire,
            search: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
            sort_by: "_id",
            sort_order: -1,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.Articles = response.data.articles;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
  },
  mounted() {
    this.getArticles(1, 10);
    this.getFournisseur();
    this.pageSwitch();
    this.countSelected();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
