<template>
  <div>
    <button
      class="flex mx-auto"
      @click="$router.push('/catalogueHubUniversel')"
    >
      Catalogue Universel
    </button>
    <div class="flex justify-center mt-10 items-center">
      <div class="flex-row bg-white p-4 rounded shadow w-4/6 h-fit">
        <!-- PLAQUE -->
        <div class="flex justify-center">
          <div class="relative" v-if="vehiculeSet">
            <div class="plate-immat">
              <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
              <input
                type="text"
                :placeholder="vehicule[0].data.AWN_immat"
                name="plate-number"
                class="uppercase plate-number focus:outline-2 outline-sky-300"
                v-model="plaque"
                v-on:keyup.enter="getPlaque()"
              />
              <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
            </div>
          </div>
          <div class="relative" v-else>
            <div class="plate-immat">
              <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
              <input
                type="text"
                placeholder="AB-123-CD"
                name="plate-number"
                class="uppercase plate-number focus:outline-2 outline-sky-300"
                v-model="plaque"
                v-on:keyup.enter="getPlaque()"
              />
              <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
            </div>
          </div>
        </div>
        <div class="relative">
          <PulseLoader color="#2d74da" v-show="isLoading" />
        </div>
        <div class="relative" v-show="errorImmat">
          <span class="material-icons-round text-orange-400 ml-3"> error </span>
        </div>
        <!-- VIN -->
        <!-- <div class="flex justify-center items-center mt-4">
          <input
            class="w-2/6 p-3 focus:outline-2 outline-sky-300"
            type="text"
            placeholder="Rechercher par VIN"
          />
        </div> -->
        <!-- type-mine -->
        <!-- <div class="flex justify-center mt-4">
          <input
            class="w-2/6 p-3 focus:outline-2 outline-sky-300"
            type="text"
            placeholder="Rechercher par type-mine"
          />
        </div> -->
        <div class="flex justify-center mt-8" v-if="!manuSelected.manuName">
          <div class="grid grid-cols-7 gap-2">
            <div
              v-for="(manuf, indexManuf) in manuTab"
              :key="indexManuf"
              class="bg-gray-100 p-2 rounded-sm shadow hover:bg-gray-300 cursor-pointer"
              @click="getModelSeries(manuf.manuId, manuf.manuName)"
            >
              {{ manuf.manuName }}
            </div>
          </div>
        </div>
        <div class="flex flex-row mt-8 space-x-2">
          <div
            class="flex flex-row space-x-2 items-center p-2 bg-gray-100 rounded-sm shadow w-fit"
            v-if="manuSelected.manuName"
          >
            <span class="">{{ manuSelected.manuName }}</span>
            <span
              class="material-icons-outlined cursor-pointer text-base"
              @click="deleteManu()"
            >
              clear
            </span>
          </div>
          <div
            class="flex flex-row space-x-2 items-center p-2 bg-gray-100 rounded-sm shadow w-fit"
            v-if="modelselected"
          >
            <span class="">{{ modelselected }}</span>
            <span
              class="material-icons-outlined cursor-pointer text-base"
              @click="deleteModel()"
            >
              clear
            </span>
          </div>
        </div>
        <div
          class="flex justify-center mt-8"
          v-if="!modelselected && manuSelected.manuName"
        >
          <div class="grid grid-cols-3 gap-2">
            <div
              v-for="(model, indexModel) in modelTab"
              :key="indexModel"
              class="p-2 hover:bg-gray-200 hover:rounded-sm hover:shadow justify-center cursor-pointer"
              @click="getVehicleIdsByCriteria(model.modelId, model.modelname)"
            >
              <span>{{ model.modelname }} </span>
              <span
                v-if="model.yearOfConstrFrom"
                class="p-1 ml-1 bg-blue-100 rounded-sm shadow"
                >{{ model.yearOfConstrFrom.toString().substring(0, 4) }}</span
              >
              <span
                v-if="model.yearOfConstrTo"
                class="p-1 ml-1 bg-blue-100 rounded-sm shadow"
              >
                {{ model.yearOfConstrTo.toString().substring(0, 4) }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="flex justify-center mt-8"
          v-if="modelselected && manuSelected.manuName"
        >
          <div class="grid grid-cols-3 gap-2">
            <div
              v-for="(vehicule, indexVehicule) in vehiculeTab"
              :key="indexVehicule"
              class="p-2 hover:bg-gray-200 hover:rounded-sm hover:shadow justify-center cursor-pointer"
              @click="getCar(vehicule.carId)"
            >
              {{ vehicule.carName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name:"MyTecDocSearch",
  data() {
    return {
      isLoading: false,
      errorImmat: false,
      manuTab:[],
      modelTab:[],
      vehiculeTab:[],
      manuSelected:{},
      modelselected:""
    }
  },
  computed: {
    ...mapGetters([
      "user",
      "vehicule",
      "vehiculeSet",
    ]),
  },
  methods: {
    ...mapActions([
      "addvehicule",
      "clearvehicule",
      "setSelectionCarId",
      "setSelectionCarData"
    ]),
    getPlaque() {
      this.clearvehicule();
      this.isLoading = true;
      this.errorImmat = false;
      this.vehiculeSet = true;

      axios
        .post(`${process.env.VUE_APP_API}/plaque`, {
          plaque: this.plaque,
          Owner: this.user.proprietaire,
        })
        .then((response) => {
          // this.addvehicule(response.data);
          if (response.data.error) {
            console.log(response.data.message);
            this.errorImmat = true;
          } else {
            this.addvehicule(response.data);
            this.errorImmat = false;
            this.$router.push("/catalogueHubNew");
            this.plaque = "";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("erooooooooor");
          console.error(error);
          this.isLoading = false;
        });
    },
    getManufacturer(){
      axios
        .get(`${process.env.VUE_APP_API}/catalogue/getManufacturer`).then((res)=>{
          this.manuTab = res.data;
        })
    },
    getModelSeries(manuId, manuName){
      this.vehiculeTab=[];
      this.modelselected="";
      this.manuSelected = { manuName : manuName, manuId : manuId};
      axios
        .post(`${process.env.VUE_APP_API}/catalogue/getModelSeries`, {
          manuId : manuId
        }).then((res)=>{
          this.modelTab = res.data;
        })
    },
    getVehicleIdsByCriteria(modId, modelname){
      this.modelselected = modelname;
      axios
        .post(`${process.env.VUE_APP_API}/catalogue/getVehicleIdsByCriteria`, {
          manuId : this.manuSelected.manuId,
          modId : modId
        }).then((res)=>{
          this.vehiculeTab = res.data;
        })
    },
    getCar(carId){
      this.setSelectionCarId({carId : carId});
      this.setSelectionCarData({manuName: this.manuSelected.manuName, modelselected : this.modelselected});
      this.$router.push("/catalogueHubNew");
    },
    deleteManu(){
      this.manuSelected.manuName = "";
      this.modelselected = "";
    },
    deleteModel(){
      this.modelselected = "";
    }
  },
  beforeMount() {
    this.getManufacturer();
  },
}
</script>
<style scoped>
.plate-immat {
  max-width: 353px;
  height: 52px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  --tw-border-opacity: 1;
  border: 1px solid rgb(45 116 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(45 116 218 / var(--tw-bg-opacity));
}
.plate-number {
  outline: 0;
  height: 100%;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgb(45 116 218 / var(--tw-border-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
}
.plate-flag {
  width: 2rem;
  text-align: center;
  font-size: 2rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
</style>
